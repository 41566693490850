import { useParams } from "react-router-dom";
import Banner from "./components/Banner";
import { useState, useEffect } from "react";
import TabNavigation from "../../exam-details-new/components/TabNavigation";
import axios from "axios";
import config from "../../../util/config";
import Loader from "../../../components/Loader/Loader";
import HtmlContent from "../../../components/HtmlContent";

const tabsWithIds = [
  { name: "About", sectionId: "about" },
  { name: "Eligibility", sectionId: "eligibility" },
  { name: "Benefits", sectionId: "benefits" },
  { name: "How to Apply", sectionId: "how-to-apply" },
  { name: "Required documents", sectionId: "required-documents" },
  { name: "Special Instructions", sectionId: "special-instruction" },
  { name: "FAQ", sectionId: "faq" },
  { name: "Contact", sectionId: "contact" },
];

const tabs = tabsWithIds.map((item) => item.name);

const ScholarshipDetail = () => {
  const { slug } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }

    const element = document.getElementById(tabsWithIds[activeTab].sectionId);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offset = 50;
      window.scrollTo({
        top: elementPosition - offset,
        behavior: "smooth",
      });
    }
  }, [activeTab]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          config.apiUrl + "/api/scholarship-loan/scholarship-detail/" + slug
        );
        setData(response.data?.data);
      } catch (e) {
        console.log("Error in fetching scholarship detail data");
      }
    };
    fetchData();
  }, [slug]);

  if (!data) return <Loader />;

  return (
    <div className="font-roboto text-black">
      <Banner title={data?.scholarshipName} logo={data?.logo} />
      <TabNavigation
        tabs={tabs}
        activeTabIndex={activeTab}
        changeActiveTabIndex={(index: number) => setActiveTab(index)}
      />
      <section className="container flex max-lg:flex-col gap-8 py-12">
        <div className="flex flex-col gap-4">
          <div id="about" className="bg-pink-light p-8">
            <h1 className="text-4xl font-medium text-blue">
              About the Program
            </h1>
            <HtmlContent htmlString={data?.aboutProgram} />
          </div>
          <div id="eligibility" className="p-8">
            <h1 className="text-4xl font-medium text-blue">Eligibility</h1>
            <HtmlContent htmlString={data?.eligibility} />
          </div>
          <div id="benefits" className="bg-pink-light p-8">
            <h1 className="text-4xl font-medium text-blue">Benefits</h1>
            <HtmlContent htmlString={data?.benefits} />
          </div>
          <div id="how-to-apply" className="p-8">
            <h1 className="text-4xl font-medium text-blue">How to Apply</h1>
            <HtmlContent htmlString={data?.howToApply} />
          </div>
          <div id="required-documents" className="bg-pink-light p-8">
            <h1 className="text-4xl font-medium text-blue">
              Required documents
            </h1>
            <HtmlContent htmlString={data?.requiredDocuments} />
          </div>
          <div id="special-instruction" className="p-8">
            <h1 className="text-4xl font-medium text-blue">
              Special Instructions
            </h1>
            <HtmlContent htmlString={data?.specialInstruction} />
          </div>
          <div id="faq" className="bg-pink-light p-8">
            <h1 className="text-4xl font-medium text-blue">FAQ</h1>
            <HtmlContent htmlString={data?.faq} />
          </div>
          <div id="contact" className="p-8">
            <h1 className="text-4xl font-medium text-blue">Contact</h1>
            <HtmlContent htmlString={data?.contact} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ScholarshipDetail;
