import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { triggerCta } from "../store/slices/leadGenSlice";
import { AppDispatch, RootState } from "../store/store";
import { postCtaData } from "../store/thunks/leadGenThunk";

const useLeadGeneration = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const { ctaTriggered } = useSelector((state: RootState) => state.leadGen);

  useEffect(() => {
    if (ctaTriggered) {
      const user = localStorage.getItem("user");
      if (!user) {
        navigate("/secure/login", { state: { sourcePagePath: pathname } });
      } else {
        dispatch(postCtaData());
      }
    }
  }, [ctaTriggered]);

  const invokeLeadGeneration = (leadPageName: string, leadOn: string) => {
    dispatch(triggerCta({ leadPageName, leadOn }));
  };

  return { invokeLeadGeneration };
};

export default useLeadGeneration;
