import React, { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

interface CardData {
  title: string;
  date: string;
  description: string;
  imageUrl: string;
}

const ExploreMore: React.FC = () => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [navBtnDisabled, setNavBtnDisabled] = useState({
    prev: true,
    next: false,
  });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);

  const tabs: string[] = ["Articles"];
  //  const tabs: string[] = ["Articles", "Mentor", "Spot", "Lights", "Webinars"];

  const [activeTab, setActiveTab] = useState<string>("Articles");

  useEffect(() => {
    if (swiperRef && swiperRef.slides) {
      setTotalSlides(swiperRef.slides.length);
    }
  }, [swiperRef]);

  const handleSlideChange = () => {
    if (swiperRef) {
      setCurrentSlide(swiperRef.realIndex || 0);
      setNavBtnDisabled({
        prev: swiperRef.isBeginning,
        next: swiperRef.isEnd,
      });
    }
  };

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const cardData: Record<string, CardData[]> = {
    Articles: [
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a01.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a02.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a03.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a04.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a02.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a03.png",
      },
    ],
    Mentor: [
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a01.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a03.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a02.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a03.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a02.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a04.png",
      },
    ],
    Spot: [
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a04.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a02.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a03.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a02.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a01.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a03.png",
      },
    ],
    Lights: [
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a02.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a03.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a01.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a04.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a03.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a02.png",
      },
    ],
    Webinars: [
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a03.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a02.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a04.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a02.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a03.png",
      },
      {
        title: "Best LearnPress WordPress Theme Collection For 2023",
        date: "Jan 24, 2024",
        description:
          "Looking for an amazing & well-functional LearnPress WordPress Theme?...",
        imageUrl: "/images/mentors/a01.png",
      },
    ],
  };

  const progressBar = (
    <div className="flex gap-4 items-center mt-4">
      <span>{currentSlide + 1}</span>
      <div className="w-24 h-1 bg-gray-300">
        <div
          className="h-full bg-blue-600"
          style={{
            width: totalSlides
              ? ((currentSlide + 1) / totalSlides) * 100 + "%"
              : "0%",
          }}
        ></div>
      </div>
      <span>{totalSlides}</span>
    </div>
  );

  return (
    <div className="container mx-auto my-10">
      <h1 className="text-[#383838] text-5xl leading-10">Explore More</h1>
      <div className="flex justify-between items-center mb-6 border-b border-gray-200">
        {tabs.map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={`px-4 py-2 font-semibold text-lg ${
              activeTab === tab
                ? "text-blue-600 border-b-4 border-blue-600"
                : "text-[#000]"
            }`}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="relative">
        <Swiper
          onSwiper={setSwiperRef}
          onSlideChange={handleSlideChange}
          spaceBetween={20}
          loop={true}
          autoplay={{ delay: 1500, disableOnInteraction: false }}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 0 },
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          modules={[Autoplay, Pagination]}
        >
          {cardData[activeTab].map((card, index) => (
            <SwiperSlide key={index}>
              <div className="bg-white rounded-lg shadow-md overflow-hidden">
                <img
                  src={card.imageUrl}
                  alt={card.title}
                  className="w-full h-40 object-cover"
                />
                <div className="p-4">
                  <h3 className="text-md font-semibold text-gray-900">
                    {card.title}
                  </h3>
                  <p className="text-sm text-[#000] mb-2">{card.date}</p>
                  <p className="text-sm text-[#000]">{card.description}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="flex justify-center items-center mt-4 space-x-4">
          <button className=" p-2 rounded-full border border-[#000] transition shadow-md !block">
            <FiChevronLeft className="text-xl text-[#000]" />
          </button>
          <button className=" p-2 rounded-full border border-[#000] transition shadow-md !block">
            <FiChevronRight className="text-xl text-[#000]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExploreMore;
