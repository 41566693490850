import React, { useState } from "react";
import CareersMechEngineering from "./careers/CareersMechEngineering";
import ScopeMechEngineeringIndia from "./careers/ScopeMechEngineeringIndia";
import UpcomingTrends from "./careers/UpcomingTrends";
import CertificationsMecEngineering from "./careers/CertificationsMecEngineering";

import type { SVGProps } from "react";
import { useCourseDetails } from "../../../screens/course-details/CourseDetailsContext";
import LeadGeneration from "../../LeadGeneration";

export function MaterialSymbolsLightClose(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z"
      ></path>
    </svg>
  );
}
const CourseCareers: React.FC = () => {
  const { data } = useCourseDetails();
  const { scopeOfCourse } = data || {};
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div>
      <div className="bg-[#ECECEC] py-6">
        <div className="container ">
          <div className="flex lg:flex-row flex-col ">
            <div className="w-full lg:w-9/12 flex flex-col">
              <CareersMechEngineering />

              <div id="scope">
                <ScopeMechEngineeringIndia />
              </div>
            </div>
            <div className="w-full lg:w-3/12 hidden md:block">
              <div className="bg-[#FFF1FA] w-full h-full flex justify-center items-center">
                <div
                  className="education-loan"
                  style={{
                    background:
                      "linear-gradient(#FFFFFF, #F4FBFE, #D7F0FB, #B7E4F7, #FFFFFF);",
                  }}
                >
                  <div className="relative ">
                    <img
                      src="/images/course-details/education-finance.png"
                      alt=""
                      className="absolute top-[15px] left-[25px] z-10"
                    />
                    <img
                      src="/images/course-details/education-loan.png"
                      alt=""
                      className="z-0"
                    />
                    <div className="absolute bottom-[1px] left-[80px] z-10">
                      <button
                        onClick={handlePopup}
                        className="flex mx-auto text-white py-1 text-sm px-3 bg-[#173CBA] rounded-full group "
                      >
                        <span className="mr-2">Apply now</span>
                        <div className="bg-[#0C9] text-[#fff] rounded-full p-1 group-hover:translate-x-2 transition-transform duration-300">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9.293 4.293a1 1 0 011.414 0L16 9.586a1 1 0 010 1.414l-5.293 5.293a1 1 0 01-1.414-1.414L13.586 11H4a1 1 0 110-2h9.586L9.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex bg-white pl-6 py-6 lg:flex-row flex-col">
            <div className="lg:w-6/12">
              <div className="flex flex-col pl-3 gap-2">
                <h5 className="text-base font-semibold text-[#4C495C] ">
                  Top Abroad Colleges
                </h5>

                <ul className="list-disc list-inside ml-[6px]">
                  {scopeOfCourse?.students_also_liked.map(
                    (item: string, index: number) => (
                      <li
                        key={index}
                        className="text-base text-[#173CBA] leading-7"
                      >
                        <a href="#">{item}</a>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
            <div className="lg:w-6/12">
              <div className="relative">
                <img
                  src="/images/course-details/apply-for-admission.png"
                  alt=""
                  className="w-full"
                />
              </div>
            </div>
          </div>
          <div className="flex bg-white">
            <div className="w-full lg:w-4/5 flex flex-col gap-12">
              <UpcomingTrends />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#ECECEC] py-4">
        <div className="container ">
          <div className="flex lg:flex-row flex-col ">
            <div className="w-full lg:w-9/12 flex flex-col">
              <CertificationsMecEngineering />
            </div>
            <div className="w-full lg:w-3/12 hidden md:block">
              <div className="bg-[#EFF] w-full h-full flex justify-center items-center">
                <div>
                  <img src="/images/course-details/buy-now.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCareers;
