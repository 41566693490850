import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import Reveal, { Slide } from "react-awesome-reveal";
import { scaleFromBottom } from "./keyframes";
import OurTeamHeadingTextHighlight from "../images/our-team-heading-text-highlight.svg";

import "./OurTeam.scss";

// const teamImages = [
//   "/images/home/team-member-1.jpg",
//   "/images/home/team-member-2.jpg",
//   "/images/home/team-member-3.jpg",
//   "/images/home/team-member-4.jpg",
// ];
const teamImagesPlaceholder = "images/about/male-placeholder-image.png";

const teamMembers = [
  {
    name: "Krishna Gollamudi",
    role: "Director",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: teamImagesPlaceholder,
  },
  {
    name: "K.A Reddy",
    role: "Technical Head",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: teamImagesPlaceholder,
  },
  {
    name: "V V Reddy",
    role: "Frontend Lead",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: teamImagesPlaceholder,
  },
  {
    name: "D Hema",
    role: "Frontend Lead",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: teamImagesPlaceholder,
  },
  {
    name: "Mohammed",
    role: "Backend Lead",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: teamImagesPlaceholder,
  },
  {
    name: "Brahma Reddy",
    role: "Frontend Developer",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: teamImagesPlaceholder,
  },
  {
    name: "Barath J",
    role: "Frontend Developer",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: teamImagesPlaceholder,
  },
  {
    name: "Thiru L",
    role: "Frontend Developer",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: teamImagesPlaceholder,
  },
  {
    name: "Madhurima",
    role: "Database Engineer",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    image: teamImagesPlaceholder,
  },
];

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + "cursor-pointer w-fit"}
      style={{ ...style, zIndex: 100 }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight} size="2x" />
    </div>
  );
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + "cursor-pointer w-fit"}
      style={{ ...style, zIndex: 100 }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronLeft} size="2x" />
    </div>
  );
};

const OurTeam = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    padding: "0 20px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center justify-center lg:flex-row lg:gap-3">
          <Slide>
            <h2 className="text-center heading2">Meet With Our</h2>
          </Slide>
          <Slide direction="right">
            <h2 className="text-center heading2">
              <div className="relative inline-block">
                <div className="relative z-10 my-2 text-primary">
                  Creative Dedicated Team
                </div>
                <img
                  src={OurTeamHeadingTextHighlight}
                  className="absolute left-0 z-0 -translate-y-1/2 top-1/2"
                />
              </div>
            </h2>
          </Slide>
        </div>
        <Slide direction="up" className="flex justify-center">
          <p className="w-full text-center text1 lg:w-3/4">
            Our expert team merges education, career guidance, and technology to
            empower students worldwide. With personalized support and innovative
            tools, we guide learners with confidence through their academic and
            career journeys.
          </p>
        </Slide>
      </div>
      <Reveal
        keyframes={scaleFromBottom}
        className="flex justify-center w-full mt-6 sm:mt-12"
      >
        <Slider {...settings} className="w-full our-team-slider">
          {teamMembers.map((member, index) => (
            <div className="w-1/4 px-1">
              <div className="info-card" key={index}>
                <img
                  className="image md:w-3/4 lg:w-full"
                  src={member.image}
                  alt={member.name}
                />
                <div className="overlay"></div>
                <div className="text-white info">
                  <h4 className="text-xl font-bold">{member.name}</h4>
                  <h5 className="mb-4 text-base font-semibold ">
                    {member.role}
                  </h5>
                  {/* <p className="text3">{member.description}</p> */}
                </div>
                <div className="progress-bar">
                  <div className="fill"></div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </Reveal>
    </>
  );
};

export default OurTeam;
