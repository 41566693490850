import React from "react";

export const FAQSection = () => {
  return (
    <div className="bg-[#FFF9E5] py-14 px-6">
      <div className="container">
        <h2 className="text-3xl font-bold text-[#383838] mb-4">
          Common Questions (FAQS)
        </h2>

        <div className="text-[#747474] text-xl">
          <p>
            Where Mentors Help Students Because It Feels Great to Support Others
          </p>
          <p>Become a Mentor</p>
          <p>Turn your knowledge into impact & income.</p>
        </div>
      </div>
    </div>
  );
};
