import { Description } from "@mui/icons-material";
import { title } from "process";
import React from "react";
import { SVGProps } from "react";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";

export function MingcuteRightLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="10px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
        ></path>
      </g>
    </svg>
  );
}

const UGCourseTable = ({ data }: { data: any }) => {
  return (
    <>
      <div className="flex flex-col bg-[#fff] relative">
        <div className="flex max-md:w-full">
          <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
            UG Courses
          </h3>
        </div>
        <div className="flex flex-col px-2 py-8 md:py-5 md:px-9 gap-6">
          <p className="text-base text-[#4C495C] leading-7">
            {data.description}
          </p>

          <table className="w-full border-collapse border-[#383838] border">
            <tbody>
              {Array.isArray(data.semesters) && data.semesters.length > 0 ? (
                data.semesters.map((semesterObj: any, index: number) => {
                  const [semesterName, subjects] = Object.entries(
                    semesterObj
                  )[0] as [string, string[]];

                  return (
                    <React.Fragment key={index}>
                      <tr className="bg-[#EEF2FF]">
                        <td
                          colSpan={2}
                          className="border-[#383838] border py-2.5 px-5 font-semibold text-base text-[#4C495C]"
                        >
                          {semesterName}
                        </td>
                      </tr>

                      {subjects
                        .reduce((rows, subject, i) => {
                          if (i % 2 === 0) rows.push([]);
                          rows[rows.length - 1].push(subject);
                          return rows;
                        }, [] as string[][])
                        .map((subjectPair, subIndex) => (
                          <tr
                            key={`${index}-${subIndex}`}
                            className={`border-[#383838] border ${
                              subIndex % 2 === 0 ? "bg-[#EEF2FF]" : "bg-[#fff]"
                            }`}
                          >
                            <td className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]">
                              {subjectPair[0]}
                            </td>
                            <td className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]">
                              {subjectPair[1] || ""}
                            </td>
                          </tr>
                        ))}
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={2}
                    className="text-center py-2.5 px-5 text-base text-[#4C495C]"
                  >
                    No semester data available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
const PGCourseTable = ({ data }: { data: any }) => {
  return (
    <>
      <div className="flex flex-col bg-[#fff] relative">
        <div className="flex max-md:w-full">
          <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
            PG Courses
          </h3>
        </div>
        <div className="flex flex-col px-2 py-8 md:py-5 md:px-9 gap-6">
          <p className="text-base text-[#4C495C] leading-7">
            {data.description}
          </p>
          <table className="w-full border-collapse border-[#383838] border">
            <tbody>
              {Array.isArray(data.semesters) && data.semesters.length > 0 ? (
                data.semesters.map((semesterObj: any, index: number) => {
                  const [semesterName, subjects] = Object.entries(
                    semesterObj
                  )[0] as [string, string[]];

                  return (
                    <React.Fragment key={index}>
                      <tr className="bg-[#EEF2FF]">
                        <td
                          colSpan={2}
                          className="border-[#383838] border py-2.5 px-5 font-semibold text-base text-[#4C495C]"
                        >
                          {semesterName}
                        </td>
                      </tr>

                      {subjects
                        .reduce((rows, subject, i) => {
                          if (i % 2 === 0) rows.push([]);
                          rows[rows.length - 1].push(subject);
                          return rows;
                        }, [] as string[][])
                        .map((subjectPair, subIndex) => (
                          <tr
                            key={`${index}-${subIndex}`}
                            className={`border-[#383838] border ${
                              subIndex % 2 === 0 ? "bg-[#EEF2FF]" : "bg-[#fff]"
                            }`}
                          >
                            <td className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]">
                              {subjectPair[0]}
                            </td>
                            <td className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]">
                              {subjectPair[1] || ""}
                            </td>
                          </tr>
                        ))}
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={2}
                    className="text-center py-2.5 px-5 text-base text-[#4C495C]"
                  >
                    No semester data available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const CourseSubjects = () => {
  const { data } = useCourseDetails();
  const { courseTitle = "", course_subjects } = data || {};
  return (
    <>
      <div className="flex flex-col gap-6" id="subjects">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-6">
            <h3 className="flex text-xl lg:text-2xl font-semibold text-[#201F1F] m-0 px-2 py-8 md:py-5 md:px-9 gap-8">
              {`Course Subjects`}
            </h3>
          </div>
          <div className="flex flex-col gap-6">
            <UGCourseTable data={course_subjects.undergraduate} />
          </div>
          <div className="flex flex-col gap-6">
            <PGCourseTable data={course_subjects.postgraduate} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseSubjects;
