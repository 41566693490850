import Banner from "./components/Banner";
import FeaturedScholarships from "./components/FeaturedScholarships";
import DreamstoReality from "./components/DreamstoReality";
import OneStopSolution from "./components/OneStopSolution";

import { useRef, useEffect } from "react";
import { fetchLandingData } from "./scholarshipThunk";
import RightScholarship from "./components/RightScholarship";
import TypeOfScholarships from "./components/TypeOfScholarships";
import InspiringStories from "./components/InspiringStories";
import Mentors from "./components/Mentors";

import WebinarAndArticles from "../../colleges-new/landing/components/WebinarAndArticles";
import CreditScore from "./components/CreditScore";
import Tabs from "./components/Tabs";
import FAQV2 from "../../../components/FAQV2";
import KnowledgeHub from "./components/KnowledgeHub";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import useLeadGeneration from "../../../hooks/useLeadGeneration";

const ScholarshipLanding = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { invokeLeadGeneration } = useLeadGeneration();

  const blogsData = useSelector(
    (state: RootState) => state.collegeLanding.blogsAndArticles
  );

  useEffect(() => {
    dispatch(fetchLandingData());
  }, [dispatch]);

  return (
    <>
      <div>
        <Banner />
        <FeaturedScholarships />
        <DreamstoReality />
        <RightScholarship />
        <TypeOfScholarships />
        <OneStopSolution />
        <div>
          <Tabs handleLeadGen={invokeLeadGeneration}/>
        </div>
        <Mentors />
        <InspiringStories />
        <WebinarAndArticles />
        <KnowledgeHub />
        <CreditScore />
      </div>
    </>
  );
};

export default ScholarshipLanding;
