import React, { useState, useEffect } from "react";
import TrendingTopics from "../components/TrendingTopics";
import BlogsBanner from "../components/BlogsAdBanner";
import BlogsBreadcrumb from "../components/BlogsBreadcrumb";
import BlogsListContainer from "./sections/BlogsListContainer";
import BlogsHeader from "../components/BlogsHeader";
import BannerImag1 from "../../../images/blog-article/blogsAdBanner.png";
import AmritaHorizontal from "../../../images/blog-article/Amrita-Horizontal.png";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import BlogShareFloatingMenu from "../components/BlogShareFloatingMenu";
import usePagination from "../../../components/filter/customHooks/usePagination";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { setCurrentCategoryPageValue } from "../../../store/slices/blogSlice";
import { url } from "inspector";
import config from "../../../util/config";
import { breadcrumbs } from "@material-tailwind/react";
import ViksitBharatScholarshipAd from "../components/ViksitBharatScholarshipAd";
interface Breadcrumb {
  newsTitle: { title: string; slug: string };
  newsSubTopic: any;
  newsTopic: { title: string; slug: string };
  home: { title: string; slug: string };
  [key: string]: any;
}
const BlogsIntermediate = () => {
  const dispatch = useDispatch();
  const hoveredMenu = useSelector((state: RootState) => state.blogSlice);
  //get category from location
  const location = useLocation();

  const { pathname, state } = location;

  const { topicSlug } = useParams<{ topicSlug: string }>();

  // dispatch(
  //   setCurrentCategoryPageValue({ category: category, subCategory: "" })
  // );
  // console.log("location", location);

  // const {
  //   totalPages,
  //   currentPage,
  //   updateTotalCount,
  //   handlePrevPage,
  //   handleNextPage,
  //   goToPage,
  //   handleResetPagination,
  // } = usePagination(10);
  // console.log("totalPages", totalPages);

  //list of blogs under specific category
  const [blogsList, setBlogsList] = useState([]);
  //Page loading
  const [loading, setLoading] = useState<boolean>(true);

  //pagination default values
  // const [paginationValues, setPaginationValues] = useState({
  //   currentPage: 1,
  //   currentPageSize: 5,
  //   totalPages: 10,
  // });
  const bannerImage2 = "/images/blogsBanner.png";
  const [breadCrumb, setBreadCrumb] = useState<Breadcrumb>({
    newsTitle: { title: "", slug: "" },
    newsSubTopic: { title: "", slug: "" },
    newsTopic: { title: "exams", slug: "" },
    home: { title: "home", slug: "/blogs" },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(4);
  const [totalNoOfPages, setTotalNoOfPages] = useState(10);

  const [trendingTopic, setTrendingTopic] = useState([]);
  const [blogRelated, setBlogRelated] = useState([]);
  const [otherNews, setOtherNews] = useState([]);

  //Get All the list of Blogs based on category
  const getBlogsListByCategory = async (topicSlug: string) => {
    setLoading(true);
    let payload = {
      topic: topicSlug,
      subtopic: state?.subTopic || "",
      currentPage: currentPage,
      currentPageSize: currentPageSize,
    };
    await axios
      .post(config.apiUrl + `/api/blogs/topic-details`, payload)
      .then((response) => {
        setTrendingTopic(response?.data?.trendingTopics);
        setBlogsList(response?.data?.details);
        setCurrentPage(response?.data?.details?.currentPage);
        setCurrentPageSize(response?.data?.details?.currentPageSize);
        setTotalNoOfPages(response?.data?.details?.totalNoOfPages);
        setBreadCrumb(response?.data?.breadCrumb);
        setLoading(false);
        setBlogRelated(response?.data?.blogRelated);
        setOtherNews(response?.data?.otherNews);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };
  const handleResetPagination = () => {
    if (currentPage != 1) setCurrentPage(1);
  };

  // useEffect(() => {
  //   getBlogsListByCategory();
  // }, [location, currentPage]);
  // useEffect(() => {
  //   if (prevRoute.pathname != pathname) {
  //     handleResetPagination();
  //   }
  // }, [prevRoute]);

  useEffect(() => {
    if (topicSlug) getBlogsListByCategory(topicSlug);
  }, [state, topicSlug, currentPage]);

  if (loading) {
    return <Loader />;
  }

  // updateTotalCount(paginationValues.totalPages * paginationValues.currentPage);
  const handleNextPage = () => {
    setCurrentPage((prev) => {
      if (prev + 1 <= totalNoOfPages) {
        window.scrollTo(0, 0); // Scroll to the top
        return prev + 1;
      } else {
        return prev;
      }
    });
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => {
      if (prev - 1 > 0) {
        window.scrollTo(0, 0); // Scroll to the top
        return prev - 1;
      } else {
        return prev;
      }
    });
  };

  const paginationProps = {
    currentPage: currentPage,
    totalPages: totalNoOfPages,
    handleNextPage: handleNextPage,
    handlePrevPage: handlePrevPage,
  };
  return (
    <>
      <div className="container grid grid-cols-1 gap-4 py-8 sm:grid-cols-1 md:grid-cols-1 gap-y-4">
        <TrendingTopics trendingTopics={trendingTopic} />
        <ViksitBharatScholarshipAd />
        {/* <div className="hidden py-4 xs:hidden sm:hidden md:block ">
          <div className="flex justify-center w-full">
            <BlogsBanner
              width=""
              height=""
              image={bannerImage2}
              link="https://sageuniversity.in/"
            />
          </div>
        </div> */}
        <BlogsBreadcrumb
          newsTitle={breadCrumb?.newsTitle}
          newsSubTopic={breadCrumb?.newsSubTopic}
          newsTopic={breadCrumb?.newsTopic}
          home={breadCrumb?.home}
          stream={breadCrumb?.stream}
        />
        <BlogsListContainer
          blogsCategory={topicSlug}
          blogsList={blogsList}
          paginationProps={paginationProps}
          blogRelated={blogRelated}
          otherNews={otherNews}
        />
      </div>
    </>
  );
};

export default BlogsIntermediate;
