import React from "react";
import CareerDiscoveryFAQ from "./components/CareerDiscoveryFAQ";
import { CareerDiscoveryBanner } from "./components/CareerDiscoveryBanner";
import { ChildrenFuture } from "./components/ChildrenFuture";
import { IkigaiCareerPath } from "./components/IkigaiCareerPath";
import { HowItWorks } from "./components/HowItWorks";
import DiscoveringGoals from "./components/DiscoveringGoals";

import { IKIGAIProcessSteps } from "./components/IKIGAIProcessSteps";
import { IkigaiDiscoveryProgram } from "./components/IkigaiDiscoveryProgram";
import IkigaiMentorSection from "./components/IkigaiMentorSection";
import { IkigaiPricingPlan } from "./components/IkigaiPricingPlan";

const CareerDiscoveryIkigai = () => {
  return (
    <div>
      <CareerDiscoveryBanner />
      <IkigaiDiscoveryProgram />
      <ChildrenFuture />
      <IkigaiCareerPath />
      <HowItWorks />
      <IKIGAIProcessSteps />
      <DiscoveringGoals />
      <IkigaiMentorSection />
      <IkigaiPricingPlan />
      <CareerDiscoveryFAQ />
    </div>
  );
};

export default CareerDiscoveryIkigai;
