import React, { useRef, useState, useEffect } from "react";
import PredictorResultCollegeCard from "../components/PredictorResultCollegeCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { useNavigate } from 'react-router-dom';
import "./PredictorResultCard.scss";
interface Course {
  lastYearCutoffAiRank: number;
  courseName: string;
}

interface College {
  collegeName: string;
  ownerShip: string;
  courseVo: Course[];
  collegeId:string;
  cutOffData:CutOff[];
}

interface CutOff{
courseName:string;
closingRank:string;
year:string;
openingRank:string;
}

interface CollegesData {
  colleges: College[];
}

interface CustomArrowProps {
  direction: "left" | "right";
  onClick: () => void;
}

const CustomArrow: React.FC<CustomArrowProps> = ({ direction, onClick }) => {
  const leftArrowSVG = (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 7L9 12L14 17" stroke="#7C7C7C" strokeWidth="2" />
      <rect x="0.25" y="0.5" width="23.5" height="23.5" stroke="#7C7C7C" strokeWidth="0.5" />
    </svg>
  );

  const rightArrowSVG = (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 7L15 12L10 17" stroke="#7C7C7C" strokeWidth="2" />
      <rect x="0.25" y="0.5" width="23.5" height="23.5" stroke="#7C7C7C" strokeWidth="0.5" />
    </svg>
  );

  return (
    <button onClick={onClick} className={`swiper-button-${direction}`} aria-label={`${direction} arrow`}>
      {direction === "left" ? leftArrowSVG : rightArrowSVG}
    </button>
  );
};

const CollegeSwiper: React.FC<{cutOff:CutOff[]; college: College; handleCompareChange: (college: College, checked: boolean) => void; isChecked: boolean }> = ({ college, handleCompareChange, isChecked,cutOff }) => {
  const swiperRef = useRef<any>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const isMediumDevice = window.innerWidth >= 768;
  const [isCutoffModalOpen, setCutoffModalOpen] = useState(false);  
  const [cutoffData, setCutoffData] = useState<CutOff[]>([]); 

  const slidesPerView = isMediumDevice?  2 : 1;

  const handleSlideChange = (swiper: any) => {
    setShowLeftArrow(swiper.activeIndex > 0);
    setShowRightArrow(swiper.activeIndex < Math.ceil(college.courseVo.length / slidesPerView) - 1);
  };
  const handleCutOff = () => {
    setCutoffData(cutOff); 
    setCutoffModalOpen(true); 
  };
  return (
    <div  className="w-full bg-white border border-[#A3A2AC] shadow dark:bg-[#A3A2AC] dark:border-[#A3A2AC] mt-4">
      <div>
        <div className="flex flex-col gap-3 p-4 border-b border-[#A3A2AC]">
          <span className="college-name">{college.collegeName}</span>
          <span className="ownership">Ownership: {college.ownerShip}</span>
        </div>
        <div className="p-4">
          <span className="goodchance">Your chances are<span className="good"> GOOD</span> in {college.courseVo.length} Courses</span>
          <Swiper
            spaceBetween={10}
            slidesPerView={slidesPerView}
            pagination={{ clickable: true }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            onSlideChange={handleSlideChange}
          >
            {college.courseVo.map((course, courseIndex) => (
              <SwiperSlide key={courseIndex}>
                <PredictorResultCollegeCard course={course} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="flex justify-end gap-2 mr-4 mt-2">
          {showLeftArrow && (
            <CustomArrow
              direction="left"
              onClick={() => {
                swiperRef.current?.slidePrev();
              }}
            />
          )}
          {showRightArrow && (
            <CustomArrow
              direction="right"
              onClick={() => {
                swiperRef.current?.slideNext();
              }}
            />
          )}
        </div>
        <div className="flex flex-row p-4 my-4">
          <div className="flex gap-2 grow">
            <button onClick={handleCutOff} className="cutoff-button border border-gray-400 px-2 py-1">View Cutoffs</button>
             <a
        href="/pdf/sample.pdf" 
        download="B.tech.pdf" className=" border border-gray-400 px-2 py-1 text-white bg-[#173CBA]">Download Brochure</a>
          </div>
          <div className="flex items-center gap-2 compare px-2 md:px-0">
            <input
              type="checkbox"
              checked={isChecked}
              className="scale-[120%]"
              onChange={(e) => handleCompareChange(college, e.target.checked)}
            />
            <label>Add to Compare</label>
          </div>
        </div>
      </div>
      {isCutoffModalOpen && (
        <CutoffModal cutOff={cutoffData} onClose={() => setCutoffModalOpen(false)} />
      )}
    </div>
  );
};

const CompareModal: React.FC<{ colleges: College[]; onRemoveCollege: (collegeName: string) => void; onClose: () => void }> = ({ colleges, onRemoveCollege, onClose }) => {
  const navigate = useNavigate();
  const [collegeIds, setCollegeIds] = useState<string[]>([]);

  useEffect(() => {
    const ids = colleges.map((college) => college.collegeId);
    setCollegeIds(ids);
  }, [colleges]);

  const handleCompare = () => {
    navigate('/college-compare', { state: { ids: collegeIds } });
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center w-[100%] h-[100%]">
      <div className="absolute inset-0 bg-black opacity-50 z-40"></div>

      <div className="relative z-50 bg-white rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3">
        <div className="flex bg-[#3a4d95] items-center justify-between p-2">
          <h2 className="text-3xl text-white font-bold text-center w-full">Compare Colleges</h2>
          <button onClick={onClose} className="text-white mr-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="goodagain m-4">You can Add up to 4 colleges</div>
        <ul className="mb-4">
          {colleges.map((college) => (
            <li key={college.collegeName} className="college-name p-2 border-b border-gray-300 flex justify-between items-center mb-2">
              <span>{college.collegeName}</span>
              <button
                onClick={() => onRemoveCollege(college.collegeName)}
                className="text-red-500 hover:text-red-700"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 3h6M10 7v10M14 7v10M4 7h16M5 7V5a1 1 0 011-1h12a1 1 0 011 1v2M6 7v12a2 2 0 002 2h8a2 2 0 002-2V7" />
                </svg>
              </button>
            </li>
          ))}
        </ul>
        <div className="flex justify-center m-2">
          <button onClick={handleCompare} className="mt-2 bg-[#3a4d95] text-white px-4 py-2 rounded">
            Compare Now
          </button>
        </div>
      </div>
    </div>
  );
};

const CutoffModal: React.FC<{ cutOff: CutOff[]; onClose: () => void }> = ({ cutOff, onClose }) => {
  const [selectedCourse, setSelectedCourse] = useState<string | null>(null);

  const handleCourseChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCourse(event.target.value);
  };

  const selectedCutOffs = cutOff.filter((course) => course.courseName === selectedCourse);

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center w-full h-full">
      <div className="absolute inset-0 bg-black opacity-50 z-40"></div>

      <div className="relative z-50 bg-white rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3 max-h-[80vh] overflow-hidden">
        <div className="flex bg-[#3a4d95] items-center justify-between p-2">
          <h2 className="text-3xl text-white font-bold text-center w-full">Round wise CutOffs</h2>
          <button onClick={onClose} className="text-white mr-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="m-4">
          <label htmlFor="course" className="block text-lg font-semibold mb-2">Select Course:</label>
          <select
            id="course"
            onChange={handleCourseChange}
            className="w-full p-2 border rounded-lg"
            value={selectedCourse || ''}
          >
            <option value="">Select a course</option>
            {Array.from(new Set(cutOff.map((course) => course.courseName))).map((uniqueCourseName) => (
  <option key={uniqueCourseName} value={uniqueCourseName}>
    {uniqueCourseName}
  </option>
))}


          </select>
        </div>

        {selectedCutOffs && (
  <div className="m-4 w-full h-[40vh] overflow-y-auto"> 
    <h3 className="text-xl font-semibold mb-2">{selectedCutOffs[0]?.courseName} Cutoffs</h3>
    <table className="min-w-full bg-white">
      <thead>
        <tr>
          <th className="px-4 py-2 border">Year</th>
          <th className="px-4 py-2 border">Opening Cutoff</th>
          <th className="px-4 py-2 border">Closing Cutoff</th>
        </tr>
      </thead>
      <tbody>
        {selectedCutOffs.map((cutoff, idx) => (
          <tr key={idx}>
            <td className="px-4 py-2 border">{cutoff.year}</td>
            <td className="px-4 py-2 border">{cutoff.openingRank}</td>
            <td className="px-4 py-2 border">{cutoff.closingRank}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}

      </div>
    </div>
  );
};



const PredictorResultCard: React.FC<{ data?: CollegesData }> = ({ data }) => {
  const [compareList, setCompareList] = useState<College[]>([]);
  const [showModal, setShowModal] = useState(false);
  const handleCompareChange = (college: College, checked: boolean) => {
    if (checked) {
      if (compareList.length >= 4) {
        alert("You can only compare up to 4 colleges.");
        return;
      }
      setCompareList((prev) => [...prev, college]);
    } else {
      setCompareList((prev) => prev.filter((item) => item.collegeName !== college.collegeName));
    }
  };

  const handleRemoveCollege = (collegeName: string) => {
    setCompareList((prev) => prev.filter((college) => college.collegeName !== collegeName));
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (compareList.length >= 2) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [compareList.length]);

  return (
    <div>
      {data?.colleges.map((college, index) => (
        <CollegeSwiper
          key={index}
          cutOff={college?.cutOffData}
          college={college}
          handleCompareChange={handleCompareChange}
          isChecked={compareList.some((item) => item.collegeName === college.collegeName)}
        />
      ))}

      {showModal && (
        <CompareModal
          colleges={compareList}
          onRemoveCollege={handleRemoveCollege}
          onClose={handleModalClose}
        />
      )}
    </div>
  );
};

export default PredictorResultCard;
