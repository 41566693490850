import { Swiper, SwiperSlide } from "swiper/react";
import React, { useCallback, useState } from "react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./CareerDetailsStyles.scss";
import { EpRight } from "../study-abroad-landing/Banner/Banner";
import LeadGeneration from "../LeadGeneration";

const CollegesDataScience: React.FC<{ collegesDataScience: any }> = ({
  collegesDataScience,
}) => {
  const [swiperRef, setSwiperRef] = useState<any>(null);

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  if (!collegesDataScience) return null;
  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  return (
    <section>
      <div className="container mx-auto">
        <div className="bg-white relative">
          <div className="flex flex-col gap-6">
            <div className="flex max-md:flex-col max-md:w-full">
              <h3 className="text-xl max-md:text-center lg:text-2xl font-semibold text-white bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0">
                {collegesDataScience.title}
              </h3>
            </div>
            <div className="flex flex-col lg:flex-row gap-5 p-7 lg:p-8">
              <div className="w-full lg:w-[92%]">
                <Swiper
                  pagination={{ clickable: true }}
                  onSwiper={setSwiperRef}
                  spaceBetween={12}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  className="TopEntranceExamsMySwiper"
                  loop={true}
                  navigation
                  modules={[Navigation, Autoplay]}
                  breakpoints={{
                    640: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    1024: { slidesPerView: 5 },
                  }}
                >
                  {collegesDataScience.description.map(
                    (college: any, index: number) => (
                      <SwiperSlide
                        key={index}
                        className="border border-[#E0E0E0] rounded-2xl bg-[#FBFBFB] flex flex-col gap-6"
                      >
                        <div className="w-full h-36 overflow-hidden">
                          <img
                            src={college.imageUrl}
                            alt={college.collegeName}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="flex flex-col text-left items-start justify-start gap-4 p-4">
                          <div className="flex flex-col gap-1">
                            <span className="text-sm text-blue font-medium bg-blue-2 bg-opacity-10 rounded-full py-1 px-3">
                              Best in Placements
                            </span>
                            <h3 className="font-semibold text-base text-black line-clamp-1 mt-1">
                              {college.collegeName}
                            </h3>
                            <p className="text-[#4F4F4F] text-xs font-medium">
                              {college.location}
                            </p>
                          </div>
                          <div className="flex flex-col gap-1">
                            <p className="text-sm text-green font-medium">
                              100% Placement Record
                            </p>
                            <p className="text-sm text-gray font-medium">
                              Highest CTC {college.highCtc}
                            </p>
                          </div>
                          <button
                            onClick={handlePopup}
                            className="bg-green hover:bg-blue button-styles py-3.5 px-3 green-arrow-button flex flex-row items-center justify-center gap-2 text-white w-full"
                          >
                            {" "}
                            Apply Now <EpRight className="green-arrow-icon" />
                          </button>
                        </div>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </div>

              <div className="flex lg:flex-col gap-4 items-center justify-center swiper-buttons relative px-12 w-full lg:w-[8%]">
                <button
                  onClick={handlePrevious}
                  className="text-white bg-blue p-4 rounded-full text-base"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="fill-current text-white"
                      d="M13.7917 5.45842H3.375L6.80208 2.03134C6.89972 1.9345 6.97721 1.8193 7.0301 1.69236C7.08298 1.56542 7.11021 1.42927 7.11021 1.29176C7.11021 1.15425 7.08298 1.01809 7.0301 0.891158C6.97721 0.764222 6.89972 0.649012 6.80208 0.552176C6.60691 0.358165 6.3429 0.249268 6.06771 0.249268C5.79252 0.249268 5.5285 0.358165 5.33333 0.552176L0.864584 5.03134C0.473346 5.42024 0.252326 5.94844 0.25 6.50008V6.50008C0.255069 7.04811 0.47588 7.57207 0.864584 7.95842L5.33333 12.4376C5.43046 12.534 5.54562 12.6104 5.67226 12.6623C5.7989 12.7142 5.93452 12.7407 6.07139 12.7402C6.20826 12.7398 6.3437 12.7123 6.46996 12.6595C6.59623 12.6067 6.71085 12.5295 6.80729 12.4324C6.90373 12.3352 6.9801 12.2201 7.03203 12.0934C7.08396 11.9668 7.11044 11.8312 7.10995 11.6943C7.10947 11.5574 7.08203 11.422 7.02921 11.2957C6.97638 11.1695 6.89921 11.0549 6.80208 10.9584L3.375 7.54175H13.7917C14.0679 7.54175 14.3329 7.432 14.5282 7.23665C14.7236 7.0413 14.8333 6.77635 14.8333 6.50008C14.8333 6.22382 14.7236 5.95887 14.5282 5.76352C14.3329 5.56817 14.0679 5.45842 13.7917 5.45842Z"
                    />
                  </svg>
                </button>
                <button
                  onClick={handleNext}
                  className="text-white bg-blue p-4 rounded-full text-base"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="curentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="fill-current text-white"
                      d="M1.20833 5.45842H11.625L8.19792 2.03134C8.10028 1.9345 8.02279 1.8193 7.9699 1.69236C7.91702 1.56542 7.88979 1.42927 7.88979 1.29176C7.88979 1.15425 7.91702 1.01809 7.9699 0.891158C8.02279 0.764222 8.10028 0.649012 8.19792 0.552176C8.39309 0.358165 8.6571 0.249268 8.93229 0.249268C9.20748 0.249268 9.4715 0.358165 9.66667 0.552176L14.1354 5.03134C14.5267 5.42024 14.7477 5.94844 14.75 6.50008V6.50008C14.7449 7.04811 14.5241 7.57207 14.1354 7.95842L9.66667 12.4376C9.56954 12.534 9.45438 12.6104 9.32774 12.6623C9.2011 12.7142 9.06548 12.7407 8.92861 12.7402C8.79174 12.7398 8.6563 12.7123 8.53004 12.6595C8.40377 12.6067 8.28915 12.5295 8.19271 12.4324C8.09627 12.3352 8.0199 12.2201 7.96797 12.0934C7.91604 11.9668 7.88956 11.8312 7.89005 11.6943C7.89053 11.5574 7.91797 11.422 7.97079 11.2957C8.02362 11.1695 8.10079 11.0549 8.19792 10.9584L11.625 7.54175H1.20833C0.932065 7.54175 0.667112 7.432 0.471762 7.23665C0.276411 7.0413 0.166666 6.77635 0.166666 6.50008C0.166666 6.22382 0.276411 5.95887 0.471762 5.76352C0.667112 5.56817 0.932065 5.45842 1.20833 5.45842Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CollegesDataScience;
