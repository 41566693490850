import React, { useState } from "react";
import { BannerSlides } from "./BannerSlides";
import Modal from "../../../components/Modal";
import QuizWidget, { QuizQuestion } from "../../../components/courses/QuizWidget";
import { useNavigate, useLocation } from "react-router-dom";

import type { SVGProps } from "react";
import LeadGeneration from "../../LeadGeneration";
const quizQuestions : QuizQuestion[] = [
  {
    question: "What area are you most interested in knowing?",
    options: [
      { answer: "Student Life" },
      { answer: "Career planning" },
      { answer: "Health and wellness" },
      { answer: "College admissions" },
      { answer: "Financial aid options" },
      { answer: "Scholarship opportunities" },
    ],
  },
  {
    question: "What challenges are you currently facing regarding your education?",
    options: [
      { answer: "Choosing the right stream or college" },
      { answer: "Balancing academics and personal life" },
      { answer: "Finding internship opportunities" },
      { answer: "Preparing for entrance exams or interviews" },
    ],
  },
  {
    question: "What industries are you most interested in exploring?",
    options: [
      { answer: "Technology" },
      { answer: "Healthcare" },
      { answer: "Finance" },
      { answer: "Arts and Media" },
      { answer: "Other" },
    ],
  },
  {
    question: "What qualities do you value most in a career mentor?",
    options: [
      { answer: "Experience in my desired industry" },
      { answer: "Strong communication skills" },
      { answer: "A supportive and encouraging attitude" },
      { answer: "Availability and responsiveness" },
    ],
  },
  {
    question: "What specific career information are you most interested in?",
    options: [
      { answer: "Salary expectations for different roles" },
      { answer: "Industry trends and future job growth" },
      { answer: "Job levels and career progression" },
      { answer: "Work-life balance and company culture" },
    ],
  },
  {
    question: "How familiar are you with salary ranges in your field of interest?",
    options: [
      { answer: "Very familiar" },
      { answer: "Somewhat familiar" },
      { answer: "Not very familiar" },
      { answer: "Completely unfamiliar" },
    ],
  },
  {
    question: "How do you prefer to learn about salary trends and job levels?",
    options: [
      { answer: "One-on-one discussions with a mentor" },
      { answer: "Webinars or workshops" },
      { answer: "Online articles and resources" },
      { answer: "Networking events" },
    ],
  },
  {
    question: "What’s your preferred communication style?",
    options: [
      { answer: "In-person meetings" },
      { answer: "Virtual consultations" },
      { answer: "Text or chat support" },
      { answer: "Email correspondence" },
      { answer: "Group workshops" },
      { answer: "Informal discussions" },
    ],
  },
];

export function LineMdArrowRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g transform="translate(24 0) scale(-1 1)">
        <g
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path strokeDasharray={20} strokeDashoffset={20} d="M21 12H3.5">
            <animate
              fill="freeze"
              attributeName="stroke-dashoffset"
              dur="0.3s"
              values="20;0"
            ></animate>
          </path>
          <path
            strokeDasharray={12}
            strokeDashoffset={12}
            d="M3 12L10 19M3 12L10 5"
          >
            <animate
              fill="freeze"
              attributeName="stroke-dashoffset"
              begin="0.3s"
              dur="0.2s"
              values="12;0"
            ></animate>
          </path>
        </g>
      </g>
    </svg>
  );
}
const bannerData = {
  title: "Find the Best Career For You",
  description:
    "Discover how you match with more than 800 careers based on your personality, interests, experience, and ambitions.",
  shortDescription: "Reflect who you are with our career quiz",
  buttonText: "Map My Career in 2 Mins",
};

interface BannerProps {
  bannerInfo: {
    image: string;
    title: string;
    description: string;
  }[];
}

export const Banner: React.FC<BannerProps> = ({ bannerInfo }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const user = localStorage.getItem("user");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const handleMapPopup = () => {
  
  
            if (!user) {
              navigate("/secure/login", { state: { sourcePagePath: pathname } });
            }
            else{
  
              setIsPopupVisible(!isPopupVisible);
            }
          }
      
  
  return (
    <div
      className="py-16"
      style={{
        background: "linear-gradient(180deg, #EDFCFF 0%, #89C9ED 100%)",
      }}
    >
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row items-center justify-center gap-14">
          <div className="w-full lg:w-1/4">
            <div className="flex flex-col gap-12">
              <div className="flex flex-col gap-5">
                <h2 className="text-5xl leading-[55px] font-bold text-blue">
                  {bannerData.title}
                </h2>
                <p className="text-base mt- text-black">
                  {bannerData.description}
                </p>
              </div>
              <div className="flex flex-col gap-5">
                <p className="text-sm mt-3 text-black">
                  {bannerData.shortDescription}
                </p>
                <div className="flex">
                  <button
                    onClick={handleMapPopup}
                    className="bg-green arrow-btn text-white py-1.5 px-3 hover:bg-green  text-base font-bold flex flex-wrap flex-row w-auto gap-3 items-center cursor-pointer button-styles"
                  >
                    {bannerData.buttonText}
                    <LineMdArrowRight className="arrow-icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-3/4">
            <BannerSlides bannerInfo={bannerInfo} />
          </div>
        </div>
      </div>
      <MapMyCareerPopup
      isOpen={isPopupVisible}
      onClose={() => {
        setIsPopupVisible(false);
      }}
    />
    </div>
     
  );
};

const MapMyCareerPopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
   <QuizWidget
      questions={quizQuestions}
      destinationLink="" />    </Modal>
  );
};