import React from "react";
import ScholorshipBanner from "./components/ScholorshipBanner";
import ScholarshipFAQ from "./components/ScholorshipFAQ";
import SclorshipObjective from "./components/SclorshipObjective";
import ScholarshipDisbursement from "./components/ScholarshipDisbursement";
import CollegeMentorServices from "./components/CollegeMentorServices";
import ScholorshipApply from "./components/ScholorshipApply";
import ScholorshipEligibility from "./components/ScholorshipEligibility";
import IndiasTopInstitutions from "./components/IndiasTopInstitutions";
import ScholorshipCategoryAward from "./components/ScholorshipCategoryAwards";

const ScholarshipTest = () => {
  return (
    <div className="text-black">
      <ScholorshipBanner />
      <IndiasTopInstitutions />
      <SclorshipObjective />
      <ScholorshipEligibility />
      <ScholorshipCategoryAward />
      <ScholarshipDisbursement />
      <CollegeMentorServices />
      <ScholorshipApply />
      <ScholarshipFAQ />
    </div>
  );
};

export default ScholarshipTest;
