import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Banner.scss";
import BannerBgIcons from "../../images/courses/banner-icons.svg";
import BannerImage from "../../images/courses/Banner/BannerImage.png";
import Rectangle from "../../images/courses/Banner/Rectangle.png";
import Vector7 from "../../images/courses/Banner/Vector 7.svg";
import Vector8 from "../../images/courses/Banner/Vector 8.png";
import Vector9 from "../../images/courses/Banner/Vector 9.svg";
import Vector10 from "../../images/courses/Banner/Vector 10.svg";
import Framesvg from "../../images/courses/Banner/Frame.svg";
import Imageleft from "../../images/courses/Banner/Ellipse 45.png";
import Imageleftcenter from "../../images/courses/Banner/Ellipse 46.png";
import Imageright from "../../images/courses/Banner/Ellipse 47.png";
import Imagelefttwo from "../../images/courses/Banner/Ellipse 45.png";
import Imageleftcentertwo from "../../images/courses/Banner/Ellipse 46.svg";
import Imagerighttwo from "../../images/courses/Banner/Ellipse 47.png";
import Imagecenter from "../../images/courses/Banner/Ellipse 48.png";
import Group from "../../images/courses/Banner/Group.svg";
import Imagecentertwo from "../../images/courses/Banner/Ellipse 48-2.svg";
import { coursesMockData } from "./Courses.mock";

interface Course {
  courseName: string;
  courseUrl: string;
}

const BannerContent = [
  {
    title:
      "Unlock your Potential: Navigate World Class Courses and Universities with",
    highlightClg: "College",
    highlightMen: "Mentor",
    caption:
      "Unlock endless opportunities with College Mentor. Explore world-class courses and universities to unleash your full potential.",
    BannerImg: BannerImage,
  },
];

const Banner: React.FC = () => {
  const [useImageOne, setUseImageOne] = useState(true);
  const [key, setKey] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedCourseUrl, setSelectedCourseUrl] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Course[]>([]);
  const searchBoxRef = useRef<HTMLDivElement | null>(null);
  const [remainingTime, setRemainingTime] = useState(10000);
  const nextKeyRef = useRef(key);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const startTimeRef = useRef<number | null>(null);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue: string = event.target.value;
    setSearchTerm(inputValue);
    if (inputValue === "") {
      setSuggestions([]);
    } else {
      const filteredSuggestions: any = coursesMockData.filter((suggestion) =>
        suggestion.courseName.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    }
  };

  const handleSuggestionClick = (suggestion: Course) => {
    setSearchTerm(suggestion.courseName);
    setSelectedCourseUrl(suggestion.courseUrl);
    setSuggestions([]);
  };

  const handleClearInput = () => {
    setSearchTerm("");
    setSuggestions([]);
  };

  const handleSearchCourse = () => {
    if (selectedCourseUrl) {
      window.location.href = selectedCourseUrl;
    }
  };

  const startInterval = (time: number) => {
    startTimeRef.current = Date.now();
    timeoutRef.current = setTimeout(() => {
      setUseImageOne((prev) => !prev);
      nextKeyRef.current += 1;
      setKey(nextKeyRef.current);
      setRemainingTime(10000);
      startInterval(9000);
    }, time);
  };

  useEffect(() => {
    startInterval(remainingTime);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      if (startTimeRef.current !== null) {
        const elapsedTime = Date.now() - startTimeRef.current;
        setRemainingTime((prevTime) => prevTime - elapsedTime);
      }
    }
  };

  const handleMouseLeave = () => {
    startInterval(remainingTime);
  };

  return (
    <div
      className="flex flex-col items-center justify-center pt-14 lg:pt-20 relative"
      style={{
        backgroundImage: "linear-gradient(#EDFCFF, #89C9ED)",
        backgroundSize: "cover",
      }}
    >
      <div className="container mx-auto">
        <img
          src={BannerBgIcons}
          alt="Banner Background Icons"
          className="absolute top-0 left-0 w-full h-full z-10"
        />
        <div className="flex flex-col xl:flex-row gap-5 relative z-20 overflow-visible">
          <div className="banner-search-box w-full xl:w-1/2 flex flex-col gap-5 pb-10 md:pb-20 xl:pb-24">
            {BannerContent.map((content) => (
              <div key={content.title}>
                <h2 className="font-roboto text-4xl leading-[3rem] lg:text-[40px] font-bold lg:leading-[58px] text-[--paragraph-color]">
                  {content.title}{" "}
                  <span className="bg-[--primary-color] text-white p-1 uppercase">
                    {content.highlightClg}
                  </span>
                  <span className="bg-[--secondary-color] text-white p-1 uppercase">
                    {content.highlightMen}
                  </span>
                </h2>
                <p className="mt-4 lg:mt-10  font-normal text-base leading-[1.6875rem] lg:text-lg text-[--paragraph-color]">
                  {content.caption}
                </p>
              </div>
            ))}
            <div className="w-full lg:w-[468px] flex gap-5 relative text-[--primary-color] mt-5 lg:mt-10">
              <div className="w-full relative">
                <svg
                  width="23"
                  height="23"
                  className="absolute top-1/2 left-5 transform -translate-y-1/2"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.3203 14.4873C17.3941 13.021 18.0364 11.2198 18.0364 9.26722C18.0364 4.37929 14.0594 0.402344 9.17148 0.402344C4.28355 0.402344 0.306641 4.37929 0.306641 9.26722C0.306641 14.1551 4.28359 18.1321 9.17152 18.1321C11.1241 18.1321 12.9255 17.4897 14.3918 16.4159L20.1994 22.2235L22.1279 20.295C22.1279 20.2949 16.3203 14.4873 16.3203 14.4873ZM9.17152 15.4044C5.78725 15.4044 3.03431 12.6515 3.03431 9.26722C3.03431 5.88295 5.78725 3.13001 9.17152 3.13001C12.5558 3.13001 15.3087 5.88295 15.3087 9.26722C15.3087 12.6515 12.5557 15.4044 9.17152 15.4044Z"
                    fill="currentColor"
                  />
                </svg>
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "100%" }}
                >
                  <div style={{ width: "100%" }}>
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      placeholder="Search your dream course"
                      className="w-full py-[14px] pl-14 pr-16 lg:pr-[164px] rounded-full focus:outline-none focus:border-[#00A3FF] text-black text-xs md:text-base"
                    />
                  </div>
                </form>
                <button
                  onClick={handleSearchCourse}
                  className="arw-button absolute top-1/2 right-0 transform -translate-y-1/2 bg-[--primary-color] text-white md:py-3 px-3 rounded-full right-1"
                  style={{
                    background:
                      "linear-gradient(90deg, #173CBA 0%, #06AFA9 100%)",
                  }}
                >
                  <span className="min-max-xss:hidden min-max-xs:hidden min-max-sm:hidden min-max-md:hidden">
                    Search Course
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="currentColor"
                    className="arrow-icon m-2"
                  >
                    <path
                      d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
                {searchTerm && (
                  <button
                    className={` absolute right-0 top-1/2 transform -translate-y-1/2 mr-14 lg:mr-[160px] bg-white`}
                    onClick={handleClearInput}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      fill="var(--primary-color)"
                      width={14}
                      height={14}
                    >
                      <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                    </svg>
                  </button>
                )}
              </div>

              {suggestions.length > 1 && (
                <div
                  className={`absolute left-0 w-full py-6 top-full z-10 ${
                    suggestions.length > 0
                      ? "animate__animated animate__fadeIn bg-white searchSuggestion"
                      : ""
                  }`}
                >
                  <hr className="border-gray-200 py-2" />
                  <div className="min-h-[200px] max-h-[300px] overflow-y-auto scrollbar-custom">
                    <ul>
                      {suggestions.map((suggestion, index) => (
                        <div key={index}>
                          <li
                            className="cursor-pointer text-sm text-[#333] font-medium py-2 px-4 hover:bg-gray-100 flex flex-row justify-between"
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            <span>{suggestion.courseName}</span>
                          </li>
                        </div>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            key={key}
            className="flex w-full xl:w-1/2 h-auto justify-center xl:justify-end items-end relative overflow-visible mt-10"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="relative">
              <img
                src={BannerContent[0].BannerImg}
                alt="Banner Image"
                className="w-full h-auto object-contain"
              />
              <div
                className="Bannerearth flex flex-col absolute object-contain max-sm:-top-8 -top-14 left-[50%]"
                style={{ transform: "translate(-50%, 0)" }}
              >
                <div className="relative w-full max-w-xs lg:max-w-md flex flex-col min-max-xss:scale-[.4] min-max-xs:scale-50 min-max-sm:scale-[0.6] min-max-md:scale-90 min-max-lg:scale-95 min-max-xl:scale-75 2xl:scale-90">
                  <img
                    src={Rectangle}
                    alt="logo-rect"
                    className="relative w-full h-auto z-10 initial-blink"
                  />
                  <img
                    src={Framesvg}
                    alt="frame"
                    className="absolute top-2 left-2 w-3/4 h-auto translate-y-[20%] translate-x-[10%] z-20 initial-blink"
                  />
                  <img
                    src={Vector7}
                    alt="left-dots"
                    className="absolute opacity-0 w-full h-auto top-14 -translate-x-[80%] scale-90 vector1-fade-in"
                  />
                  <img
                    src={Vector9}
                    alt="near left"
                    className="absolute opacity-0 vector2-fade-in translate-y-[40%]"
                  />
                  <img
                    src={Vector8}
                    alt="center"
                    className="absolute right-[45%] opacity-0 scale-90 translate-y-[40%] vector-fade-in"
                  />
                  <img
                    src={Vector10}
                    alt="right-dots"
                    className="absolute opacity-0 w-full h-auto top-14 translate-x-[80%] scale-90 vector3-fade-in"
                  />
                  <div className="absolute opacity-0 top-60 scale-90 -translate-x-[170%] image1-fade-in group flex">
                    <img
                      src={useImageOne ? Imageleft : Imagelefttwo}
                      alt="left"
                      className="peer mb-2"
                    />
                    <div className="translate-x-6 opacity-0 peer-hover:opacity-100 transition-opacity duration-300">
                      <span className="image-text">
                        {useImageOne ? "VIT" : "SSN"}
                      </span>
                    </div>
                  </div>
                  <div className="absolute opacity-0 top-48 -translate-x-[55%] image2-fade-in group flex">
                    <img
                      src={useImageOne ? Imageleftcenter : Imageleftcentertwo}
                      alt="center"
                      className="peer mb-2"
                    />
                    <div className="translate-x-3 opacity-0 peer-hover:opacity-100 transition-opacity duration-300">
                      <span className="image-text">
                        {useImageOne ? "SRM" : "GITAM"}
                      </span>
                    </div>
                  </div>
                  <div className="absolute opacity-0 top-60 scale-90 right-0 translate-x-[170%] image3-fade-in group flex">
                    <img
                      src={useImageOne ? Imageright : Imagerighttwo}
                      alt="right"
                      className="peer mb-2"
                    />
                    <div className="translate-x-4 opacity-0 peer-hover:opacity-100 transition-opacity duration-300">
                      <span className="image-text">
                        {useImageOne ? "Amrita" : "RMK"}
                      </span>
                    </div>
                  </div>
                  <img
                    src={useImageOne ? Imagecenter : Imagecentertwo}
                    alt="center"
                    className="absolute opacity-0 top-52 translate-x-[50%] center-fade-in"
                  />
                  <img
                    src={Group}
                    alt="quotes"
                    className="absolute opacity-0 top-40 translate-x-[120%] group-fade-in"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
