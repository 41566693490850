import React from "react";
import CampusLatestUpdates from "../campuslife/CampusLifeLatestUpdates";
import FacilitySection from "../campuslife/FacilitySections";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";

interface ClubItem {
  title: string;
  description: string;
}

interface College {
  image: string;
  name: string;
  logo: string;
  location: string;
  title: string;
}

interface CollegeLifeTabProps {
  collegeLifeData: {
    key_0?: {
      title: string;
      description: string;
    };
    key_1?: { title: string; description: string };
    key_2?: { title: string; description: string };
    key_3?: { title: string; description: string };
    key_4?: {
      title: string;
      description: string;
      array: ClubItem[];
    };
    similarColleges: College[];
  };
}

const CollegeLifeTab: React.FC<CollegeLifeTabProps> = ({ collegeLifeData }) => {
  return (
    <div className="py-10 bg-[#f4f4f4]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-9/12">
            {collegeLifeData.key_0 && (
              <CampusLatestUpdates
                title={collegeLifeData.key_0.title}
                description={collegeLifeData.key_0.description}
              />
            )}
          </div>
        </div>
      </div>

      <FacilitySection
        sections={[
          collegeLifeData.key_1,
          collegeLifeData.key_2,
          collegeLifeData.key_3,
          collegeLifeData.key_4,
        ]}
      />

      {collegeLifeData.similarColleges &&
      collegeLifeData.similarColleges.length > 0 ? (
        <SimilarEngineeringColleges
          colleges={collegeLifeData.similarColleges}
        />
      ) : (
        <p className="text-center text-gray-600">
          No similar colleges available at the moment.
        </p>
      )}
    </div>
  );
};

export default CollegeLifeTab;

{
  /* {collegeLifeData.key_4 && (
        <div className="py-6">
          <h2 className="text-3xl font-bold mb-6">
            {collegeLifeData.key_4.title}
          </h2>
          <div
            className="text-lg mb-8"
            dangerouslySetInnerHTML={{
              __html: collegeLifeData.key_4.description,
            }}
          />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {collegeLifeData.key_4.array.map((club, index) => (
              <div
                key={`${club.title}-${index}`}
                className="bg-white p-6 rounded-lg shadow-md border border-gray-200"
              >
                <h3 className="text-xl font-semibold mb-4">{club.title}</h3>
                <div
                  className="text-base text-gray-700"
                  dangerouslySetInnerHTML={{ __html: club.description }}
                />
              </div>
            ))}
          </div>
        </div>
      )} */
}
