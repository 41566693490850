import axios from "axios";
import React, { useState, useEffect } from "react";

const IndiaRankingOverall = () => {
  const [colleges, setColleges] = useState<any[]>([]);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    const fetchColleges = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://my.api.mockaroo.com/overall.json?key=9bef46d0"
        );
        setColleges(response.data.colleges);
      } catch (error) {
        setError("Failed to load data.");
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchColleges();
  }, []);

  const filteredColleges = colleges.filter((college) =>
    college.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredColleges.length / entriesPerPage);
  const startIndex = (currentPage - 1) * entriesPerPage;
  const displayedColleges = filteredColleges.slice(
    startIndex,
    startIndex + entriesPerPage
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleEntriesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  return (
    <div className="bg-[#F7F7F7] w-full">
      <div className="container mx-auto py-10 flex flex-col gap-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="mr-2 text-sm">Show</span>
            <select
              className="border border-black rounded p-1 text-sm"
              value={entriesPerPage}
              onChange={handleEntriesChange}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <span className="ml-2 text-sm">entries</span>
          </div>

          <div className="flex items-center">
            <span className="mr-2 text-sm">Search:</span>
            <input
              type="text"
              className="border border-black rounded p-1 text-sm"
              placeholder=""
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {!loading && !error && (
          <div className="w-full">
            <table className="min-w-full bg-white border border-black">
              <thead>
                <tr className="bg-white text-left">
                  <th className="px-4 py-3 border">Rank</th>
                  <th className="px-4 py-3 border">Score</th>
                  <th className="px-4 py-3 border">Name</th>
                  <th className="px-4 py-3 border">City</th>
                  <th className="px-4 py-3 border">State</th>
                </tr>
              </thead>
              <tbody>
                {displayedColleges.map((college) => (
                  <tr key={college.rank} className="text-left">
                    <td className="px-4 py-2 border">{college.rank}</td>
                    <td className="px-4 py-2 border">{college.score}</td>
                    <td className="px-4 py-2 border">{college.name}</td>
                    <td className="px-4 py-2 border">{college.city}</td>
                    <td className="px-4 py-2 border">{college.state}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {!loading && !error && (
          <div className="flex items-center justify-between mb-4">
            <div className="text-sm text-black">
              Showing {startIndex + 1} to{" "}
              {Math.min(startIndex + entriesPerPage, filteredColleges.length)}{" "}
              of {filteredColleges.length} entries
            </div>

            <button className="bg-[#173CBA] text-white py-2 px-4 rounded-full">
              View all
            </button>

            <div className="flex items-center">
              <button
                className="text-sm text-black mr-2"
                onClick={handlePreviousPage}
              >
                Previous
              </button>
              <input
                type="number"
                className="w-12 border border-black rounded p-1 text-sm text-center"
                value={currentPage}
                readOnly
              />
              <button
                className="text-sm text-black ml-2"
                onClick={handleNextPage}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IndiaRankingOverall;
