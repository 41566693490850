import React from "react";
import KnowledgeHubFAQ from "../rankings/KnowledgeHubFAQ";
import QsRankingsSection from "../rankings/QsRankingsSection";
import NirfRankingsSection from "../rankings/NirfRankingsSection";
import InternationalRankingsSection from "../rankings/InternationalRankingsSection";
import LatestUpdatesSection from "../rankings/LatestUpdatesSection";
import AwardsAccreditations from "../rankings/AwardsAccreditations";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import SidebarAdd from "../sidebar/SidebarAdd";

interface AccreditationItem {
  description: string;
  orderIndex: string;
  title: string;
}

interface RankingSectionProps {
  title: string;
  description: string;
  array?: AccreditationItem[];
}

interface CollegeProps {
  image: string;
  name: string;
  location: string;
  title: string;
}

interface FAQItem {
  description: string;
  orderIndex: string;
  title: string;
}

interface RankingsTabProps {
  rankings: {
    key_4?: RankingSectionProps;
    key_1?: RankingSectionProps;
    key_3?: RankingSectionProps;
    key_2?: RankingSectionProps;
    key_0?: RankingSectionProps;
    key_5: RankingSectionProps;
    similarColleges?: CollegeProps[];
    key_6?: {
      title: string;
      orderIndex: string;
      array: FAQItem[];
    };
  };
}

const RankingsTab: React.FC<RankingsTabProps> = ({ rankings }) => {
  const {
    key_4: qsRanking,
    key_1: nirfRanking,
    key_3: internationalRanking,
    key_2: nirfComparisonRanking,
    key_0: latestUpdates,
    key_5: awardsAccreditations,
    similarColleges,
    key_6,
  } = rankings;

  return (
    <>
      <div className="py-10 bg-[#f4f4f4]">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="w-full lg:w-9/12">
              {latestUpdates && (
                <LatestUpdatesSection
                  title={latestUpdates.title}
                  description={latestUpdates.description}
                />
              )}
              {qsRanking && (
                <QsRankingsSection
                  title={qsRanking.title}
                  description={qsRanking.description}
                />
              )}
              {nirfRanking && (
                <NirfRankingsSection
                  title={nirfRanking.title}
                  description={nirfRanking.description}
                />
              )}
              {internationalRanking && (
                <InternationalRankingsSection
                  title={internationalRanking.title}
                  description={internationalRanking.description}
                />
              )}
              {nirfComparisonRanking && (
                <NirfRankingsSection
                  title={nirfComparisonRanking.title}
                  description={nirfComparisonRanking.description}
                />
              )}
              {awardsAccreditations?.array &&
                awardsAccreditations.array.length > 0 && (
                  <AwardsAccreditations
                    title={awardsAccreditations.title}
                    description={awardsAccreditations.description}
                    array={awardsAccreditations.array || []}
                  />
                )}
            </div>
            <div className="w-full lg:w-1/4">
              <SidebarAdd />
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        {key_6 && key_6.array?.length > 0 && (
          <KnowledgeHubFAQ
            title={key_6.title || "Knowledge Hub (FAQs)"}
            faqs={key_6.array.map((item) => ({
              question: item.title,
              answer: item.description || "Information not available.",
            }))}
          />
        )}
        {similarColleges && (
          <SimilarEngineeringColleges colleges={similarColleges} />
        )}
      </div>
    </>
  );
};

export default RankingsTab;
