import React, { FC } from "react";
import bannerImg from "../images/banner-img.png";
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";

interface IProps {
  title: string;
  logo: string;
}

const Banner: FC<IProps> = ({ title, logo }) => {
  const isMdDevice = window.innerWidth >= 768;
  return (
    <section
      style={{
        background: `linear-gradient(${
          isMdDevice ? 90 : 180
        }deg, #0B3D8C 0.11%, #107CC4 54.49%, rgba(6, 175, 169, 0.745055) 83.43%)`,
      }}
    >
      <div className="container max-w-screen-2xl text-white py-6">
        <Breadcrumbs
          links={[
            { name: "Home", path: "/" },
            {
              name: "List of scholarships in India",
              path: "/scholarships/list-of-scholarships-in-india",
            },
            {
              name: title,
              path: "",
            },
          ]}
        />
        <div className="flex flex-col md:flex-row gap-8">
          <div className="relative w-full md:w-1/2 flex flex-col justify-center pt-12 md:pt-0">
            <img src={logo} className="w-48" alt="scholarship brand logo" />
            <h1 className="text-4xl mt-6 font-bold">{title}</h1>
          </div>
          <div className="w-full md:w-1/2 flex items-center justify-center">
            <img
              src={bannerImg}
              className="w-full md:w-2/3 aspect-square object-cover rounded-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
