import React from "react";
import creditScoreImg from "../../../images/college/details/creditscore-img.png";

const CreditScoreCheck = () => {
  return (
    <div className="bg-white  shadow-sm">
      <h4 className="w-full bg-blue text-white py-2 mb-4 text-lg text-center font-medium">
        Check your Free Credit score and unlock credit card offers
      </h4>

      <div className="flex flex-col items-center py-2 px-3">
        <div className="w-full">
          <img src={creditScoreImg} alt="Credit Score" className="w-full" />
        </div>
      </div>
      <div className="py-2 px-3">
        <form className="mt-6 space-y-4">
          <p className="text-center text-lg font-semibold text-gray-800">
            Enter your details
          </p>

          <div>
            <label className="block text-sm text-[#747474]">
              First Name (as per the PAN card)*
            </label>
            <input
              type="text"
              className="w-full border-b border-gray-300 py-2 outline-none focus:border-green-400"
              placeholder="First Name"
              required
            />
          </div>

          <div>
            <label className="block text-sm text-[#747474]">
              Last Name (as per the PAN card)*
            </label>
            <input
              type="text"
              className="w-full border-b border-gray-300 py-2 outline-none focus:border-green-400"
              placeholder="Last Name"
              required
            />
          </div>

          <div>
            <label className="block text-sm text-[#747474]">Pincode*</label>
            <input
              type="text"
              className="w-full border-b border-gray-300 py-2 outline-none focus:border-green-400"
              placeholder="Pincode"
              required
            />
          </div>

          <div>
            <label className="block text-sm text-[#747474]">
              Mobile Number*
            </label>
            <input
              type="text"
              className="w-full border-b border-gray-300 py-2 outline-none focus:border-green-400"
              placeholder="Mobile Number"
              required
            />
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-green text-white px-5 py-3 rounded-lg font-semibold mt-4 hover:bg-blue"
            >
              Continue on App →
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreditScoreCheck;
