import React from "react";

interface Course {
  lastYearCutoffAiRank: number;
  courseName: string;
}

const PredictorResultCollegeCard: React.FC<{ course: Course }> = ({ course }) => {
  return (<div className="mt-8 mb-4">
    <div className="w-[100%] border border-[#f7f7f7] shadow dark:bg-gray-800 dark:border-gray-700">
      <div className="flex flex-col p-2 bg-[#f7f7f7] gap-2">
        <span className="course-name">{course.courseName}</span>
        <p className="fee text-sm text-gray-500">Gen Fees ₹ 5.72 Lakhs | Seats 115</p>
      </div>
      <div className="p-2">
        <div className="flex flex-col items-center justify-center">
          <span className="goodagain">Good Chances</span>
          <p className="cutoff text-sm text-gray-500">Last Year's Cut-off AI Rank: {course.lastYearCutoffAiRank}</p>
        </div>
      </div>
    </div></div>
  );
};

export default PredictorResultCollegeCard;
