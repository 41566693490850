import React from "react";

const IndiaRanking = () => {
  return (
    <div className="px-16 mt-4 max-md:px-8 w-full max-md:w-full  text-center bg-gradient-to-b py-7 from-[#173CBA] to-[#06AFA9] text-white">
      <h3 className="text-white text-center  text-[40px] font-light leading-[55px]">
        India Rankings 2024 Overall
      </h3>
    </div>
  );
};

export default IndiaRanking;
