import React from "react";

interface AccreditationItem {
  description: string;
  orderIndex: string;
  title: string;
}

interface AwardsAccreditationsSecProps {
  title: string;
  description: string;
  array: AccreditationItem[];
}

const AwardsAccreditations: React.FC<AwardsAccreditationsSecProps> = ({
  title,
  description,
  array,
}) => {
  return (
    <section className="qs-rankings-section">
      <h4 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
        {title}
      </h4>
      <div
        className="text-base leading-relaxed bg-white p-3 rankingsTab"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className="flex flex-col gap-4 mt-4">
        {array
          .sort((a, b) => parseInt(a.orderIndex) - parseInt(b.orderIndex))
          .map((item, index) => (
            <div key={index} className="bg-white p-4 rounded">
              <h5 className="text-base  text-black font-bold mb-2">
                {item.title}
              </h5>
              <p
                className="text-base text-black"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </div>
          ))}
      </div>
    </section>
  );
};

export default AwardsAccreditations;
