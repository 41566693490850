import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

interface IProps {
  isOpen: boolean;
  hideCloseBtn?: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal: React.FC<IProps> = ({
  isOpen,
  hideCloseBtn,
  onClose,
  children,
}) => {
  if (!isOpen) return;
  return (
    <>
      <div
        className="fixed inset-0 bg-black-3 opacity-70 z-[999]"
        onClick={onClose}
      ></div>
      <div className=" md:mt-0 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[10000000]">
        {children}
        {!hideCloseBtn && (
          <button className="absolute top-4 right-4" onClick={onClose}>
            <FontAwesomeIcon icon={faClose} size="xl" />
          </button>
        )}
      </div>
    </>
  );
};

export default Modal;
