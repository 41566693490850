import React, { useState } from "react";
import Modal from "./assets/Modal";
import { Job } from "./Careers";

interface JobBoardProps {
  jobs: Job[];
}

const JobBoard: React.FC<JobBoardProps> = ({ jobs }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isJDOpen, setIsJDOpen] = useState<boolean>(false);
  const [jobIndex, setJobIndex] = useState<number>(0);
  const jobsPerPage = 4;
  const totalPages = Math.ceil(jobs.length / jobsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const JDOpenHandler = (index: number) => {
    setJobIndex(index);
    setIsJDOpen(true);
  };

  const JDCloseHandler = () => {
    setIsJDOpen(false);
  };

  const JDModalContent = (job: Job) => {
    const {
      title,
      minExp,
      maxExp,
      isFullTime,
      location,
      description,
      responsibilities,
      requirements,
      benefits,
      aboutCompany,
    } = job;
    const handleApplyClick = (title: string) => {
      const subject = encodeURIComponent(`Job Application for ${title}`);
      const body = encodeURIComponent(
        `I am interested in applying for the job position ${title}.\n\nPlease attach your resume here.`
      );
      const mailtoLink = `mailto:admin@dreamsanddegrees.com?subject=${subject}&body=${body}`;
      window.location.href = mailtoLink;
    };
    return (
      <div className="p-4">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl mb-2">{title}</h2>
        <p className="text-lg">{`${minExp} - ${maxExp} years | ${
          isFullTime ? "Full Time" : "Part Time"
        } | ${location}`}</p>
        <hr className="my-4" />
        <h3 className="text-2xl mb-2">About Us:</h3>
        <p className="mb-4">{aboutCompany}</p>
        <h3 className="text-2xl mb-2">Job Description:</h3>
        <p className="mb-4">{description}</p>
        <h3 className="text-2xl mb-2">Responsibilities:</h3>
        <ul className="list-disc pl-6 mb-4">
          {responsibilities.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <h3 className="text-2xl mb-2">Requirements:</h3>
        <ul className="list-disc pl-6 mb-4">
          {requirements.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <h3 className="text-2xl mb-2">Benefits:</h3>
        <ul className="list-disc pl-6 mb-4">
          {benefits?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <div className="flex justify-end">
          <button
            onClick={() => handleApplyClick(title)}
            className="CTABtn lg:block w-fit py-4 px-8 heading5 text-white bg-primary hover:text-black hover:bg-secondary"
          >
            Apply
          </button>
        </div>
      </div>
    );
  };

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);

  return (
    <div className="container mx-auto mt-8">
      <div
        className={
          currentJobs.length === 4
            ? "grid grid-cols-1 lg:grid-cols-2 gap-y-8 gap-x-6"
            : "grid grid-cols-1 md:grid-cols-1 gap-y-8 gap-x-6"
        }
      >
        {currentJobs.map((job, index) => (
          <JobItem
            key={index}
            index={index + indexOfFirstJob}
            title={job.title}
            minExp={job.minExp}
            maxExp={job.maxExp}
            isFullTime={job.isFullTime}
            location={job.location}
            tags={job.tags}
            aboutCompany={job.aboutCompany}
            responsibilities={job.responsibilities}
            requirements={job.requirements}
            benefits={job.benefits}
            jobType={job.isFullTime ? "Full Time" : "Part Time"}
            JDOpenHandler={JDOpenHandler}
          />
        ))}
      </div>
      <div className="flex justify-between mt-6">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="py-2 px-4 bg-gray-300 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="py-2 px-4 bg-gray-300 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
      <Modal isOpen={isJDOpen} onClose={JDCloseHandler}>
        {JDModalContent(jobs[jobIndex])}
      </Modal>
    </div>
  );
};

interface JobItemProps {
  index: number;
  title: string;
  minExp: number;
  maxExp: number;
  isFullTime: boolean;
  location: string;
  tags: string[];
  benefits: string[];
  aboutCompany: string;
  responsibilities: string[];
  requirements: string[];
  jobType: string;
  JDOpenHandler: (index: number) => void;
}

const JobItem: React.FC<JobItemProps> = ({
  index,
  title,
  minExp,
  maxExp,
  isFullTime,
  location,
  tags,
  JDOpenHandler,
}) => {
  return (
    <div
      className={`flex flex-col md:flex-row gap-6 md:gap-0 items-center px-0 md:px-6 py-6 border border-[#000000] rounded-2xl relative`}
    >
      <div className="w-full md:w-1/3 flex-col relative">
        <h3
          className="relative text-2xl mb-2 pl-3 md:pl-0 whitespace-nowrap"
          style={{
            fontFamily: "Inter, sans-serif",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "30px",
            color: "#161c2d",
          }}
        >
          {title}
        </h3>
        <div className="absolute top-0 left-0 w-full -translate-y-10">
          {tags.map((tag, i) => (
            <span
              key={i}
              className="rounded-full px-4 py-1 bg-[#ffc700] text-inter-bold-16 mr-2 mb-1 whitespace-nowrap"
            >
              {tag}
            </span>
          ))}
        </div>
        <div
  className="pl-3 md:pl-0 whitespace-nowrap text-gray-600"
  style={{
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
  }}
>
  <span className="inline md:inline">
    {`${minExp} - ${maxExp} years | ${isFullTime ? "Full Time" : "Part Time"} |`}
  </span>
  <span className="block md:inline">
    {` ${location}`}
  </span>
</div>

      </div>
      <div className="w-full flex md:justify-end right-0 pl-3 md:pl-0">
        <button
          className="CTABtn lg:block w-fit py-4 px-8 heading5 text-white bg-primary hover:text-black hover:bg-secondary"
          onClick={() => {
            JDOpenHandler(index);
          }}
        >
          View info
        </button>
      </div>
    </div>
  );
};

export default JobBoard;
