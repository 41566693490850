import React from "react";
import ArticlesBlogs from "./components/ArticlesBlogs";
import GetAdmissionAdvise from "./components/GetAdmissionAdvise";
import WhatWeOffer from "./components/WhatWeOffer";
import CollegeAdmissionProcess from "./components/CollegeAdmissionProcess";
import Testimonials from "./components/Testimonials";
import ApplicatonToAdmission from "./components/ApplicationToAdmission";
import TopColleges from "./components/TopColleges";
import ExploreCollegeLife from "./components/ExploreCollegeLife";

import ExploreYourDreamCollege from "./components/ExploreYourDreamCollege";
import MentorSection from "./components/MentorSection";
import { Banner } from "./components/Banner";
import ConnectWithTopUniversities from "./components/ConnectWithTopUniversities";
import useLeadGeneration from "../../hooks/useLeadGeneration";

const ApplicationsDirectAdmissions = () => {
  const { invokeLeadGeneration } = useLeadGeneration();

  return (
    <div>
      <Banner handleLeadGen={invokeLeadGeneration} />
      <TopColleges handleLeadGen={invokeLeadGeneration} />

      <CollegeAdmissionProcess handleLeadGen={invokeLeadGeneration} />

      <WhatWeOffer />
      <GetAdmissionAdvise />
      <ExploreCollegeLife handleLeadGen={invokeLeadGeneration} />
      <Testimonials />
      <ApplicatonToAdmission handleLeadGen={invokeLeadGeneration} />
      <MentorSection />
      <ArticlesBlogs />
      <ExploreYourDreamCollege />
      <ConnectWithTopUniversities handleLeadGen={invokeLeadGeneration} />
    </div>
  );
};

export default ApplicationsDirectAdmissions;
