import React, { FC, useEffect, useState } from "react";
import Logo from "../../../images/navbar/College Mentor_Final_Logo-01.png";
import "./BlogsMegaMenu.scss";
import { Link, NavLink } from "react-router-dom";
import BlogsMobileHeader from "./BlogsMobileHeader";
import { GetWindowDimensions } from "../../../hooks/window-hooks/WindowHooks";
import usePagination from "../../../components/filter/customHooks/usePagination";
import CustomPagination from "../../../components/pagination/CustomPagination";
import axios from "axios";
import config from "../../../util/config";
import { getBlogOrNewsUrl } from "../utils";
import { subcategoryOrderMap } from "../data/SubCategorySort";
import DefaultThumbNail from "../../../images/blog-article/default.png";
import UserAvatar from "../../../components/UserAvatar";
import { useNavigate } from "react-router-dom";

interface MegaMenuProps {
  categories: any;
  sectionsList: any;
}

const BlogsMegaMenu: React.FC<MegaMenuProps> = ({
  categories = {},
  sectionsList = [],
}) => {
  let currentUser = localStorage.getItem("user");
  const navigate = useNavigate();

  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);

  const [isNavLink, setIsNavLink] = useState<boolean | null>(false);

  const handleMouseEnterCategory = (menuTitle: string) => {
    setIsNavLink(true);
    setActiveMenu(menuTitle);
    // setActiveSubMenu(null); // Reset subcategory when switching categories

    // set the first subcategory when hovering over a category

    // const firstSubcategory = Object.keys(
    //   categories[menuTitle].subcategories
    // )[0];
    // setActiveSubMenu(firstSubcategory);

    const firstSubcategory = subcategoryOrderMap[menuTitle]?.find(
      (key) => key in categories[menuTitle].subcategories
    );
    setActiveSubMenu(firstSubcategory || null);
  };

  const handleMouseEnterSubcategory = (subCategory: string) => {
    setActiveSubMenu(subCategory);
  };

  const handleMouseLeave = () => {
    setActiveMenu(null);
    setActiveSubMenu(null);
  };

  const LinkItem = ({ section }: any) => (
    <NavLink
      to={`/blogs/${section.slug}`}
      className={({ isActive }) =>
        `text-md font-medium ${
          isActive
            ? "text-white bg-[#113cc0]"
            : "text-gray-700 hover:bg-[#113cc0] hover:text-white transition duration-200"
        } px-4 py-2 rounded`
      }
    >
      {section.title}
    </NavLink>
  );
  const { width } = GetWindowDimensions();

  return (
    <>
      {width < 1024 ? (
        <BlogsMobileHeader
          sectionsList={sectionsList}
          categories={categories}
        />
      ) : (
        <div className="relative" onMouseLeave={handleMouseLeave}>
          <header className="bg-white shadow">
            {/* <div className="container flex items-center justify-between px-4 py-3 mx-auto"> */}
            <div className="container flex flex-wrap items-center justify-between max-w-screen-xl p-2 mx-auto">
              <Link to="/blogs">
                <div className="logo">
                  <img src={Logo} alt="Logo" className="h-12" />
                </div>
              </Link>
              <nav>
                <ul className="flex items-center space-x-3 list-none md:mt-4 lg:mt-0">
                  <li key={"/"} className="relative group whitespace-nowrap">
                    <NavLink
                      to={`/blogs`}
                      className={() =>
                        ` hover:bg-[#113cc0] text-grey hover:text-white text-md font-medium px-4 py-2 rounded `
                      }
                    >
                      All
                    </NavLink>
                  </li>
                  {sectionsList.map((section: any) => (
                    <li
                      key={section.slug}
                      className="relative group whitespace-nowrap"
                      onMouseEnter={() =>
                        handleMouseEnterCategory(section.title)
                      }
                    >
                      <LinkItem section={section} />
                    </li>
                  ))}
                  {/* {!currentUser ? (
                    <button
                      className="py-2.5 px-2 md:px-6 blue-color button-styles text-sm font-semibold rounded-full duration-200"
                      onClick={(e) => navigate("/secure/login")}
                    >
                      login
                    </button>
                  ) : (
                    ""
                  )}

                  {currentUser && (
                    <UserAvatar
                      link={"dashboard/my-profile"}
                      logoutUrl={"secure/login"}
                    />
                  )} */}
                </ul>
              </nav>
            </div>
          </header>

          {activeMenu && (
            <div
              className="absolute left-0 z-10 w-full overflow-y-auto bg-white shadow-lg top-full" // Set fixed height with overflow handling
              onMouseEnter={() => setActiveMenu(activeMenu)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="container flex items-stretch max-w-screen-xl mx-auto">
                {/* Subcategories on the Left */}
                <div className="w-1/4  bg-[#00c798] text-white py-2 px-1">
                  {/* {Object.keys(categories[activeMenu].subcategories).map(
                    (subCategory) => (
                      <div
                        key={subCategory}
                        className={`cursor-pointer mb-4 px-4 py-2 ${
                          activeSubMenu === subCategory
                            ? "bg-white text-[#113cc0]"
                            : ""
                        }`}
                        onMouseEnter={() =>
                          handleMouseEnterSubcategory(subCategory)
                        }
                      >
                        <h4
                          className={`font-semibold ${
                            activeSubMenu === subCategory
                              ? "text-[#113cc0]"
                              : "text-white"
                          }`}
                        >
                          {subCategory}
                        </h4>
                      </div>
                    )
                  )} */}

                  {activeMenu in categories &&
                    subcategoryOrderMap[activeMenu]?.map(
                      (subCategory) =>
                        subCategory in
                          categories[activeMenu]?.subcategories && (
                          <Link
                            to={`/blogs/${
                              sectionsList.find(
                                (topic: any) => topic.title == activeMenu
                              )?.slug
                            }`}
                            state={{ subTopic: activeSubMenu || "" }}
                            key={subCategory}
                            className={`block mb-4 px-4 py-2 ${
                              activeSubMenu === subCategory
                                ? "bg-white text-[#113cc0]"
                                : ""
                            }`}
                            onMouseEnter={() =>
                              handleMouseEnterSubcategory(subCategory)
                            }
                          >
                            <h4
                              className={`font-semibold ${
                                activeSubMenu === subCategory
                                  ? "text-[#113cc0]"
                                  : "text-white"
                              }`}
                            >
                              {subCategory}
                            </h4>
                          </Link>
                        )
                    )}
                </div>

                {/* Results on the Right - Horizontal Layout */}
                <ActiveSubMenuList
                  activeMenu={activeMenu}
                  activeSubMenu={activeSubMenu}
                  categories={categories}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BlogsMegaMenu;

interface IActiveSubMenuList {
  activeSubMenu: string | null;
  categories: any;
  activeMenu: string;
}

const ActiveSubMenuList: FC<IActiveSubMenuList> = ({
  activeSubMenu,
  activeMenu,
  categories,
}) => {
  const defaultBlogList =
    activeSubMenu && categories[activeMenu].subcategories[activeSubMenu]
      ? categories[activeMenu].subcategories[
          activeSubMenu
        ].paginatedResponse.results.slice(0, 4)
      : null;

  const [blogList, setBlogList] = useState(defaultBlogList);

  const {
    totalPages,
    currentPage,
    updateTotalCount,
    handlePrevPage,
    handleNextPage,
    goToPage,
    handleResetPagination,
  } = usePagination(4);

  const pageCountFromAPIResponse =
    categories?.[activeMenu]?.subcategories?.[activeSubMenu || ""]
      ?.paginatedResponse?.totalNoOfPages;

  useEffect(() => {
    updateTotalCount(pageCountFromAPIResponse * 4 || 0);
  }, [activeMenu, activeSubMenu, categories]);

  useEffect(() => {
    if (currentPage == 1) setBlogList(defaultBlogList);
    else {
      axios({
        url: config.apiUrl + `/api/blogs/topic-details`,
        method: "POST",
        data: {
          topic: activeMenu,
          subTopic: activeSubMenu || "",
          currentPage: currentPage,
          currentPageSize: 4,
        },
      }).then((response) => {
        setBlogList(response.data?.details?.results);
      });
    }
  }, [categories, activeSubMenu, currentPage]);

  return (
    <div className="flex flex-col items-start w-3/4 p-4">
      <div className="flex w-[100%] gap-2">
        {blogList
          ? blogList.map((result: any, index: any) => (
              <Link
                to={getBlogOrNewsUrl(result)}
                key={index}
                className={`flex flex-col w-1/4`}
                // ${blogList?.length > 1 ? "w-1/4" : "w-1/4"}
              >
                {/* Image */}
                <img
                  src={result.imageUrl || DefaultThumbNail}
                  alt={result.title}
                  className="object-cover w-full mb-2 rounded-l h-52"
                />
                {/* Title */}
                <h5 className="mb-1 text-lg font-semibold line-clamp-2">
                  {result.title}
                </h5>
                {/* Teaser */}
                <p className="text-sm text-gray-600 line-clamp-2">
                  {result.teaser}
                </p>
              </Link>
            ))
          : // Show empty placeholders if fewer than 4 results
            Array.from({ length: 4 }).map((_, index) => (
              <div key={index} className="flex flex-col w-1/4">
                <div className="w-full h-32 mb-2 bg-gray-200 rounded-lg"></div>
                <h5 className="mb-1 text-lg font-semibold text-transparent bg-gray-200 rounded">
                  Title Placeholder
                </h5>
                <p className="text-sm text-gray-200 bg-gray-200 rounded">
                  Teaser Placeholder
                </p>
              </div>
            ))}
      </div>
      <div className="flex items-center justify-center mx-auto w-fit">
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          goToPage={goToPage}
        />
      </div>
    </div>
  );
};
