import { FC } from "react";
import parse from "html-react-parser";

interface IProps {
  htmlString: string | null;
}

const HtmlContent: FC<IProps> = ({ htmlString }) => {
  if (htmlString)
    return (
      <div className="mt-2">
        <style>{`
      .blog-content{
        text-align:justify;
      }

      .blog-content p {
        margin: 1em 0;
        line-height: 1.5;
        color: #333; /* Adjust text color */
      }

      .blog-content ul {
        list-style-type: disc;
        margin-left: 20px;
      }

      .blog-content ol {
        margin-left: 20px;
        list-style-type: number;
      }

      .blog-content img {
        max-width: 100%; /* Makes images responsive */
        height: auto;
      }

      .blog-content table, .blog-content th, .blog-content td {
        border: 1px solid black !important;
        padding: 0.5rem;
      }

      @media (max-width: 768px) { 
        .blog-content h1, .blog-content h2, .blog-content h3, .blog-content h4, .blog-content h5, .blog-content h6, p.heading10, p.heading20, p.heading30 {
          text-align: left !important;
        }
      }
    `}</style>
        <div className="blog-content">{parse(htmlString)}</div>
      </div>
    );
};

export default HtmlContent;
