import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import defaultImg from "../../images/study-abroad/profile-img.png";
import BadgeIcon from "../../images/study-abroad/badges-icon.png";
import type { SVGProps } from "react";
import { Link } from "react-router-dom";
import config from "../../util/config";

// SVG for navigation button
export function IconParkOutlineRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="m19 12l12 12l-12 12"
      ></path>
    </svg>
  );
}

interface Mentor {
  name: string;
  role: string;
  imageUrl?: string;
  worksOn?: string[] | null;
  info: string;
  id: number;
  country?: string;
}

const UniversitiesWorldwideMentors: React.FC = () => {
  const [mentors, setMentors] = useState<Mentor[]>([]);
  const [filteredMentors, setFilteredMentors] = useState<Mentor[]>([]);
  const [activeTab, setActiveTab] = useState<string>("All");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const tabs = [
    "All",
    "USA",
    "UK",
    "Canada",
    "Australia",
    "France",
    "Germany",
    "Ireland",
    "Italy",
    "New Zealand",
    "Dubai",
    "Singapore",
    "Sweden",
  ];

  useEffect(() => {
    const fetchMentors = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${config.apiUrl}/api/auth/mentors/mentorList`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch mentor data");
        }

        const data = await response.json();
        setMentors(data.data || []);
        setFilteredMentors(data.data || []);
      } catch (err: any) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchMentors();
  }, []);

  const handleTabChange = (country: string) => {
    setActiveTab(country);
    if (country === "All") {
      setFilteredMentors(mentors);
    } else {
      setFilteredMentors(
        mentors.filter((mentor) => mentor.country === country)
      );
    }
  };

  if (loading) {
    return (
      <div className="text-center text-gray-500 py-10">Loading mentors...</div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 py-10">
        {error || "Failed to load mentors."}
      </div>
    );
  }

  return (
    <section className="universities-mentors-container bg-[#D9F6EF]">
      <div className="flex justify-center flex-wrap bg-[#061958] py-4">
        {tabs.map((tab, index) => (
          <React.Fragment key={index}>
            {index !== 0 && <span className="text-white opacity-75">|</span>}
            <button
              className={`px-3 text-white 
              } focus:outline-none`}
            >
              {tab}
            </button>
          </React.Fragment>
        ))}
      </div>

      <div className="py-10 lg:py-7">
        <div className="container mx-auto">
          <div className="w-full lg:w-1/2">
            <h3 className="text-2xl lg:text-5xl font-light text-left text-blue mx-12">
              We will get you Admitted into Top Class Universities Worldwide
            </h3>
          </div>

          <div className="relative mt-5 ">
            <Swiper
              slidesPerView={1}
              spaceBetween={34}
              breakpoints={{
                640: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                1024: { slidesPerView: 3 },
                1280: { slidesPerView: 4 },
              }}
              loop={true}
              autoplay={{ delay: 5000, disableOnInteraction: false }}
              modules={[Autoplay, Navigation]}
              navigation={{
                nextEl: ".mentor-button-next",
                prevEl: ".mentor-button-prev",
              }}
              className="universities-mentors-swiper px-11"
            >
              {filteredMentors.length > 0 ? (
                filteredMentors.map((mentor, index) => (
                  <SwiperSlide key={mentor.id} className="text-center">
                    <div
                      className="relative group bg-[#FFF9E5] rounded-lg overflow-hidden"
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <div
                        className={`flex flex-col overflow-hidden transition-opacity duration-300 ${
                          hoveredIndex === index ? "grayscale" : "opacity-100"
                        }`}
                      >
                        <div className="w-full h-72 overflow-hidden">
                          <img
                            src={mentor.imageUrl || defaultImg}
                            alt={mentor.name}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="p-2 bg-blue text-center relative">
                          <img
                            src={BadgeIcon}
                            alt="Badge Icon"
                            className="w-[26px] h-[36px] mx-auto absolute -top-4 right-4"
                          />
                          <h4 className="font-bold text-white text-base">
                            {mentor.name}
                          </h4>
                          <p className="text-white text-sm">{mentor.role}</p>
                        </div>
                      </div>

                      <div
                        className={`absolute inset-0 text-white p-4 flex flex-col justify-center items-center transition-opacity duration-300 ease-in-out rounded-lg ${
                          hoveredIndex === index
                            ? "bg-black bg-opacity-65 visible opacity-100"
                            : "bg-transparent opacity-0 invisible"
                        }`}
                      >
                        <p className="text-base mb-4">{mentor.info}</p>
                        <div className="flex flex-wrap justify-center gap-2 mb-4">
                          {Array.isArray(mentor.worksOn) &&
                          mentor.worksOn.length > 0 ? (
                            mentor.worksOn.map((work, idx) => (
                              <span
                                key={idx}
                                className="bg-transparent border-2 border-green text-white rounded-full px-3 py-1 text-xs"
                              >
                                {work}
                              </span>
                            ))
                          ) : (
                            <span className="text-gray-500 text-sm">
                              No expertise listed
                            </span>
                          )}
                        </div>
                        <Link
                          to="/mentors"
                          className="bg-green text-white px-3 py-1 rounded-full text-xs"
                        >
                          Chat with me
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              ) : (
                <div className="text-center text-gray-500 p-10">
                  No mentors available for this country.
                </div>
              )}
            </Swiper>

            <div className="mentor-button-prev w-9 h-9 bg-blue rounded-full flex justify-center items-center rotate-180 text-white absolute left-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer">
              <IconParkOutlineRight />
            </div>
            <div className="mentor-button-next w-9 h-9 bg-blue rounded-full flex justify-center items-center absolute text-white right-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer">
              <IconParkOutlineRight />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UniversitiesWorldwideMentors;
