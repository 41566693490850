import React from "react";
import { Link } from "react-router-dom";
import CustomSwiper from "../../components/Slider/CustomSwiper";
import type { SVGProps } from "react";

export function MdiLocation(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7"
      ></path>
    </svg>
  );
}
interface College {
  name: string;
  location: string;
  image: string;
  title: string;
}

interface SimilarEngineeringCollegesProps {
  colleges: College[];
}

const SimilarEngineeringColleges: React.FC<SimilarEngineeringCollegesProps> = ({
  colleges,
}) => {
  if (!colleges || colleges.length === 0) {
    return <p>No similar colleges available.</p>;
  }

  const slideTrackContent = colleges.map((college, index) => (
    <div
      key={index}
      className="text-black overflow-hidden bg-white border border-grey-3 flex flex-col gap-4"
    >
      <Link
        to="/college-details"
        className="w-full flex flex-col rounded-md text-white text-sm"
      >
        <div className="relative flex flex-col">
          <div className="w-full h-48 overflow-hidden">
            <img
              src={college.image}
              alt={college.title}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="px-4 py-5 flex flex-col gap-3">
            <h3 className="font-semibold text-base text-black line-clamp-2">
              {college.title}
            </h3>
            <p className="text-sm text-grey line-clamp-1 flex gap-1 items-center">
              <MdiLocation />
              {college.location}
            </p>
          </div>
        </div>
      </Link>
    </div>
  ));

  return (
    <div className="py-8">
      <div className="relative container mx-auto flex flex-col gap-8">
        <h3 className="text-2xl font-bold lg:w-[60%] bg-[#113cc0] text-white px-4 py-2">
          Similar Engineering Colleges
        </h3>
        <div className="flex justify-center">
          <CustomSwiper
            items={slideTrackContent}
            navigation={"bottom"}
            autoplay={true}
            breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 5 },
              640: { slidesPerView: 2, spaceBetween: 10 },
              768: { slidesPerView: 2, spaceBetween: 10 },
              1024: { slidesPerView: 3, spaceBetween: 10 },
              1080: { slidesPerView: 4, spaceBetween: 10 },
            }}
            progressBar={true}
          />
        </div>
      </div>
    </div>
  );
};

export default SimilarEngineeringColleges;
