import React from "react";
import {
  MdiHeartOutline,
  MingcuteQuestionLine,
  SiDownloadOfflineLine,
} from "../../screens/college-details/CollegeDetails";

interface Button {
  text: string;
  link: string;
  type: "primary" | "secondary";
}

interface BannerProps {
  data: {
    title: string;
    description: string;
    buttons: Button[];
    imageUrl: string;
  };
}

const Banner: React.FC<BannerProps> = ({ data }) => {
  return (
    <div
      className="flex flex-col lg:flex-row items-center bg-[#0a0a5c] text-white py-16 px-4 lg:px-10 relative"
      style={{
        backgroundImage:
          window.innerWidth >= 767 ? `url(${data.imageUrl})` : "none",
        backgroundSize: "contain",
        backgroundPosition: "right center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="lg:w-1/2 space-y-4">
        <h2 className="text-4xl font-bold">{data.title}</h2>
        <p className="text-xl">{data.description}</p>
        <div className="flex flex-col gap-7">
          <div className="flex items-center space-x-6">
            <div className="flex gap-4 mt-8">
              <button className="border-none bg-transparent text-base text-green font-bold flex gap-1.5 items-center">
                <MdiHeartOutline /> <span className="text-white">Save</span>
              </button>
              <button className="border-none bg-transparent text-base font-bold text-green flex gap-1.5 items-center">
                <MingcuteQuestionLine />
                <span className="text-white">Compare College</span>
              </button>
              <button className="border-none bg-transparent text-base font-bold text-green flex gap-1.5 items-center">
                <SiDownloadOfflineLine />{" "}
                <span className="text-white">Download Guide</span>
              </button>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            {data.buttons.map((button, index) => (
              <a
                key={index}
                href={button.link}
                className={`button-styles green-bg items-center rounded-none ${
                  button.type === "primary"
                    ? "bg-green hover:bg-blue text-white"
                    : "bg-green text-green hover:bg-blue hover:text-white"
                }`}
              >
                {button.text}
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="lg:w-1/3 mt-6 lg:mt-0 relative">
        <img src={data.imageUrl} alt="Banner" className="w-full" />
      </div> */}
    </div>
  );
};

export default Banner;
