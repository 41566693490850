import React from "react";

import UniversityLogo from "../../images/college/details/amrita-vishwa-vidyapeetham-university.png";
import UniversityImage from "../../images/college/details/tamil-nadu-agri-university.jpeg";

interface UniversityData {
  name: string;
  logo: string;
  description: string;
  ctaText: string;
  ctaLink: string;
  image: string;
}

const universityData: UniversityData = {
  name: "Amrita Vishwa Vidyapeetham University Coimbatore",
  logo: UniversityLogo,
  description:
    "Ready for Application to Admission to Amrita University Campuses? Say goodbye to your doubts and connect with our expert mentors!",
  ctaText: "Get Free Mentor Help",
  ctaLink: "/mentor-help",
  image: UniversityImage,
};

const UniversityMentorHelp: React.FC = () => {
  const { name, logo, description, ctaText, ctaLink, image } = universityData;

  return (
    <div className="bg-[#F4F4F4] flex items-center justify-between">
      <div className="container mx-auto">
        <div className="bg-white p-8 rounded-lg shadow-lg border border-[#747474] mt-10">
          <div className="flex flex-col lg:flex-row gap-6 lg:gap-14 items-center">
            <div className="w-full sm:w-48 lg:w-60 overflow-hidden">
              <img
                src={logo}
                alt={name}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="flex flex-col gap-3 text-left">
              <h3 className="text-2xl font-bold text-black">
                Will you get admission in {name}?
              </h3>
              <p className="text-black">{description}</p>
              <div className="flex flex-row gap-3">
                <a
                  href={ctaLink}
                  className="white-color map-mentor button-styles arrow-button"
                >
                  {ctaText}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="currentColor"
                    className="arrow-icon"
                  >
                    <path
                      d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-48 lg:w-60">
              <img
                src={image}
                alt={name}
                className="rounded-lg object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniversityMentorHelp;
