import React from "react";
import AdmissionContactDetails from "../admissions/AdmissionContactDetails";
import InternationalAdmissionInfo from "../admissions/InternationalAdmissionInfo";
import PGAdmissionDetails from "../admissions/PGAdmissionDetails";
import TuitionFeeStructure from "../admissions/TuitionFeeStructure";
import MBAAdmissionsInfo from "../admissions/MBAAdmissionsInfo";
import KnowledgeHubFAQ from "../KnowledgeHubFAQ";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import LatestUpdates from "../admissions/LatestUpdates";
import OnlineCourses from "../admissions/OnlineCourses";
import ApplicationForms from "../sidebar/ApplicationForms";
import CoursesList from "../sidebar/CoursesList";

interface AdmissionsTabProps {
  admissionsDetails: {
    key_0: {
      title: string;
      description: string;
    };
    key_6: {
      title: string;
      description: string;
    };
    key_7: {
      title: string;
      description: string;
    };
    key_3: {
      title: string;
      description: string;
    };
    key_1: {
      title: string;
      description: string;
    };
    key_4: {
      title: string;
      description: string;
    };
    key_2: {
      title: string;
      description: string;
    };
    key_5: {
      title: string;
      description: string;
    };
    key_8: {
      title: string;
      description: string;
    };
    key_9?: {
      title: string;
      orderIndex: string;
      array: Array<{ title: string; description: string; orderIndex: string }>;
    };
    similarColleges?: Array<{
      image: string;
      name: string;
      location: string;
      title: string;
    }>;
  };
}

const AdmissionsTab: React.FC<AdmissionsTabProps> = ({ admissionsDetails }) => {
  const {
    key_6,
    key_7,
    key_3,
    key_1,
    key_4,
    key_2,
    key_5,
    key_8,
    key_9,
    similarColleges,
  } = admissionsDetails;

  return (
    <>
      <div className="py-10 bg-[#f4f4f4]">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="w-full lg:w-9/12">
              <LatestUpdates
                title={key_1.title}
                description={key_1.description}
              />
              <PGAdmissionDetails
                title={key_2.title}
                description={key_2.description}
              />
              <MBAAdmissionsInfo
                title={key_3.title}
                description={key_3.description}
              />
               <InternationalAdmissionInfo
                title={key_4.title}
                description={key_4.description}
              />
              <TuitionFeeStructure
                title={key_5.title}
                description={key_5.description}
              />
              <OnlineCourses
                title={key_6.title}
                description={key_6.description}
              />
              <AdmissionContactDetails
                title={key_7.title}
                description={key_7.description}
              />
              <AdmissionContactDetails
                title={key_8.title}
                description={key_8.description}
              />
            </div>
            <div className="w-full lg:w-3/12 hidden lg:block">
              <div className="flex flex-col gap-10">
                <ApplicationForms />
                <CoursesList />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        {key_9 && key_9.array?.length > 0 && (
          <KnowledgeHubFAQ
            title={key_9.title || "Knowledge Hub (FAQ’S)"}
            faqs={key_9.array.map((item) => ({
              question: item.title,
              answer: item.description || "Information not available.",
            }))}
          />
        )}
        {similarColleges && similarColleges.length > 0 && (
          <SimilarEngineeringColleges colleges={similarColleges} />
        )}
      </div>
    </>
  );
};

export default AdmissionsTab;
