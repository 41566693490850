import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../util/config";
import { RootState } from "../store";
import { useDispatch } from "react-redux";
import { closeAlert } from "../slices/leadGenSlice";

export const postCtaData = createAsyncThunk(
  "leadGen/postCtaData",
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState;
    const { leadPageName, leadOn } = state.leadGen;

    try {
      await axios.post(
        config.apiUrl + "/api/leads/create",
        {
          leadPageName: leadPageName,
          leadOn: leadOn,
          userId: JSON.parse(localStorage.getItem("user") || "{}")?.userId,
          scheduleOn: new Date(Date.now()).toISOString(),
        },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      setTimeout(() => {
        dispatch(closeAlert());
      }, 5000);

      return null;
    } catch (e) {
      setTimeout(() => {
        dispatch(closeAlert());
      }, 5000);

      return rejectWithValue("Error creating lead");
    }
  }
);
