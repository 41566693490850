import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CollegeBanner from "../../components/college-details/components/Banner";
import NavigationTabs from "../../components/college-details/components/NavigationTabs";
import Loader from "../../components/Loader/Loader";
import config from "../../util/config";

import type { SVGProps } from "react";

export function IcBaselineError(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3em"
      height="3em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m1 15h-2v-2h2zm0-4h-2V7h2z"
      ></path>
    </svg>
  );
}
export function MdiHeartOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m12.1 18.55l-.1.1l-.11-.1C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5c1.54 0 3.04 1 3.57 2.36h1.86C13.46 6 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5c0 2.89-3.14 5.74-7.9 10.05M16.5 3c-1.74 0-3.41.81-4.5 2.08C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.41 2 8.5c0 3.77 3.4 6.86 8.55 11.53L12 21.35l1.45-1.32C18.6 15.36 22 12.27 22 8.5C22 5.41 19.58 3 16.5 3"
      ></path>
    </svg>
  );
}

export function SiDownloadOfflineLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="m8 11l4 4m0 0l4-4m-4 4V6m4 12H8m14-6c0 5.523-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2s10 4.477 10 10"
      ></path>
    </svg>
  );
}
export function MingcuteQuestionLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none">
        <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m0 12a1 1 0 1 1 0 2a1 1 0 0 1 0-2m0-9.5a3.625 3.625 0 0 1 1.348 6.99a.8.8 0 0 0-.305.201c-.044.05-.051.114-.05.18L13 14a1 1 0 0 1-1.993.117L11 14v-.25c0-1.153.93-1.845 1.604-2.116a1.626 1.626 0 1 0-2.229-1.509a1 1 0 1 1-2 0A3.625 3.625 0 0 1 12 6.5"
        ></path>
      </g>
    </svg>
  );
}

interface CollegeDetailsProps {
  selectedCollege: any;
}

const CollegeDetails: React.FC<CollegeDetailsProps> = ({ selectedCollege }) => {
  const { collegeName } = useParams<{ collegeName: string }>();
  const [tabs, setTabs] = useState<any[]>([]);
  const [collegeInfo, setCollegeInfo] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchCollegeDetails = async () => {
      try {
        const response = await fetch(
          `${config.apiUrl}/api/college/college/detailsTabnames/${collegeName}`,
          { method: "GET", headers: { Accept: "*/*" } }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch college details");
        }

        const data = await response.json();

        console.log("API Response Data:", data);

        if (data?.data?.collegeTabs) {
          const mappedTabs = data.data.collegeTabs.map((tab: any) => ({
            orderIndex: tab.orderIndex,
            title: tab.title,
            name: tab.name,
            subTabs: tab.subTabs || [],
          }));
          setTabs(mappedTabs);
        } else {
          console.warn("No collegeTabs data found in API response");
        }

        if (data?.data?.collegeInfo) {
          setCollegeInfo({
            collegeTitle: data.data.collegeInfo.collegeTitle,
            collegeDescription: data.data.collegeInfo.collegeDescription,
            collegeType: data.data.collegeInfo.collegeType,
          });
        } else {
          console.warn("No collegeInfo data found in API response");
        }
      } catch (error) {
        console.error("Error fetching college details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCollegeDetails();
  }, [selectedCollege]);

  if (loading) {
    return <Loader />;
  }

  if (!collegeInfo) {
    return (
      <div className="container mx-auto">
        <div className="flex flex-col gap-8 items-center h-full py-10 text-red-400">
          <IcBaselineError />
          <span className="text-3xl font-bold">
            Error loading college information.
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      <CollegeBanner collegeInfo={collegeInfo} />
      <NavigationTabs tabs={tabs} />
    </>
  );
};

export default CollegeDetails;
