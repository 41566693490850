import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { Icon } from "leaflet";
import { Link } from "react-router-dom";

const customIcon: Icon = new L.Icon({
  iconUrl: "https://upload.wikimedia.org/wikipedia/commons/8/88/Map_marker.svg",
  iconSize: [32, 32],
  iconAnchor: [16, 32],
});

interface CollegeData {
  state: string;
  lat: number;
  lng: number;
  numberOfColleges: number;
}

const collegesData: CollegeData[] = [
  { state: "Maharashtra", lat: 19.7515, lng: 75.7139, numberOfColleges: 45 },
  { state: "Tamil Nadu", lat: 11.1271, lng: 78.6569, numberOfColleges: 40 },
  { state: "Uttar Pradesh", lat: 26.8467, lng: 80.9462, numberOfColleges: 35 },
  { state: "Karnataka", lat: 15.3173, lng: 75.7139, numberOfColleges: 32 },
  { state: "Andhra Pradesh", lat: 15.9129, lng: 79.74, numberOfColleges: 28 },
  { state: "Telangana", lat: 18.1124, lng: 79.0193, numberOfColleges: 25 },
  { state: "West Bengal", lat: 22.9868, lng: 87.855, numberOfColleges: 22 },
  { state: "Madhya Pradesh", lat: 22.9734, lng: 78.6569, numberOfColleges: 20 },
  { state: "Gujarat", lat: 22.2587, lng: 71.1924, numberOfColleges: 18 },
  { state: "Rajasthan", lat: 27.0238, lng: 74.2179, numberOfColleges: 15 },
  { state: "Kerala", lat: 10.8505, lng: 76.2711, numberOfColleges: 14 },
  { state: "Punjab", lat: 31.1471, lng: 75.3412, numberOfColleges: 12 },
];

const MapView: React.FC = () => {
  return (
    <div className="map-container">
      <div>
        <Link
          to="/college-intermediate"
          className="inline-block px-4 py-2 bg-[#AAD3DF] text-white font-bold rounded transition-colors duration-300 hover:bg-[#89B8C8]"
        >
          Back to College Intermediate
        </Link>
      </div>
      <MapContainer
        center={[20.5937, 78.9629]}
        zoom={4}
        style={{ height: "500px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* Markers */}
        {collegesData.map((college, index) => (
          <Marker
            key={index}
            position={[college.lat, college.lng]}
            icon={customIcon}
          >
            <Popup>
              <strong>{college.state}</strong> <br />
              Number of Colleges: {college.numberOfColleges}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default MapView;
