import React from "react";

interface AdmissionProcessSectionProps {
  title: string;
  description: string;
}

const AdmissionProcessSection: React.FC<AdmissionProcessSectionProps> = ({
  title,
  description,
}) => {
  return (
    <div className="py-10">
      <h3 className="text-3xl font-bold text-blue">{title}</h3>
      <div
        className="text-black text-base flex flex-col gap-4"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

export default AdmissionProcessSection;
