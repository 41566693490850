import { useState } from "react";
import "./EmiCalculator.scss";

const EmiCalculator = () => {
  const [principal, setPrincipal] = useState(0);
  const [interest, setInterest] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [result, setResult] = useState({
    principal: 0,
    emi: 0,
    percentage: 0,
  });

  const calculateEMI = () => {
    const monthlyInterest = interest / (12 * 100); // Convert annual interest rate to monthly interest rate
    const emi = Math.round(
      (principal * monthlyInterest * Math.pow(1 + monthlyInterest, tenure)) /
        (Math.pow(1 + monthlyInterest, tenure) - 1)
    );
    setResult({
      principal: principal,
      emi: emi,
      percentage: Math.round((emi * 100) / (emi + principal)),
    });
  };

  return (
    <div className="w-full flex max-md:flex-col md:items-center gap-4 p-4 border border-grey-4 shadow-xl rounded-xl max-md:divide-y md:divide-x divide-grey-4">
      <div className="emi-calculator flex-1 flex flex-col justify-between">
        <div>
          <div className="flex items-center justify-between">
            <label htmlFor="interestRate">Annual Rate of Interest (%)</label>
            <span className="border-b border-grey-4 min-w-12 text-center">
              {interest}
            </span>
          </div>
          <input
            type="range"
            id="interestRate"
            min="0"
            max="20"
            value={interest}
            onChange={(e) => setInterest(parseInt(e.target.value))}
            className="w-full"
            style={{
              background: `linear-gradient(to right, #00cc99 ${
                (interest / 20) * 100
              }%, #173cba ${(interest / 20) * 100}%)`,
            }}
          />
        </div>
        <div>
          <div className="flex items-center justify-between">
            <label htmlFor="tenure">Tenure (in months):</label>
            <span className="border-b border-grey-4 min-w-12 text-center">
              {tenure}
            </span>
          </div>
          <input
            type="range"
            id="tenure"
            min="1"
            max="30"
            value={tenure}
            onChange={(e) => setTenure(parseInt(e.target.value))}
            className="w-full"
            style={{
              background: `linear-gradient(to right, #00cc99 ${
                ((tenure - 1) / 29) * 100
              }%, #173cba ${((tenure - 1) / 29) * 100}%)`,
            }}
          />
        </div>
        <div>
          <div className="flex items-center justify-between">
            <label htmlFor="loanAmount">Loan Amount ()</label>
            <span className="border-b border-grey-4 min-w-12 text-center">
              {principal}
            </span>
          </div>
          <input
            type="range"
            id="loanAmount"
            min="0"
            max="1000000"
            value={principal}
            onChange={(e) => setPrincipal(parseInt(e.target.value))}
            className="w-full"
            style={{
              background: `linear-gradient(to right, #00cc99 ${
                (principal / 1000000) * 100
              }%, #173cba ${(principal / 1000000) * 100}%)`,
            }}
          />
        </div>
        <button
          onClick={calculateEMI}
          className="bg-green text-white font-bold py-2 px-4 rounded-full mt-4 self-center"
        >
          Calculate
        </button>
      </div>
      <div className="max-md:pt-4 md:pl-4 flex md:flex-col items-center gap-4 justify-center">
        <DonutChart
          percentage={result.percentage}
          amount={result.emi + result.principal}
        />
        <div className="flex max-md:flex-col gap-4">
          <div>
            <p className="ml-3">{formatRupees(result.principal)}</p>
            <p>
              <span className="inline-block w-2 h-2 rounded-full bg-blue"></span>{" "}
              Principal Amount
            </p>
          </div>
          <div>
            <p className="ml-3">{formatRupees(result.emi)}</p>
            <p>
              <span className="inline-block w-2 h-2 rounded-full bg-green"></span>{" "}
              Total Interest
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmiCalculator;

const DonutChart = ({
  size = 200,
  percentage = 50,
  colorA = "#173cba",
  colorB = "#00CC99",
  amount,
}: {
  size?: number;
  percentage?: number;
  colorA?: string;
  colorB?: string;
  amount: number;
}) => {
  // Calculate the center and radius of the donut
  const center = size / 2;
  const radius = center - 15; // Padding for stroke width
  const circumference = 2 * Math.PI * radius; // Circumference of the donut

  // Calculate the stroke-dasharray values for each segment
  const dashArrayA = (percentage / 100) * circumference;
  const dashArrayB = circumference - dashArrayA;

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      {/* Background circle (Category B) */}
      <circle
        cx={center}
        cy={center}
        r={radius}
        fill="transparent"
        stroke={colorB}
        strokeWidth="20"
        strokeDasharray={`${circumference}`}
        strokeDashoffset="0"
      />

      {/* Foreground circle (Category A) */}
      <circle
        cx={center}
        cy={center}
        r={radius}
        fill="transparent"
        stroke={colorA}
        strokeWidth="20"
        strokeDasharray={`${dashArrayA} ${dashArrayB}`}
        strokeDashoffset="0"
        transform={`rotate(-90 ${center} ${center})`} // Rotate to start from the top
      />

      {/* Center text (percentage) */}
      <text
        x="50%"
        y="45%"
        textAnchor="middle"
        dy=".3em"
        fontSize={size * 0.07}
        fontWeight={700}
        fill="#333"
      >
        Total Loan Amount
      </text>
      <text
        x="50%"
        y="55%"
        textAnchor="middle"
        dy=".3em"
        fontSize={size * 0.08}
        fontWeight={700}
        fill="#333"
      >
        {formatRupees(amount)}
      </text>
    </svg>
  );
};

function formatRupees(amount: number) {
  return amount
    .toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    })
    .replace("₹", "₹ ");
}
