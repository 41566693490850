import React from "react";

const festivalData = [
  { festival: "Diwali", votes: 939 },
  { festival: "Holi", votes: 508 },
  { festival: "Ganesh Chaturthi", votes: 634 },
  { festival: "Janmashtami", votes: 634 },
  { festival: "Eid", votes: 634 },
  { festival: "Lohri", votes: 634 },
  { festival: "Christmas", votes: 634 },
];

const FavoriteFestival = () => {
  const maxVotes = Math.max(...festivalData.map((f) => f.votes));

  return (
    <div className="bg-white  shadow-sm">
      <h4 className="w-full bg-blue text-white py-2 mb-4 text-lg text-center font-medium">
        What's your favorite festival?{" "}
      </h4>
      <div className="flex items-center mt-4 text-gray-600 text-sm">
        <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 1.67A8.33 8.33 0 1010 18.33 8.33 8.33 0 1010 1.67zM10 3c.37 0 .73.03 1.09.08l-.59 1.58a7.06 7.06 0 00-1.03-.08 7.14 7.14 0 107.1 6.35l1.6-.5a8.34 8.34 0 11-7.17 7.17l.5-1.6A7.14 7.14 0 0010 3z" />
        </svg>
        <p>Select one or more</p>
      </div>
      <div className="flex flex-col py-2 px-3">
        <ul className="list-none flex gap-4 flex-col">
          {festivalData.map((item, index) => (
            <li key={index} className="flex items-center gap-1">
              <input type="radio" name="festival" className="mr-4" />
              <div className="flex-grow">
                <p className="font-medium text-gray-800">{item.festival}</p>
                <div className="relative h-2 mt-1 rounded-full bg-gray-300">
                  <div
                    className="absolute top-0 left-0 h-full bg-green-400 rounded-full"
                    style={{ width: `${(item.votes / maxVotes) * 100}%` }}
                  ></div>
                </div>
              </div>
              <span className="ml-4 text-gray-700">{item.votes}</span>
            </li>
          ))}
        </ul>
        <div className="flex justify-center items-center mt-6 text-gray-500">
          <svg
            className="w-6 h-6 text-red-500 mr-1"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
          </svg>
          <span>37</span>
        </div>
      </div>
    </div>
  );
};

export default FavoriteFestival;
