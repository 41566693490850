import React, { useState } from "react";

const coursesData = [
  {
    courseName: "Agricultural Engineering",
    link: "/courses/agricultural-engineering",
  },
  {
    courseName: "Aerospace Engineering",
    link: "/courses/aerospace-engineering",
  },
  {
    courseName: "Automobile Engineering",
    link: "/courses/automobile-engineering",
  },
  {
    courseName: "Architecture Engineering",
    link: "/courses/architecture-engineering",
  },
  {
    courseName: "Biotechnology Engineering",
    link: "/courses/biotechnology-engineering",
  },
  {
    courseName: "Biomedical Engineering",
    link: "/courses/biomedical-engineering",
  },
  { courseName: "Civil Engineering", link: "/courses/civil-engineering" },
  { courseName: "Chemical Engineering", link: "/courses/chemical-engineering" },
  {
    courseName: "Computer Science Engineering",
    link: "/courses/computer-science-engineering",
  },
  {
    courseName: "Electrical Engineering",
    link: "/courses/electrical-engineering",
  },
  {
    courseName: "Environmental Engineering",
    link: "/courses/environmental-engineering",
  },
  {
    courseName: "Electronics and Communication Engineering",
    link: "/courses/electronics-communication-engineering",
  },
  {
    courseName: "Food Technology Engineering",
    link: "/courses/food-technology-engineering",
  },
  { courseName: "Genetic Engineering", link: "/courses/genetic-engineering" },
  {
    courseName: "Information Technology",
    link: "/courses/information-technology",
  },
  { courseName: "Marine Engineering", link: "/courses/marine-engineering" },
  {
    courseName: "Mechanical Engineering",
    link: "/courses/mechanical-engineering",
  },
  { courseName: "Mining Engineering", link: "/courses/mining-engineering" },
  { courseName: "Medical Electronics", link: "/courses/medical-electronics" },
  {
    courseName: "Nanotechnology Engineering",
    link: "/courses/nanotechnology-engineering",
  },
  { courseName: "Ocean Engineering", link: "/courses/ocean-engineering" },
  {
    courseName: "Pharmaceutical Engineering",
    link: "/courses/pharmaceutical-engineering",
  },
  {
    courseName: "Petroleum Engineering",
    link: "/courses/petroleum-engineering",
  },
  {
    courseName: "Rubber and Plastic Engineering",
    link: "/courses/rubber-plastic-engineering",
  },
  { courseName: "Robotics Engineering", link: "/courses/robotics-engineering" },
  { courseName: "Textile Engineering", link: "/courses/textile-engineering" },
  {
    courseName: "Telecommunication Engineering",
    link: "/courses/telecommunication-engineering",
  },
  { courseName: "Dual Degree BS + MS", link: "/courses/dual-degree-bs-ms" },
  {
    courseName: "Dual Degree B.Tech + M.Tech",
    link: "/courses/dual-degree-btech-mtech",
  },
  {
    courseName: "Dual Degree B.Tech + MBA",
    link: "/courses/dual-degree-btech-mba",
  },
];

const CoursesList = () => {
  const [showAll, setShowAll] = useState(false);

  const displayedCourses = showAll ? coursesData : coursesData.slice(0, 15);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="bg-white shadow-sm">
      <h4 className="w-full bg-blue text-white py-2 mb-4 text-lg text-center font-medium">
        Courses You May Be Interested In
      </h4>
      <div className="px-3 py-2">
        <ul className="list-none">
          {displayedCourses.map((course, index) => (
            <li key={index} className="mb-2 border-b border-[#DAD5D5] pb-2">
              <a href={course.link} className="text-[#747474] text-sm">
                {course.courseName}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex justify-center bg-green">
        <button
          onClick={toggleShowAll}
          className="bg-blue text-white px-4 py-2 rounded-full text-lg"
        >
          {showAll ? "Show Less" : "View More"}
        </button>
      </div>
    </div>
  );
};

export default CoursesList;
