import React from "react";
import RegulatoryProfessionalBanner from "./components/RegulatoryProfessionalBanner";
import RegulatoryProfessional from "./components/RegulatoryProfessional";
import UGCDetailedInfo from "./components/UGCDetailedInfo";
import AIUDetailedInfo from "./components/AIUDetailedInfo";
import RegulatoryCouncilCards from "./components/RegulatoryCouncilCards";

const RegulatoryProfessionalCouncil = () => {
  return (
    <div>
      <RegulatoryProfessionalBanner />
      <RegulatoryProfessional />
      <UGCDetailedInfo />
      <AIUDetailedInfo />
      <RegulatoryCouncilCards />
    </div>
  );
};

export default RegulatoryProfessionalCouncil;
