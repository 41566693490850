import React from "react";

interface QsRankingsSectionProps {
  title: string;
  description: string;
}

const QsRankingsSection: React.FC<QsRankingsSectionProps> = ({
  title,
  description,
}) => {
  return (
    <section className="qs-rankings-section">
      <h4 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
        {title}
      </h4>
      <div
        className="text-base leading-relaxed bg-white p-3 flex flex-col gap-4 rankingsTab"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </section>
  );
};

export default QsRankingsSection;
