import { title } from "process";
import React from "react";
import { useCourseDetails } from "../../../../screens/course-details/CourseDetailsContext";

import { SVGProps } from "react";
import { Link } from "react-router-dom";
export function MingcuteRightLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="10px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
        ></path>
      </g>
    </svg>
  );
}
const AverageSalaryContent = {
  title: "Average Salary",
  description:
    "The average salary of a Mechanical Engineering graduate depends on the capabilities and skills of the student; it also varies depending on the job profile and the location of the student. An estimated average salaries of some of the popular career options in the field of Mechanical Engineering are given in the table below.",
  source: "Ambition Box",
  AverageTableData: {
    tableData: [
      {
        title: "Purchase and Quality Control Executive",
        content: "Rs. 5 LPA",
      },
      {
        title: "Design Engineer",
        content: "Rs. 3 LPA",
      },
      {
        title: "Mechanical Engineer",
        content: "Rs. 4.4 LPA",
      },
      {
        title: "Assistant Mechanical Engineer",
        content: "Rs. 3.50 LPA",
      },
    ],
  },
};

const AverageSalaryTable = () => {
  const { data } = useCourseDetails();
  const { average_salary } = data;

  return (
    <>
      <table className="w-full border-collapse border-[#383838] border">
        <tbody>
          <tr className="border-[#383838] border bg-[#EEF2FF]">
            <th className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]  text-left">
              Job Profile
            </th>
            <th className="py-2.5 px-5 text-base text-[#4C495C] text-left">
              Average Salary
            </th>
          </tr>
          {average_salary?.salary_by_job_profiles.map(
            (detail: any, index: number) => (
              <tr
                key={index}
                className={`border-[#383838] border ${
                  index % 2 === 0 ? "bg-[#ffffff]" : "bg-[#EEF2FF]"
                }`}
              >
                <td className="border-[#383838] border py-2.5 px-5 text-base text-[#4C495C]">
                  <Link to={`/careers/career-details/${detail.job_title}`}>
                    {detail.job_title}
                  </Link>
                </td>
                <td className="py-2.5 px-5 text-base text-[#4C495C]">
                  {detail.average_salary}
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </>
  );
};

const AverageSalary: React.FC = () => {
  const { data } = useCourseDetails();
  const { average_salary } = data;
  return (
    <div className="flex flex-col bg-[#fff] relative" id="salaries">
      <div className="flex flex-col gap-2.5">
        <div className="flex max-md:w-full">
          <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
            {AverageSalaryContent.title}
          </h3>
        </div>
        <div className="flex flex-col px-2 py-8 md:py-5 md:px-9 gap-6">
          <p className="text-base text-[#4C495C] m-0">
            {average_salary.description}
          </p>
          <AverageSalaryTable />
          <p className="text-base text-[#4C495C] m-0">
            Source: {AverageSalaryContent.source}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AverageSalary;
