import React from "react";
import RevealFromBottom from "./RevealFromBottom";
import Reveal from "react-awesome-reveal";
import { customAnimationLeft, customAnimationRight } from "./keyframes";
import DotsAndShades from "../assets/dots-and-shades-for-hero.png";
import YellowShade from "../assets/yellow-shade.png";

export interface HeroSectionProps {
  headings: React.ReactNode[];
  paragraphs: React.ReactNode[];
  image: string;
  linkText?: string;
  anchorLink?: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  headings,
  paragraphs,
  image,
  linkText = undefined,
  anchorLink = undefined,
}) => {
  const handleButtonClick = () => {
    const element = document.getElementById("contact-section");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="container max-w-screen-xl pt-12 sm:pt-12 lg:pt-24 pb-12 flex flex-col md:flex-row gap-12 md:gap-12">
      <img
        src={YellowShade}
        className="absolute top-0 sm:top-12 left-0 h-96 w-96 z-[-10]"
      />
      <div className="w-full md:w-3/5 flex flex-col justify-center items-left">
        {headings.map((heading) => (
          <RevealFromBottom className="">
            <h1 className="heading1">{heading}</h1>
          </RevealFromBottom>
        ))}
        <div className="py-2"></div>
        {paragraphs.map((paragraph) => (
          <Reveal keyframes={customAnimationLeft}>
            <p className="text1">{paragraph}</p>
          </Reveal>
        ))}
        {linkText && anchorLink && (
          <>
            <Reveal
              className="mt-2 sm:mt-6"
              keyframes={customAnimationLeft}
              delay={200}
            >
              <button
                onClick={handleButtonClick}
                className="CTABtn  lg:block w-fit py-4 px-8 heading5 text-white bg-primary hover:text-black hover:bg-secondary"
              >
                Contact us
              </button>
            </Reveal>
          </>
        )}
      </div>
      <div className="w-full md:w-1/2 md:h-[30vw] relative">
        <Reveal keyframes={customAnimationRight}>
          <img src={image} className="h-full w-full object-cover rounded-3xl" />
        </Reveal>
        <img
          src={DotsAndShades}
          className="absolute top-0 left-0 transform scale-[150%] sm:scale-[160%] -translate-x-[4.5rem] sm:-translate-x-32 translate-y-10 sm:translate-y-16"
          style={{ zIndex: -10 }}
        />
      </div>
    </div>
  );
};

export default HeroSection;
