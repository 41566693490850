import React from "react";
import TitleCard from "../../components/TitleCard";
import StudyAbroadImage from "../../../../images/blog-article/study-abroad-image.png";
import ExamBlogsImage4 from "../../../../images/blog-article/exam-section-image4.jpg";
import ExamBlogsImage2 from "../../../../images/blog-article/exam-section-image2.jpeg";
import ExamBlogsImage3 from "../../../../images/blog-article/exam-section-image3.jpg";
import ExamBlogsImage1 from "../../../../images/blog-article/exam-section-image1.jpg";
import BlogInfoCard from "../../components/BlogInfoCard";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import VerticalNewsCard from "../../components/VerticalNewsCard";
import { GetWindowDimensions } from "../../../../hooks/window-hooks/WindowHooks";
import { Link } from "react-router-dom";

interface StudyAbroadProps {
  studyAbroadResponse: any;
}
const StudyAbroad: React.FC<StudyAbroadProps> = ({ studyAbroadResponse }) => {
  const { width, height } = GetWindowDimensions();
  const getCurrentHoveredBlog = (param: any) => {
    console.log("first", param);
  };

  let isMobile = width < 700;

  const showOnlyFiveList = (results: any) => {
    if (isMobile) return results.slice(0, 5);
    else return results;
  };

  return (
    <>
      <div className="container grid grid-cols-1 gap-4 py-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
        <div className="grid grid-flow-row gap-4 grid-rows">
          <TitleCard title="Study Abroad" bgColor="#173CBA" color="#ffffff" />
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 ">
            <div className="">
              <CustomSwiper
                className="!p-2 h-full"
                items={studyAbroadResponse[0]?.["Study Abroad"]?.subcategories[
                  "Articles & Blogs"
                ]?.results?.map((item: any) => (
                  <VerticalNewsCard item={item} />
                ))}
                navigation={"bottom"}
                autoplay={true}
                progressBar={true}
                breakpoints={{
                  320: { slidesPerView: 1, spaceBetween: 20 },
                  768: { slidesPerView: 3, spaceBetween: 20 },
                  1024: { slidesPerView: 2, spaceBetween: 18 },
                  1280: { slidesPerView: 2, spaceBetween: 30 },
                }}
              />
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-col-1">
              <div className="grid grid-row md:grid-rows-5 md:grid-flow-col gap-x-6">
                {showOnlyFiveList(
                  studyAbroadResponse[0]?.["Study Abroad"]?.subcategories[
                    "Latest News"
                  ]?.results
                )?.map((blogInfo: any) => (
                  <BlogInfoCard
                    dateValue={true}
                    rowReverse={true}
                    blogInfo={blogInfo}
                    getCurrentHoveredItem={getCurrentHoveredBlog}
                    height=""
                    categoryOrderReverse={false}
                    borderBottom={true}
                    imageField={true}
                  />
                ))}
              </div>
            </div>
          </div>
          {isMobile && (
            <div className="flex flex-row items-center justify-center">
              <Link
                to={`/blogs/study-abroad`}
                
                state={{
                  from: "StudyAbroad view all",
                  category: "study abroad",
                }}
                
                className="h-full white-color map-mentor button-styles arrow-button"
              >
                {" "}
                View All
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="currentColor"
                  className="arrow-icon"
                >
                  <path
                    d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                    fill="currentColor"
                  />
                </svg>
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* Old implementation without Swipers */}

      {/* <div className="container grid grid-cols-1 gap-4 py-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
        <div className="grid grid-flow-row gap-4 grid-rows">
          <TitleCard title="Study Abroad" bgColor="#173CBA" color="#ffffff" />
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
              <div className="">
                <img
                  className=""
                  src={ExamBlogsImage4}
                  alt=""
                  width="100%"
                  style={{ objectFit: "cover", height: "24rem" }}
                />

                <div className="py-2">
                  <a href="#">
                    <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                      Town Celebrates Grand Opening of New Public Library with
                      Festive Ribbon Cutting
                    </h5>
                  </a>
                  <p className="mb-3 text-base">College | September 13, 2024</p>
                  <p className="font-normal text-gray-700 dark:text-gray-400">
                    In the dynamic world of business and entrepreneurship,
                    success is not just about having a great idea or a solid
                    business plan. It requires a combination of factors,
                    including...
                  </p>
                </div>
              </div>
              <div className="h-full">
                <a href="#">
                  <img
                    className=""
                    src={StudyAbroadImage}
                    alt=""
                    width="100%"
                    style={{ height: "24rem" }}
                  />
                </a>
                <div className="py-2">
                  <a href="#">
                    <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                      Town Celebrates Grand Opening of New Public Library with
                      Festive Ribbon Cutting
                    </h5>
                  </a>
                  <p className="mb-3">College | September 13, 2024</p>
                  <p className="font-normal text-gray-700 dark:text-gray-400">
                    In the dynamic world of business and entrepreneurship,
                    success is not just about having a great idea or a solid
                    business plan. It requires a combination of factors,
                    including...
                  </p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2">
              <div className="">
                {[...Array(5)].map(() => (
                  <BlogInfoCard dateValue={true} rowReverse={true} />
                ))}
              </div>
              <div className="">
                {[...Array(5)].map(() => (
                  <BlogInfoCard dateValue={true} rowReverse={true} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default StudyAbroad;
