import React from "react";
import FacilitySection from "../campusfacilities/FacilitySection";

interface FacilitySectionsProps {
  sections: Array<{ title: string; description: string } | undefined>;
}

const FacilitySections: React.FC<FacilitySectionsProps> = ({ sections }) => (
  <>
    {sections.map((section, index) =>
      section ? (
        <FacilitySection
          key={section.title}
          title={section.title}
          description={section.description}
          index={index}
          tabType="collegeLife"
        />
      ) : null
    )}
  </>
);

export default FacilitySections;
