import React from "react";

interface Review {
  name: string;
  date: string;
  title: string;
  content: string;
}

const reviews: Review[] = [
  {
    name: "Suresh P",
    date: "Aug - 01 - 2024",
    title: "Transformative Guidance",
    content:
      "Rajashekar provided invaluable insights throughout my college application process. His personalized approach helped me highlight my strengths and ultimately secure admission to my dream school.",
  },
  {
    name: "Chidambaram R",
    date: "April - 16 - 2024",
    title: "Expert Knowledge",
    content:
      "With over 15 years of experience, Rajashekar has an incredible depth of knowledge about admissions. He guided me through every step, from test prep to scholarship applications, making the process much smoother.",
  },
  {
    name: "Sneha S",
    date: "April 21, 2024",
    title: "Transformative Guidance",
    content:
      "Rajashekar provided invaluable insights throughout my college application process. His personalized approach helped me highlight my strengths and ultimately secure admission to my dream school.",
  },
];

const MentorReviews = () => {
  return (
    <div className="bg-[#FFF1FA] p-6 rounded-lg mx-auto text-[#383838]">
      <div className="container">
        <h2 className="text-xl font-semibold text-[#173CBA] mb-4">Reviews</h2>

        {reviews.map((review, index) => (
          <div key={index} className="mb-6">
            <h3 className="text-md font-semibold">{review.name}</h3>
            <div className="flex items-center mt-1">
              <span className=" text-lg flex flex-row">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <path
                    d="M18.9681 7.32982C18.8903 7.09402 18.6528 6.81231 17.9506 6.81231H13.0919C12.5705 6.81231 11.9693 6.38036 11.8095 5.88581L10.3084 1.29921C10.0918 0.637724 9.74913 0.5 9.49895 0.5C9.24876 0.5 8.90607 0.63981 8.68953 1.29921L7.18842 5.88581C7.02653 6.37828 6.42735 6.81231 5.90596 6.81231H1.04733C0.347234 6.81231 0.107563 7.09402 0.0318764 7.32982C-0.0459121 7.56562 -0.0185817 7.93288 0.546962 8.34188L4.47844 11.1756C4.90102 11.4803 5.13018 12.1793 4.96829 12.6739L3.46718 17.2605C3.32843 17.6841 3.34314 18.0138 3.51133 18.2412C3.63117 18.4061 3.82249 18.5 4.03693 18.5C4.25979 18.5 4.50366 18.404 4.77908 18.2058L8.70845 15.372C8.91028 15.2259 9.20041 15.1425 9.50105 15.1425C9.80169 15.1425 10.0918 15.2259 10.2937 15.372L14.2251 18.2058C14.5005 18.404 14.7423 18.5 14.9652 18.5C15.1796 18.5 15.3709 18.4061 15.4908 18.2412C15.659 18.0138 15.6737 17.682 15.5349 17.2605L14.0338 12.6739C13.8719 12.1814 14.1011 11.4803 14.5237 11.1756L18.453 8.34188C19.0186 7.93288 19.0459 7.56562 18.9681 7.32982Z"
                    fill="#FFC700"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <path
                    d="M18.9681 7.32982C18.8903 7.09402 18.6528 6.81231 17.9506 6.81231H13.0919C12.5705 6.81231 11.9693 6.38036 11.8095 5.88581L10.3084 1.29921C10.0918 0.637724 9.74913 0.5 9.49895 0.5C9.24876 0.5 8.90607 0.63981 8.68953 1.29921L7.18842 5.88581C7.02653 6.37828 6.42735 6.81231 5.90596 6.81231H1.04733C0.347234 6.81231 0.107563 7.09402 0.0318764 7.32982C-0.0459121 7.56562 -0.0185817 7.93288 0.546962 8.34188L4.47844 11.1756C4.90102 11.4803 5.13018 12.1793 4.96829 12.6739L3.46718 17.2605C3.32843 17.6841 3.34314 18.0138 3.51133 18.2412C3.63117 18.4061 3.82249 18.5 4.03693 18.5C4.25979 18.5 4.50366 18.404 4.77908 18.2058L8.70845 15.372C8.91028 15.2259 9.20041 15.1425 9.50105 15.1425C9.80169 15.1425 10.0918 15.2259 10.2937 15.372L14.2251 18.2058C14.5005 18.404 14.7423 18.5 14.9652 18.5C15.1796 18.5 15.3709 18.4061 15.4908 18.2412C15.659 18.0138 15.6737 17.682 15.5349 17.2605L14.0338 12.6739C13.8719 12.1814 14.1011 11.4803 14.5237 11.1756L18.453 8.34188C19.0186 7.93288 19.0459 7.56562 18.9681 7.32982Z"
                    fill="#FFC700"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <path
                    d="M18.9681 7.32982C18.8903 7.09402 18.6528 6.81231 17.9506 6.81231H13.0919C12.5705 6.81231 11.9693 6.38036 11.8095 5.88581L10.3084 1.29921C10.0918 0.637724 9.74913 0.5 9.49895 0.5C9.24876 0.5 8.90607 0.63981 8.68953 1.29921L7.18842 5.88581C7.02653 6.37828 6.42735 6.81231 5.90596 6.81231H1.04733C0.347234 6.81231 0.107563 7.09402 0.0318764 7.32982C-0.0459121 7.56562 -0.0185817 7.93288 0.546962 8.34188L4.47844 11.1756C4.90102 11.4803 5.13018 12.1793 4.96829 12.6739L3.46718 17.2605C3.32843 17.6841 3.34314 18.0138 3.51133 18.2412C3.63117 18.4061 3.82249 18.5 4.03693 18.5C4.25979 18.5 4.50366 18.404 4.77908 18.2058L8.70845 15.372C8.91028 15.2259 9.20041 15.1425 9.50105 15.1425C9.80169 15.1425 10.0918 15.2259 10.2937 15.372L14.2251 18.2058C14.5005 18.404 14.7423 18.5 14.9652 18.5C15.1796 18.5 15.3709 18.4061 15.4908 18.2412C15.659 18.0138 15.6737 17.682 15.5349 17.2605L14.0338 12.6739C13.8719 12.1814 14.1011 11.4803 14.5237 11.1756L18.453 8.34188C19.0186 7.93288 19.0459 7.56562 18.9681 7.32982Z"
                    fill="#FFC700"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <path
                    d="M18.9681 7.32982C18.8903 7.09402 18.6528 6.81231 17.9506 6.81231H13.0919C12.5705 6.81231 11.9693 6.38036 11.8095 5.88581L10.3084 1.29921C10.0918 0.637724 9.74913 0.5 9.49895 0.5C9.24876 0.5 8.90607 0.63981 8.68953 1.29921L7.18842 5.88581C7.02653 6.37828 6.42735 6.81231 5.90596 6.81231H1.04733C0.347234 6.81231 0.107563 7.09402 0.0318764 7.32982C-0.0459121 7.56562 -0.0185817 7.93288 0.546962 8.34188L4.47844 11.1756C4.90102 11.4803 5.13018 12.1793 4.96829 12.6739L3.46718 17.2605C3.32843 17.6841 3.34314 18.0138 3.51133 18.2412C3.63117 18.4061 3.82249 18.5 4.03693 18.5C4.25979 18.5 4.50366 18.404 4.77908 18.2058L8.70845 15.372C8.91028 15.2259 9.20041 15.1425 9.50105 15.1425C9.80169 15.1425 10.0918 15.2259 10.2937 15.372L14.2251 18.2058C14.5005 18.404 14.7423 18.5 14.9652 18.5C15.1796 18.5 15.3709 18.4061 15.4908 18.2412C15.659 18.0138 15.6737 17.682 15.5349 17.2605L14.0338 12.6739C13.8719 12.1814 14.1011 11.4803 14.5237 11.1756L18.453 8.34188C19.0186 7.93288 19.0459 7.56562 18.9681 7.32982Z"
                    fill="#FFC700"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <path
                    d="M18.9681 7.32982C18.8903 7.09402 18.6528 6.81231 17.9506 6.81231H13.0919C12.5705 6.81231 11.9693 6.38036 11.8095 5.88581L10.3084 1.29921C10.0918 0.637724 9.74913 0.5 9.49895 0.5C9.24876 0.5 8.90607 0.63981 8.68953 1.29921L7.18842 5.88581C7.02653 6.37828 6.42735 6.81231 5.90596 6.81231H1.04733C0.347234 6.81231 0.107563 7.09402 0.0318764 7.32982C-0.0459121 7.56562 -0.0185817 7.93288 0.546962 8.34188L4.47844 11.1756C4.90102 11.4803 5.13018 12.1793 4.96829 12.6739L3.46718 17.2605C3.32843 17.6841 3.34314 18.0138 3.51133 18.2412C3.63117 18.4061 3.82249 18.5 4.03693 18.5C4.25979 18.5 4.50366 18.404 4.77908 18.2058L8.70845 15.372C8.91028 15.2259 9.20041 15.1425 9.50105 15.1425C9.80169 15.1425 10.0918 15.2259 10.2937 15.372L14.2251 18.2058C14.5005 18.404 14.7423 18.5 14.9652 18.5C15.1796 18.5 15.3709 18.4061 15.4908 18.2412C15.659 18.0138 15.6737 17.682 15.5349 17.2605L14.0338 12.6739C13.8719 12.1814 14.1011 11.4803 14.5237 11.1756L18.453 8.34188C19.0186 7.93288 19.0459 7.56562 18.9681 7.32982Z"
                    fill="#FFC700"
                  />
                </svg>
              </span>
              <span className="text-sm text-[#383838] ml-2">{review.date}</span>
            </div>
            <p className="mt-2 text-sm font-semibold">{review.title}:</p>
            <p className="mt-1 text-sm text-[#383838]0">"{review.content}"</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MentorReviews;
