import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../../util/config";

export const fetchLandingData = createAsyncThunk(
  "collegePredictorLanding/fetchLandingData",
  async (examName: string) => {
    const response = await axios.get(
      config.apiUrl + `/api/college/predictorIntermediatePage/${examName}`
    );
    return response.data;
  }
);
