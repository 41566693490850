import { Link } from "react-router-dom";

const CTAButton = ({
  text,
  link,
  className = "",
  onMouseEnter,
  onMouseLeave,
}: {
  text: React.ReactNode;
  link: string;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) => {
  return (
    <Link
      to={link}
      className={
        className +
        " CTABtn w-fit py-4 px-8 heading5 text-white bg-primary hover:text-black hover:bg-secondary"
      }
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {text}
    </Link>
  );
};

export default CTAButton;
