import React, { ReactNode, useEffect, useRef } from 'react';
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
    const contentRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        if (isOpen && contentRef.current) {
            contentRef.current.focus();
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen])

    if (!isOpen) return null;

    return (
        <>
            <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center z-[1000]" onClick={onClose}>

            </div>
            <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-4/5 md:w-2/3 lg:1/2 max-h-[80vh] overflow-y-auto p-6 z-[1001]">
                <button className="absolute top-4 right-3.5 text-gray-500 hover:text-gray-700" onClick={onClose} ref={contentRef}>
                    <FontAwesomeIcon icon={faClose} className="size-6" />
                </button>
                {children}
            </div>
        </>
    );
};

export default Modal;