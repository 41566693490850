import React from "react";

interface HighlightsSectionProps {
  title: string;
  content: string;
}

const HighlightsSection: React.FC<HighlightsSectionProps> = ({
  title,
  content,
}) => {
  return (
    <div className="tab-content">
      <h4 className="text-2xl font-semibold bg-[#113cc0] text-white py-1 px-3 lg:w-[80%]">
        {title}
      </h4>
      <div className="collegeHighlights">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

export default HighlightsSection;
