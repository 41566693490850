import React from "react";
import "./CourseStreamQuiz.scss";
import Arrow from "../../images/Arrow.svg";
import quizQuestions from "../../screens/quiz/data.json";
import { useNavigate, useLocation } from "react-router-dom";

interface IQuizItem {
  title: string;
  image: string;
  link: string;
  id: number;
}

interface CardProps {
  quiz: IQuizItem;
  additionalClasses?: string;
}

const QuizCard: React.FC<CardProps> = ({ quiz, additionalClasses = "" }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleQuiz = (link: string) => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/secure/login", { state: { sourcePagePath: pathname } });
    } else {
      navigate(link);
    }
  };

  return (
    <div
      key={quiz.id}
      className={`quizes lg:w-[197px] sm:w-[140px] w-[160px] mb-8 rounded-xl overflow-hidden bg-white items-center ${additionalClasses}`}
    >
      <img
        loading="lazy"
        src={quiz.image}
        className="w-full aspect-[1.39] max-w-[197px]"
        alt={quiz.title}
      />
      <div className="self-center mt-4 mb-3 text-center text-[#173CBA] text-base font-bold">
        {quiz.title}
        <br />
        Quizzes
      </div>
      <a
        onClick={() => handleQuiz(quiz.link)}
        className="pb-2 cta flex justify-center items-center"
      >
        <div className="pt-4">
          <img src={Arrow} className="arrow-icon" alt="arrow-icon" />
        </div>
      </a>
    </div>
  );
};

const CourseQuizContent = [
  {
    title: "Popular Quizzes",
    button: "View All",
    quizcategories: quizQuestions.map((topic, index) => ({
      id: index,
      title: topic.topic,
      image: topic.image || "/images/Online-test.jpg",
      link: `/courses/quiz/${topic.topic}`,
    })),
  },
];

const CourseStreamQuiz: React.FC = () => {
  return (
    <div className="popular-quizes-parent flex sm:py-10 sm:h-[673px] md:h-auto sm:overflow-hidden py-20 bg-[#F7F7F7]">
      <div className="container mx-auto parent-container">
        <div className="flex flex-col">
          <div
            key="explore-course-by-stream"
            className="flex flex-col gap-4 lg:gap-10"
          >
            <div className="flex flex-row justify-between items-center">
              <h3 className="text-3xl lg:text-[40px] font-bold lg:leading-[58px] text-black m-0">
                Popular Quizzes
              </h3>
            </div>
            <div className="popular-quizes flex flex-wrap lg:justify-center justify-between sm:gap-1 lg:gap-6">
              {CourseQuizContent[0].quizcategories.slice(0, 10).map((quiz) => (
                <QuizCard key={quiz.id} quiz={quiz} additionalClasses="hidden md:block" />
              ))}
              {CourseQuizContent[0].quizcategories.slice(0, 4).map((quiz) => (
                <QuizCard key={quiz.id} quiz={quiz} additionalClasses="block md:hidden" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseStreamQuiz;
