import React from "react";
import CMRLatestUpdates from "../cmresource/CMResourceLatestUpdates";
import ChatWithAmbassadors from "../cmresource/ChatWithAmbassadors";
import NewsAndArticles from "../cmresource/NewsAndArticles";
import ResearchInitiatives from "../cmresource/ResearchInitiatives";
import InternationalPartnerships from "../cmresource/InternationalPartnerships";
import VisualStories from "../cmresource/VisualStories";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import TopPreferences from "./courseFees/TopPreferences";

const CMResourcesTab: React.FC<{ resourcesData: any }> = ({
  resourcesData,
}) => {
  const {
    key_0,
    chatIntro,
    key_1,
    key_2,
    key_3,
    key_4,
    visualStories,
    similarColleges,
    chatWithAmbassadors,
  } = resourcesData;

  return (
    <>
      <div className="py-10 bg-[#f4f4f4]">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="w-full lg:w-9/12">
              {key_0 && (
                <CMRLatestUpdates
                  title={key_0.title}
                  description={key_0.description}
                />
              )}
              {key_3 && (
                <InternationalPartnerships
                  title={key_3.title}
                  description={key_3.description}
                />
              )}
            </div>
          </div>

          {key_1 && (
            <NewsAndArticles title={key_1.title} articles={key_1.array} />
          )}
        </div>
        <TopPreferences />
        <div className="container mx-auto">
          {chatIntro && key_4 && (
            <ChatWithAmbassadors
              title={chatIntro.title}
              description={chatIntro.description}
              ambassadors={key_4}
            />
          )}
        </div>
        {key_2 && <ResearchInitiatives initiatives={key_2} />}
        {/* {visualStories && <VisualStories stories={visualStories} />} */}
      </div>

      <div className="container mx-auto">
        {similarColleges && (
          <SimilarEngineeringColleges colleges={similarColleges} />
        )}
      </div>
    </>
  );
};

export default CMResourcesTab;
