import React from "react";
interface BenefitItem {
  title: string;
  description: string;
}

const ScholarshipDisbursement = () => {
  const benefits: BenefitItem[] = [
    {
      title: "Tuition fees",
      description: "will be disbursed directly to institutions.",
    },
    {
      title: "Cash prizes",
      description: "will be transferred to the winners' bank accounts.",
    },
    {
      title: "Enjoy a ₹5,000 to ₹10,000 scholarship",
      description:
        "applied at the time of admission to partner universities, exclusively through the College Mentor admission process. Scholarship scores will be prioritized in the admission process, with a list of participating universities provided upon results.",
    },
  ];

  const BenefitList: React.FC<{ benefits: BenefitItem[] }> = ({ benefits }) => {
    return (
      <ul>
        {benefits.map((benefit, index) => (
          <li key={index}>
            <span className="font-semibold">{benefit.title}</span>
            {` ${benefit.description}`}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <div className="px-20 py-10 w-full bg-[#EFF] max-lg:px-7 max-lg:w-full my-6">
      <div className="flex gap-5 max-lg:flex-col-reverse">
        <div className="flex flex-col w-1/2 max-lg:ml-0 max-lg:w-full">
          <img
            loading="lazy"
            src="/images/scholorshiptest/scholorship-disbusrement.jpg"
            alt="Eligibility Information"
            className="object-contain grow w-full rounded-xl aspect-[1.45] max-lg:mt-10 max-lg:max-w-full"
          />
        </div>
        <div className="flex flex-col space-y-6 w-1/2 max-lg:ml-0 max-lg:w-full my-auto leading-[30px] lg:p-4 max-lg:mx-auto">
          <h3 className="text-[#173CBA] text-[48px] font-light max-lg:text-[24px]">
            Scholarship Disbursement
          </h3>
          <div>
            <p className="p-2">
              Join the exclusive World Education Tour to Europe! Experience rich
              cultures, gain invaluable insights, and expand your horizons.
              Don't miss this chance to elevate your education.
            </p>
            <div className="mt-5 pl-7">
              <BenefitList benefits={benefits} />
              <strong className="inline-block mt-2">Note: </strong> Students
              need to present their achievements in the following areas.
              <ul>
                <li>
                  <strong>State-Level Sports Certificate:</strong> Achieved
                  within the last two years in any sport.
                </li>
                <li>
                  <strong>District-Level Sports Certificate:</strong> Achieved
                  within the last four years in any sport.
                </li>
                <li>
                  <strong>Painting & Sketching:</strong> Submit portfolio of
                  original artwork that showcases their skills and creativity.
                </li>
                <li>
                  <strong>Photography:</strong> Present a collection of
                  photographs or a photography project demonstrating technical
                  proficiency and artistic vision.
                </li>
                <li>
                  <strong>Community Service:</strong> Provide documentation of
                  involvement in meaningful community service projects,
                  highlighting the impact and leadership demonstrated.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipDisbursement;
