import React from "react";
import CreditScoreCheck from "./CreditScoreCheck";
import FavoriteFestivalPoll from "./FestivalPoll";
import CollegeCompare from "./Compare";
import CoursesList from "./CoursesList";
import ApplicationForms from "./ApplicationForms";

const SidebarAdd = () => {
  return (
    <div className="space-y-8">
      <ApplicationForms />

      {/* <CollegeCompare
        compare={{
          title: "",
          subtitle: "",
          buttonText: "",
          image: "",
        }}
      /> */}
      <CoursesList />
      <FavoriteFestivalPoll />
      <CreditScoreCheck />
    </div>
  );
};

export default SidebarAdd;
