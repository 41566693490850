import React from "react";

interface CampusItem {
  title: string;
  description: string;
}

interface MultiCampusOverviewProps {
  title: string;
  description: string;
  array: CampusItem[];
}

const MultiCampusOverview: React.FC<MultiCampusOverviewProps> = ({
  title,
  description,
  array,
}) => {
  return (
    <section className="flex flex-col gap-6">
      <div className="flex flex-col">
        <h4 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
          {title}
        </h4>
        <div
          className="text-base leading-relaxed bg-white p-3 flex flex-col gap-4 rankingsTab"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {array.map((campus, index) => (
          <div
            key={`${campus.title}-${index}`}
            className="bg-white p-6 rounded-lg shadow-md border border-gray-200"
          >
            <h3 className="text-2xl font-semibold mb-4 text-blue">
              {campus.title}
            </h3>
            <div
              className="text-base text-gray-700"
              dangerouslySetInnerHTML={{ __html: campus.description }}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default MultiCampusOverview;
