import React from "react";

interface Article {
  title: string;
  description: string;
  imageUrl?: string;
}

interface NewsAndArticlesProps {
  title: string;
  articles?: Article[];
}

const NewsAndArticles: React.FC<NewsAndArticlesProps> = ({
  title,
  articles = [],
}) => (
  <section className="NewsEvents bg-gray-100">
    <div className="flex flex-col gap-4 py-5">
      <h4 className="text-2xl font-semibold bg-blue text-white py-1 px-3 w-full lg:w-9/12">
        {title}
      </h4>
      <p>
        Explore the latest updates on college admissions and exam formats as
        universities strive to enhance student experience. Our dedicated
        ambassadors are here to provide insights and reviews on courses, helping
        you make informed decisions about your academic journey.
      </p>{" "}
      <p>
        Connect with us to get personalized advice and support tailored to your
        goals!
      </p>
    </div>
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 py-10">
      {articles.map((article, index) => (
        <div
          key={index}
          className="flex flex-col lg:flex-row justify-center items-center"
        >
          <div className="h-40 bg-gray-300 rounded-md overflow-hidden w-full lg:w-1/2">
            <img
              src={article.imageUrl || "https://via.placeholder.com/150x100"}
              alt={article.title}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="flex flex-col gap-2 p-10 bg-white w-full lg:w-1/2">
            <h3 className="text-sm font-semibold">{article.title}</h3>

            <p className="line-clamp-4">{article.description}</p>
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default NewsAndArticles;
