import React from "react";
import PlacementHighlights from "../placements/PlacementHighlights";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import TopRecruiters from "../placements/TopRecruiters";
import PlacementLatestUpdates from "../placements/PlacementLatestUpdates";
import ApplicationForms from "../sidebar/ApplicationForms";
import CoursesList from "../sidebar/CoursesList";

interface PlacementsTabProps {
  placementData: {
    key_3?: {
      title: string;
      description: string;
    };
    key_2?: {
      title: string;
      description: string;
    };
    key_1?: {
      title: string;
      description: string;
    };
    key_5?: {
      title: string;
      description: string;
    };
    key_4?: {
      title: string;
      description: string;
    };
    key_6?: {
      title: string;
      description: string;
    };
    key_0?: {
      title: string;
      description: string;
    };
    similarColleges?: Array<{
      image: string;
      name: string;
      logo: string;
      location: string;
      title: string;
    }>;
  };
}

const PlacementsTab: React.FC<PlacementsTabProps> = ({ placementData }) => {
  const { key_3, key_1, key_5, key_2, key_6, key_4, key_0, similarColleges } =
    placementData;

  return (
    <div className="py-10 bg-[#f4f4f4]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-9/12">
            {key_0 && (
              <PlacementLatestUpdates
                title={key_0.title}
                description={key_0.description}
              />
            )}
            {key_4 && (
              <PlacementHighlights
                title={key_4.title}
                description={key_4.description}
              />
            )}
            {key_1 && (
              <PlacementHighlights
                title={key_1.title}
                description={key_1.description}
              />
            )}

            {key_3 && (
              <PlacementHighlights
                title={key_3.title}
                description={key_3.description}
              />
            )}
            {key_5 && (
              <PlacementHighlights
                title={key_5.title}
                description={key_5.description}
              />
            )}

            {key_2 && (
              <PlacementHighlights
                title={key_2.title}
                description={key_2.description}
              />
            )}
            {key_6 && (
              <TopRecruiters
                title={key_6.title}
                description={key_6.description}
              />
            )}
          </div>
          <div className="w-full lg:w-3/12 hidden lg:block">
            <div className="flex flex-col gap-10">
              <ApplicationForms />
              <CoursesList />
            </div>
          </div>
        </div>
        {similarColleges && similarColleges.length > 0 && (
          <SimilarEngineeringColleges colleges={similarColleges} />
        )}
      </div>
    </div>
  );
};

export default PlacementsTab;
