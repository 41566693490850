import React from "react";
import Banner from "./components/Banner";
import HowItWorks from "./components/HowItWorks";
import TermsAndConditions from "./components/TermsAndConditions";
import WhatOurReferences from "./components/WhatOurReferences";
import ReferralBenefits from "./components/ReferralBenefits";
import TopEarners from "./components/TopEarners";
import FAQ from "./components/FAQ";
import ReferralSupport from "./components/ReferralSupport";

const ReferAndEarn = () => {
  return (
    <div>
      <Banner />
      <HowItWorks />
      <ReferralBenefits />
      <TermsAndConditions />
      <TopEarners />
      <WhatOurReferences />
      <FAQ />
      <ReferralSupport />
    </div>
  );
};

export default ReferAndEarn;
