import Filter from "./Filter";
import { useEffect, useState } from "react";
import axios from "axios";
import CollegeCard from "./CollegeCard";
import config from "../../../util/config";
import CollegesPreferenceSliderBox from "./Components/CollegesPreferenceSliderBox";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
const Explore = () => {
  const [responseData, setResponseData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [location, setLocation] = useState<string | null>(null);

  const filterData = useSelector((state: RootState) => state.filter.filterData);

  const filteredStreams = filterData["stream"]?.selected;
  const filteredStates = filterData["state"]?.selected;
  const filteredCities = filterData["city"]?.selected;

  useEffect(() => {
    const fetchUserExploreData = async () => {
      console.log("🍎🍎🍎🍎", filteredStreams, filteredStates, filteredCities);
      const requestBody = {
        stream: filteredStreams,
        states: filteredStates,
        city: filteredCities,
        fees: [],
        pageNo: 1,
        pageSize: 10,
      };

      try {
        const response = await axios.post(
          config.apiUrl + "/api/auth/user/userExplore",
          requestBody,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        setResponseData(response.data.data);
        console.log(responseData);
      } catch (error) {
        console.error("Error fetching user explore data:", error);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    if (filteredStreams && filteredStates && filteredCities)
      fetchUserExploreData();
  }, [filteredStreams, filteredStates, filteredCities]);
  const getLocation = (college: any) => {
    return college.collegeCity && college.collegeState
      ? `${college.collegeCity}, ${college.collegeState}`
      : "Location not available";
  };
  return (
    <section className="flex flex-col gap-4 md:p-8">
      <Filter />
      {/* <div className="grid grid-cols-1 gap-4 m-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {responseData?.popularColleges?.map((college: any, index: number) => (
          <CollegeCard
            key={index}
            collegeName={college.collegeName}
            image={college.iconUrl}
            category={college.category}
            categoryId={college.collegeId}
            location={getLocation(college)}
            type={"explore"}
          />
        ))}
      </div> */}

      {/* <div className="border border-[#e0e0e9] bg-gradient-to-b from-[#e9ecff] to-white p-6 pb-3 relative mb-6 flex flex-col gap-4">
        <div className="">
          <span className="bg-[#f5a041] font-medium px-4 py-1.5 inline-flex rounded-md text-sm">
            RECOMMENDED COLLEGES
          </span>
        </div>
        <div className="flex flex-col justify-between md:flex-row">
          <div className="MainHeadleft">
            <span>
              Engineering Colleges Near Your Preferred City (Fees ~Rs 60K per
              year)
            </span>
          </div>
          <div className="MainHeadright">
            <span>4 colleges</span>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {responseData?.popularColleges
            ?.slice(0, 4)
            .map((college: any, index: number) => (
              <CollegeCard
                key={index}
                collegeName={college.collegeName}
                image={college.iconUrl}
                category={college.category}
                categoryId={college.collegeId}
                location={getLocation(college)}
                type={"explore"}
              />
            ))}
        </div>
      </div> */}

      <CollegesPreferenceSliderBox
        header="POPULAR COLLEGES"
        title="Popular Colleges You Might Be Interested In"
        collegesList={responseData?.popularColleges}
      />
      {/* <CollegesPreferenceSliderBox
        header="POPULAR COLLEGES"
        title="Engineering Colleges Near Your Preferred City (Fees ~Rs 60K per year)"
        collegesList={responseData?.popularColleges}
      /> */}
    </section>
  );
};

export default Explore;
