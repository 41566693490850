import {
  faArrowRight,
  faCalendar,
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CmLogo from "../../../images/College Mentor_Final_Logo-01.png";
import DefaultThumbNail from "../../../images/blog-article/default.png";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FC, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { setCurrentCategoryPageValue } from "../../../store/slices/blogSlice";
import { captilizeFirstLetter } from "../../../util/helpers/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import "./BlogFooter.scss";
import { getBlogOrNewsUrl } from "../utils";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import Disclaimer from "./Disclaimer";
interface IProps {
  sectionsList?: any;
}
// interface ModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   content: React.ReactNode;

// }

// const Modal: React.FC<ModalProps> = ({ isOpen, onClose, content }) => {
//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-white">
//       <div className="p-4 bg-white rounded shadow-lg z-150">
//         <button onClick={onClose} className="mb-2 bg-black">
//           Close
//         </button>
//         <div className="text-black">{content}</div>
//       </div>
//     </div>
//   );
// };

const BlogFooter: FC<IProps> = ({ sectionsList }) => {
  const pulseRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);

  // const openModal = (type: "terms" | "privacy" | "disclaimer") => {
  //   let content;
  //   if (type === "terms") {
  //     content = <TermsAndConditions />;
  //   } else if (type === "privacy") {
  //     content = <PrivacyPolicy />;
  //   } else if (type === "disclaimer") {
  //     content = <Disclaimer />;
  //   }
  //   setModalContent(content);
  //   setIsModalOpen(true);
  // };
  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      pulseRefs.current.forEach((ref, i) => {
        if (i == index) ref?.classList.add("active");
        else ref?.classList.remove("active");
      });
      index = (index + 1) % 4;
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  });

  const latestNews = useSelector((state: RootState) => {
    const sections = state.blogSlice.landingData?.sections;
    let data = null;
    if (sections)
      data =
        sections[0].topArticlesAndBlogsAndLatestNews?.subcategories?.[
          "Articles & Blogs"
        ]?.results;
    return data?.slice(0, 3);
  });

  const LinkItem = ({ section }: any) => (
    <Link to={`/blogs/${section.slug}`}>{section.title}</Link>
  );

  if (!sectionsList || !latestNews) return null;

  return (
    <section className="relative pt-12 pb-4 overflow-hidden text-base text-white blog-footer bg-green">
      <div className="container relative z-10 flex gap-12 max-lg:flex-col lg:gap-8">
        <div className="flex flex-col flex-1 gap-4">
          <img
            src={CmLogo}
            alt="college mentor logo"
            className="w-48 p-2 bg-white rounded-lg"
          />
          <p className="mt-6">
            College Mentor connects you with Mentors who share their
            experiences, provide insights, educational content and inspire you
            to reach for the stars. It's Mentorship in the Digital Age.
          </p>
          <div className="flex flex-col gap-2 w-fit">
            <div className="flex items-center gap-2 px-4 py-2 bg-[#ffffff33] rounded-full">
              <FontAwesomeIcon icon={faEnvelope} />
              <span className="font-bold opacity-80">Mail:</span>
              <span>admin@dreamsanddegrees.com</span>
            </div>
            <div className="flex items-center gap-2 px-4 py-2 bg-[#ffffff33] rounded-full">
              <FontAwesomeIcon icon={faLocationDot} />
              <span className="font-bold opacity-80">Address:</span>
              <span>Hyderabad, Telangana</span>
            </div>
            <div className="flex items-center gap-2 px-4 py-2 bg-[#ffffff33] rounded-full">
              <FontAwesomeIcon icon={faPhone} />
              <span className="font-bold opacity-80">Phone:</span>
              <span>+91 7997166666</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-1 gap-4">
          <h3>News & Article Categories</h3>
          <div className="bg-white opacity-80">
            <div className="w-24 h-0.5 bg-blue"></div>
          </div>
          <div className="flex flex-col gap-4 mt-6">
            {sectionsList.map((section: any) => (
              <LinkItem section={section} />
            ))}
          </div>
        </div>
        <div className="flex flex-col flex-1 gap-4">
          <h3>Latest Posts</h3>
          <div className="bg-white opacity-80">
            <div className="w-24 h-0.5 bg-blue"></div>
          </div>
          {latestNews &&
            latestNews.map((item: any) => (
              <BlogCard
                url={getBlogOrNewsUrl(item)}
                image={item.imageUrl || DefaultThumbNail}
                title={item.title}
                date={formatDate(item.publishedDate)}
              />
            ))}
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-fit">
        <img src="/images/blog/blog-footer-bg.png" />
        <div
          className="absolute w-2 h-2 rounded-full bg-pink right-36 top-48 pulse"
          ref={(el) => (pulseRefs.current[3] = el)}
        ></div>
        <div
          className="absolute w-2 h-2 rounded-full bg-pink bottom-12 right-16 pulse"
          ref={(el) => (pulseRefs.current[0] = el)}
        ></div>
        <div
          className="absolute w-2 h-2 rounded-full bg-pink top-36 left-60 pulse"
          ref={(el) => (pulseRefs.current[2] = el)}
        ></div>
        <div
          className="absolute w-2 h-2 rounded-full bg-pink top-36 left-24 pulse"
          ref={(el) => (pulseRefs.current[1] = el)}
        ></div>
      </div>
      <div className="absolute left-0 right-0 -bottom-32">
        <svg viewBox="0 0 100 25">
          <path fill="#ffffff22" d="M0 30 V12 Q30 17 55 12 T100 11 V30z"></path>
        </svg>
      </div>
      <div className="flex flex-col w-full gap-4 mt-8 max-lg:items-center">
        <div className="bg-white opacity-80">
          <div className="w-24 h-0.5 bg-blue mx-auto max-lg:mx-0"></div>
        </div>
        <div className="container flex flex-col lg:flex-row justify-between items-center z-10">
          <div className="flex justify-start">
            <Link
              to="/blogs/disclaimer"
              className="text-white px-2 md:px-4 py-2 rounded border-r border-white whitespace-nowrap"
            >
              <span className="font-bold">Disclaimer</span>
            </Link>
            <Link
              to="/blogs/policy"
              className="text-white px-2 md:px-4 py-2 rounded border-r border-white whitespace-nowrap"
            >
              <span className="font-bold">Privacy Policy</span>
            </Link>
            <Link
              to="/blogs/terms"
              className="text-white px-2 md:px-4 py-2 rounded whitespace-nowrap"
            >
              <span className="font-bold">Terms & Conditions</span>
            </Link>
          </div>

          <div className="text-center px-4 whitespace-nowrap">
            Copyright &copy; {new Date().getFullYear()} Dreams and Degrees Pvt
            Ltd
          </div>

          <div className="flex justify-end whitespace-nowrap">
            <span>
              Partner Site: <span className="font-bold">College Mentor</span>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogFooter;

interface IBlogCard {
  image: string;
  title: string;
  date: string;
  url: string;
}

const BlogCard: FC<IBlogCard> = ({ image, title, date, url }) => {
  return (
    <Link to={url} className="flex block gap-2">
      <img src={image} className="w-36 shrink-0" />
      <div className="pb-4 border-b border-white">
        <h5 className="text-lg font-bold line-clamp-2">{title}</h5>
        <div className="flex items-center gap-4 mt-4">
          <FontAwesomeIcon icon={faCalendar} className="text-blue" />
          <span>{date}</span>
        </div>
      </div>
    </Link>
  );
};

function formatDate(isoString: string) {
  const date = new Date(isoString);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return `${
    monthNames[date.getUTCMonth()]
  } ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
}
