import React from "react";

interface SocialSectionProps {
  title: string;
  content?: string;
}

const SocialSection: React.FC<SocialSectionProps> = ({ title, content }) => {
  return (
    <div className="social-section py-6">
      <h4 className="text-3xl font-semibold text-[#113cc0] text-center">
        {title}
      </h4>
      {content && (
        <div
          className="text-lg leading-relaxed mt-4"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </div>
  );
};

export default SocialSection;
