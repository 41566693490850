import React from "react";

interface EligibilityCriteriaSectionProps {
  title: string;
  description: string;
}

const EligibilityCriteriaSection: React.FC<EligibilityCriteriaSectionProps> = ({
  title,
  description,
}) => {
  return (
    <div className="section-container">
      <div className="flex flex-col gap-5">
        <h3 className="text-3xl font-bold text-blue">{title}</h3>
        <div
          className="text-black text-base examDetailsTable examDetailsList flex flex-col gap-4"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};

export default EligibilityCriteriaSection;
