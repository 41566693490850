import React, { useEffect, useState, useRef } from "react";
import "./AboutUs.scss";
import { usePageLoad } from "./PageLoadContext";
import OurTeam from "./components/OurTeam";

import { Reveal, Slide } from "react-awesome-reveal";
import {
  customAnimationLeft,
  customAnimationLeftLong,
  customAnimationRightLong,
  customAnimationTopLong,
  customAnimationBottomLong,
  customAnimationRight,
  customAnimationBottom,
} from "./components/keyframes";
import { Link } from "react-router-dom";
import Hero from "./components/Hero";
import CircleHeadingTextHighlight from "./images/mission-vision-heading-text-highlight.svg";
import SketchHeadingTextHighlight from "./images/our-investors-heading-text-highlight.svg";
import BlogHeadingTextHighlight from "./images/blogs-heading-text-highlight.svg";
import CTAButton from "./components/CTAButton";
import malePlaceholderImage from "./images/male-placeholder-image.png";
const whyDreamsData = [
  {
    img: "/images/about/why-dreams-student.png",
    bgColor: "#FFF",
    text: (
      <>
        Student <span className="inline-block ml-4 scale-150">&rarr;</span>
      </>
    ),
    link: "/students",
  },
  {
    img: "/images/about/why-dreams-parent.png",
    bgColor: "#FCD6A1",
    text: (
      <>
        Parent <span className="inline-block ml-4 scale-150">&rarr;</span>
      </>
    ),
    link: "/parents",
  },
  {
    img: "/images/about/why-dreams-institute.png",
    bgColor: "#FFF",
    text: (
      <>
        Institute <span className="inline-block ml-4 scale-150">&rarr;</span>
      </>
    ),
    link: "/educational-institutes",
  },
];

export default function AboutUs() {
  // const { hasLoadingBeenShown, setHasLoadingBeenShown } = usePageLoad();
  const whyDreamsSecRef = useRef<HTMLDivElement>(null);
  const missionVisionElementRef = useRef<HTMLDivElement>(null);

  const [isWhyDreamsPinned, setIsWhyDreamsPinned] = useState(false);
  const [isWhyDreamsBeforePin, setIsWhyDreamsBeforePin] = useState(true);
  const [whyDreamsCardIndex, setWhyDreamsCardIndex] = useState(0);

  const isMobileView = window.innerWidth < 640;

  const scrollToMissionVisionSection = () => {
    if (missionVisionElementRef.current) {
      missionVisionElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScrollForPin = (
      elementRef: React.RefObject<HTMLDivElement>,
      setIsPinned: {
        (value: React.SetStateAction<boolean>): void;
        (arg0: boolean): void;
      },
      setIsBeforePin: (arg0: boolean) => void
    ) => {
      const element = elementRef.current;
      if (element) {
        const { top, bottom } = element.getBoundingClientRect();
        if (top <= 0 && bottom >= window.innerHeight) {
          setIsPinned(true);
        } else if (top > 0) {
          setIsPinned(false);
          setIsBeforePin(true);
        } else if (bottom < window.innerHeight) {
          setIsPinned(false);
          setIsBeforePin(false);
        }
      }
    };

    window.addEventListener("scroll", () =>
      handleScrollForPin(
        whyDreamsSecRef,
        setIsWhyDreamsPinned,
        setIsWhyDreamsBeforePin
      )
    );

    return () => {
      window.removeEventListener("scroll", () =>
        handleScrollForPin(
          whyDreamsSecRef,
          setIsWhyDreamsPinned,
          setIsWhyDreamsBeforePin
        )
      );
    };
  }, []);

  useEffect(() => {
    const handleScrollIndexUpdate = () => {
      const element = whyDreamsSecRef.current;
      if (element) {
        const { top, bottom } = element.getBoundingClientRect();
        if (top <= 0 && bottom >= window.innerHeight) {
          let value = Math.floor((top * -1) / (window.innerHeight / 2));
          value = Math.min(value, 2);
          value = Math.max(value, 0);
          setWhyDreamsCardIndex(value);
        }
      }
    };

    window.addEventListener("scroll", handleScrollIndexUpdate);

    return () => window.removeEventListener("scroll", handleScrollIndexUpdate);
  });

  // useEffect(() => {
  //   if (!hasLoadingBeenShown) {
  //     const timer = setTimeout(() => {
  //       setHasLoadingBeenShown(true);
  //     }, 3000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [hasLoadingBeenShown, setHasLoadingBeenShown]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // if (!hasLoadingBeenShown)
  //   return (
  //     <div className="loader" style={{ zIndex: 9999 }}>
  //       <Reveal keyframes={customAnimationLeft}>
  //         <h2 className="text-center heading2 text-primary">
  //           <span>Dreams</span>
  //         </h2>
  //       </Reveal>
  //       <Reveal keyframes={customAnimationBottom} delay={1000}>
  //         <h2 className="text-center text-black heading2">
  //           <span>and</span>
  //         </h2>
  //       </Reveal>
  //       <Reveal keyframes={customAnimationRight} delay={2000}>
  //         <h2 className="text-center heading2 text-secondary">
  //           <span>Degrees</span>
  //         </h2>
  //       </Reveal>
  //     </div>
  //   );

  return (
    <div className="text-black home-page">
      <Hero scrollToMissionVisionSection={scrollToMissionVisionSection} />
      <div
        ref={missionVisionElementRef}
        className="relative -translate-y-24"
      ></div>
      <section className="mission-vision pb-12 bg-[#EAF8FB] relative">
        <div className="container max-w-[1024px] flex flex-col items-center gap-12 sm:gap-4 relative z-10">
          <div className="flex flex-col">
            <Reveal keyframes={customAnimationRight}>
              <h2 className="items-center gap-4 text-center heading2">
                We want to{" "}
                <div className="relative inline-block">
                  <div className="relative z-10 my-2 text-primary">
                    reinvent
                  </div>
                  <img
                    src={CircleHeadingTextHighlight}
                    className="absolute left-0 z-0 -translate-y-1/2 top-1/2"
                  />
                </div>{" "}
                the wheel.
              </h2>
              <p className="w-full mx-auto text-center text1 lg:w-2/3">
                Dreams and Degrees envisions a world where education transcends
                boundaries, empowering all to pursue dreams, innovate, and
                thrive. Our mission pioneers inclusive pathways, fostering
                lifelong learning and resilience.
              </p>
            </Reveal>
          </div>
          <div className="flex flex-col items-stretch justify-center gap-12 md:flex-row sm:gap-16">
            <Reveal
              keyframes={customAnimationLeftLong}
              className="card w-fit sm:w-1/2 lg:w-1/3"
            >
              <div className="flex flex-col items-stretch h-full group">
                <div className="relative overflow-hidden ">
                  <div className="absolute top-0 bottom-0 left-0 w-0 bg-[#00000066] z-10 group-hover:w-full transition-width duration-500 ease-in-out"></div>
                  <img
                    className="object-cover w-full h-full transition duration-500 ease-in-out transform group-hover:scale-110"
                    src={"/images/about/our-vision-graphic.jpg"}
                    alt="Our vision photo"
                  />
                </div>
                <div className="flex-1 p-6 bg-white">
                  <div className="flex items-center gap-4">
                    <h2 className="w-auto heading3" data-splitting="">
                      Our Vision
                    </h2>
                    <div className="h-0.5 flex-1 bg-secondary"></div>
                  </div>
                  <p className="text2">
                    To empower learners, Dreams and Degrees envisions a future
                    where every individual has access to personalized
                    educational experiences.
                  </p>
                </div>
              </div>
            </Reveal>
            <Reveal
              keyframes={customAnimationRightLong}
              delay={200}
              className="card w-fit sm:w-1/2 lg:w-1/3"
            >
              <div className="flex flex-col items-stretch h-full group">
                <div className="relative overflow-hidden ">
                  <div className="absolute top-0 bottom-0 left-0 w-0 bg-[#00000066] z-10 group-hover:w-full transition-width duration-500 ease-in-out"></div>
                  <img
                    className="object-cover w-full h-full transition duration-500 ease-in-out transform group-hover:scale-110"
                    src={"/images/about/our-mission-graphic.jpg"}
                    alt="Our mission photo"
                  />
                </div>
                <div className="flex-1 p-6 bg-white">
                  <div className="flex items-center gap-4">
                    <h2 className="w-auto heading3" data-splitting="">
                      Our Mission
                    </h2>
                    <div className="h-0.5 flex-1 bg-secondary"></div>
                  </div>
                  <p className="text2">
                    At Dreams and Degrees, our mission is to revolutionize
                    education by providing comprehensive end to end counselling
                    and personalized learning solution.
                  </p>
                </div>
              </div>
            </Reveal>
          </div>
        </div>
      </section>
      <section
        className="relative justify-center hidden transition-colors duration-500 ease-in-out WhyDreams lg:flex"
        style={{
          backgroundColor: whyDreamsData[whyDreamsCardIndex]?.bgColor,
          height: "250vh",
        }}
        ref={whyDreamsSecRef}
      >
        <div className="sticky top-12 container max-w-[1200px] pt-12 sm:pt-0 pb-6 sm:mt-0 h-[90vh] sm:h-screen w-full mx-auto flex flex-col lg:flex-row lg:flex-row justify-around  lg:justify-stretch items-center gap-4">
          <div className="w-full lg:w-3/5 lg:pr-[5vw] relative">
            <Reveal keyframes={customAnimationLeft}>
              <h3 className="heading2 sm:mb-6">
                Why Dreams and{" "}
                <div className="relative inline-block">
                  <div className="relative z-10 my-2 text-primary">
                    Degrees?
                  </div>
                  <img
                    src={SketchHeadingTextHighlight}
                    className="absolute left-0 z-0 -translate-y-1/2 top-1/2"
                  />
                </div>
              </h3>
            </Reveal>
            <Reveal keyframes={customAnimationLeft}>
              <div className="min-h-36 md:min-h-24 lg:min-h-36">
                {whyDreamsCardIndex == 0 && (
                  <p className="mb-5 text1">
                    Dreams and Degrees stands as India's foremost career
                    guidance platform, offering state-of-the-art educational
                    technology solutions tailored for parents, students, and
                    counselors alike.
                  </p>
                )}
                {whyDreamsCardIndex == 1 && (
                  <p className="mb-5 text1">
                    Our mission is to become the primary ‘Trusted Mentor’ for
                    every parent throughout their child's career expedition.
                  </p>
                )}
                {whyDreamsCardIndex == 2 && (
                  <p className="mb-5 text1">
                    We offer complete career guidance, leveraging university
                    partnerships, expert advisors, and advanced educational
                    tech, catering to both students and parents.
                  </p>
                )}
              </div>
            </Reveal>
            <CTAButton
              text={whyDreamsData[whyDreamsCardIndex]?.text}
              link={whyDreamsData[whyDreamsCardIndex]?.link}
            />
          </div>
          <div className="relative flex items-center justify-center w-full overflow-hidden lg:w-2/5">
            {whyDreamsData.map(
              (data, index) =>
                data.img &&
                data.text && (
                  <div
                    key={index}
                    style={{ color: "#FFFFFF" }}
                    className={`whyDreamsCard w-fit h-full flex-col items-center justify-center ${index === whyDreamsCardIndex
                      ? "active"
                      : index < whyDreamsCardIndex
                        ? "in-active"
                        : ""
                      }`}
                  >
                    <img
                      src={data.img}
                      alt={`why dreams image - ${index + 1}`}
                      className="object-cover w-auto h-full imagewhy"
                      loading="lazy"
                    />
                  </div>
                )
            )}
          </div>
        </div>
      </section>
      <section className="flex flex-col lg:hidden">
        <div className="WhyDreamsMobile relative py-12 bg-[#ffffff]">
          <div className="container max-w-screen-xl mx-auto">
            <div className="flex flex-col gap-4">
              <div className="w-full lg:w-3/5 lg:pr-[5vw] relative flex flex-col justify-center">
                <Reveal keyframes={customAnimationLeft}>
                  <h3 className="text-center heading2 sm:mb-6">
                    Why Dreams and{" "}
                    <div className="relative inline-block">
                      <div className="relative z-10 my-2 text-primary">
                        Degrees?
                      </div>
                      <img
                        src={SketchHeadingTextHighlight}
                        className="absolute left-0 z-0 -translate-y-1/2 top-1/2"
                      />
                    </div>
                  </h3>
                </Reveal>
                <Reveal keyframes={customAnimationLeft}>
                  <div className="flex flex-col w-[95%] mx-auto">
                    <p className="mb-5 text-center text1">
                      Dreams and Degrees stands as India's foremost career
                      guidance platform, offering state-of-the-art educational
                      technology solutions tailored for parents, students, and
                      counselors alike.
                    </p>
                  </div>
                </Reveal>
                <div className="flex justify-center w-full">
                  <a
                    href="/students"
                    className="px-8 py-4 text-white CTABtn w-fit heading5 bg-primary hover:text-black hover:bg-secondary"
                  >
                    Student &rarr;
                  </a>
                </div>
              </div>
              <div className="relative flex items-center justify-center w-full overflow-hidden lg:w-2/5">
                <div className="flex-col items-center justify-center w-full h-full">
                  <Reveal keyframes={customAnimationLeft}>
                    <img
                      src={whyDreamsData[0].img}
                      alt="why dreams image"
                      className="object-cover w-full h-full imagewhy"
                      loading="lazy"
                    />
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="WhyDreamsMobile relative py-12 bg-[#FCD6A1]">
          <div className="container max-w-screen-xl mx-auto">
            <div className="flex flex-col gap-4">
              <div className="w-full lg:w-3/5 lg:pr-[5vw] flex flex-col justify-center">
                <Reveal keyframes={customAnimationLeft}>
                  <h3 className="text-center heading2 sm:mb-6">
                    Why Dreams and{" "}
                    <div className="relative inline-block">
                      <div className="relative z-10 my-2 text-primary">
                        Degrees?
                      </div>
                      <img
                        src={SketchHeadingTextHighlight}
                        className="absolute left-0 z-0 -translate-y-1/2 top-1/2"
                      />
                    </div>
                  </h3>
                </Reveal>
                <Reveal keyframes={customAnimationLeft}>
                  <div className="flex flex-col w-[95%] mx-auto">
                    <p className="mb-5 text-center text1">
                      Our mission is to become the primary ‘Trusted Mentor’ for
                      every parent throughout their child's career expedition.
                    </p>
                  </div>
                </Reveal>
                <div className="flex justify-center w-full">
                  <a
                    href="/parents"
                    className="px-8 py-4 text-white CTABtn w-fit heading5 bg-primary hover:text-black hover:bg-secondary"
                  >
                    Parent &rarr;
                  </a>
                </div>
              </div>
              <div className="relative flex items-center justify-center w-full overflow-hidden lg:w-2/5">
                <div className="flex-col items-center justify-center w-full h-full">
                  <Reveal keyframes={customAnimationLeft}>
                    <img
                      src={whyDreamsData[1].img}
                      alt="why dreams image"
                      className="object-cover w-full h-full imagewhy"
                      loading="lazy"
                    />
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative py-12 bg-white WhyDreamsMobile">
          <div className="container max-w-screen-xl mx-auto">
            <div className="flex flex-col gap-4">
              <div className="w-full lg:w-3/5 lg:pr-[5vw] relative flex justify-center flex-col">
                <Reveal keyframes={customAnimationLeft}>
                  <h3 className="text-center heading2 sm:mb-6">
                    Why Dreams and{" "}
                    <div className="relative inline-block">
                      <div className="relative z-10 my-2 text-primary">
                        Degrees?
                      </div>
                      <img
                        src={SketchHeadingTextHighlight}
                        className="absolute left-0 z-0 -translate-y-1/2 top-1/2"
                      />
                    </div>
                  </h3>
                </Reveal>
                <Reveal keyframes={customAnimationLeft}>
                  <div className="flex flex-col w-[95%] mx-auto">
                    <p className="mb-5 text-center text1">
                      We offer complete career guidance, leveraging university
                      partnerships, expert advisors, and advanced educational
                      tech, catering to both students and parents.
                    </p>
                  </div>
                </Reveal>
                <div className="flex justify-center w-full">
                  <a
                    href="/educational-institutes"
                    className="px-8 py-4 text-white CTABtn w-fit heading5 bg-primary hover:text-black hover:bg-secondary"
                  >
                    Institute &rarr;
                  </a>
                </div>
              </div>
              <div className="relative flex items-center justify-center w-full overflow-hidden lg:w-2/5">
                <div className="flex-col items-center justify-center w-full h-full">
                  <Reveal keyframes={customAnimationLeft}>
                    <img
                      src={whyDreamsData[2].img}
                      alt="why dreams image"
                      className="object-cover w-full h-full imagewhy"
                      loading="lazy"
                    />
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ourInvestors py-12 bg-[#EAF8FB]">
        <div className="container max-w-[1024px]">
          <div className="flex items-center justify-center gap-3">
            <Slide>
              <h2 className="text-center heading2">Meet our</h2>
            </Slide>
            <Slide direction="right">
              <h2 className="text-center heading2">
                <div className="relative inline-block">
                  <div className="relative z-10 my-2 text-primary">
                    Investors
                  </div>
                  <img
                    src={SketchHeadingTextHighlight}
                    className="absolute left-0 z-0 -translate-y-1/2 top-1/2"
                  />
                </div>
              </h2>
            </Slide>
          </div>
          <div className="grid grid-cols-1 gap-8 mt-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
            <Reveal keyframes={customAnimationBottomLong} className="w-full">
              <div className="info-card">
                <img
                  className="image md:w-3/4 lg:w-full"
                  src={malePlaceholderImage}
                  alt="M.Raja Shekar"
                />
                <div className="overlay"></div>
                <div className="text-white info">
                  <h4 className="heading4">M.Raja Shekar</h4>
                  <h5 className="mb-4 heading5">Founder</h5>
                </div>
                <div className="progress-bar">
                  <div className="fill"></div>
                </div>
              </div>
            </Reveal>
            <Reveal
              keyframes={customAnimationBottomLong}
              delay={200}
              className="w-full"
            >
              <div className="info-card">
                <img
                  className="image md:w-3/4 lg:w-full"
                  src={malePlaceholderImage}
                  alt="Vamsee Prasad Kotha"
                />
                <div className="overlay"></div>
                <div className="text-white info">
                  <h4 className="heading4">Vamsee Prasad Kotha</h4>
                  <h5 className="mb-4 heading5">Co - Founder</h5>
                </div>
                <div className="progress-bar">
                  <div className="fill"></div>
                </div>
              </div>
            </Reveal>
            <Reveal
              keyframes={customAnimationBottomLong}
              delay={400}
              className="w-full"
            >
              <div className="info-card">
                <img
                  className="image md:w-3/4 lg:w-full"
                  src={malePlaceholderImage}
                  alt="P. Siddharth"
                />
                <div className="overlay"></div>
                <div className="text-white info">
                  <h4 className="heading4">P. Siddharth</h4>
                  <h5 className="mb-4 heading5">Advisory and Investor</h5>
                </div>
                <div className="progress-bar">
                  <div className="fill"></div>
                </div>
              </div>
            </Reveal>
          </div>
        </div>
      </section>
      <section className="relative py-12 ourTeam">
        <div className="container max-w-[1024px]">
          <OurTeam />
        </div>
      </section>
      <section className="relative pb-12 blogs-section">
        <Link
          to={"/blog"}
          className="absolute left-full top-48 z-30 w-max bg-[#003fbc] p-2 transform rotate-90 origin-top-left"
        >
          <h5 className="text-white heading5">Featured Blogs</h5>
        </Link>
        <div className="container max-w-[1024px] flex flex-col items-center relative z-10">
          <div className="flex items-center justify-center gap-3 mb-4">
            <Slide>
              <h2 className="text-center heading2">Read our</h2>
            </Slide>
            <Slide direction="right">
              <h2 className="text-center heading2">
                <div className="relative inline-block">
                  <div className="relative z-10 my-2 text-primary">Blogs</div>
                  <img
                    src={BlogHeadingTextHighlight}
                    className="absolute left-0 z-0 -translate-y-1/2 top-1/2"
                  />
                </div>
              </h2>
            </Slide>
          </div>
          <div className="flex flex-col gap-2 md:flex-row">
            <div className="flex flex-col gap-2 w-full md:w-[50%] lg:w-[30%]">
              <Reveal keyframes={customAnimationLeftLong} className="h-full">
                <Link to={"/post/top-10-study-tips"} className="info-card">
                  <img
                    className="w-1/2 image"
                    src={"/images/about/blog1.jpeg"}
                  />
                  <div className="overlay"></div>
                  <div className="info">
                    <h1 className="mb-4 text-xl font-bold text-white sm:mb-8">
                      Top 10 Study Tips for Exam Preparation
                    </h1>
                    <p className="text-white">
                      Here are the top 10 study tips to help you prepare
                      effectively and perform your best in exams.
                    </p>
                  </div>
                  <div className="progress-bar">
                    <div className="fill"></div>
                  </div>
                </Link>
              </Reveal>
              <Reveal
                keyframes={
                  isMobileView
                    ? customAnimationRightLong
                    : customAnimationTopLong
                }
                delay={200}
                className="block lg:hidden"
              >
                <Link
                  to={"/post/importance-of-one-on-one"}
                  className="info-card"
                >
                  <img className="image" src={"/images/about/blog2.jpg"} />
                  <div className="overlay"></div>
                  <div className="info">
                    <h1 className="mb-4 text-xl font-bold text-white sm:mb-8">
                      The Importance of One-on-One Tutoring in Education
                    </h1>
                    <p className="text-white">
                      One-on-one tutoring plays a pivotal role in education,
                      offering personalized support.
                    </p>
                  </div>
                  <div className="progress-bar">
                    <div className="fill"></div>
                  </div>
                </Link>
              </Reveal>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full md:w-[50%] lg:w-[70%]">
              <Reveal
                keyframes={
                  isMobileView
                    ? customAnimationRightLong
                    : customAnimationTopLong
                }
                delay={200}
                className="hidden lg:block"
              >
                <Link
                  to={"/post/importance-of-one-on-one"}
                  className="info-card"
                >
                  <img className="image" src={"/images/about/blog2.jpg"} />
                  <div className="overlay"></div>
                  <div className="info">
                    <h1 className="mb-4 text-xl font-bold text-white sm:mb-8">
                      The Importance of One-on-One Tutoring in Education
                    </h1>
                    <p className="text-white">
                      One-on-one tutoring plays a pivotal role in education,
                      offering personalized support.
                    </p>
                  </div>
                  <div className="progress-bar">
                    <div className="fill"></div>
                  </div>
                </Link>
              </Reveal>
              <Reveal
                keyframes={
                  isMobileView
                    ? customAnimationLeftLong
                    : customAnimationRightLong
                }
                delay={400}
              >
                <Link
                  to={"/post/navigating-college-application-process"}
                  className="info-card"
                >
                  <img className="image" src={"/images/about/blog3.jpg"} />
                  <div className="overlay"></div>
                  <div className="info">
                    <h1 className="mb-4 text-xl font-bold text-white sm:mb-8">
                      Navigating the College Application Process
                    </h1>
                    <p className="text-white">
                      With the right guidance and resources, college application
                      it can be navigated smoothly.
                    </p>
                  </div>
                  <div className="progress-bar">
                    <div className="fill"></div>
                  </div>
                </Link>
              </Reveal>
              <Reveal
                keyframes={
                  isMobileView
                    ? customAnimationRightLong
                    : customAnimationBottomLong
                }
                delay={600}
              >
                <Link
                  to={"/post/role-of-counseling-services-in-academic-success"}
                  className="info-card"
                >
                  <img className="image" src={"/images/about/blog6.jpg"} />
                  <div className="overlay"></div>
                  <div className="info">
                    <h1 className="mb-4 text-xl font-bold text-white sm:mb-8">
                      The Role of Counseling Services in Academic Success
                    </h1>
                    <p className="text-white">
                      Counseling services play a crucial role in fostering
                      academic success among students at all levels of
                      education.
                    </p>
                  </div>
                  <div className="progress-bar">
                    <div className="fill"></div>
                  </div>
                </Link>
              </Reveal>
              <Reveal
                keyframes={
                  isMobileView
                    ? customAnimationLeftLong
                    : customAnimationRightLong
                }
                delay={800}
              >
                <Link
                  to={"/post/effective-strategies-for-managing"}
                  className="info-card"
                >
                  <img className="image" src={"/images/about/blog5.jpg"} />
                  <div className="overlay"></div>
                  <div className="info">
                    <h1 className="mb-4 text-xl font-bold text-white sm:mb-8">
                      Effective Strategies for Managing Test Anxiety
                    </h1>
                    <p className="text-white">
                      Effective strategies for managing test anxiety can help
                      students perform better on exams.
                    </p>
                  </div>
                  <div className="progress-bar">
                    <div className="fill"></div>
                  </div>
                </Link>
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
