import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import LeadGeneration from "../../../components/LeadGeneration";

const CollegeAdmissionProcess = ({
  handleLeadGen,
}: {
  handleLeadGen: (leadPageName: string, leadOn: string) => void;
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const swiperRef = useRef<any>(null);

  const handlePopup = () => {
    handleLeadGen(
      "application-to-direct-admission-landing",
      "register-now-to-get-started"
    );
  };

  const steps = [
    {
      label: "Application to Admission",
      image: "/images/admission-process.jpg",
      content: (
        <>
          <p>
            <strong>
              Register College Mentor and allow all colleges to contact you.
            </strong>
            <br />
            Add a Application to Direct Admission College to your list on
            College Mentor.
          </p>
          <p className="mt-5">
            <strong>Eligibility Criteria:</strong> Students from all over India
            who meet certain academic and other predefined criteria are
            automatically considered for admission to participating colleges
            through College Mentor. These criteria may include academic
            performance scores, standardized test scores, and other factors
            determined by the participating institutions.
          </p>
          <p className="mt-5">
            <strong>Application to Admission:</strong> Eligible students receive
            an admission offer letter from their dream college through a
            streamlined process. This approach reduces the burden on students
            and simplifies the entire admissions procedure.
          </p>
          <p className="mt-5">
            <strong>Enrollment Decision:</strong> Once offered automatic
            admission, students have the option to explore the college further,
            visit the campus, and determine if it aligns with their academic and
            personal goals before making a final decision.
          </p>
        </>
      ),
    },
    {
      label: "Early Bird Admission Offer",
      image: "/images/early-admission-process.jpg",
      content: (
        <>
          <p>
            <strong>The Early Bird Offer Admission 2025-26 :</strong> is open to
            encourage early seat booking in the program of your choice and enjoy
            a special discount.
          </p>
          <p className="mt-5">
            <strong>Cost Savings:</strong> Parents can save on application fees
            or receive discounts, reducing the overall expense of the college
            admission process.
          </p>
          <p className="mt-5">
            <strong>Less Stress:</strong> Early submission can simplify the
            application process, allowing families more time to gather required
            documents and plan financially, reducing last-minute stress.
          </p>
          <p className="mt-5">
            <strong>Priority Processing:</strong> With early applications,
            parents can receive quicker responses and have more time to make
            informed decisions, improving the overall experience and planning.
          </p>
        </>
      ),
    },
    {
      label: "Overview College Mentoring Process",
      image: "/images/admisson-process-cm.jpg",
      content: (
        <>
          <p className="mt-5">
            <strong>Onboard - Major and Career Exploration</strong> Assess the
            interests, expectations, and goals of both the student and their
            parents/guardians. Evaluate and discuss strategies related to
            curriculum, testing, and extracurricular activities. Outline the
            admissions process and timeline.
          </p>
          <p className="mt-5">
            <strong>Discovery - College Selection</strong> Research and create a
            well-balanced list of colleges, including likely, target, reach, and
            potential wildcard schools. Develop a genuine and compelling
            application for each selected college.
          </p>
          <p className="mt-5">
            <strong>Choose - Financial Aid and Financing</strong> Finalize Your
            Decision: Select the college that best fits your academic goals,
            financial situation, and cultural preferences. Financial Aid
            Guidance: Receive assistance in understanding and evaluating
            financial aid packages, scholarships, and grants. Loan Support:
            Explore and compare bank loan options for funding your education,
            with guidance on managing repayment plans and terms.
          </p>
        </>
      ),
    },
  ];

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(selectedTab);
    }
  }, [selectedTab]);

  return (
    <div className="flex flex-col bg-[#E9F3EE] my-7">
      <div className="flex flex-col py-12 w-full container max-md:px-5 max-md:max-w-full">
        <div className="md:w-[800px]">
          <h3 className="text-4xl font-bold text-[#173CBA] leading-[55px] max-md:max-w-full">
            <span className="font-light">
              College Mentor's Approach to the{" "}
            </span>
            <span className="text-[#0C9]">Application</span>{" "}
            <span className="font-light">and</span>{" "}
            <span className="text-[#0C9]">Admission Process</span>{" "}
            <span className="font-light">
              ensures Comprehensive, Personalized Support from{" "}
            </span>
            <span className="text-[#0C9]">Start to Finish.</span>
          </h3>
        </div>
        <div className="flex flex-wrap gap-8 items-center mt-8 text-xl font-semibold text-[#173CBA] max-md:max-w-full">
          {steps.map((step, index) => (
            <button
              key={index}
              onClick={() => handleTabClick(index)}
              className={`px-2.5 rounded-3xl border border-[#0C9] transition-colors duration-300 ease-in-out 
        ${
          selectedTab === index
            ? "bg-[#0C9] text-white"
            : "bg-white text-[#173CBA]"
        } 
        hover:bg-[#0C9] hover:text-white`}
            >
              {step.label}
            </button>
          ))}
        </div>

        <div className="relative mt-11 max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col-reverse">
            <div className="relative w-[40%] max-md:ml-0 max-md:w-full min-max-md:w-[40%] ">
              <Swiper
                ref={swiperRef}
                slidesPerView={1}
                spaceBetween={30}
                navigation
                pagination={{ clickable: true }}
                loop={false}
                onSlideChange={(swiper) => setSelectedTab(swiper.activeIndex)}
              >
                {steps.map((step, index) => (
                  <SwiperSlide key={index}>
                    <div className="relative mb-10">
                      <img
                        src={step.image}
                        alt={`Slide ${index + 1}`}
                        className="object-contain md:w-[414px] min-max-lg:ml-4 rounded-lg h-auto ml-20 min-max-md:ml-4 min-max-xss:ml-5 max-md:w-[319px] max-md:h-[254px]"
                      />
                      <div className="absolute bottom-[-40px] left-0 bg-white rounded-md border border-[#0C9] p-2 text-base font-normal w-[305px] min-max-md:w-[250px]">
                        <p>
                          With college mentor guidance and user-friendly tools,
                          applying to top schools is now easier than ever
                        </p>
                        <div className="rounded-full bg-[#0C9] min-max-md:hidden min-max-lg:hidden text-white text-center w-8 h-8 py-2 px-1 ml-[280px] !bottom-0  ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="17"
                            viewBox="0 0 23 17"
                            fill="none"
                          >
                            <path
                              d="M9.84401 15.2602L3.14526 7.94458L4.84726 6.43158L9.75201 11.7866L18.0895 1.67725L19.8663 3.09958L9.84401 15.2602Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="flex flex-col ml-5 w-[60%] max-md:ml-0 max-md:w-full min-max-md:w-[60%]">
              <div className="flex flex-col grow text-base leading-7 text-black max-md:mt-10 max-md:max-w-full md:w-[700px] min-max-lg:w-full min-max-md:w-full ">
                {steps[selectedTab].content}
              </div>
              <button
                onClick={handlePopup}
                className="flex  text-white gap-0.5 justify-center items-center w-[300px]   button-styles mt-8  rounded min-h-[32px] arrow-btn hover:bg-[#0c9]"
              >
                <span className="my-auto text-base font-semibold ">
                  Register now to get started
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="12"
                  viewBox="0 0 15 12"
                  fill="currentColor"
                  className="ml-1 arrow-icon"
                >
                  <path
                    d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM14.5303 6.53033C14.8232 6.23744 14.8232 5.76256 14.5303 5.46967L9.75736 0.6967C9.46447 0.403806 8.98959 0.403806 8.6967 0.6967C8.40381 0.989593 8.40381 1.46447 8.6967 1.75736L12.9393 6L8.6967 10.2426C8.40381 10.5355 8.40381 11.0104 8.6967 11.3033C8.98959 11.5962 9.46447 11.5962 9.75736 11.3033L14.5303 6.53033ZM1 6.75L14 6.75L14 5.25L1 5.25L1 6.75Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeAdmissionProcess;
