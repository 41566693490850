import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import Table from "./Table";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import LeadGeneration from "../../../../components/LeadGeneration";

const isMobile = window.innerWidth < 768;

interface ICard {
  collegeName: string;
  program: string;
  rating: string;
  reviews: number;
  fees: string;
  round: string;
  rank: string;
  exam: string;
  rounds: {
    round: number;
    closingRanks: { [year: string]: number };
  }[];
  openPopup: () => void;
}

const CutOffs = () => {
  const [showPopup, setShowPopup] = useState(false);
  const data = useSelector(
    (state: RootState) =>
      state.collegePredictorIntermediate.landingData?.cutOffs
  );
  console.log("CutOffs", data);
  return (
    <section className="py-12 bg-[#FFF6F1] text-black">
      <div className="container">
        <h3 className="text-2xl font-semibold">{data?.title}</h3>
        {data?.data && (
          <CustomSwiper
            items={data?.data.map((item: ICard) => (
              <Card {...item} openPopup={() => setShowPopup(true)} />
            ))}
            className="mt-6"
            navigation={"bottom"}
            autoplay={true}
            breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 20 },
              768: { slidesPerView: 1, spaceBetween: 20 },
              1024: { slidesPerView: 2, spaceBetween: 20 },
              1280: { slidesPerView: 2, spaceBetween: 30 },
            }}
            progressBar={true}
          />
        )}
      </div>
    </section>
  );
};

export default CutOffs;

const Card: FC<ICard> = ({
  collegeName,
  program,
  rating,
  reviews,
  fees,
  round,
  rank,
  exam,
  rounds,
  openPopup,
}) => {
  const [showAllRounds, setShowAllRounds] = useState(true);
  const toggleShowAllRounds = () => setShowAllRounds((prev) => !prev);

  return (
    <>
      <div className="bg-white border border-grey-3">
        <div className="p-4 border-b border-grey-3">
          <Link
            to={`/college-details/${collegeName}`}
            className="text-lg font-semibold line-clamp-2 md:line-clamp-1"
          >
            {collegeName}
          </Link>
          <div className="flex max-sm:flex-col gap-2 justify-between mt-3 text-xs text-grey">
            <span>
              <FontAwesomeIcon icon={faLocationDot} />{" "}
              <span className="text-[#42f554]">Hyderabad</span> |{" "}
              <Link to={`/college-details/${collegeName}`}>Placement</Link> |{" "}
              <Link to={`/college-details/${collegeName}`}>CutOff</Link>
            </span>
            <Link to={`/college-details/${collegeName}`}>
              View Details <FontAwesomeIcon icon={faChevronRight} size="xs" />
            </Link>
          </div>
        </div>
        <div className="p-4">
          <div className="flex flex-wrap justify-between gap-2">
            <Link
              to={`/course-details/${program}`}
              className="text-sm font-semibold text-blue"
            >
              {program}
            </Link>
            <Link
              to={`/course-details/${program}`}
              className="text-xs text-grey"
            >
              View Details <FontAwesomeIcon icon={faChevronRight} size="xs" />
            </Link>
          </div>
          <div className="mt-4 text-xs">
            <span className="font-semibold">{rating}</span>{" "}
            <span
              style={{
                background: `linear-gradient(90deg, #FFC700 ${
                  (parseInt(rating) * 100) / 5
                }%, #C8C8C8 ${((5 - parseInt(rating)) * 100) / 5}%)`,
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              ★★★★★
            </span>{" "}
            ({reviews}) | ₹ {fees}
          </div>
          <div className="flex max-sm:flex-col gap-4 md:items-center justify-between mt-4 text-xs md:text-sm text-grey">
            <span>
              <Link
                to={`/exam-details/${exam}`}
                className="p-1 border border-grey"
              >
                {exam}
              </Link>{" "}
              {round} | {rank}
            </span>
            <button
              className="px-2 py-1 bg-grey-1"
              onClick={toggleShowAllRounds}
            >
              All Rounds{" "}
              <FontAwesomeIcon
                icon={showAllRounds ? faChevronUp : faChevronDown}
                className="text-blue"
              />
            </button>
          </div>
          {showAllRounds && (
            <Table
              data={convertRoundDataToTable(rounds)}
              className="mt-6"
              rowHeader={true}
              justify={isMobile ? "left" : "center"}
            />
          )}
          <div className="flex gap-4 mt-4 max-sm:flex-col">
            <button
              className="flex-1 p-2 text-base font-semibold border border-blue text-blue"
              onClick={openPopup}
            >
              Shortlist
            </button>
            <a
              href="/downloads/sample.pdf"
              download={true}
              className="flex-1 p-2 text-base font-semibold text-center text-white border border-blue bg-blue"
            >
              Download brochure
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const convertRoundDataToTable = (
  rawData: {
    round: number;
    closingRanks: { [year: string]: number | string };
  }[]
) => {
  const yearsSet = new Set<string>();
  rawData?.forEach((round) => {
    if (round && round.closingRanks)
      Object.keys(round?.closingRanks).forEach((year) => yearsSet.add(year));
  });
  const yearsList: string[] = Array.from(yearsSet);

  const tableData: ReactNode[][] = [
    [
      <span className="max-sm:text-xs">Round</span>,
      ...yearsList.map((year) => (
        <span className="max-sm:text-xs">{`Closing Rank '${year.slice(
          2,
          4
        )}`}</span>
      )),
    ],
  ];

  rawData?.forEach((round) => {
    tableData.push([
      round?.round?.toString(),
      ...yearsList.map((year) =>
        round.closingRanks[year] == "" ? (
          "-"
        ) : (
          <span className="max-sm:text-sm">
            {round?.closingRanks[year]?.toLocaleString("en-US")}
          </span>
        )
      ),
    ]);
  });

  return tableData;
};
