import React from "react";

const RankingBanner = () => {
  return (
    <div>
      <div className="pl-20  w-full  max-md:max-w-full flex flex-row max-md:flex-col bg-gradient-to-b from-[#EDFCFF] to-[#89C9ED] ">
        <div className="flex flex-col justify-between w-[20%] max-md:w-full py-16">
          <div>
            <img
              src="/images/ranking-framework/emblem.png"
              alt=""
              className="object-contain w-full aspect-[2.67]"
            />
          </div>
          <div className="text-2xl">
            <span className="max-md:max-w-full font-semibold">
              Ministry of Education
            </span>
            <br />
            <span className="mt-2 text-2xl leading-none max-md:max-w-full font-normal">
              Government of India
            </span>
            <br />
            <span className="mt-2 max-md:max-w-full font-semibold">
              nirf 2024 Ranking
            </span>
          </div>
        </div>
        <div className="flex w-[80%] max-md:w-full">
          <img
            src="/images/ranking-framework/ranking-banner.png"
            alt=""
            className="object-contain w-full aspect-[2.67] relative"
          />
          <div className="absolute mt-14">
            <h3 className="text-[#383838] md:text-[40px] font-medium leading-[50px]">
              National Institutional Ranking Framework
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RankingBanner;
