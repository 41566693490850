import React from "react";
import RankingBanner from "../ranking-framework/components/RankingBanner";
import IndiaRanking from "./components/IndiaRanking";
import IndiaRankingOverall from "./components/IndiaRankingOverall";

const RankingFrameworkTwo = () => {
  return (
    <div>
      <RankingBanner />
      <IndiaRanking />
      <IndiaRankingOverall />
    </div>
  );
};

export default RankingFrameworkTwo;
