import React, { useEffect } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface DownloadLinkProps {
  href: string;
  download: string;
  className?: string;
  children: any;
  autoDownload?: boolean;
}

const DownloadLink: React.FC<DownloadLinkProps> = ({
  href,
  download,
  className,
  children,
  autoDownload,
}) => {
  const { otpSent, error, isAuthenticated, token, user } = useSelector(
    (state: RootState) => state.auth
  );

  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("user");

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (isLoggedIn) {
      initiateDownload(href, download);
    } else {
      localStorage.setItem("redirectAfterLogin", location.pathname);
      localStorage.setItem("downloadHref", href);
      localStorage.setItem("downloadFileName", download);
      navigate("/secure/login"); // Redirect to login
    }
  };

  const initiateDownload = (fileHref: string, fileName: string) => {
    const link = document.createElement("a");
    link.href = fileHref;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <a
      href={isAuthenticated ? href : "#"}
      //   download={download}
      className={className}
      onClick={handleClick}
    >
      {children}
    </a>
  );
};

export default DownloadLink;
