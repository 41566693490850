import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import useDebouncedSearch from "../../../components/filter/customHooks/useDebouncedSearch";
import { FilterCategory } from "../../../components/filter/FilterEnum";
import {
  changeFilterState,
  clearAllSelectedFilters,
} from "../../../store/slices/filterSlice";
import { RootState, AppDispatch } from "../../../store/store";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCategoryIndex: number;
  handleSelectedCategoryIndexChange: (newIndex: number) => void;
}

const FilterMenu: FC<IProps> = ({
  isOpen,
  onClose,
  selectedCategoryIndex,
  handleSelectedCategoryIndexChange,
}) => {
  const filterData = useSelector((state: RootState) => state.filter.filterData);
  const filterCategories = Object.keys(filterData);

  const dispatch = useDispatch<AppDispatch>();

  const { searchKeyword, handleKeywordChange, deboundedSearchKeyword } =
    useDebouncedSearch();

  if (!filterData) return;

  const selectedFilterData =
    filterData[filterCategories[selectedCategoryIndex]];

  const categoryKey = filterCategories[selectedCategoryIndex];

  const type = selectedFilterData?.type;

  const options = selectedFilterData?.options;

  const selected = selectedFilterData?.selected;

  const searchFilteredOptions = options?.filter((option) =>
    option.name
      .toLowerCase()
      .includes(deboundedSearchKeyword.toLocaleLowerCase())
  );

  return (
    <>
      <div
        className="fixed top-0 right-0 w-full lg:w-1/3 h-screen bg-white z-[100] flex flex-col transition-transform duration-500"
        style={{ transform: isOpen ? "" : "translateX(100%)" }}
      >
        {/* top section */}
        <div className="flex items-center justify-between border p-4">
          <span>Filters</span>
          <button onClick={onClose}>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        {/* mid section with filter categories and options */}
        <div className="flex h-[80%]">
          <div>
            <ul className="h-full flex flex-col list-none">
              {filterCategories.map((category, index) => (
                <li
                  onClick={() => handleSelectedCategoryIndexChange(index)}
                  className={`border p-4 pr-8 ${
                    index == selectedCategoryIndex ? "bg-orange-50" : ""
                  }`}
                >
                  {filterData[category]?.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="border p-4 h-full flex-1">
            {options?.length > 5 && (
              <input
                value={searchKeyword}
                onChange={(e) => handleKeywordChange(e.target.value)}
                className="border border-black p-2 w-full mb-2"
              />
            )}
            <ul
              className="flex flex-col gap-2 overflow-auto"
              style={{
                height: `calc(100% - ${options?.length > 5 ? "2.75rem" : "0"})`,
              }}
            >
              {type == FilterCategory.MultiSelect &&
                searchFilteredOptions.map((option) => (
                  <li
                    className="flex items-center gap-2"
                    onClick={() =>
                      dispatch(
                        changeFilterState({
                          categoryKey: categoryKey,
                          optionKey: option.key,
                        })
                      )
                    }
                  >
                    <input
                      type="checkbox"
                      onClick={() => {}}
                      checked={selected.includes(option.key)}
                    />
                    <label>{option.name}</label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        {/* bottom sections with actions */}
        <div className="flex gap-4 items-center border p-4">
          <button
            className="flex-1 border border-black p-4"
            onClick={() => {
              dispatch(clearAllSelectedFilters());
              onClose();
            }}
          >
            Clear All
          </button>
          <button
            className="flex-1 border border-black p-4"
            onClick={() => onClose()}
          >
            Apply
          </button>
        </div>
      </div>
      {isOpen && (
        <div
          className="fixed inset-0 bg-[#000] opacity-70 z-50"
          onClick={onClose}
        ></div>
      )}
    </>
  );
};

export default FilterMenu;
