import React, { useState, useEffect, useCallback } from 'react';
import Card from './Card';
import Carroussel from './Carroussel';
import './blog-styles.scss';
import axios from 'axios';
import config from "../../util/config";

interface BlogItem {
  imageUrl: string;
  description: string;
  title: string;
  slug?: string;
  name:string;
}

interface Category {
  title: string;
  items: BlogItem[];
}

interface ApiResponse {
  featuredCategories: Category[];
}

function Blogs() {
  const [goToSlide, setGoToSlide] = useState(0);
  const [dataSet, setDataSet] = useState<Category[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<ApiResponse>(config.apiUrl + '/api/blogs/get-featured-topics');
        console.log("Fetched Data:", response.data);
        setDataSet(response.data.featuredCategories || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleGoToSlide = useCallback((index: number) => {
    console.log("Attempting to go to slide:", index);

    if (index !== goToSlide && index >= 0 && index < dataSet.length) {
      setGoToSlide(index);
    }
  }, [goToSlide, dataSet.length]);

  const cards = dataSet.map((category, index) => ({
    key: index + 1,
    content: (
      <Card
        content={category.items || []}
        title={category.title || ''} 
        keyindex={index} 
      />
    ),
    onClick: () => handleGoToSlide(index),
  }));

  useEffect(() => {
    console.log("Current slide:", goToSlide);
  }, [goToSlide]);

  return (
    <div className="flex flex-row p-12 relative mb-12">
      <div className="mx-auto container">
        <div className="flex justify-center items-center mb-12">
          <h3 className="text-3xl font-extrabold text-center bg-transparent mb-12">
            Blogs & Article
          </h3>
        </div>
        <div>
          <Carroussel
            cards={cards}
            height="400px"
            width="60%"
            margin="0 auto"
            offset={2}
            showArrows={false}
            goToSlide={goToSlide}
          />
        </div>
      </div>
    </div>
  );
}

export default Blogs;
