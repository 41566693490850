import React from "react";
import "./DidYouKnow.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";

// Define the type for the slide data
interface SlideData {
  mainText: string;
  subText?: string;
  subItems?: string[];  // Corrected type
}

// Define the type for the props
interface DidYouKnowProps {
  slidesData: SlideData[];
}

const DidYouKnow: React.FC<DidYouKnowProps> = ({ slidesData }) => {
  const renderSlides = () => {
    return slidesData.map((slide, index) => (
      <SwiperSlide key={index}>
        <div className="flex flex-col mx-auto pb-7 items-center px-4 md:px-32 w-full">
          <div className=" mt-1 w-full text-2xl font-semibold text-center text-white">
            {slide.mainText}
          </div>
          {slide.subText && (
            <div className="mt-1 text-lg text-center text-white py-1">{slide.subText}</div>
          )}
          {slide.subItems && (
            <ul className="grid grid-cols-2 gap-x-8  text-white pb-4">
              {slide.subItems.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      </SwiperSlide>
    ));
  };

  return (
    <div className="did-you-know-main">
      <div className="py-2 text-xl font-medium text-center text-white">Did you know?</div>
      <Swiper
        pagination={{ clickable: true }}
        autoplay={{ delay: 4000, disableOnInteraction: true }}
        loop={true}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {renderSlides()}
      </Swiper>
    </div>
  );
};

export default DidYouKnow;
