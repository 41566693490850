import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IPerfectCollegeDataItem {
  name: string;
  link: string;
}

export type IState = {
  name: string;
  image: string;
  deemedUniversities: number;
  statePrivateUniversities: number;
  publicUniversities: number;
  collegeData: { image: string; name: string; collegeUrl: string }[];
};

interface CollegeLanding {
  featuredColleges: {
    image: string;
    url: string;
    collegeLogo: string;
  }[];
  perfectCollege: {
    stream: string;
    data: {
      colleges: IPerfectCollegeDataItem[];
      ranking: IPerfectCollegeDataItem[];
      exam: IPerfectCollegeDataItem[];
      degreeAndCourse: IPerfectCollegeDataItem[];
    };
  }[];
  worldClass: {
    image: string;
    video: string;
  }[];
  statewiseColleges: {
    country: IState;
    states: IState[];
  } | null;
  blogsAndArticles: {
    webinar: any[];
    articles: any[];
    spotLight: any[];
    news: any[];
    visualStories: any[];
  } | null;
  dreamsHappen: {
    bg: string;
    title: string;
    bannerPath: string;
    bendPath: string;
    description: string;
    name: string;
    college: string;
  }[];
  cardsDreams: {
    image: string;
    name: string;
    experience: string;
    role: string;
  }[];
}

const initialState: CollegeLanding = {
  featuredColleges: [],
  perfectCollege: [],
  worldClass: [],
  statewiseColleges: null,
  blogsAndArticles: null,
  dreamsHappen: [],
  cardsDreams: [],
};

const collegeLandingSlice = createSlice({
  name: "collegeLanding",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<CollegeLanding>) => {
      return action.payload;
    },
  },
});

export const { update } = collegeLandingSlice.actions;
export default collegeLandingSlice.reducer;
