import { useEffect, useRef, useState } from "react";
import {
  customAnimationBottom,
  customAnimationLeft,
  customAnimationRight,
  customAnimationTop,
} from "./keyframes";
import arrowDownIcon from "../images/chevron-down-icon.svg";
import Reveal from "react-awesome-reveal";
import "./Hero.scss";
import RevealFromBottom from "./RevealFromBottom";
import HeadingTextHighlight from "../images/hero-heading-text-highlight.svg";
import DotsAndShades from "../images/dots-and-shades-hoe-hero.png";
import BlueShade from "../images/blue-shade.png";

type scrollToMissionVisionSectionType = () => void;

interface Props {
  scrollToMissionVisionSection: scrollToMissionVisionSectionType;
}

const Hero: React.FC<Props> = ({ scrollToMissionVisionSection }) => {
  return (
    <section className="home-hero mt-24 overflow-hidden">
      <div className="container max-w-[1200px] lg:py-12 flex flex-col lg:flex-row items-center justify-center gap-4 lg:gap-12">
        <img
          src={BlueShade}
          className="absolute top-0 lg:top-12 -left-20 h-96 w-96 z-[-10]"
        />
        <div className="w-full lg:w-3/5 lg:h-[60vh] lg:py-12 flex flex-col justify-center items-left">
          <RevealFromBottom className="">
            <h1 className="heading1">
              We Help to{" "}
              <div className="relative inline-block">
                <div className="relative z-10 my-2 text-primary">Build</div>
                <img
                  src={HeadingTextHighlight}
                  className="absolute top-1/2 -translate-y-1/2 left-0 z-0"
                />
              </div>
            </h1>
          </RevealFromBottom>
          <RevealFromBottom className="">
            <h1 className="heading1">Your Dream</h1>
          </RevealFromBottom>
          <div className="py-2"></div>
          <Reveal keyframes={customAnimationLeft}>
            <p className="text1">
              Welcome to Dreams and Degrees, where we believe in empowering
              learners to unlock their full potential through personalized
              guidance and transformative educational experiences.
            </p>
          </Reveal>
        </div>
        <div className="w-full sm:w-1/2 lg:w-2/5 flex justify-center items-center">
          <div className="w-2/3 relative">
            <Reveal keyframes={customAnimationRight} className="z-40">
              <div className="relative overflow-hidden">
                <img
                  src="images/about/home-hero.jpg"
                  className="w-full h-full object-cover border-x-8 border-secondary"
                />
                <div className="overlay-reveal absolute left-0 top-0 h-full w-full bg-white z-10"></div>
              </div>
            </Reveal>
            <img
              src={DotsAndShades}
              className="absolute top-0 left-0 w-full z-[-10] scale-[230%] lg:scale-[250%] transform -translate-x-24 lg:-translate-x-36 translate-y-48"
            />
          </div>
        </div>
      </div>
      <div className="mt-24 lg:mt-0 w-full h-0 flex justify-center items-center">
        <button
          className="z-10 relative flex flex-col gap-6 items-center justify-center focus:outline-none"
          onClick={scrollToMissionVisionSection}
        >
          <span className="text3">SCROLL TO EXPLORE</span>
          <div className="socialdown bg-primary rounded-full p-1 hover:scale-125 transform transition-all duration-200 ease-in-out">
            <img
              src={arrowDownIcon}
              className="arrow w-14"
              alt="arrow down icon"
            />
          </div>
        </button>
      </div>
      <div className="arc-div"></div>
    </section>
  );
};

export default Hero;
