import React, { FC, useEffect, useRef, useState } from "react";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  tabList: string[];
  activeTab: number;
  changeActiveTab: (index: number) => void;
}

const TabSelectorV1: FC<IProps> = ({ tabList, activeTab, changeActiveTab }) => {
  const tabSize = tabList.length;
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const screenRef = useRef<HTMLDivElement | null>(null);

  const [underlineWidth, setUnderlineWidth] = useState(0);
  const [underlineMargin, setUnderlineMargin] = useState(0);

  useEffect(() => {
    const activeTabRef = tabRefs.current[activeTab];
    if (activeTabRef && containerRef.current && screenRef.current) {
      setUnderlineWidth(activeTabRef.clientWidth / 2);

      const containerLeft = containerRef.current.getBoundingClientRect().left;
      const activeTabLeft = activeTabRef.getBoundingClientRect().left;
      const activeTabRight = activeTabRef.getBoundingClientRect().right;
      const screenLeft = screenRef.current.getBoundingClientRect().left;
      const screenRight = screenRef.current.getBoundingClientRect().right;

      console.log(screenRight, activeTabRight);

      setUnderlineMargin(activeTabLeft - containerLeft);

      if (screenLeft > activeTabLeft) {
        screenRef.current.scrollTo({
          left: screenRef.current.scrollLeft - screenLeft + activeTabLeft,
          behavior: "smooth",
        });
      } else if (activeTabRight > screenRight) {
        screenRef.current.scrollTo({
          left: screenRef.current.scrollLeft + activeTabRight - screenRight,
          behavior: "smooth",
        });
      }
    }
  }, [activeTab, tabRefs, containerRef, screenRef]);

  return (
    <div className="w-full flex gap-6 items-center">
      <div
        ref={screenRef}
        className="flex-1 overflow-x-auto scrollbar-none"
        style={{
          scrollbarWidth: "none" /* Firefox */,
          msOverflowStyle: "none" /* IE and Edge */,
        }}
      >
        <div ref={containerRef}>
          <div className="flex gap-4 items-center text-xl text-grey">
            {tabList.map((tab, index) => {
              const isActiveTab = activeTab === index;
              return (
                <React.Fragment key={index}>
                  {index > 0 && "|"}
                  <button
                    className={`shrink-0 max-sm:max-w-72 ${
                      isActiveTab ? "text-blue font-bold" : "font-semibold"
                    }`}
                    onClick={() => changeActiveTab(index)}
                    ref={(el) => (tabRefs.current[index] = el)}
                  >
                    {tab}
                  </button>
                </React.Fragment>
              );
            })}
          </div>
          <div className="mt-4">
            <div
              className="h-1 bg-green transition-all duration-500 rounded-full"
              style={{
                width: underlineWidth,
                marginLeft: underlineMargin + underlineWidth / 2,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="flex gap-6 max-sm:hidden">
        <button
          className="h-12 w-12 bg-white flex items-center justify-center rounded-full text-black hover:text-green"
          style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
          onClick={() => {
            changeActiveTab((tabSize + activeTab - 1) % tabSize);
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <button
          className="h-12 w-12 bg-white flex items-center justify-center rounded-full text-black hover:text-green"
          style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
          onClick={() => {
            changeActiveTab((tabSize + activeTab + 1) % tabSize);
          }}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
};

export default TabSelectorV1;
