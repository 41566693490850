import React, { useCallback, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "animate.css";
import "swiper/css";
import "swiper/css/bundle";
import { Navigation } from "swiper/modules";
import rightarrow from "../../../images/courses/course-details/rightarrow.svg";
import LeadGeneration from "../../../components/LeadGeneration";

const TopIndianPrivateCollegesList = () => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  const ApplicatonToAdmissionContent = {
    title: "Top Private Colleges & Universities ",
    collegeList: [
      {
        collegeImg:
          "/images/course-details/ManipalAcademyofHigherEducation.png",
        title: "Manipal Academy of Higher Education",
        location: "Karnataka",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/AmritaVishwaVidyapeetham.png",
        title: "Amrita Vishwa Vidyapeetham, ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Coimbatore",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/Vellore InstituteofTechnology.png",
        title: "Vellore Institute of Technology (VIT), ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Vellore",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/SaveethaInstitute.png",
        title: "Saveetha Institute of Medical and Technical Sciences, ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Chennai",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/AmritaVishwaVidyapeetham.png",
        title: "Amrita Vishwa Vidyapeetham, ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Coimbatore",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/Vellore InstituteofTechnology.png",
        title: "Vellore Institute of Technology (VIT), ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Vellore",
        moreLink: "/college",
      },
      {
        collegeImg: "/images/course-details/SaveethaInstitute.png",
        title: "Saveetha Institute of Medical and Technical Sciences, ",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total 20 + Specialisations Offered",
        totalFee: "Rs 4 Lakh Yearly",
        location: "Chennai",
        moreLink: "/college",
      },
    ],
  };

  return (
    <div className="flex w-full flex-col lg:flex-row">
      <Swiper
        onSwiper={setSwiperRef}
        spaceBetween={30}
        className="w-full"
        modules={[Navigation]}
        loop={true}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
      >
        {ApplicatonToAdmissionContent.collegeList.map(
          (college: any, index: number) => (
            <SwiperSlide
              className="flex flex-col gap-4 bg-[#FFF6F1] rounded-lg"
              style={{
                boxShadow: "0px 2.033px 7.622px 0px rgba(0, 0, 0, 0.10)",
              }}
              key={index}
            >
              <div className="flex flex-col gap-1">
                <img
                  src={college.collegeImg}
                  alt="college"
                  className="w-full h-48 object-cover"
                  style={{ borderRadius: "7px 7px 0px 0px" }}
                />
                <div className="flex flex-col gap-2 py-2 px-2">
                  <h4 className="text-sm font-semibold text-[#383838] m-0">
                    {college.title}, <span>{college.location}</span>
                  </h4>

                  <p className="text-[10px] text-[#383838] m-0 flex items-center gap-2">
                    {college?.program}
                  </p>
                  <p className="text-[10px] text-[#4C495C]">
                    {college.specialisations}
                  </p>
                  <p className="text-[8px] text-right text-[#4C495C] font-bold">
                    Total Fees: {college.totalFee}
                  </p>
                </div>
              </div>
              <div className="mx-auto flex flex-row w-[240px] justify-center rounded-md my-2 gap-2 bg-[--primary-color] hover:bg-[--secondary-color] transition-all duration-300 ease-in-out group">
                <button
                  onClick={handlePopup}
                  className=" flex  text-[11px] text-white font-semibold rounded-md py-2 px-5 cursor-pointer "
                >
                  <span> Apply Now & Book College Tour</span>
                </button>
                <img
                  src={rightarrow}
                  className="w-3 h-3 max-xss:w-4 max-xss:h-4 lg:w-8 lg:h-8 group-hover:translate-x-2 transition-transform duration-300 "
                />
              </div>
            </SwiperSlide>
          )
        )}
      </Swiper>
      <div className="flex lg:flex-col gap-4 items-center justify-center  swiper-buttons relative mt-4 lg:mt-0 lg:ml-14 ">
        <button onClick={handleNext} className="text-white max-md:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
          >
            <path
              d="M20.5 41.0022C31.8218 41.0022 41 31.8235 41 20.5011C41 9.17866 31.8218 0 20.5 0C9.17816 0 0 9.17866 0 20.5011C0 31.8235 9.17816 41.0022 20.5 41.0022Z"
              fill="#173CBA"
            />
            <path
              d="M15.4883 6.37793V12.6599L23.0759 20.5009L15.4883 28.3419V34.6239L29.1549 20.5009L15.4883 6.37793Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

const ApplicatonsToAdmission: React.FC = () => {
  return (
    <div className="bg-[#FFF1FA] w-full relative md:pt-[51px] md:pb-[70px] md:px-20 max-md:py-10 my-6">
      <h3 className="pt-0 mb-7 w-full text-4xl font-bold leading-none text-blue-800 max-md:text-center">
        Application to <span className="text-teal-500">Admission</span>
      </h3>
      <div className="flex flex-col  bg-white max-md:mx-[30px]">
        <div className="flex ">
          <h3 className="text-xl lg:text-2xl font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
            {`Top Private Colleges & Universities `}
          </h3>
        </div>
        <div className="flex p-9 container ">
          <TopIndianPrivateCollegesList />
        </div>
      </div>
    </div>
  );
};

export default ApplicatonsToAdmission;
