import React, { useCallback, useState } from "react";
import { HiMiniArrowSmallRight } from "react-icons/hi2";
import ExamsLogo from "../../../../images/courses/course-details/jeemainimage.png";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "animate.css";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { SVGProps } from "react";
import { Link } from "react-router-dom";
import LeadGeneration from "../../../LeadGeneration";
export function MingcuteRightLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="10px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
        ></path>
      </g>
    </svg>
  );
}
interface Exam {
  name: string;
  description: string;
  examDate: string;
  examEndDate: string;
  applicationFormDate: string;
  onlineExam: boolean;
  examLink: string;
  logo: string;
  resultAnnounceDate: string;
  applyNow: boolean;
}
const popularEngineeringExams: Exam[] = [
  {
    name: "JEE MAIN 2024",
    description: "Joint Entrance Examination",
    examDate: "March 20,2024",
    examEndDate: "May 2023",
    applicationFormDate: "March 20,2024",
    resultAnnounceDate: "March 20,2024",
    applyNow: true,
    onlineExam: true,
    examLink: "https://collegereadiness.collegeboard.org/sat-subject-tests",
    logo: ExamsLogo,
  },
  {
    name: "JEE MAIN 2024",
    description: "Joint Entrance Examination",
    examDate: "March 20,2024",
    examEndDate: "May 2023",
    applicationFormDate: "March 20,2024",
    resultAnnounceDate: "March 20,2024",
    applyNow: true,
    onlineExam: true,
    examLink: "https://collegereadiness.collegeboard.org/sat-subject-tests",
    logo: ExamsLogo,
  },
  {
    name: "JEE MAIN 2024",
    description: "Joint Entrance Examination",
    examDate: "March 20,2024",
    examEndDate: "May 2023",
    applicationFormDate: "March 20,2024",
    resultAnnounceDate: "March 20,2024",
    applyNow: true,
    onlineExam: true,
    examLink: "https://collegereadiness.collegeboard.org/sat-subject-tests",
    logo: ExamsLogo,
  },
  {
    name: "JEE MAIN 2024",
    description: "Joint Entrance Examination",
    examDate: "March 20,2024",
    examEndDate: "May 2023",
    applicationFormDate: "March 20,2024",
    resultAnnounceDate: "March 20,2024",
    applyNow: true,
    onlineExam: true,
    examLink: "https://collegereadiness.collegeboard.org/sat-subject-tests",
    logo: ExamsLogo,
  },
  {
    name: "JEE MAIN 2024",
    description: "Joint Entrance Examination",
    examDate: "March 20,2024",
    examEndDate: "May 2023",
    applicationFormDate: "March 20,2024",
    resultAnnounceDate: "March 20,2024",
    applyNow: true,
    onlineExam: true,
    examLink: "https://collegereadiness.collegeboard.org/sat-subject-tests",
    logo: ExamsLogo,
  },
];

const ExamsList: React.FC = () => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);
  return (
    <div>
      <div className="flex gap-8 w-full px-9 py-7 flex-col lg:flex-row">
        <Swiper
          pagination={{
            clickable: true,
          }}
          onSwiper={setSwiperRef}
          spaceBetween={16}
          className="w-full !pb-14"
          modules={[Navigation, Pagination, Autoplay]}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {popularEngineeringExams.map((exam, index) => (
            <SwiperSlide
              key={index}
              className="border border-[#E0E0E0] rounded-2xl bg-[#FBFBFB] p-5 flex flex-col gap-6"
            >
              <div className="flex flex-row gap-4 justify-between">
                <div className="flex flex-row gap-2.5">
                  <div className="flex w-12 h-12">
                    <img
                      src={exam.logo}
                      alt={exam.name}
                      className=" w-full h-full object-cover"
                    />
                  </div>
                  <div className="flex flex-col text-left items-start justify-start">
                    <h3 className="font-semibold text-base text-black mb-[1px]">
                      {exam.name}
                    </h3>
                    <p className="text-[#828282] text-sm font-medium mb-1.5">
                      {exam.description}
                    </p>
                    <div>
                      <span
                        className="text-[#113CC0] text-sm font-medium py-2 px-3 rounded-full"
                        style={{ background: "rgba(17, 60, 192, 0.08)" }}
                      >
                        {exam.onlineExam ? "Online Exam" : "Offline Exam"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <p className="text-[#EB5757] text-sm font-semibold">Closed</p>
                </div>
              </div>
              <div className="flex flex-col gap-4 mt-4">
                <div className="flex flex-col border-[#E0E0E0] bg-white border p-2.5 rounded-lg gap-2.5">
                  <p className="text-black text-sm font-medium m-0 flex justify-between w-full">
                    <span>Exam Date:</span> <span>{exam.examDate}</span>
                  </p>
                  <hr className="w-full border-[#E0E0E0]" />
                  <p className="text-black text-sm font-medium m-0 flex justify-between w-full">
                    <span>Application Form:</span>{" "}
                    <span>{exam.resultAnnounceDate}</span>
                  </p>
                  <hr className="w-full border-[#E0E0E0]" />
                  <p className="text-black text-sm font-medium m-0 flex justify-between w-full">
                    <span>Results Announce:</span>{" "}
                    <span>{exam.applicationFormDate}</span>
                  </p>
                  <hr className="w-full border-[#E0E0E0]" />
                  <p className="text-black text-sm font-medium m-0 flex justify-between w-full">
                    <button
                      onClick={handlePopup}
                      className="text-[#113CC0] text-sm font-medium flex"
                    >
                      Apply Now
                      <HiMiniArrowSmallRight className="text-xl text-[#00C798]" />
                    </button>
                  </p>
                </div>
                <div className="flex flex-row items-center gap-2.5">
                  <Link
                    to={"/exams"}
                    className="flex justify-center items-center gap-1 mx-auto bg-[#113CC0] text-white text-sm font-semibold rounded-full py-2 px-3 w-1/2 text-center"
                  >
                    <span>Explore Exam</span>
                    <HiMiniArrowSmallRight className="text-xl" />
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex lg:flex-col gap-4 items-center justify-center  swiper-buttons relative px-12 ">
          <button
            onClick={handlePrevious}
            className="text-white bg-blue p-4 rounded-full text-base"
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={`fill-current text-white`}
                d="M13.7917 5.45842H3.375L6.80208 2.03134C6.89972 1.9345 6.97721 1.8193 7.0301 1.69236C7.08298 1.56542 7.11021 1.42927 7.11021 1.29176C7.11021 1.15425 7.08298 1.01809 7.0301 0.891158C6.97721 0.764222 6.89972 0.649012 6.80208 0.552176C6.60691 0.358165 6.3429 0.249268 6.06771 0.249268C5.79252 0.249268 5.5285 0.358165 5.33333 0.552176L0.864584 5.03134C0.473346 5.42024 0.252326 5.94844 0.25 6.50008V6.50008C0.255069 7.04811 0.47588 7.57207 0.864584 7.95842L5.33333 12.4376C5.43046 12.534 5.54562 12.6104 5.67226 12.6623C5.7989 12.7142 5.93452 12.7407 6.07139 12.7402C6.20826 12.7398 6.3437 12.7123 6.46996 12.6595C6.59623 12.6067 6.71085 12.5295 6.80729 12.4324C6.90373 12.3352 6.9801 12.2201 7.03203 12.0934C7.08396 11.9668 7.11044 11.8312 7.10995 11.6943C7.10947 11.5574 7.08203 11.422 7.02921 11.2957C6.97638 11.1695 6.89921 11.0549 6.80208 10.9584L3.375 7.54175H13.7917C14.0679 7.54175 14.3329 7.432 14.5282 7.23665C14.7236 7.0413 14.8333 6.77635 14.8333 6.50008C14.8333 6.22382 14.7236 5.95887 14.5282 5.76352C14.3329 5.56817 14.0679 5.45842 13.7917 5.45842Z"
              />
            </svg>
          </button>
          <button
            onClick={handleNext}
            className="text-white bg-blue p-4 rounded-full text-base"
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="curentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={`fill-current text-white`}
                d="M1.20833 5.45842H11.625L8.19792 2.03134C8.10028 1.9345 8.02279 1.8193 7.9699 1.69236C7.91702 1.56542 7.88979 1.42927 7.88979 1.29176C7.88979 1.15425 7.91702 1.01809 7.9699 0.891158C8.02279 0.764222 8.10028 0.649012 8.19792 0.552176C8.39309 0.358165 8.6571 0.249268 8.93229 0.249268C9.20748 0.249268 9.4715 0.358165 9.66667 0.552176L14.1354 5.03134C14.5267 5.42024 14.7477 5.94844 14.75 6.50008V6.50008C14.7449 7.04811 14.5241 7.57207 14.1354 7.95842L9.66667 12.4376C9.56954 12.534 9.45438 12.6104 9.32774 12.6623C9.2011 12.7142 9.06548 12.7407 8.92861 12.7402C8.79174 12.7398 8.6563 12.7123 8.53004 12.6595C8.40377 12.6067 8.28915 12.5295 8.19271 12.4324C8.09627 12.3352 8.0199 12.2201 7.96797 12.0934C7.91604 11.9668 7.88956 11.8312 7.89005 11.6943C7.89053 11.5574 7.91797 11.422 7.97079 11.2957C8.02362 11.1695 8.10079 11.0549 8.19792 10.9584L11.625 7.54175H1.20833C0.932065 7.54175 0.667112 7.432 0.471762 7.23665C0.276411 7.0413 0.166666 6.77635 0.166666 6.50008C0.166666 6.22382 0.276411 5.95887 0.471762 5.76352C0.667112 5.56817 0.932065 5.45842 1.20833 5.45842Z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamsList;
