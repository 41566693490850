import React, { useState, useEffect } from "react";
import "./Card.scss";
import "animate.css";
import useLeadGeneration from "../../hooks/useLeadGeneration";

export const Card = ({
  image,
  title,
  linktag,
}: {
  image: string;
  title: string;
  linktag: string;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [buttonName, setButtonName] = useState("");
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const { invokeLeadGeneration } = useLeadGeneration();
  const handleLeadGeneration = (name: string) => {
    invokeLeadGeneration("home-landing", name);
  };
  return (
    <div className="career-parent">
      <div
        className="career-card group"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="career-img">
          <img src={image} alt="" loading="lazy" />
        </div>
        <div className="career-content  group-hover:bg-[#113cc0] p-4 absolute -bottom-[95px]">
          <div className="flex justify-between items-center">
            <div className="text-center w-full">
              <h3 className="group-hover:text-white mb-2">{title}</h3>
            </div>
            <button>
              {isHovered ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="27"
                    height="27"
                    rx="13.5"
                    fill="white"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="27"
                    height="27"
                    rx="13.5"
                    stroke="#BDBDBD"
                  />
                  <path
                    d="M19 13H9C8.44772 13 8 13.4477 8 14C8 14.5523 8.44772 15 9 15H19C19.5523 15 20 14.5523 20 14C20 13.4477 19.5523 13 19 13Z"
                    fill="#333333"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="27"
                    height="27"
                    rx="13.5"
                    fill="white"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="27"
                    height="27"
                    rx="13.5"
                    stroke="#113cc0"
                  />
                  <path
                    d="M19 13H15V9C15 8.73478 14.8946 8.48043 14.7071 8.29289C14.5196 8.10536 14.2652 8 14 8C13.7348 8 13.4804 8.10536 13.2929 8.29289C13.1054 8.48043 13 8.73478 13 9V13H9C8.73478 13 8.48043 13.1054 8.29289 13.2929C8.10536 13.4804 8 13.7348 8 14C8 14.2652 8.10536 14.5196 8.29289 14.7071C8.48043 14.8946 8.73478 15 9 15H13V19C13 19.2652 13.1054 19.5196 13.2929 19.7071C13.4804 19.8946 13.7348 20 14 20C14.2652 20 14.5196 19.8946 14.7071 19.7071C14.8946 19.5196 15 19.2652 15 19V15H19C19.2652 15 19.5196 14.8946 19.7071 14.7071C19.8946 14.5196 20 14.2652 20 14C20 13.7348 19.8946 13.4804 19.7071 13.2929C19.5196 13.1054 19.2652 13 19 13Z"
                    fill="#113cc0"
                  />
                </svg>
              )}
            </button>
          </div>
          <div
            className={`list-items ${
              isHovered ? "animate__animated animate__fadeInBottomLeft" : ""
            }`}
          >
            <ul className="grid grid-cols-2 gap-4 px-[20px] pt-4">
              <li className="">
                <a href={`/college-details/${linktag}`}>Overview</a>
              </li>
              <li>
                <a href="/">Courses</a>
              </li>
              <li>
                <a href="/">Admission</a>
              </li>
              <li>
                <a href="/">Placements</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="career-button-section flex justify-between">
        <button
          onClick={() => {
            handleLeadGeneration("book-campus-tour");
          }}
          className="virtual-campus-btn  button-styles white-color map-mentor arrow-button"
        >
          Book Campuse tour
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="currentColor"
            className="arrow-icon"
          >
            <path
              d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
              fill="currentColor"
            />
          </svg>
        </button>
        <button
          onClick={() => {
            handleLeadGeneration("apply-now");
          }}
          className="apply-now-btn  button-styles arrow-btn  text-white  hover:bg-[#0c9]"
        >
          Apply Now
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="currentColor"
            className="arrow-icon"
          >
            <path
              d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
