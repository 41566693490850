import { Link } from "react-router-dom";
import "./CareerDetailsStyles.scss";
const HistoricalTimeline: React.FC<{ timeline: any }> = ({ timeline }) => {
  if (!timeline || !timeline.description || !timeline.description.length) {
    return null;
  }

  return (
    <section>
      <div className="container mx-auto">
        <div className="bg-white relative">
          <div className="flex flex-col lg:flex-row justify-center md:pr-8 gap-6">
            <div className="flex flex-col w-full lg:w-4/5">
              <div className="flex max-md:w-full">
                <h3 className="text-xl lg:text-2xl max-md:text-center font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
                  {timeline.title}
                </h3>
              </div>
              <div className="p-5 lg:py-24 lg:px-8">
                <div className="relative flex flex-col justify-center items-center xl:w-3/4 mx-auto gap-10 md:gap-20">
                  <div className="absolute top-0 left-0 md:left-1/2 -translate-x-1/2 h-full w-[2px] bg-gray-300"></div>
                  {timeline.description.map((item: any, index: number) => (
                    <div
                      key={index}
                      className={`timeline-item flex md:items-center justify-center relative gap-4 md:gap-10 w-full ${
                        index % 2 === 0
                          ? "flex-col md:flex-row-reverse md:text-right blueDot-contentLeft"
                          : "flex-col md:flex-row blueDot-contentRight"
                      }`}
                    >
                      <div className="md:w-1/2 max-md:flex flex-row items-end">
                        <div className="relative flex justify-center w-[5%] h-[5%] blueDot md:hidden">
                          <div className="absolute top-1/2 left-0 md:left-1/2 w-6 h-6 bg-blue rounded-full transform -translate-x-1/2 -translate-y-1/2 z-10"></div>
                        </div>
                        <div
                          className="text-[#4F4F4F] text-sm max-md:ml-8"
                          dangerouslySetInnerHTML={{
                            __html: item.description as string,
                          }}
                        />
                      </div>
                      <div className="relative md:flex justify-center w-[5%] h-[5%] blueDot hidden">
                        <div className="absolute top-1/2 left-1/2 w-6 h-6 bg-blue rounded-full transform -translate-x-1/2 -translate-y-1/2 z-10"></div>
                      </div>
                      <div
                        className="md:w-1/2 flex items-center p-5 bg-white rounded-2xl shadow-lg max-md:ml-8"
                        style={{ boxShadow: "0px 10px 60px 0px #0000001A" }}
                      >
                        <img
                          src={item.image}
                          alt={item.title}
                          className="w-20 h-20 object-cover rounded-md shadow-md"
                        />
                        <h3 className="text-base font-semibold text-black ml-4 text-left">
                          {item.title}
                        </h3>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full lg:w-1/5 relative bg-[#FFF1FA]">
              <div className="py-4 bg-white">
                <img
                  src="/images/course-details/TopMedical Courses_071.png"
                  alt=""
                />
                <Link
                  to={"/blogdetails-page"}
                  className="flex mx-auto  text-white py-1 text-sm px-3 bg-[#173CBA] rounded-full group w-[125px]"
                >
                  <span className="mr-2">Read more</span>
                  <div className="bg-[#0C9] text-[#fff] rounded-full p-1 group-hover:translate-x-2 transition-transform duration-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.293 4.293a1 1 0 011.414 0L16 9.586a1 1 0 010 1.414l-5.293 5.293a1 1 0 01-1.414-1.414L13.586 11H4a1 1 0 110-2h9.586L9.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HistoricalTimeline;
