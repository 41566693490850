import { FC, useState } from "react";
import AnimatedTabSlider from "../../../../components/AnimatedTabSlider";
import collegeIcon from "../images/find-perfect-college/college-icon.svg";
import examIcon from "../images/find-perfect-college/exam-icon.svg";
import courseIcon from "../images/find-perfect-college/course-icon.svg";
import rankingIcon from "../images/find-perfect-college/ranking-icon.svg";
import leftArrowIcon from "../images/find-perfect-college/left-arrow.svg";
import rightArrowIcon from "../images/find-perfect-college/right-arrow.svg";
import SearchBarWithSuggestion from "./SearchBarWithSuggestion";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import TabSelectorV1 from "../../../../components/TabSelectorV1";

const cardsList: {
  title: (stream: string) => string;
  key: "colleges" | "ranking" | "exam" | "degreeAndCourse";
  colorDark: string;
  colorLight: string;
  icon: string;
}[] = [
  {
    title: (stream: string) => `Top ${stream} Colleges`,
    key: "colleges",
    colorDark: "#F49FB6",
    colorLight: "#FFEFFF",
    icon: collegeIcon,
  },
  {
    title: (stream: string) => `${stream} Exams`,
    key: "exam",
    colorDark: "#F4D2D2",
    colorLight: "#FFFAFA",
    icon: rankingIcon,
  },
  {
    title: (stream: string) => `${stream} Courses & Specializations`,
    key: "degreeAndCourse",
    colorDark: "#A6E0DE",
    colorLight: "#ECFFFF",
    icon: examIcon,
  },
  {
    title: (stream: string) => `${stream} Resources`,
    key: "ranking",
    colorDark: "#FFE5D9",
    colorLight: "#FFFFFF",
    icon: courseIcon,
  },
];

const indianColleges = [
  "Indian Institute of Technology Bombay",
  "Indian Institute of Technology Delhi",
  "Indian Institute of Technology Madras",
  "Indian Institute of Technology Kanpur",
  "Indian Institute of Technology Kharagpur",
  "Indian Institute of Science",
  "Indian Institute of Technology Roorkee",
  "Indian Institute of Technology Guwahati",
  "National Institute of Technology Tiruchirappalli",
  "Birla Institute of Technology and Science, Pilani",
  "All India Institute of Medical Sciences, New Delhi",
  "Jawaharlal Nehru University",
  "Banaras Hindu University",
  "Anna University",
  "University of Delhi",
  "National Institute of Technology Surathkal",
  "Manipal Academy of Higher Education",
  "Vellore Institute of Technology",
  "Savitribai Phule Pune University",
  "Amrita Vishwa Vidyapeetham",
];

const indianCollegesWithLinks = indianColleges.map((college) => ({
  name: college,
  link: "/college-details",
}));

const isLargeScreen = window.innerWidth > 1280;

const FindPerfectCollege = () => {
  const data = useSelector(
    (state: RootState) => state.collegeLanding.perfectCollege
  );

  console.log(data);

  const [backgroundColor, setBackgroundColor] = useState(
    cardsList[0].colorDark
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const changeSelectedTabIndex = (index: number) => setSelectedTabIndex(index);

  if (!data || data.length <= 0) return;

  return (
    <div
      style={{
        background: isLargeScreen
          ? `linear-gradient(to bottom, transparent 60%, ${backgroundColor} 40%)`
          : "transparent",
      }}
    >
      <div className="container text-black py-12">
        <div className="flex flex-col lg:flex-row lg:items-end gap-6 pb-12">
          <div className="flex-[2_2_0%]">
            <h1 className="text-4xl lg:text-5xl font-bold">
              Find the Perfect College For You
            </h1>
            <p className="text-base text-grey">
              Explore the leading College Profiles, Rankings & Accreditations.
            </p>
          </div>
          <div className="flex-1">
            <SearchBarWithSuggestion data={indianCollegesWithLinks} />
          </div>
        </div>
        <TabSelectorV1
          tabList={data.map((item) => item.stream)}
          activeTab={selectedTabIndex}
          changeActiveTab={changeSelectedTabIndex}
        />
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
          {cardsList.map((card) => {
            let title = card.title(data[selectedTabIndex].stream);
            return (
              <Card
                title={title}
                list={data[selectedTabIndex].data[card.key]}
                colorDark={card.colorDark}
                colorLight={card.colorLight}
                icon={card.icon}
                handleHover={(color) => setBackgroundColor(color)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FindPerfectCollege;

interface CardProps {
  title: string;
  list: { name: string; link: string }[];
  colorDark: string;
  colorLight: string;
  icon: string;
  handleHover: (color: string) => void;
}

const Card: FC<CardProps> = ({
  title,
  list,
  colorDark,
  colorLight,
  icon,
  handleHover,
}) => {
  const SIZE = 8;
  const totalCount = list?.length;
  const totalPages = Math.ceil(totalCount / SIZE);
  const [currentPage, setCurrentPage] = useState(0);
  const start = currentPage * SIZE;
  const listItemsToShow = list?.slice(start, start + SIZE);

  return (
    <div
      className="w-full p-4 flex flex-col gap-4"
      style={{ backgroundColor: colorDark }}
      onMouseEnter={() => handleHover(colorDark)}
    >
      <h5 className="text-xl font-bold">{title}</h5>
      <div className="flex-1">
        <div style={{ backgroundColor: colorLight }}>
          {listItemsToShow?.map((item) => (
            <div
              className="p-2 flex items-center gap-2"
              style={{ borderBottom: `2px solid ${colorDark}` }}
            >
              <img src={icon} />
              <Link to="/college-intermediate" className="hover:text-blue">
                {item.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="flex gap-4 items-center justify-center text-grey">
        <button
          onClick={() =>
            setCurrentPage((prev) => (totalPages + prev - 1) % totalPages)
          }
        >
          <img src={leftArrowIcon} className="w-4" />
        </button>
        <span>{currentPage + 1}</span>
        <div className="w-24 h-1 bg-grey">
          <div
            className="h-full bg-blue"
            style={{
              width: ((currentPage + 1) / totalPages) * 100 + "%",
            }}
          ></div>
        </div>
        <span>{totalPages}</span>
        <button
          onClick={() => setCurrentPage((prev) => (prev + 1) % totalPages)}
        >
          <img src={rightArrowIcon} className="w-4" />
        </button>
      </div>
    </div>
  );
};
