import { SVGProps, useState } from "react";
import "./SubscribeNow.scss";
import LeadGeneration from "../LeadGeneration";
import { useNavigate, useLocation } from "react-router-dom";
import useLeadGeneration from "../../hooks/useLeadGeneration";

const takeAFreeTestContent = [
  {
    title:
      "Discover Top 100 Trending  Dream Careers, Dream Courses, Dream Colleges",
    button: "Registration",
  },
];
export function MaterialSymbolsLightClose(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z"
      ></path>
    </svg>
  );
}

const SubscribeNow = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState<string | null>(
    localStorage.getItem("user")
  );
  const [leadGenerationPopupIsOpen, setLeadGenerationPopupIsOpen] =
  useState(false);
  const { pathname } = useLocation();
  const user = localStorage.getItem("user");
  const navigate = useNavigate();
    const { invokeLeadGeneration } = useLeadGeneration();
    const handleLeadGeneration = () => {
      invokeLeadGeneration(
        "course-landing",
        "registration"
      );
    };
  
  return (
    <div className="flex py-8 my-10 subscribe-now-section bg-gradient-to-r from-blue-900 to-teal-500">
      <div className="container px-4 mx-auto">
        <div className="flex flex-row w-full">
          {takeAFreeTestContent.map((content, index) => (
            <div
              key={index}
              className="relative flex items-center justify-between w-full gap-4 md:flex-row"
            >
              <div className="flex flex-row items-center gap-3">
                <div className="p-2 magazine-box">
                  <span className="text-xs text-white">
                    <strong>College Mentor</strong> <br /> Magazine PRO
                  </span>
                  <img src="/images/magazinePro.png" alt="" className="mb-2" />
                  <button
                    className="flex flex-row items-center justify-center gap-2 mx-auto subscribe-btn white-color sm:text-xs sm:w-48"
                    onClick={handleLeadGeneration} // Open popup on button click
                  >
                    {content.button}
                  </button>
                </div>
                <h3 className="m-0 ml-40 text-sm font-bold text-white subscribe-title md:text-lg lg:text-2xl">
                  {content.title}
                </h3>
              </div>
              <div className="flex flex-col">
                <button
                  className="flex flex-row items-center justify-center gap-2 subscribe-now white-color btn-styles sm:text-xs sm:w-48 button-styles"
                  onClick={handleLeadGeneration} // Open popup on button click
                >
                  {content.button}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscribeNow;
