import { faBars, faBell, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode, useState } from "react";
import Modal from "../../components/Modal";
import { Link, NavLink, useNavigate } from "react-router-dom";

interface IProps {
  navLinks: { name: string; icon: ReactNode; link: string }[];
}

const MobileHeader: FC<IProps> = ({ navLinks }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <div className="px-8 py-4 bg-white flex items-center justify-between">
        <div className="flex items-center gap-4">
          <Link to="/">
            <img
              src="/images/CM.png"
              alt="Icon"
              className="w-[30px] h-[30px]"
            />
          </Link>
          <div className="flex flex-col">
            <div className="font-bold text-[12px] whitespace-nowrap">
              College Mentor
            </div>
            <div className="text-grey text-[10px] whitespace-nowrap">
              Education panel
            </div>
          </div>
        </div>
        <div className="user-content relative flex gap-4 item-center">
          <button>
            <FontAwesomeIcon icon={faBell} className="text-gray-600" />
          </button>
          {/* <div className="w-12 h-12 flex items-center justify-center bg-gray-200 rounded-lg shadow-lg">
            <img src="/images/avatar.webp" className="h-8 w-8 text-gray-700" />
          </div>
          <div className="flex flex-col">
            <div className="text-[12px] font-bold">John</div>
            <div className="text-[12px]"></div>
            Student
          </div> */}
          <button
            className="text-base text-blue border border-blue rounded-full px-4 py-2"
            onClick={handleLogout}
          >
            Log out
          </button>
          <button onClick={() => setExpanded((prev) => !prev)}>
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
      </div>
      <Modal isOpen={expanded} onClose={() => setExpanded(false)}>
        <div className="pt-20 p-4 bg-white h-screen w-screen flex flex-col">
          {navLinks.map((item) => (
            <NavLink
              to={item.link}
              onClick={() => setExpanded(false)}
              className={({ isActive }) =>
                `p-4 ${
                  isActive ? "bg-[#e6e6e6] text-black font-bold" : ""
                } rounded-lg flex gap-4 items-center`
              }
            >
              {item.icon}
              <span className="w-full text-sm">{item.name}</span>
            </NavLink>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default MobileHeader;
