import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postCtaData } from "../thunks/leadGenThunk";

interface ILeadGen {
  ctaTriggered: boolean;
  isLoading: boolean;
  isSuccessful: boolean;
  isAlertOpen: boolean;
  leadPageName: string;
  leadOn: string;
}

const initialState: ILeadGen = {
  ctaTriggered: false,
  isLoading: false,
  isSuccessful: false,
  isAlertOpen: false,
  leadPageName: "",
  leadOn: "",
};

const leadGenSlice = createSlice({
  name: "leadGen",
  initialState,
  reducers: {
    triggerCta: (
      state,
      action: PayloadAction<{ leadPageName: string; leadOn: string }>
    ) => {
      state.ctaTriggered = true;
      state.leadPageName = action.payload.leadPageName;
      state.leadOn = action.payload.leadOn;
      console.log("redux action: trigger CTA");
      return state;
    },
    closeAlert: (state) => {
      state.isAlertOpen = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCtaData.pending, (state) => {
        state.isLoading = true;
        state.ctaTriggered = false;
      })
      .addCase(postCtaData.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccessful = true;
        state.isAlertOpen = true;
      })
      .addCase(postCtaData.rejected, (state) => {
        state.isLoading = false;
        state.isSuccessful = false;
        state.isAlertOpen = true;
      });
  },
});

export const { triggerCta, closeAlert } = leadGenSlice.actions;

export default leadGenSlice.reducer;
