import React from "react";
import PhDStudents from "../research/PhDStudents";
import StudentsProjects from "../research/StudentsProjects";
import FundedProjects from "../research/FundedProjects";
import LatestUpdates from "../research/ResearchLatestUpdates";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import ApplicationForms from "../sidebar/ApplicationForms";
import CoursesList from "../sidebar/CoursesList";
import ContentMaping from "../research/ContentMaping";

interface College {
  image: string;
  name: string;
  logo: string;
  location: string;
  title: string;
}

interface ResearchTabProps {
  data: {
    key_0?: { description: string; title: string };
    key_1?: { description: string; title: string };
    key_2?: { description: string; title: string };
    key_3?: { description: string; title: string };
    key_4?: { description: string; title: string };
    key_6?: {
      array: { description: string; orderIndex: string; title: string }[];
      title: string;
    };
    key_7?: {
      array: { description: string; orderIndex: string; title: string }[];
      title: string;
    };
    similarColleges: College[];
  };
}

const ResearchTab: React.FC<ResearchTabProps> = ({ data }) => {
  const {  key_1, key_2, key_3, key_4,key_6, key_7, similarColleges } =
    data;

  const contentKeys = [key_1, key_2, key_3, key_4].filter(
    (key): key is { description: string; title: string } => !!key
  );

  return (
    <div className="bg-gray-100 p-6">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-9/12">
            {key_1 && (
              <LatestUpdates
                title={key_1.title}
                description={key_1.description}
              />
            )}

            {contentKeys.map((content, index) => (
              <ContentMaping
                key={`content-mapping-${index}`}
                title={content.title}
                description={content.description}
              />
            ))}

            {key_7 && (
              <StudentsProjects title={key_7.title} array={key_7.array} />
            )}

            {key_6 && (
              <FundedProjects title={key_6.title} array={key_6.array} />
            )}
          </div>

          <div className="w-full lg:w-3/12 hidden lg:block">
            <div className="flex flex-col gap-10">
              <ApplicationForms />
              <CoursesList />
            </div>
          </div>
        </div>

        {similarColleges && similarColleges.length > 0 ? (
          <SimilarEngineeringColleges colleges={similarColleges} />
        ) : (
          <p className="text-center text-gray-600">
            No similar colleges available at the moment.
          </p>
        )}
      </div>
    </div>
  );
};

export default ResearchTab;
