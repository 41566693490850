import React, { useState } from "react";

interface FAQ {
  question: string;
  answer: string;
}

interface KnowledgeHubFAQProps {
  title: string;
  faqs: FAQ[];
}

const KnowledgeHubFAQ: React.FC<KnowledgeHubFAQProps> = ({ title, faqs }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(0);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-white py-10">
      <div className="flex flex-col gap-4">
        <div className="w-full lg:w-9/12">
          <div className="flex flex-col gap-3">
            <h3 className="text-4xl font-bold text-blue">{title}</h3>
            <div className="flex flex-col gap-4">
              {faqs.map((faq, index) => (
                <div key={index} className="border-b last:border-b-0">
                  <button
                    onClick={() => toggleFAQ(index)}
                    className="w-full text-black text-base text-left font-semibold gap-3"
                  >
                    <div className="flex items-center gap-3">
                      <span className="text-2xl bg-blue text-white rounded-md flex flex-col px-1 py-0">
                        {openIndex === index ? "−" : "+"}
                      </span>
                      <span>{faq.question}</span>
                    </div>
                  </button>
                  {openIndex === index && (
                    <div className="px-6 py-3">
                      <p className="text-black">{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeHubFAQ;
