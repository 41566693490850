import { FC, useState } from "react";
import Question from "./Question";
import { useNavigate, useLocation } from "react-router-dom";

export interface Option {
  answer: string;
  suggestion?: string; 
}

export interface QuizQuestion {
  question: string;
  options: Option[];
}

interface QuizWidgetProps {
  questions: QuizQuestion[];
  destinationLink: string;
}

const QuizWidget: FC<QuizWidgetProps> = ({ questions, destinationLink }) => {
  const numberOfQuestions = questions.length;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [responses, setResponses] = useState<{ question: string; answer: string; suggestion?: string }[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [showThanks, setShowThanks] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleResponse = (answer: string, suggestion?: string) => {
    const user = localStorage.getItem("user");
    if (currentQuestion === 1 && !user) {
      navigate("/secure/login", { state: { sourcePagePath: pathname } });
    }
    setSelectedOption(answer);
    const updatedResponses = [...responses];
    updatedResponses[currentQuestion] = {
      question: questions[currentQuestion].question,
      answer,
      suggestion, 
    };
    setResponses(updatedResponses);
  };

  const goToNextQuestion = () => {
    if (currentQuestion + 1 < numberOfQuestions) {
      setCurrentQuestion((prev) => prev + 1);
      setSelectedOption(null);
    } else {
      if (destinationLink) {
        navigate(destinationLink);
      } else {
        setShowThanks(true);
      }
    }
  };

  const goToPreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prev) => prev - 1);
      setSelectedOption(null);
    }
  };
  const handleMapMyCollege = () => {
    
  }
  return (
    <div className="bg-white p-8 w-80 md:w-[640px]">
      {showThanks ? (
        <div className="text-center">
          <h2 className="text-3xl font-bold">Thank you for the response!</h2>
          <p>We appreciate your time and answers. Have a great day!</p>
        </div>
      ) : (
        <>
          <h1 className="text-3xl font-bold text-blue">Map Your College Journey!</h1>
          <span className="text-sm text-grey">{`Qn: ${currentQuestion + 1}/${numberOfQuestions}`}</span>
          <Question
            questionData={questions[currentQuestion]}
            handleResponse={handleResponse}
            selectedOption={selectedOption}
          />
          {selectedOption && responses[currentQuestion]?.suggestion && (
            <div className="mt-4 p-4 bg-blue-50 rounded-md text-blue-900">
              <h3 className="font-semibold">Suggested Path:</h3>
              <p>{responses[currentQuestion].suggestion}</p>
            </div>
          )}
          <div className="mt-6 flex justify-between">
            <button
              onClick={goToPreviousQuestion}
              disabled={currentQuestion === 0}
              className="px-4 py-2 bg-gray-300 rounded-lg"
            >
              Previous
            </button>
            <button
              onClick={goToNextQuestion}
              disabled={selectedOption === null}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg"
            >
              {currentQuestion + 1 < numberOfQuestions ? "Next" : "Finish"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default QuizWidget;
