import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { emailValidator, passwordValidator } from "../../util/FormValidator";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/InputField";
import { AiFillLock, AiOutlineMail } from "react-icons/ai";

function Login() {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);

  const [data, setData] = useState({
    userName: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    userName: "",
    password: "",
  });

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    let errorRes = "";
    if (name === "userName") {
      errorRes = emailValidator(value);
    } else if (name === "password") {
      errorRes = passwordValidator(value);
    }

    setErrors((prevState) => ({
      ...prevState,
      [name]: errorRes,
    }));
  };
  const handleRememberPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    setSubmitted(true);
  };

  const registerAction = (event: any) => {
    navigate("/registration");
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        border: "1px solid #F2F2F2",
        borderRadius: 5,
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Login</Typography>
        <Box
          sx={{
            border: "1px solid #047AE8",
            borderRadius: "10px",
            flexDirection: "row",
            marginLeft: 0,
            justifyContent: "space-between",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <Button
            style={{
              height: "25px",
              fontFamily: "Arial",
              fontSize: 12,
              backgroundColor: "#047AE8",
              borderRadius: "8px",
              color: "white",
            }}
          >
            Login
          </Button>
          <Button
            sx={{ height: "25px", fontFamily: "Arial", fontSize: 12 }}
            onClick={registerAction}
          >
            Register
          </Button>
        </Box>
        <Typography
          variant="h6"
          sx={{ fontFamily: "Arial", fontSize: 12, color: "#B5B5B5" }}
        >
          Kindly fill in below details to Login
        </Typography>
        <Box sx={{ mt: 1, width: "100%" }} width={"100%"}>
          <InputField
            icon={<AiOutlineMail />}
            type={"text"}
            name={"userName"}
            label={"User Name"}
            placeholder={"Enter your Username"}
            value={data?.userName}
            onChange={handleChange}
            required={true}
            error={errors?.userName !== ""}
            helperText={errors?.userName !== "" ? errors?.userName : " "}
          />

          <InputField
            icon={<AiFillLock />}
            type={"password"}
            name={"password"}
            label={"Password"}
            placeholder={"Enter your Password"}
            value={data?.password}
            onChange={handleChange}
            required={true}
            error={errors?.password !== ""}
            helperText={errors?.password !== "" ? errors?.password : " "}
          />

          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 1,
              m: 1,
            }}
          >
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    onChange={handleRememberPassword}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Remember me"
              />
            </Grid>
            <Grid item>
              <Link href={"/forget-password"} variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, borderRadius: 10 }}
            onClick={handleSubmit}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
