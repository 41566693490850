import React, { useState } from "react";
import StepNavigationWrapper from "./common/StepNavigationWrapper";
import { useForm } from "react-hook-form";
import PlaceholderChipGroup from "./common/PlaceholderChip/PlaceholderChipGroup";
import {
  nextStep,
  prevStep,
  updateForm,
} from "../../../../store/slices/registrationFormSlice";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../store/store";
import OptionGroup from "./common/OptionsCard/OptionGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const FundEducation = () => {
  const dispatch = useDispatch();
  const { firstName, lastName, email, mobileNumber, currentLevelOfStudy } =
    useSelector((state: RootState) => state.registrationFormSlice.data);

  const { step } = useSelector(
    (state: RootState) => state.registrationFormSlice
  );

  const { pafData } = useSelector((state: RootState) => state.auth);
  const { register, handleSubmit } = useForm();

  const [selectedCard, setSelectedCard] = useState<any>("");
  const [selectedSingle, setSelectedSingle] = useState<any>(null);
  const [selectedScholarship, setSelectedScholarship] = useState<any>(null);

  const onSubmit = (data: any) => {
    dispatch(updateForm({ degree: selectedSingle }));
    dispatch(nextStep());
  };

  const handleCardClick = (card: string) => {
    console.log("CourseLookingFor - Message from Child: ", card);
    setSelectedCard(card);
  };

  const handleSingleSelectionChange = (selected: any) => {
    setSelectedSingle(selected);
  };

  const handleBoardSelectionChange = (selected: any) => {
    const existingData = JSON.parse(localStorage.getItem("paf_latest") || "{}");

    const updatedData = {
      ...existingData,
      lookingForScholarship: selected,
    };

    localStorage.setItem("paf_latest", JSON.stringify(updatedData));
    setSelectedScholarship(selected);
    // dispatch(updateForm({ lookingForScholarship: selected }));
    // Reset percentage options whenever board changes
  };

  const handleNext = () => {
    debugger;
    dispatch(updateForm({ educationFund: selectedSingle }));
    console.log("step", step);
    // dispatch(nextStep());
  };

  const handlePrev = () => {
    dispatch(prevStep());
  };

  return (
    <div className="flex flex-col w-auto gap-2">
      {" "}
      <StepNavigationWrapper
        onNext={handleSubmit(onSubmit)}
        showNext={true}
        showBack={true}
        stepName="Fund your Education?"
        stepDescription=""
      >
        <div className="w-full p-4 overflow-auto">
          <OptionGroup
            options={pafData?.educationFunds}
            mode="single"
            getTitle={(option) =>
              typeof option === "string" ? option : option.title
            }
            onSelectionChange={handleSingleSelectionChange}
            cardSize="52"
            localStorageKey="educationFund"
          />

          {/* Conditionally render the additional question */}
          {selectedSingle === "Scholarship" && (
            <div className="flex flex-col gap-4 mt-4">
              
              <span className="container text-2xl font-semibold text-[#113cc0]">
              Are You Looking for Scholarship?
              </span>
              <OptionGroup
                options={pafData?.shcolarShipType} // Assuming you have options for the educational boards
                mode="single"
                getTitle={(option) =>
                  typeof option === "string" ? option : option.title
                }
                onSelectionChange={handleBoardSelectionChange} // Define a function for handling board selection
                cardSize="52"
                localStorageKey="lookingForScholarship"
              />
            </div>
          )}

          <div className="flex items-center justify-center mt-10">
            <div className="flex flex-row items-center gap-2">
              <div className="flex items-center gap-2">
                {/* <button
                  className="flex items-center justify-center w-8 h-8 text-black bg-white rounded-full hover:text-green"
                  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                  onClick={handlePrev}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <span>Step {step} of 10</span>
                <button
                  className="flex items-center justify-center w-8 h-8 text-black bg-white rounded-full hover:text-green"
                  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                  onClick={handleNext}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button> */}

                {/* <button
                  className="px-2 py-2 m-3 text-sm font-semibold duration-200 rounded-full md:px-6 blue-color button-styles"
                  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                  onClick={handlePrev}
                >
                  Back
                </button> */}

                {/* <button
                  className="px-2 py-2 m-3 text-sm font-semibold duration-200 rounded-full md:px-6 blue-color button-styles"
                  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                  onClick={handleNext}
                >
                  Next
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </StepNavigationWrapper>
    </div>
  );
};

export default FundEducation;
