import { Link } from "react-router-dom";

const adBanner = "/images/viksit-bharat-sch-banner.png";
const logo = "/images/viksit-bharat-sch-logo.png";

const ViksitBharatScholarshipAd = () => {
  return (
    <Link
      to="/viksit-bharat-scholarship"
      className="w-fit p-4 mx-auto my-6 flex max-md:flex-col items-center justify-center border border-grey-4 rounded-lg shadow-lg"
    >
      <div className="shrink-1">
        <img src={logo} />
      </div>
      <div className="shrink-1">
        <img src={adBanner} />
      </div>
    </Link>
  );
};

export default ViksitBharatScholarshipAd;
