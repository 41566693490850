import React from "react";

export const HowItWorks: React.FC = () => {
  return (
    <div className="flex flex-wrap gap-5 justify-between px-20 py-16 w-full bg-[#EEFFFF] max-md:px-8 max-md:max-w-full my-1">
      <div className="flex flex-row max-md:flex-col justify-between max-md:gap-7">
        <div className="flex flex-col my-auto max-md:w-full w-[47%]">
          <h2 className="text-[32px] font-bold leading-none text-[#173CBA] max-md:max-w-full">
            How It Works
          </h2>
          <div className="flex flex-col mt-6 w-full text-base leading-7 text-black max-md:max-w-full">
            <h3 className="text-[32px] font-light leading-10 max-md:max-w-full">
              "College Mentor Solutions for Students from Class 8 to College
              Graduates."
            </h3>
            <p className="mt-2.5 max-md:max-w-full">
              <span className="font-semibold">
                India's Premier IkIGAI Career Discovery Platform
              </span>
              <br />
              Our psychometric assessment (MCMF), developed by expert
              psychologists from IIT-Madras and top psychology colleges,
              provides a scientific evaluation of your interests.
            </p>
            <p className="mt-2.5 max-md:max-w-full">
              Following the assessment, you'll receive comprehensive career
              counseling with our team of psychologists, educators, mentors, and
              career coaches, along with lifelong mentoring until you embark on
              your chosen career path.
            </p>
          </div>
        </div>
        <div className="w-[35%] max-md:w-full">
          <img
            loading="lazy"
            src="/images/career-discovery/how-it-works.png"
            alt="How It Works Process"
            className="object-contain  aspect-square max-md:max-w-full w-[427px] h-[427px]"
          />
        </div>
      </div>
    </div>
  );
};
