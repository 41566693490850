import React from "react";

const ProcessSteps = () => {
  return (
    <div className="w-full bg-[#061958] flex flex-col md:gap-10 pb-10">
      <div className="flex max-md:flex-col max-md:gap-6 items-center md:px-[140px] justify-between py-10 max-md:px-10 ">
        <div className="flex flex-col self-stretch my-auto min-w-[240px] md:w-[522px] max-md:max-w-full ">
          <h3 className="text-[40px] font-bold leading-[55px] text-[#0c9] ">
            Your Campus Tour Admissions Advisor
          </h3>
          <div className="flex flex-col mt-6 w-full text-base leading-8 text-white max-md:max-w-full">
            <div className="max-md:max-w-full">
              <span className="font-semibold text-[#0c9]">
                From Anywhere to Everywhere:
              </span>
              Virtual Campus Tours with Insightful Guidance Explore your future
              campus with our interactive tours - see facilities, meet students,
              and get a real feel for campus life. Make informed choices with
              our guided, immersive experience
            </div>
            <div className="mt-4 max-md:max-w-full">
              <button className="px-3 py-2 text-sm  rounded text-white bg-[#0C9] self-center mt-7 items-center flex max-md:mt-6">
                Map My Campus Tour
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="12"
                  viewBox="0 0 15 12"
                  fill="none"
                  className="ml-2"
                >
                  <path
                    d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM14.5303 6.53033C14.8232 6.23744 14.8232 5.76256 14.5303 5.46967L9.75736 0.6967C9.46447 0.403806 8.98959 0.403806 8.6967 0.6967C8.40381 0.989593 8.40381 1.46447 8.6967 1.75736L12.9393 6L8.6967 10.2426C8.40381 10.5355 8.40381 11.0104 8.6967 11.3033C8.98959 11.5962 9.46447 11.5962 9.75736 11.3033L14.5303 6.53033ZM1 6.75L14 6.75L14 5.25L1 5.25L1 6.75Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center px-20 py-32 rounded-xl min-w-[240px] w-[572px] bg-[#89C9ED] max-md:px-5 max-md:py-24 max-md:max-w-full cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="74"
            height="74"
            viewBox="0 0 74 74"
            fill="none"
          >
            <path
              d="M32 25L50 36.5L32 48V25Z"
              stroke="white"
              stroke-width="2.99638"
              stroke-miterlimit="10"
              stroke-linejoin="bevel"
            />
            <path
              d="M61.748 12.2527C75.4173 25.9197 75.4173 48.0792 61.748 61.7473C48.0798 75.4176 25.919 75.4176 12.252 61.7473C-1.41733 48.0803 -1.41733 25.9208 12.252 12.2527C25.919 -1.41758 48.0787 -1.41758 61.748 12.2527Z"
              stroke="white"
              stroke-width="2.99638"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
      </div>
      <div className="flex  max-md:flex-row justify-between items-center text-5xl font-light leading-none text-center text-[#0C9] py-10 px-[50px] max-md:text-4xl">
        <div className="shrink-0 self-stretch my-auto h-px border border-solid border-white border-opacity-50 max-md:w-[40px] md:w-[514px]" />
        <h2 className="self-stretch my-auto max-md:text-4xl">The Process</h2>
        <div className="shrink-0 self-stretch my-auto h-px border border-solid border-white border-opacity-50 max-md:w-[40px] md:w-[546px]" />
      </div>
      <div className="flex max-md:flex-col items-center md:px-[100px] gap-5 py-10  max-md:px-10">
        <div className="flex flex-col self-stretch my-auto min-w-[240px] md:w-[522px] max-md:max-w-full">
          <div className="flex max-md:gap-4">
            <h3 className="text-4xl max-md:text-[28px] font-semibold leading-none max-md:my-auto text-[#0c9] ">
              Pre-Tour Preparation
            </h3>
            <span className="self-stretch my-auto w-14 leading-none text-[104px] text-white text-opacity-20  md:hidden">
              1
            </span>
          </div>
          <div className="flex flex-col mt-6 w-full text-base leading-8 text-white max-md:max-w-full">
            <div className="max-md:max-w-full">
              <span className="font-semibold text-[#0c9]">
                Review Your Goals
              </span>
              : Your College mentor can help you clarify what you're looking for
              in a college, whether it's specific academic programs, campus
              culture, or extracurricular activities.
            </div>
            <div className="mt-4 max-md:max-w-full">
              <span className="font-semibold text-[#0c9]">
                Research Colleges Together
              </span>
              : Use your mentor's experience to research colleges, discuss
              potential options, and prioritize which campuses to visit based on
              your interests and goals.
            </div>
          </div>
        </div>
        <div className="self-stretch my-auto w-36 leading-none text-[250px] text-white text-opacity-20 max-md:text-4xl max-md:hidden">
          1
        </div>
        <div className="flex flex-col self-stretch my-auto rounded-xl min-w-[240px] w-[572px] max-md:max-w-full">
          <img
            loading="lazy"
            src="/images/applicationToAdmission/process-step1.png"
            alt="Pre-tour preparation visual"
            className="object-contain w-full rounded-xl aspect-[1.75] max-md:max-w-full"
          />
        </div>
      </div>

      <div className="flex max-md:flex-col items-center md:px-[100px] gap-5 py-10  max-md:px-10">
        <div className="gap-14 flex flex-row max-md:flex-col-reverse">
          <div className="flex flex-col self-stretch my-auto rounded-xl min-w-[240px] md:w-[572px] max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/applicationToAdmission/process-step2.png"
              alt="Pre-tour preparation visual"
              className="object-contain w-full rounded-xl aspect-[1.75] max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col self-stretch my-auto min-w-[240px] md:w-[522px] max-md:max-w-full">
            <div className="flex max-md:gap-4">
              <h3 className="text-4xl max-md:text-[28px] font-semibold leading-none max-md:my-auto text-[#0c9] ">
                Develop a Customized Tour Plan
              </h3>
              <span className="self-stretch my-auto w-14 leading-none text-[104px] text-white text-opacity-20  md:hidden">
                2
              </span>
            </div>
            <div className="flex flex-col mt-6 w-full text-base leading-8 text-white max-md:max-w-full">
              <div className="max-md:max-w-full">
                <span className="font-semibold text-[#0c9]">
                  Create an Itinerary :
                </span>
                Work with your mentor to develop a detailed tour schedule,
                including guided tours, information sessions, and any meetings
                with faculty or admissions staff.
              </div>
              <div className="mt-4 max-md:max-w-full">
                <span className="font-semibold text-[#0c9]">
                  List Key Questions :
                </span>
                Your mentor can help you come up with insightful questions to
                ask during the tour, tailored to your academic and personal
                interests.
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch my-auto w-36 leading-none text-[250px] text-white text-opacity-20 max-md:text-4xl max-md:hidden">
          2
        </div>
      </div>
      <div className="flex max-md:flex-col gap-5 items-center md:px-[100px] py-10  max-md:px-10">
        <div className="flex flex-col self-stretch my-auto min-w-[240px] md:w-[522px] max-md:max-w-full">
          <div className="flex max-md:gap-4">
            <h3 className="text-4xl max-md:text-[28px] font-semibold leading-none max-md:my-auto text-[#0c9] ">
              Maximize Your Campus Visit
            </h3>
            <span className="self-stretch my-auto w-14 leading-none text-[104px] text-white text-opacity-20  md:hidden">
              3
            </span>
          </div>
          <div className="flex flex-col mt-6 w-full text-base leading-8 text-white max-md:max-w-full">
            <div className="max-md:max-w-full">
              <span className="font-semibold text-[#0c9]">
                Focus on Priorities :
              </span>
              With your college mentor, determine which aspects of the campus
              visit are most important to you and ensure these are covered
              during the tour.
            </div>
            <div className="mt-4 max-md:max-w-full">
              <span className="font-semibold text-[#0c9]">
                Engage Effectively :
              </span>
              Practice asking questions and engaging with tour guides and
              current Buddy mentor students based on advice from your mentor to
              get the most out of your interactions.
            </div>
          </div>
        </div>
        <div className="self-stretch my-auto w-36 leading-none text-[250px] text-white text-opacity-20 max-md:text-4xl max-md:hidden">
          3
        </div>
        <div className="flex flex-col self-stretch my-auto rounded-xl min-w-[240px] w-[572px] max-md:max-w-full">
          <img
            loading="lazy"
            src="/images/applicationToAdmission/process-step3.png"
            alt="Pre-tour preparation visual"
            className="object-contain w-full rounded-xl aspect-[1.75] max-md:max-w-full"
          />
        </div>
      </div>
      <div className="flex max-md:flex-col items-center md:px-[100px] gap-5 py-10  max-md:px-10">
        <div className="gap-14 flex flex-row max-md:flex-col-reverse">
          <div className="flex flex-col self-stretch my-auto rounded-xl min-w-[240px] md:w-[572px] max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/applicationToAdmission/process-step4.png"
              alt="Pre-tour preparation visual"
              className="object-contain w-full rounded-xl aspect-[1.75] max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col self-stretch my-auto min-w-[240px] md:w-[522px] max-md:max-w-full">
            <div className="flex max-md:gap-4">
              <h3 className="text-4xl max-md:text-[28px] font-semibold leading-none max-md:my-auto text-[#0c9] ">
                Evaluate and Reflect
              </h3>
              <span className="self-stretch my-auto w-14 leading-none text-[104px] text-white text-opacity-20  md:hidden">
                4
              </span>
            </div>
            <div className="flex flex-col mt-6 w-full text-base leading-8 text-white max-md:max-w-full">
              <div className="max-md:max-w-full">
                <span className="font-semibold text-[#0c9]">
                  Discuss Impressions :
                </span>
                After each campus visit, have a debriefing session with your
                mentor to discuss your impressions, feelings, and any notable
                observations.
              </div>
              <div className="mt-4 max-md:max-w-full">
                <span className="font-semibold text-[#0c9]">
                  Compare Notes :
                </span>
                Use your mentor’s guidance to help compare the colleges you’ve
                visited, focusing on how each aligns with your academic and
                personal goals. Consider Follow-Up Actions:
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch my-auto w-36 leading-none text-[250px] text-white text-opacity-20 max-md:text-4xl max-md:hidden">
          4
        </div>
      </div>
      <div className="flex max-md:flex-col gap-5 items-center md:px-[100px] py-10  max-md:px-10">
        <div className="flex flex-col self-stretch my-auto min-w-[240px] md:w-[522px] max-md:max-w-full">
          <div className="flex max-md:gap-4">
            <h3 className="text-4xl max-md:text-[28px] font-semibold leading-none max-md:my-auto text-[#0c9] ">
              Plan Additional Visits
            </h3>
            <span className="self-stretch my-auto w-14 leading-none text-[104px] text-white text-opacity-20  md:hidden">
              5
            </span>
          </div>
          <div className="flex flex-col mt-6 w-full text-base leading-8 text-white max-md:max-w-full">
            <div className="max-md:max-w-full">
              <span className="font-semibold text-[#0c9]">If necessary :</span>
              work with your college mentor to arrange follow-up visits or
              meetings with faculty or current students for deeper insights.
            </div>
            <div className="mt-4 max-md:max-w-full">
              <span className="font-semibold text-[#0c9]">
                Review Application Requirements :
              </span>
              Your College mentor can help you understand the application
              process for the colleges you’re interested in, including deadlines
              and required materials.
            </div>
          </div>
        </div>
        <div className="self-stretch my-auto w-36 leading-none text-[250px] text-white text-opacity-20 max-md:text-4xl max-md:hidden">
          5
        </div>
        <div className="flex flex-col self-stretch my-auto rounded-xl min-w-[240px] w-[572px] max-md:max-w-full">
          <img
            loading="lazy"
            src="/images/applicationToAdmission/process-step5.png"
            alt="Pre-tour preparation visual"
            className="object-contain w-full rounded-xl aspect-[1.75] max-md:max-w-full"
          />
        </div>
      </div>
      <div className="flex max-md:flex-col items-center md:px-[100px] gap-5 py-10  max-md:px-10">
        <div className="gap-14 flex flex-row max-md:flex-col-reverse">
          <div className="flex flex-col self-stretch my-auto rounded-xl min-w-[240px] md:w-[572px] max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/applicationToAdmission/process-step6.png"
              alt="Pre-tour preparation visual"
              className="object-contain w-full rounded-xl aspect-[1.75] max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col self-stretch my-auto min-w-[240px] md:w-[522px] max-md:max-w-full">
            <div className="flex max-md:gap-4">
              <h3 className="text-4xl max-md:text-[28px] font-semibold leading-none max-md:my-auto text-[#0c9] ">
                Make a Decision
              </h3>
              <span className="self-stretch my-auto w-14 leading-none text-[104px] text-white text-opacity-20  md:hidden">
                6
              </span>
            </div>
            <div className="flex flex-col mt-6 w-full text-base leading-8 text-white max-md:max-w-full">
              <div className="max-md:max-w-full">
                <span className="font-semibold text-[#0c9]">
                  Weigh Your Options :
                </span>
                Use the feedback and reflections from your mentor to help weigh
                the pros and cons of each college.
              </div>
              <div className="mt-4 max-md:max-w-full">
                <span className="font-semibold text-[#0c9]">
                  Final Decision :
                </span>
                With your mentor’s support, make a final decision based on a
                comprehensive evaluation of each college and how well they meet
                your criteria. Having a college mentor involved in your campus
                tours can provide valuable guidance and ensure you’re making
                well-informed decisions about your future.
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch my-auto w-36 leading-none text-[250px] text-white text-opacity-20 max-md:text-4xl max-md:hidden">
          6
        </div>
      </div>
    </div>
  );
};

export default ProcessSteps;
