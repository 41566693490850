import axios from "axios";
import { useEffect, useState } from "react";
import useDebouncedSearch from "../../components/filter/customHooks/useDebouncedSearch";
import { College } from "./types";
import CollegeCard from "./components/CollegeCard";
import config from '../../util/config';
const DUMMY_COLLEGE_DATA: College[] = [
  {
    collegeLocation: ", Assam",
    collegeName: "SRM University",
    rankings: "NIRF Rank 32nd",
    ownerShip: "Private",
    collegeShortName: "SRM University",
    collegeId: 1726,
    approvedBy: "BCI",
    accrediationBy: "NIRF,NAAC",
    collegeState: "Assam",
    collegeCity: "",
    collegeUrl: config.apiUrl+"/ajmal-law-college-hojai",
  },
  {
    collegeLocation: ", West Bengal",
    collegeName: "Acharya Jagadish Chandra Bose Polytechnic, North 24 Parganas",
    rankings: "",
    ownerShip: "",
    collegeShortName:
      "Acharya Jagadish Chandra Bose Polytechnic, North 24 Parganas",
    collegeId: 1818,
    approvedBy: "AICTE",
    accrediationBy: "NIRF,NAAC",
    collegeState: "West Bengal",
    collegeCity: "",
    collegeUrl:
      config.apiUrl+"acharya-jagadish-chandra-bose-polytechnic-north-24-parganas",
  },
  {
    collegeLocation: ", Rajasthan",
    collegeName: "SRM University",
    rankings: "NIRF Rank 32nd",
    ownerShip: "Private",
    collegeShortName: "SRM University",
    collegeId: 1914,
    approvedBy: "AICTE",
    accrediationBy: "NIRF,NAAC",
    collegeState: "Rajasthan",
    collegeCity: "",
    collegeUrl: config.apiUrl+"aishwarya-college-jodhpur",
  },
];

const CollegeIntermediate2: React.FC = () => {
  const [collegeListData, setCollegeListData] = useState<College[] | null>([]);
  const { searchKeyword, handleKeywordChange, deboundedSearchKeyword } =
    useDebouncedSearch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          config.apiUrl + "/api/college/dream-college",
          {
            ...{
              course: [],
              state: [],
              branch: [],
              stream: [],
              affiliated: "",
              search: deboundedSearchKeyword,
              city: [""],
              offset: 0,
              fetchCount: 10,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
            },
          }
        );

        setCollegeListData(response?.data?.data?.list);
      } catch (error) {
        console.error("Error making API request:", error);
      }
    };

    fetchData();
  }, [deboundedSearchKeyword]);

  return (
    <div className="container max-w-screen-xl">
      <div className="flex gap-4">
        {/* filter section */}
        <div className="w-1/3 flex flex-col gap-2"></div>

        {/* list section */}
        <div className="w-2/3 flex flex-col gap-2">
          <input
            className="border-2 p-2"
            placeholder="Search college by name"
            value={searchKeyword}
            onChange={(event) => handleKeywordChange(event.target.value)}
          />
          {collegeListData == null && <>Couldn't fetch colleges</>}
          {collegeListData && collegeListData.length == 0 && (
            <>No colleges found</>
          )}
          {collegeListData &&
            collegeListData.length > 0 &&
            collegeListData.map((item) => <CollegeCard data={item} />)}
        </div>
      </div>
    </div>
  );
};

export default CollegeIntermediate2;
