import React, { useEffect, useState } from "react";
import graduationImg from "../../images/comprehensive/graduated-2.png";
import collegeImg from "../../images/comprehensive/collegeIcon.png";
import certificationImg from "../../images/comprehensive/certification.png";
import CounterAnimation from "../CounterAnimation";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import config from "../../util/config";
import { Loader } from "lucide-react";

export default function ComprehensiveTest({
  animationCheckPointRef,
}: {
  animationCheckPointRef: (el: HTMLDivElement | null) => void;
}) {
  const [examStats, setExamStats] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const response = await axios.get(
          config.apiUrl + "/api/exam/getexamLandingPage"
        );
        const featuredExams = response.data.data.featuredExams.stats;
        setExamStats(featuredExams);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching exam data", error);
        setLoading(false);
      }
    };

    fetchExamData();

    return () => {};
  }, []);

  const upcomingExamsStat = examStats?.find(
    (stat: { title: string }) => stat.title === "Upcoming Exams"
  );
  const activeExamsStat = examStats?.find(
    (stat: { title: string }) => stat.title === "Active Exams"
  );
  return (
    <div
      className="comprehensive-test py-11 "
      style={{ backgroundColor: "var(--Background-4, #DAFFF7)" }}
    >
      <div className="w-[1360px] mx-auto max-md:w-full">
        <div className="flex gap-5 max-md:flex-col-reverse max-md:gap-0">
          <div className="flex flex-col w-1/2 max-md:ml-0 max-md:w-full max-md:px-3">
            <div className="relative flex overflow-hidden relative flex-col grow justify-center md:min-h-[581.42px] w-full max-md:mt-10 max-md:max-w-full">
              <img
                loading="lazy"
                src="/images/group.png"
                alt="group img"
                className="w-full  max-md:max-w-full"
              />
              <div
                className="absolute top-1/2 left-1/2 -translate-x-8 -translate-y-20 h-16 w-16"
                ref={animationCheckPointRef}
              ></div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-1/2 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch px-5 my-auto max-md:mt-10 max-md:max-w-full">
              <div className="text-base font-extrabold tracking-widest text-indigo-500 max-md:max-w-full">
                Exam Prep Made Easy
              </div>
              <motion.div
                initial={{ x: "-8rem", opacity: 0 }}
                transition={{ ease: "easeOut", duration: 0.8 }}
                whileInView={{ x: 0, opacity: 1 }}
                className="mt-2 text-3xl font-extrabold capitalize text-slate-800 max-md:max-w-full"
              >
                Conquer Your Exams with College Mentor
              </motion.div>
              <div className="mt-6 text-sm font-normal leading-6 text-black max-md:max-w-full">
                Your Pathway to Excelling
                <br />
                Boost your scores and enhance admission possibilities with our
                all-encompassing test prep covering 250+ exams, JEE Mains, NEET,
                NIFT, NID, NATA, CLAT, CAT and BITS Pilani
              </div>
              <Link
                to="/exams"
                className="arrow-button flex gap-2 justify-center self-start px-9 py-3.5 mt-6 text-base font-semibold text-white white-color map-mentor  button-styles rounded-[50px] max-md:px-5"
              >
                Visit our test Library
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="currentColor"
                  className="arrow-icon"
                >
                  <path
                    d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                    fill="currentColor"
                  />
                </svg>
              </Link>
              <div className="mt-6 max-md:pr-5 max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-row max-md:gap-4">
                  <div className="library-card hover:shadow-lg flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow items-center md:px-5 md:py-9 w-full text-center bg-white rounded-xl border border-gray-100 border-solid max-md:px-5 max-md:mt-5">
                      <img
                        loading="lazy"
                        src={graduationImg}
                        alt="icon"
                        className="aspect-square w-[60px]"
                      />
                      <div className="self-stretch mt-4 text-2xl  font-bold leading-7 text-[#173CBA] max-md:text-[20px]">
                        <h3>Live Exams</h3>

                        <span>
                          <CounterAnimation
                            start={0}
                            end={activeExamsStat?.count || 0}
                          />{" "}
                          +
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="library-card hover:shadow-lg flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow items-center md:px-7 md:py-9 w-full text-center bg-white rounded-xl border border-gray-100 border-solid max-md:px-5 max-md:mt-5">
                      <img
                        loading="lazy"
                        src={collegeImg}
                        alt="icon"
                        className="aspect-[1.22] w-[73px]"
                      />
                      <div className="self-stretch mt-4 text-2xl font-bold leading-7 text-[#173CBA] max-md:text-[20px]">
                        <h3>Up Coming Exams</h3>
                        <span>
                          <CounterAnimation
                            start={0}
                            end={upcomingExamsStat?.count || 0}
                          />{" "}
                          +
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="library-card hover:shadow-lg flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow items-center md:px-7 md:py-8 w-full text-center bg-white rounded-xl border border-gray-100 border-solid max-md:px-5 max-md:mt-5">
                      <img
                        loading="lazy"
                        src="/images/viksith-bharath.png"
                        alt="icon"
                        className="max-md:aspect-[1.22] w-[85px] mt-3 md:aspect-[1.6]"
                      />
                      <div className="self-stretch mt-6 text-2xl font-bold leading-7 text-[#173CBA] max-md:text-[20px]">
                        <h3>Quizzes</h3>
                        <span>
                          <CounterAnimation start={0} end={2500} /> +
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
