import React, { useState, useEffect, useRef } from "react";
import "./SearchBox.scss";
import "animate.css";
import config from '../../util/config';

interface SearchProps {
  // Optional props for customization (placeholder text, etc.)
}
interface College {
  college_name: string;
  college_url: string;
  college_category: string;
}
const collegeNames = [
  {
    college_name:
      "CCSHAU Hisar - Chaudhary Charan Singh Haryana Agricultural University, Hisar",
    college_url:
      config.apiUrl+"/chaudhary-charan-singh-haryana-agricultural-university-hisar",
    college_category: "Agriculture",
  },
  {
    college_name: "Yenepoya University, Mangalore",
    college_url:
      config.apiUrl+"/yenepoya-university-mangalore",
    college_category: "Medical",
  },
  {
    college_name: "MDU Rohtak - Maharshi Dayanand University, Rohtak",
    college_url:
      config.apiUrl+"/maharshi-dayanand-university-rohtak",
    college_category: "Course",
  },
  {
    college_name: "Burdwan University - University of Burdwan, Bardhaman",
    college_url:
      config.apiUrl+"/university-of-burdwan-bardhaman",
    college_category: "Course",
  },
  {
    college_name: "NITTE University, Mangalore",
    college_url:
      config.apiUrl+"/nitte-university-mangalore",
    college_category: "Engineering",
  },
  {
    college_name: "Central University of Tamil Nadu, Thiruvarur",
    college_url:
      config.apiUrl+"/central-university-of-tamil-nadu-thiruvarur",
    college_category: "Exam",
  },
  {
    college_name: "NIT Srinagar - National Institute of Technology Srinagar",
    college_url:
      config.apiUrl+"/national-institute-of-technology-srinagar",
    college_category: "Exam",
  },
  {
    college_name: "PSG Tech Coimbatore - PSG College of Technology, Coimbatore",
    college_url:
      config.apiUrl+"/psg-college-of-technology-coimbatore",
    college_category: "ACADEMIC",
  },
  {
    college_name:
      "SKCET Coimbatore - Sri Krishna College of Engineering and Technology, Coimbatore",
    college_url:
      config.apiUrl+"/sri-krishna-college-of-engineering-and-technology-coimbatore",
    college_category: "ACADEMIC",
  },
  {
    college_name:
      "MNNIT Allahabad - Motilal Nehru National Institute of Technology Allahabad Prayagraj",
    college_url:
      config.apiUrl+"/motilal-nehru-national-institute-of-technology-allahabad-prayagraj",
    college_category: "Colleges",
  },
  {
    college_name:
      "Shoolini University Solan - Shoolini University of Biotechnology and Management Sciences, Solan",
    college_url:
      config.apiUrl+"/shoolini-university-of-biotechnology-and-management-sciences-solan",
    college_category: "Colleges",
  },
  {
    college_name: "AIIMS Patna - All India Institute of Medical Sciences Patna",
    college_url:
      config.apiUrl+"/all-india-institute-of-medical-sciences-patna",
    college_category: "Colleges",
  },
  {
    college_name:
      "SVNIT Surat - Sardar Vallabhbhai National Institute of Technology Surat",
    college_url:
      config.apiUrl+"/sardar-vallabhbhai-national-institute-of-technology-surat",
    college_category: "Colleges",
  },
  {
    college_name: "Visva Bharati University - Visva Bharati Santiniketan",
    college_url:
      config.apiUrl+"/visva-bharati-santiniketan",
    college_category: "Colleges",
  },
  {
    college_name: "Sharda University - Sharda University, Greater Noida",
    college_url:
      config.apiUrl+"/sharda-university-greater-noida",
    college_category: "Colleges",
  },
  {
    college_name:
      "Vignan's Foundation for Science Technology and Research, Guntur",
    college_url:
      config.apiUrl+"/vignans-foundation-for-science-technology-and-research-guntur",
    college_category: "Colleges",
  },
  {
    college_name: "Calicut University - University of Calicut, Malappuram",
    college_url:
      config.apiUrl+"/university-of-calicut-malappuram",
    college_category: "Colleges",
  },
  {
    college_name:
      "Birla Institute of Technology, Mesra (BIT Mesra) - Birla Institute of Technology, Mesra",
    college_url:
      config.apiUrl+"/birla-institute-of-technology-mesra",
    college_category: "Colleges",
  },
  {
    college_name: "Bharath Institute of Higher Education and Research, Chennai",
    college_url:
      config.apiUrl+"/bharath-institute-of-higher-education-and-research-chennai",
    college_category: "Colleges",
  },
  {
    college_name: "MMU Ambala - Maharishi Markandeshwar University, Ambala",
    college_url:
      config.apiUrl+"/maharishi-markandeshwar-university-ambala",
    college_category: "Colleges",
  },
  {
    college_name:
      "UAS Bangalore - University of Agricultural Sciences, Bangalore",
    college_url:
      config.apiUrl+"/university-of-agricultural-sciences-bangalore",
    college_category: "Colleges",
  },
  {
    college_name: "Central University of Punjab, Bathinda",
    college_url:
      config.apiUrl+"/central-university-of-punjab-bathinda",
    college_category: "Colleges",
  },
  {
    college_name: "Chettinad Academy of Research and Education, Kelambakkam",
    college_url:
      config.apiUrl+"/chettinad-academy-of-research-and-education-kelambakkam",
    college_category: "Colleges",
  },
  {
    college_name: "Periyar University - Periyar University, Salem",
    college_url:
      config.apiUrl+"/periyar-university-salem",
    college_category: "Colleges",
  },
  {
    college_name: "Ashoka University, Sonepat",
    college_url:
      config.apiUrl+"/ashoka-university-sonepat",
    college_category: "Colleges",
  },
  {
    college_name: "Sri Balaji Vidyapeeth, Pondicherry",
    college_url:
      config.apiUrl+"/sri-balaji-vidyapeeth-pondicherry",
    college_category: "Colleges",
  },
  {
    college_name: "Tezpur University - Tezpur University, Tezpur",
    college_url:
      config.apiUrl+"/tezpur-university-tezpur",
    college_category: "Colleges",
  },
  {
    college_name: "Jammu University - University of Jammu, Jammu",
    college_url:
      config.apiUrl+"/university-of-jammu-jammu",
    college_category: "Colleges",
  },
  {
    college_name: "Dr MGR Educational and Research Institute, Chennai",
    college_url:
      config.apiUrl+"/dr-mgr-educational-and-research-institute-chennai",
    college_category: "Colleges",
  },
  {
    college_name:
      "IISER TVM - Indian Institute of Science Education and Research Thiruvananthapuram",
    college_url:
      config.apiUrl+"/indian-institute-of-science-education-and-research-thiruvananthapuram",
    college_category: "Colleges",
  },
  {
    college_name:
      "MANIT Bhopal - Maulana Azad National Institute of Technology Bhopal",
    college_url:
      config.apiUrl+"/maulana-azad-national-institute-of-technology-bhopal",
    college_category: "Colleges",
  },
  {
    college_name:
      "NSUT Delhi - Netaji Subhas University of Technology, New Delhi",
    college_url:
      config.apiUrl+"/netaji-subhas-university-of-technology-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name:
      "IIIT Hyderabad - International Institute of Information Technology, Hyderabad",
    college_url:
      config.apiUrl+"/international-institute-of-information-technology-hyderabad",
    college_category: "Colleges",
  },
  {
    college_name: "Pondicherry University - Pondicherry University, Puducherry",
    college_url:
      config.apiUrl+"/pondicherry-university-puducherry",
    college_category: "Colleges",
  },
  {
    college_name: "Bharati Vidyapeeth, Pune",
    college_url:
      config.apiUrl+"/bharati-vidyapeeth-pune",
    college_category: "Colleges",
  },
  {
    college_name:
      "GGSIPU Delhi - Guru Gobind Singh Indraprastha University, Delhi",
    college_url:
      config.apiUrl+"/guru-gobind-singh-indraprastha-university-delhi",
    college_category: "Colleges",
  },
  {
    college_name:
      "Avinashilingam Institute for Home Science and Higher Education for Women, Coimbatore",
    college_url:
      config.apiUrl+"/avinashilingam-institute-for-home-science-and-higher-education-for-women-coimbatore",
    college_category: "Colleges",
  },
  {
    college_name: "NIT Patna - National Institute of Technology Patna",
    college_url:
      config.apiUrl+"/national-institute-of-technology-patna",
    college_category: "Colleges",
  },
  {
    college_name: "NIT Raipur - National Institute of Technology Raipur",
    college_url:
      config.apiUrl+"/national-institute-of-technology-raipur",
    college_category: "Colleges",
  },
  {
    college_name: "SVU Tirupati - Sri Venkateswara University, Tirupati",
    college_url:
      config.apiUrl+"/sri-venkateswara-university-tirupati",
    college_category: "Colleges",
  },
  {
    college_name: "NEHU Shillong - North Eastern Hill University, Shillong",
    college_url:
      config.apiUrl+"/north-eastern-hill-university-shillong",
    college_category: "Colleges",
  },
  {
    college_name: "Manonmaniam Sundaranar University, Tirunelveli",
    college_url:
      config.apiUrl+"/manonmaniam-sundaranar-university-tirunelveli",
    college_category: "Colleges",
  },
  {
    college_name: "Gauhati University - Gauhati University, Guwahati",
    college_url:
      config.apiUrl+"/gauhati-university-guwahati",
    college_category: "Colleges",
  },
  {
    college_name:
      "MSRIT Bangalore - Ramaiah Institute of Technology, Bangalore",
    college_url:
      config.apiUrl+"/ramaiah-institute-of-technology-bangalore",
    college_category: "Colleges",
  },
  {
    college_name: "Jain University - Jain Deemed to be University, Bangalore",
    college_url:
      config.apiUrl+"/jain-deemed-be-university-bangalore",
    college_category: "Colleges",
  },
  {
    college_name: "Mizoram University, Aizawl",
    college_url:
      config.apiUrl+"/mizoram-university-aizawl",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Palakkad - Indian Institute of Technology Palakkad",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-palakkad",
    college_category: "Colleges",
  },
  {
    college_name: "Amity University Gurgaon - Amity University, Gurugram",
    college_url:
      config.apiUrl+"/amity-university-gurugram",
    college_category: "Colleges",
  },
  {
    college_name:
      "Vel Tech Chennai - Vel Tech Rangarajan Dr Sagunthala R and D Institute of Science and Technology, Chennai",
    college_url:
      config.apiUrl+"/vel-tech-rangarajan-dr-sagunthala-r-and-d-institute-of-science-and-technology-chennai",
    college_category: "Colleges",
  },
  {
    college_name: "Utkal University, Bhubaneswar",
    college_url:
      config.apiUrl+"/utkal-university-bhubaneswar",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Madras - Indian Institute of Technology Madras",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-madras",
    college_category: "Colleges",
  },
  {
    college_name: "IISc Bangalore - Indian Institute of Science, Bangalore",
    college_url:
      config.apiUrl+"/indian-institute-of-science-bangalore",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Delhi - Indian Institute of Technology Delhi",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Bombay - Indian Institute of Technology Bombay",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-bombay",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Kanpur - Indian Institute of Technology Kanpur",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-kanpur",
    college_category: "Colleges",
  },
  {
    college_name:
      "AIIMS Delhi - All India Institute of Medical Sciences New Delhi",
    college_url:
      config.apiUrl+"/all-india-institute-of-medical-sciences-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Kharagpur - Indian Institute of Technology Kharagpur",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-kharagpur",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Roorkee - Indian Institute of Technology Roorkee",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-roorkee",
    college_category: "Colleges",
  },
  {
    college_name:
      "IIT Guwahati (IITG) - Indian Institute of Technology Guwahati",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-guwahati",
    college_category: "Colleges",
  },
  {
    college_name: "JNU Delhi - Jawaharlal Nehru University, New Delhi",
    college_url:
      config.apiUrl+"/jawaharlal-nehru-university-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "BHU Varanasi - Banaras Hindu University, Varanasi",
    college_url:
      config.apiUrl+"/banaras-hindu-university-varanasi",
    college_category: "Colleges",
  },
  {
    college_name: "JMI New Delhi - Jamia Millia Islamia, New Delhi",
    college_url:
      config.apiUrl+"/jamia-millia-islamia-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "Jadavpur University, Kolkata",
    college_url:
      config.apiUrl+"/jadavpur-university-kolkata",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Hyderabad - Indian Institute of Technology Hyderabad",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-hyderabad",
    college_category: "Colleges",
  },
  {
    college_name: "Amrita Vishwa Vidyapeetham, Coimbatore",
    college_url:
      config.apiUrl+"/amrita-vishwa-vidyapeetham-coimbatore",
    college_category: "Colleges",
  },
  {
    college_name:
      "Manipal Academy of Higher Education (MAHE) - Manipal Academy of Higher Education, Manipal",
    college_url:
      config.apiUrl+"/manipal-academy-of-higher-education-manipal",
    college_category: "Colleges",
  },
  {
    college_name: "VIT Vellore - Vellore Institute of Technology, Vellore",
    college_url:
      config.apiUrl+"/vellore-institute-of-technology-vellore",
    college_category: "Colleges",
  },
  {
    college_name: "AMU Aligarh - Aligarh Muslim University, Aligarh",
    college_url:
      config.apiUrl+"/aligarh-muslim-university-aligarh",
    college_category: "Colleges",
  },
  {
    college_name:
      "University of Hyderabad - University of Hyderabad, Hyderabad",
    college_url:
      config.apiUrl+"/university-of-hyderabad-hyderabad",
    college_category: "Colleges",
  },
  {
    college_name:
      "NIT Trichy - National Institute of Technology Tiruchirappalli",
    college_url:
      config.apiUrl+"/national-institute-of-technology-tiruchirappalli",
    college_category: "Colleges",
  },
  {
    college_name: "DU Delhi - University of Delhi, Delhi",
    college_url:
      config.apiUrl+"/university-of-delhi-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "Calcutta University - University of Calcutta, Kolkata",
    college_url:
      config.apiUrl+"/university-of-calcutta-kolkata",
    college_category: "Colleges",
  },
  {
    college_name:
      "IIT Gandhinagar (IIT GN) - Indian Institute of Technology Gandhinagar",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-gandhinagar",
    college_category: "Colleges",
  },
  {
    college_name:
      "BITS Pilani - Birla Institute of Technology and Science, Pilani",
    college_url:
      config.apiUrl+"/birla-institute-of-technology-and-science-pilani",
    college_category: "Colleges",
  },
  {
    college_name: "Siksha 'O' Anusandhan, Bhubaneswar",
    college_url:
      config.apiUrl+"/siksha-o-anusandhan-bhubaneswar",
    college_category: "Colleges",
  },
  {
    college_name:
      "SIMATS Chennai - Saveetha Institute of Medical and Technical Sciences, Chennai",
    college_url:
      config.apiUrl+"/saveetha-institute-of-medical-and-technical-sciences-chennai",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Indore - Indian Institute of Technology Indore",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-indore",
    college_category: "Colleges",
  },
  {
    college_name:
      "KIIT University - Kalinga Institute of Industrial Technology, Bhubaneswar",
    college_url:
      config.apiUrl+"/kalinga-institute-of-industrial-technology-bhubaneswar",
    college_category: "Colleges",
  },
  {
    college_name: "Homi Bhabha National Institute, Mumbai",
    college_url:
      config.apiUrl+"/homi-bhabha-national-institute-mumbai",
    college_category: "Colleges",
  },
  {
    college_name:
      "IIT BHU - Indian Institute of Technology Banaras Hindu University Varanasi",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-banaras-hindu-university-varanasi",
    college_category: "Colleges",
  },
  {
    college_name:
      "SRM University Chennai - SRM Institute of Science and Technology, Chennai",
    college_url:
      config.apiUrl+"/srm-institute-of-science-and-technology-chennai",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Ropar (IIT-RPR) - Indian Institute of Technology Ropar",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-ropar",
    college_category: "Colleges",
  },
  {
    college_name:
      "IISER Pune - Indian Institute of Science Education and Research Pune",
    college_url:
      config.apiUrl+"/indian-institute-of-science-education-and-research-pune",
    college_category: "Colleges",
  },
  {
    college_name:
      "UNIPUNE (Pune University) - Savitribai Phule Pune University, Pune",
    college_url:
      config.apiUrl+"/savitribai-phule-pune-university-pune",
    college_category: "Colleges",
  },
  {
    college_name: "Bharathiar University - Bharathiar University, Coimbatore",
    college_url:
      config.apiUrl+"/bharathiar-university-coimbatore",
    college_category: "Colleges",
  },
  {
    college_name: "NIT Rourkela - National Institute of Technology Rourkela",
    college_url:
      config.apiUrl+"/national-institute-of-technology-rourkela",
    college_category: "Colleges",
  },
  {
    college_name:
      "NIT Surathkal (NITK) - National Institute of Technology Karnataka Surathkal",
    college_url:
      config.apiUrl+"/national-institute-of-technology-karnataka-surathkal",
    college_category: "Colleges",
  },
  {
    college_name:
      "JIPMER Puducherry - Jawaharlal Institute of Postgraduate Medical Education and Research Puducherry",
    college_url:
      config.apiUrl+"/jawaharlal-institute-of-postgraduate-medical-education-and-research-puducherry",
    college_category: "Colleges",
  },
  {
    college_name:
      "Thapar University - Thapar Institute of Engineering and Technology, Patiala",
    college_url:
      config.apiUrl+"/thapar-institute-of-engineering-and-technology-patiala",
    college_category: "Colleges",
  },
  {
    college_name: "ICT Mumbai - Institute of Chemical Technology, Mumbai",
    college_url:
      config.apiUrl+"/institute-of-chemical-technology-mumbai",
    college_category: "Colleges",
  },
  {
    college_name:
      "IIT (ISM) Dhanbad - Indian Institute of Technology Indian School of Mines Dhanbad",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-indian-school-of-mines-dhanbad",
    college_category: "Colleges",
  },
  {
    college_name:
      "IISER Kolkata - Indian Institute of Science Education and Research Kolkata",
    college_url:
      config.apiUrl+"/indian-institute-of-science-education-and-research-kolkata",
    college_category: "Colleges",
  },
  {
    college_name: "PUCHD - Panjab University, Chandigarh",
    college_url:
      config.apiUrl+"/panjab-university-chandigarh",
    college_category: "Colleges",
  },
  {
    college_name: "Chandigarh University - Chandigarh University, Chandigarh",
    college_url:
      config.apiUrl+"/chandigarh-university-chandigarh",
    college_category: "Colleges",
  },
  {
    college_name: "LPU Jalandhar - Lovely Professional University, Phagwara",
    college_url:
      config.apiUrl+"/lovely-professional-university-phagwara",
    college_category: "Colleges",
  },
  {
    college_name:
      "Kerala University - University of Kerala, Thiruvananthapuram",
    college_url:
      config.apiUrl+"/university-of-kerala-thiruvananthapuram",
    college_category: "Colleges",
  },
  {
    college_name:
      "Kalasalingam Academy of Research and Education, Virudhunagar",
    college_url:
      config.apiUrl+"/kalasalingam-academy-of-research-and-education-virudhunagar",
    college_category: "Colleges",
  },
  {
    college_name:
      "SASTRA University Thanjavur - Shanmugha Arts Science Technology Research and Academy, Thanjavur",
    college_url:
      config.apiUrl+"/shanmugha-arts-science-technology-research-and-academy-thanjavur",
    college_category: "Colleges",
  },
  {
    college_name:
      "KL University Guntur - Koneru Lakshmaiah Education Foundation, Guntur",
    college_url:
      config.apiUrl+"/koneru-lakshmaiah-education-foundation-guntur",
    college_category: "Colleges",
  },
  {
    college_name:
      "IISER Mohali - Indian Institute of Science Education and Research Mohali",
    college_url:
      config.apiUrl+"/indian-institute-of-science-education-and-research-mohali",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Madras - Indian Institute of Technology Madras",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-madras",
    college_category: "Colleges",
  },
  {
    college_name: "IISc Bangalore - Indian Institute of Science, Bangalore",
    college_url:
      config.apiUrl+"/indian-institute-of-science-bangalore",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Delhi - Indian Institute of Technology Delhi",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Bombay - Indian Institute of Technology Bombay",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-bombay",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Kanpur - Indian Institute of Technology Kanpur",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-kanpur",
    college_category: "Colleges",
  },
  {
    college_name:
      "AIIMS Delhi - All India Institute of Medical Sciences New Delhi",
    college_url:
      config.apiUrl+"/all-india-institute-of-medical-sciences-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Kharagpur - Indian Institute of Technology Kharagpur",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-kharagpur",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Roorkee - Indian Institute of Technology Roorkee",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-roorkee",
    college_category: "Colleges",
  },
  {
    college_name:
      "IIT Guwahati (IITG) - Indian Institute of Technology Guwahati",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-guwahati",
    college_category: "Colleges",
  },
  {
    college_name: "JNU Delhi - Jawaharlal Nehru University, New Delhi",
    college_url:
      config.apiUrl+"/jawaharlal-nehru-university-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "BHU Varanasi - Banaras Hindu University, Varanasi",
    college_url:
      config.apiUrl+"/banaras-hindu-university-varanasi",
    college_category: "Colleges",
  },
  {
    college_name: "JMI New Delhi - Jamia Millia Islamia, New Delhi",
    college_url:
      config.apiUrl+"/jamia-millia-islamia-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "Jadavpur University, Kolkata",
    college_url:
      config.apiUrl+"/jadavpur-university-kolkata",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Hyderabad - Indian Institute of Technology Hyderabad",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-hyderabad",
    college_category: "Colleges",
  },
  {
    college_name: "Amrita Vishwa Vidyapeetham, Coimbatore",
    college_url:
      config.apiUrl+"/amrita-vishwa-vidyapeetham-coimbatore",
    college_category: "Colleges",
  },
  {
    college_name:
      "Manipal Academy of Higher Education (MAHE) - Manipal Academy of Higher Education, Manipal",
    college_url:
      config.apiUrl+"/manipal-academy-of-higher-education-manipal",
    college_category: "Colleges",
  },
  {
    college_name: "VIT Vellore - Vellore Institute of Technology, Vellore",
    college_url:
      config.apiUrl+"/vellore-institute-of-technology-vellore",
    college_category: "Colleges",
  },
  {
    college_name: "AMU Aligarh - Aligarh Muslim University, Aligarh",
    college_url:
      config.apiUrl+"/aligarh-muslim-university-aligarh",
    college_category: "Colleges",
  },
  {
    college_name:
      "University of Hyderabad - University of Hyderabad, Hyderabad",
    college_url:
      config.apiUrl+"/university-of-hyderabad-hyderabad",
    college_category: "Colleges",
  },
  {
    college_name:
      "NIT Trichy - National Institute of Technology Tiruchirappalli",
    college_url:
      config.apiUrl+"/national-institute-of-technology-tiruchirappalli",
    college_category: "Colleges",
  },
  {
    college_name: "DU Delhi - University of Delhi, Delhi",
    college_url:
      config.apiUrl+"/university-of-delhi-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "Calcutta University - University of Calcutta, Kolkata",
    college_url:
      config.apiUrl+"/university-of-calcutta-kolkata",
    college_category: "Colleges",
  },
  {
    college_name:
      "IIT Gandhinagar (IIT GN) - Indian Institute of Technology Gandhinagar",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-gandhinagar",
    college_category: "Colleges",
  },
  {
    college_name:
      "BITS Pilani - Birla Institute of Technology and Science, Pilani",
    college_url:
      config.apiUrl+"/birla-institute-of-technology-and-science-pilani",
    college_category: "Colleges",
  },
  {
    college_name: "Siksha 'O' Anusandhan, Bhubaneswar",
    college_url:
      config.apiUrl+"/siksha-o-anusandhan-bhubaneswar",
    college_category: "Colleges",
  },
  {
    college_name:
      "SIMATS Chennai - Saveetha Institute of Medical and Technical Sciences, Chennai",
    college_url:
      config.apiUrl+"/saveetha-institute-of-medical-and-technical-sciences-chennai",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Indore - Indian Institute of Technology Indore",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-indore",
    college_category: "Colleges",
  },
  {
    college_name:
      "KIIT University - Kalinga Institute of Industrial Technology, Bhubaneswar",
    college_url:
      config.apiUrl+"/kalinga-institute-of-industrial-technology-bhubaneswar",
    college_category: "Colleges",
  },
  {
    college_name: "Homi Bhabha National Institute, Mumbai",
    college_url:
      config.apiUrl+"/homi-bhabha-national-institute-mumbai",
    college_category: "Colleges",
  },
  {
    college_name:
      "IIT BHU - Indian Institute of Technology Banaras Hindu University Varanasi",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-banaras-hindu-university-varanasi",
    college_category: "Colleges",
  },
  {
    college_name:
      "SRM University Chennai - SRM Institute of Science and Technology, Chennai",
    college_url:
      config.apiUrl+"/srm-institute-of-science-and-technology-chennai",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Ropar (IIT-RPR) - Indian Institute of Technology Ropar",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-ropar",
    college_category: "Colleges",
  },
  {
    college_name:
      "IISER Pune - Indian Institute of Science Education and Research Pune",
    college_url:
      config.apiUrl+"/indian-institute-of-science-education-and-research-pune",
    college_category: "Colleges",
  },
  {
    college_name:
      "UNIPUNE (Pune University) - Savitribai Phule Pune University, Pune",
    college_url:
      config.apiUrl+"/savitribai-phule-pune-university-pune",
    college_category: "Colleges",
  },
  {
    college_name: "Bharathiar University - Bharathiar University, Coimbatore",
    college_url:
      config.apiUrl+"/bharathiar-university-coimbatore",
    college_category: "Colleges",
  },
  {
    college_name: "NIT Rourkela - National Institute of Technology Rourkela",
    college_url:
      config.apiUrl+"/national-institute-of-technology-rourkela",
    college_category: "Colleges",
  },
  {
    college_name:
      "NIT Surathkal (NITK) - National Institute of Technology Karnataka Surathkal",
    college_url:
      config.apiUrl+"/national-institute-of-technology-karnataka-surathkal",
    college_category: "Colleges",
  },
  {
    college_name:
      "JIPMER Puducherry - Jawaharlal Institute of Postgraduate Medical Education and Research Puducherry",
    college_url:
      config.apiUrl+"/jawaharlal-institute-of-postgraduate-medical-education-and-research-puducherry",
    college_category: "Colleges",
  },
  {
    college_name:
      "Thapar University - Thapar Institute of Engineering and Technology, Patiala",
    college_url:
      config.apiUrl+"/thapar-institute-of-engineering-and-technology-patiala",
    college_category: "Colleges",
  },
  {
    college_name: "ICT Mumbai - Institute of Chemical Technology, Mumbai",
    college_url:
      config.apiUrl+"/institute-of-chemical-technology-mumbai",
    college_category: "Colleges",
  },
  {
    college_name:
      "IIT (ISM) Dhanbad - Indian Institute of Technology Indian School of Mines Dhanbad",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-indian-school-of-mines-dhanbad",
    college_category: "Colleges",
  },
  {
    college_name:
      "IISER Kolkata - Indian Institute of Science Education and Research Kolkata",
    college_url:
      config.apiUrl+"/indian-institute-of-science-education-and-research-kolkata",
    college_category: "Colleges",
  },
  {
    college_name: "PUCHD - Panjab University, Chandigarh",
    college_url:
      config.apiUrl+"/panjab-university-chandigarh",
    college_category: "Colleges",
  },
  {
    college_name: "Chandigarh University - Chandigarh University, Chandigarh",
    college_url:
      config.apiUrl+"/chandigarh-university-chandigarh",
    college_category: "Colleges",
  },
  {
    college_name: "LPU Jalandhar - Lovely Professional University, Phagwara",
    college_url:
      config.apiUrl+"/lovely-professional-university-phagwara",
    college_category: "Colleges",
  },
  {
    college_name:
      "Kerala University - University of Kerala, Thiruvananthapuram",
    college_url:
      config.apiUrl+"/university-of-kerala-thiruvananthapuram",
    college_category: "Colleges",
  },
  {
    college_name:
      "Kalasalingam Academy of Research and Education, Virudhunagar",
    college_url:
      config.apiUrl+"/kalasalingam-academy-of-research-and-education-virudhunagar",
    college_category: "Colleges",
  },
  {
    college_name:
      "SASTRA University Thanjavur - Shanmugha Arts Science Technology Research and Academy, Thanjavur",
    college_url:
      config.apiUrl+"/shanmugha-arts-science-technology-research-and-academy-thanjavur",
    college_category: "Colleges",
  },
  {
    college_name:
      "KL University Guntur - Koneru Lakshmaiah Education Foundation, Guntur",
    college_url:
      config.apiUrl+"/koneru-lakshmaiah-education-foundation-guntur",
    college_category: "Colleges",
  },
  {
    college_name:
      "IISER Mohali - Indian Institute of Science Education and Research Mohali",
    college_url:
      config.apiUrl+"/indian-institute-of-science-education-and-research-mohali",
    college_category: "Colleges",
  },
  {
    college_name: "MG University (MGU) - Mahatma Gandhi University, Kottayam",
    college_url:
      config.apiUrl+"/mahatma-gandhi-university-kottayam",
    college_category: "Colleges",
  },
  {
    college_name: "NIT Warangal - National Institute of Technology Warangal",
    college_url:
      config.apiUrl+"/national-institute-of-technology-warangal",
    college_category: "Colleges",
  },
  {
    college_name: "NIT Calicut - National Institute of Technology Calicut",
    college_url:
      config.apiUrl+"/national-institute-of-technology-calicut",
    college_category: "Colleges",
  },
  {
    college_name: "JSS Academy of Higher Education and Research, Mysuru",
    college_url:
      config.apiUrl+"/jss-academy-of-higher-education-and-research-mysuru",
    college_category: "Colleges",
  },
  {
    college_name: "Alagappa University - Alagappa University, Karaikudi",
    college_url:
      config.apiUrl+"/alagappa-university-karaikudi",
    college_category: "Colleges",
  },
  {
    college_name: "Amity University, Noida",
    college_url: config.apiUrl+"/amity-university-noida",
    college_category: "Colleges",
  },
  {
    college_name: "Kashmir University - University of Kashmir, Srinagar",
    college_url:
      config.apiUrl+"/university-of-kashmir-srinagar",
    college_category: "Colleges",
  },
  {
    college_name: "Symbiosis Pune - Symbiosis International University, Pune",
    college_url:
      config.apiUrl+"/symbiosis-international-university-pune",
    college_category: "Colleges",
  },
  {
    college_name:
      "IISER Bhopal - Indian Institute of Science Education and Research Bhopal",
    college_url:
      config.apiUrl+"/indian-institute-of-science-education-and-research-bhopal",
    college_category: "Colleges",
  },
  {
    college_name: "DTU Delhi - Delhi Technological University, Delhi",
    college_url:
      config.apiUrl+"/delhi-technological-university-delhi",
    college_category: "Colleges",
  },
  {
    college_name:
      "MNIT Jaipur - Malaviya National Institute of Technology Jaipur",
    college_url:
      config.apiUrl+"/malaviya-national-institute-of-technology-jaipur",
    college_category: "Colleges",
  },
  {
    college_name:
      "CUSAT Kochi - Cochin University of Science and Technology, Kochi",
    college_url:
      config.apiUrl+"/cochin-university-of-science-and-technology-kochi",
    college_category: "Colleges",
  },
  {
    college_name: "Osmania University - Osmania University, Hyderabad",
    college_url:
      config.apiUrl+"/osmania-university-hyderabad",
    college_category: "Colleges",
  },
  {
    college_name: "Madras University - University of Madras, Chennai",
    college_url:
      config.apiUrl+"/university-of-madras-chennai",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Jodhpur - Indian Institute of Technology Jodhpur",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-jodhpur",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Patna - Indian Institute of Technology Patna",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-patna",
    college_category: "Colleges",
  },
  {
    college_name:
      "IIEST Shibpur - Indian Institute of Engineering Science and Technology Shibpur",
    college_url:
      config.apiUrl+"/indian-institute-of-engineering-science-and-technology-shibpur",
    college_category: "Colleges",
  },
  {
    college_name:
      "BBAU Lucknow - Babasaheb Bhimrao Ambedkar University, Lucknow",
    college_url:
      config.apiUrl+"/babasaheb-bhimrao-ambedkar-university-lucknow",
    college_category: "Colleges",
  },
  {
    college_name:
      "Bharathidasan University - Bharathidasan University, Tiruchirappalli",
    college_url:
      config.apiUrl+"/bharathidasan-university-tiruchirappalli",
    college_category: "Colleges",
  },
  {
    college_name: "Mysore University - University of Mysore, Mysore",
    college_url:
      config.apiUrl+"/university-of-mysore-mysore",
    college_category: "Colleges",
  },
  {
    college_name:
      "NIT Jalandhar - Dr B R Ambedkar National Institute of Technology Jalandhar",
    college_url:
      config.apiUrl+"/dr-b-r-ambedkar-national-institute-of-technology-jalandhar",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Mandi - Indian Institute of Technology Mandi",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-mandi",
    college_category: "Colleges",
  },
  {
    college_name: "PAU Ludhiana - Punjab Agricultural University, Ludhiana",
    college_url:
      config.apiUrl+"/punjab-agricultural-university-ludhiana",
    college_category: "Colleges",
  },
  {
    college_name:
      "DMIHER - Datta Meghe Institute of Higher Education and Research, Wardha",
    college_url:
      config.apiUrl+"/datta-meghe-institute-of-higher-education-and-research-wardha",
    college_category: "Colleges",
  },
  {
    college_name: "Andhra University, Visakhapatnam",
    college_url:
      config.apiUrl+"/andhra-university-visakhapatnam",
    college_category: "Colleges",
  },
  {
    college_name: "KGMU Lucknow - King George's Medical University, Lucknow",
    college_url:
      config.apiUrl+"/king-georges-medical-university-lucknow",
    college_category: "Colleges",
  },
  {
    college_name: "Jamia Hamdard, New Delhi",
    college_url:
      config.apiUrl+"/jamia-hamdard-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "UPES Dehradun - UPES, Dehradun",
    college_url: config.apiUrl+"/upes-dehradun",
    college_category: "Colleges",
  },
  {
    college_name:
      "SSN College of Engineering - Sri Sivasubramaniya Nadar College of Engineering, Kalavakkam",
    college_url:
      config.apiUrl+"/sri-sivasubramaniya-nadar-college-of-engineering-kalavakkam",
    college_category: "Colleges",
  },
  {
    college_name: "DY Patil Vidyapeeth - Dr DY Patil Vidyapeeth, Pune",
    college_url:
      config.apiUrl+"/dr-dy-patil-vidyapeeth-pune",
    college_category: "Colleges",
  },
  {
    college_name:
      "VNIT Nagpur - Visvesvaraya National Institute of Technology Nagpur",
    college_url:
      config.apiUrl+"/visvesvaraya-national-institute-of-technology-nagpur",
    college_category: "Colleges",
  },
  {
    college_name: "NIT Silchar - National Institute of Technology Silchar",
    college_url:
      config.apiUrl+"/national-institute-of-technology-silchar",
    college_category: "Colleges",
  },
  {
    college_name:
      "Sathyabama University - Sathyabama Institute of Science and Technology, Chennai",
    college_url:
      config.apiUrl+"/sathyabama-institute-of-science-and-technology-chennai",
    college_category: "Colleges",
  },
  {
    college_name: "Gujarat University - Gujarat University, Ahmedabad",
    college_url:
      config.apiUrl+"/gujarat-university-ahmedabad",
    college_category: "Colleges",
  },
  {
    college_name:
      "AIIMS Rishikesh - All India Institute of Medical Sciences Rishikesh",
    college_url:
      config.apiUrl+"/all-india-institute-of-medical-sciences-rishikesh",
    college_category: "Colleges",
  },
  {
    college_name: "GNDU Amritsar - Guru Nanak Dev University, Amritsar",
    college_url:
      config.apiUrl+"/guru-nanak-dev-university-amritsar",
    college_category: "Colleges",
  },
  {
    college_name:
      "NMIMS Mumbai - Narsee Monjee Institute of Management Studies, Mumbai",
    college_url:
      config.apiUrl+"/narsee-monjee-institute-of-management-studies-mumbai",
    college_category: "Colleges",
  },
  {
    college_name: "Graphic Era University, Dehradun",
    college_url:
      config.apiUrl+"/graphic-era-university-dehradun",
    college_category: "Colleges",
  },
  {
    college_name: "NIT Durgapur - National Institute of Technology Durgapur",
    college_url:
      config.apiUrl+"/national-institute-of-technology-durgapur",
    college_category: "Colleges",
  },
  {
    college_name:
      "IIT Bhubaneswar - Indian Institute of Technology Bhubaneswar",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-bhubaneswar",
    college_category: "Colleges",
  },
  {
    college_name: "Visvesvaraya Technological University, Belagavi",
    college_url:
      config.apiUrl+"/visvesvaraya-technological-university-belagavi",
    college_category: "Colleges",
  },
  {
    college_name:
      "TNAU Coimbatore - Tamil Nadu Agricultural University, Coimbatore",
    college_url:
      config.apiUrl+"/tamil-nadu-agricultural-university-coimbatore",
    college_category: "Colleges",
  },
  {
    college_name:
      "Madurai Kamaraj University - Madurai Kamaraj University, Madurai",
    college_url:
      config.apiUrl+"/madurai-kamaraj-university-madurai",
    college_category: "Colleges",
  },
  {
    college_name: "Shiv Nadar University, Greater Noida",
    college_url:
      config.apiUrl+"/shiv-nadar-university-greater-noida",
    college_category: "Colleges",
  },
  {
    college_name: "Mumbai University - University of Mumbai, Mumbai",
    college_url:
      config.apiUrl+"/university-of-mumbai-mumbai",
    college_category: "Colleges",
  },
  {
    college_name: "Banasthali Vidyapith, Banasthali",
    college_url:
      config.apiUrl+"/banasthali-vidyapith-banasthali",
    college_category: "Colleges",
  },
  {
    college_name:
      "Sri Ramachandra Institute of Higher Education and Research, Chennai",
    college_url:
      config.apiUrl+"/sri-ramachandra-institute-of-higher-education-and-research-chennai",
    college_category: "Colleges",
  },
  {
    college_name: "IIM Calcutta - Indian Institute of Management Calcutta",
    college_url:
      config.apiUrl+"/indian-institute-of-management-calcutta",
    college_category: "Colleges",
  },
  {
    college_name: "Christ University - Christ University, Bangalore",
    college_url:
      config.apiUrl+"/christ-university-bangalore",
    college_category: "Colleges",
  },
  {
    college_name:
      "Govind Ballabh Pant University of Agriculture and Technology, Pantnagar (GBPUAT Pantnagar) - Govind Ballabh Pant University of Agriculture and Technology, Pantnagar",
    college_url:
      config.apiUrl+"/govind-ballabh-pant-university-of-agriculture-and-technology-pantnagar",
    college_category: "Colleges",
  },
  {
    college_name:
      "CCSHAU Hisar - Chaudhary Charan Singh Haryana Agricultural University, Hisar",
    college_url:
      config.apiUrl+"/chaudhary-charan-singh-haryana-agricultural-university-hisar",
    college_category: "Colleges",
  },
  {
    college_name: "Yenepoya University, Mangalore",
    college_url:
      config.apiUrl+"/yenepoya-university-mangalore",
    college_category: "Colleges",
  },
  {
    college_name: "MDU Rohtak - Maharshi Dayanand University, Rohtak",
    college_url:
      config.apiUrl+"/maharshi-dayanand-university-rohtak",
    college_category: "Colleges",
  },
  {
    college_name: "Burdwan University - University of Burdwan, Bardhaman",
    college_url:
      config.apiUrl+"/university-of-burdwan-bardhaman",
    college_category: "Colleges",
  },
  {
    college_name: "NITTE University, Mangalore",
    college_url:
      config.apiUrl+"/nitte-university-mangalore",
    college_category: "Colleges",
  },
  {
    college_name: "Central University of Tamil Nadu, Thiruvarur",
    college_url:
      config.apiUrl+"/central-university-of-tamil-nadu-thiruvarur",
    college_category: "Colleges",
  },
  {
    college_name: "NIT Srinagar - National Institute of Technology Srinagar",
    college_url:
      config.apiUrl+"/national-institute-of-technology-srinagar",
    college_category: "Colleges",
  },
  {
    college_name: "PSG Tech Coimbatore - PSG College of Technology, Coimbatore",
    college_url:
      config.apiUrl+"/psg-college-of-technology-coimbatore",
    college_category: "Colleges",
  },
  {
    college_name:
      "SKCET Coimbatore - Sri Krishna College of Engineering and Technology, Coimbatore",
    college_url:
      config.apiUrl+"/sri-krishna-college-of-engineering-and-technology-coimbatore",
    college_category: "Colleges",
  },
  {
    college_name:
      "MNNIT Allahabad - Motilal Nehru National Institute of Technology Allahabad Prayagraj",
    college_url:
      config.apiUrl+"/motilal-nehru-national-institute-of-technology-allahabad-prayagraj",
    college_category: "Colleges",
  },
  {
    college_name:
      "Shoolini University Solan - Shoolini University of Biotechnology and Management Sciences, Solan",
    college_url:
      config.apiUrl+"/shoolini-university-of-biotechnology-and-management-sciences-solan",
    college_category: "Colleges",
  },
  {
    college_name: "AIIMS Patna - All India Institute of Medical Sciences Patna",
    college_url:
      config.apiUrl+"/all-india-institute-of-medical-sciences-patna",
    college_category: "Colleges",
  },
  {
    college_name:
      "SVNIT Surat - Sardar Vallabhbhai National Institute of Technology Surat",
    college_url:
      config.apiUrl+"/sardar-vallabhbhai-national-institute-of-technology-surat",
    college_category: "Colleges",
  },
  {
    college_name: "Visva Bharati University - Visva Bharati Santiniketan",
    college_url:
      config.apiUrl+"/visva-bharati-santiniketan",
    college_category: "Colleges",
  },
  {
    college_name: "Sharda University - Sharda University, Greater Noida",
    college_url:
      config.apiUrl+"/sharda-university-greater-noida",
    college_category: "Colleges",
  },
  {
    college_name:
      "Vignan's Foundation for Science Technology and Research, Guntur",
    college_url:
      config.apiUrl+"/vignans-foundation-for-science-technology-and-research-guntur",
    college_category: "Colleges",
  },
  {
    college_name: "Calicut University - University of Calicut, Malappuram",
    college_url:
      config.apiUrl+"/university-of-calicut-malappuram",
    college_category: "Colleges",
  },
  {
    college_name:
      "Birla Institute of Technology, Mesra (BIT Mesra) - Birla Institute of Technology, Mesra",
    college_url:
      config.apiUrl+"/birla-institute-of-technology-mesra",
    college_category: "Colleges",
  },
  {
    college_name: "Bharath Institute of Higher Education and Research, Chennai",
    college_url:
      config.apiUrl+"/bharath-institute-of-higher-education-and-research-chennai",
    college_category: "Colleges",
  },
  {
    college_name: "MMU Ambala - Maharishi Markandeshwar University, Ambala",
    college_url:
      config.apiUrl+"/maharishi-markandeshwar-university-ambala",
    college_category: "Colleges",
  },
  {
    college_name:
      "UAS Bangalore - University of Agricultural Sciences, Bangalore",
    college_url:
      config.apiUrl+"/university-of-agricultural-sciences-bangalore",
    college_category: "Colleges",
  },
  {
    college_name: "Central University of Punjab, Bathinda",
    college_url:
      config.apiUrl+"/central-university-of-punjab-bathinda",
    college_category: "Colleges",
  },
  {
    college_name: "Chettinad Academy of Research and Education, Kelambakkam",
    college_url:
      config.apiUrl+"/chettinad-academy-of-research-and-education-kelambakkam",
    college_category: "Colleges",
  },
  {
    college_name: "Periyar University - Periyar University, Salem",
    college_url:
      config.apiUrl+"/periyar-university-salem",
    college_category: "Colleges",
  },
  {
    college_name: "Ashoka University, Sonepat",
    college_url:
      config.apiUrl+"/ashoka-university-sonepat",
    college_category: "Colleges",
  },
  {
    college_name: "Sri Balaji Vidyapeeth, Pondicherry",
    college_url:
      config.apiUrl+"/sri-balaji-vidyapeeth-pondicherry",
    college_category: "Colleges",
  },
  {
    college_name: "Tezpur University - Tezpur University, Tezpur",
    college_url:
      config.apiUrl+"/tezpur-university-tezpur",
    college_category: "Colleges",
  },
  {
    college_name: "Jammu University - University of Jammu, Jammu",
    college_url:
      config.apiUrl+"/university-of-jammu-jammu",
    college_category: "Colleges",
  },
  {
    college_name: "Dr MGR Educational and Research Institute, Chennai",
    college_url:
      config.apiUrl+"/dr-mgr-educational-and-research-institute-chennai",
    college_category: "Colleges",
  },
  {
    college_name:
      "IISER TVM - Indian Institute of Science Education and Research Thiruvananthapuram",
    college_url:
      config.apiUrl+"/indian-institute-of-science-education-and-research-thiruvananthapuram",
    college_category: "Colleges",
  },
  {
    college_name:
      "MANIT Bhopal - Maulana Azad National Institute of Technology Bhopal",
    college_url:
      config.apiUrl+"/maulana-azad-national-institute-of-technology-bhopal",
    college_category: "Colleges",
  },
  {
    college_name:
      "NSUT Delhi - Netaji Subhas University of Technology, New Delhi",
    college_url:
      config.apiUrl+"/netaji-subhas-university-of-technology-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name:
      "IIIT Hyderabad - International Institute of Information Technology, Hyderabad",
    college_url:
      config.apiUrl+"/international-institute-of-information-technology-hyderabad",
    college_category: "Colleges",
  },
  {
    college_name: "Pondicherry University - Pondicherry University, Puducherry",
    college_url:
      config.apiUrl+"/pondicherry-university-puducherry",
    college_category: "Colleges",
  },
  {
    college_name: "Bharati Vidyapeeth, Pune",
    college_url:
      config.apiUrl+"/bharati-vidyapeeth-pune",
    college_category: "Colleges",
  },
  {
    college_name:
      "GGSIPU Delhi - Guru Gobind Singh Indraprastha University, Delhi",
    college_url:
      config.apiUrl+"/guru-gobind-singh-indraprastha-university-delhi",
    college_category: "Colleges",
  },
  {
    college_name:
      "Avinashilingam Institute for Home Science and Higher Education for Women, Coimbatore",
    college_url:
      config.apiUrl+"/avinashilingam-institute-for-home-science-and-higher-education-for-women-coimbatore",
    college_category: "Colleges",
  },
  {
    college_name: "NIT Patna - National Institute of Technology Patna",
    college_url:
      config.apiUrl+"/national-institute-of-technology-patna",
    college_category: "Colleges",
  },
  {
    college_name: "NIT Raipur - National Institute of Technology Raipur",
    college_url:
      config.apiUrl+"/national-institute-of-technology-raipur",
    college_category: "Colleges",
  },
  {
    college_name: "SVU Tirupati - Sri Venkateswara University, Tirupati",
    college_url:
      config.apiUrl+"/sri-venkateswara-university-tirupati",
    college_category: "Colleges",
  },
  {
    college_name: "NEHU Shillong - North Eastern Hill University, Shillong",
    college_url:
      config.apiUrl+"/north-eastern-hill-university-shillong",
    college_category: "Colleges",
  },
  {
    college_name: "Manonmaniam Sundaranar University, Tirunelveli",
    college_url:
      config.apiUrl+"/manonmaniam-sundaranar-university-tirunelveli",
    college_category: "Colleges",
  },
  {
    college_name: "Gauhati University - Gauhati University, Guwahati",
    college_url:
      config.apiUrl+"/gauhati-university-guwahati",
    college_category: "Colleges",
  },
  {
    college_name:
      "MSRIT Bangalore - Ramaiah Institute of Technology, Bangalore",
    college_url:
      config.apiUrl+"/ramaiah-institute-of-technology-bangalore",
    college_category: "Colleges",
  },
  {
    college_name: "Jain University - Jain Deemed to be University, Bangalore",
    college_url:
      config.apiUrl+"/jain-deemed-be-university-bangalore",
    college_category: "Colleges",
  },
  {
    college_name: "Mizoram University, Aizawl",
    college_url:
      config.apiUrl+"/mizoram-university-aizawl",
    college_category: "Colleges",
  },
  {
    college_name: "IIT Palakkad - Indian Institute of Technology Palakkad",
    college_url:
      config.apiUrl+"/indian-institute-of-technology-palakkad",
    college_category: "Colleges",
  },
  {
    college_name: "Amity University Gurgaon - Amity University, Gurugram",
    college_url:
      config.apiUrl+"/amity-university-gurugram",
    college_category: "Colleges",
  },
  {
    college_name:
      "Vel Tech Chennai - Vel Tech Rangarajan Dr Sagunthala R and D Institute of Science and Technology, Chennai",
    college_url:
      config.apiUrl+"/vel-tech-rangarajan-dr-sagunthala-r-and-d-institute-of-science-and-technology-chennai",
    college_category: "Colleges",
  },
  {
    college_name: "Utkal University, Bhubaneswar",
    college_url:
      config.apiUrl+"/utkal-university-bhubaneswar",
    college_category: "Colleges",
  },
  {
    college_name: "Anand Agricultural University, Anand",
    college_url:
      config.apiUrl+"/anand-agricultural-university-anand",
    college_category: "Colleges",
  },
  {
    college_name: "KVV Karad - Krishna Vishwa Vidyapeeth, Karad",
    college_url:
      config.apiUrl+"/krishna-vishwa-vidyapeeth-karad",
    college_category: "Colleges",
  },
  {
    college_name: "Dr DY Patil University, Navi Mumbai",
    college_url:
      config.apiUrl+"/dr-dy-patil-university-navi-mumbai",
    college_category: "Colleges",
  },
  {
    college_name:
      "TANUVAS Chennai - Tamil Nadu Veterinary and Animal Sciences University, Chennai",
    college_url:
      config.apiUrl+"/tamil-nadu-veterinary-and-animal-sciences-university-chennai",
    college_category: "Colleges",
  },
  {
    college_name: "Assam University, Silchar",
    college_url:
      config.apiUrl+"/assam-university-silchar",
    college_category: "Colleges",
  },
  {
    college_name:
      "JNTUK Kakinada - Jawaharlal Nehru Technological University, Kakinada",
    college_url:
      config.apiUrl+"/jawaharlal-nehru-technological-university-kakinada",
    college_category: "Colleges",
  },
  {
    college_name:
      "IIIT Delhi - Indraprastha Institute of Information Technology, Delhi",
    college_url:
      config.apiUrl+"/indraprastha-institute-of-information-technology-delhi",
    college_category: "Colleges",
  },
  {
    college_name:
      "Karunya University - Karunya Institute of Technology and Sciences, Coimbatore",
    college_url:
      config.apiUrl+"/karunya-institute-of-technology-and-sciences-coimbatore",
    college_category: "Colleges",
  },
  {
    college_name: "Kalinga University, Raipur",
    college_url:
      config.apiUrl+"/kalinga-university-raipur",
    college_category: "Colleges",
  },
  {
    college_name: "COEP Pune - COEP Technological University, Pune",
    college_url:
      config.apiUrl+"/coep-technological-university-pune",
    college_category: "Colleges",
  },
  {
    college_name: "CV Raman Global University, Bhubaneswar",
    college_url:
      config.apiUrl+"/cv-raman-global-university-bhubaneswar",
    college_category: "Colleges",
  },
  {
    college_name:
      "KPRIET Coimbatore - KPR Institute of Engineering and Technology, Coimbatore",
    college_url:
      config.apiUrl+"/kpr-institute-of-engineering-and-technology-coimbatore",
    college_category: "Colleges",
  },
  {
    college_name: "Meenakshi Academy of Higher Education and Research, Chennai",
    college_url:
      config.apiUrl+"/meenakshi-academy-of-higher-education-and-research-chennai",
    college_category: "Colleges",
  },
  {
    college_name: "LNCT University, Bhopal",
    college_url: config.apiUrl+"/lnct-university-bhopal",
    college_category: "Colleges",
  },
  {
    college_name:
      "Vels University Chennai - Vel's Institute of Science Technology and Advanced Studies, Chennai",
    college_url:
      config.apiUrl+"/vels-institute-of-science-technology-and-advanced-studies-chennai",
    college_category: "Colleges",
  },
  {
    college_name:
      "HITS Chennai - Hindustan Institute of Technology and Science, Chennai",
    college_url:
      config.apiUrl+"/hindustan-institute-of-technology-and-science-chennai",
    college_category: "Colleges",
  },
  {
    college_name:
      "OUAT Bhubaneswar - Odisha University of Agriculture and Technology, Bhubaneswar",
    college_url:
      config.apiUrl+"/odisha-university-of-agriculture-and-technology-bhubaneswar",
    college_category: "Colleges",
  },
  {
    college_name:
      "BAMU Aurangabad - Dr Babasaheb Ambedkar Marathwada University, Aurangabad",
    college_url:
      config.apiUrl+"/dr-babasaheb-ambedkar-marathwada-university-aurangabad",
    college_category: "Colleges",
  },
  {
    college_name:
      "CSK HPKV - Chaudhary Sarwan Kumar Himachal Pradesh Krishi Vishvavidyalaya, Palampur",
    college_url:
      config.apiUrl+"/chaudhary-sarwan-kumar-himachal-pradesh-krishi-vishvavidyalaya-palampur",
    college_category: "Colleges",
  },
  {
    college_name:
      "ANGRAU Guntur - Acharya NG Ranga Agricultural University, Guntur",
    college_url:
      config.apiUrl+"/acharya-ng-ranga-agricultural-university-guntur",
    college_category: "Colleges",
  },
  {
    college_name:
      "IGIMS Patna - Indira Gandhi Institute of Medical Sciences, Patna",
    college_url:
      config.apiUrl+"/indira-gandhi-institute-of-medical-sciences-patna",
    college_category: "Colleges",
  },
  {
    college_name: "Mepco Schlenk Engineering College, Sivakasi",
    college_url:
      config.apiUrl+"/mepco-schlenk-engineering-college-sivakasi",
    college_category: "Colleges",
  },
  {
    college_name:
      "SMS Medical College Jaipur - Sawai Man Singh Medical College, Jaipur",
    college_url:
      config.apiUrl+"/sawai-man-singh-medical-college-jaipur",
    college_category: "Colleges",
  },
  {
    college_name: "Galgotias University - Galgotias University, Greater Noida",
    college_url:
      config.apiUrl+"/galgotias-university-greater-noida",
    college_category: "Colleges",
  },
  {
    college_name: "GITAM University - GITAM University, Visakhapatnam",
    college_url:
      config.apiUrl+"/gitam-university-visakhapatnam",
    college_category: "Colleges",
  },
  {
    college_name:
      "Chitkara University Chandigarh - Chitkara University, Patiala",
    college_url:
      config.apiUrl+"/chitkara-university-patiala",
    college_category: "Colleges",
  },
  {
    college_name: "Presidency University, Kolkata",
    college_url:
      config.apiUrl+"/presidency-university-kolkata",
    college_category: "Colleges",
  },
  {
    college_name: "TISS Mumbai - Tata Institute of Social Sciences, Mumbai",
    college_url:
      config.apiUrl+"/tata-institute-of-social-sciences-mumbai",
    college_category: "Colleges",
  },
  {
    college_name:
      "KLE Deemed University - KLE Academy of Higher Education and Research, Belgaum",
    college_url:
      config.apiUrl+"/kle-academy-of-higher-education-and-research-belgaum",
    college_category: "Colleges",
  },
  {
    college_name: "Kalyani University - University of Kalyani, Kalyani",
    college_url:
      config.apiUrl+"/university-of-kalyani-kalyani",
    college_category: "Colleges",
  },
  {
    college_name:
      "VSSUT Sambalpur - Veer Surendra Sai University of Technology, Sambalpur",
    college_url:
      config.apiUrl+"/veer-surendra-sai-university-of-technology-sambalpur",
    college_category: "Colleges",
  },
  {
    college_name: "Dibrugarh University, Dibrugarh",
    college_url:
      config.apiUrl+"/dibrugarh-university-dibrugarh",
    college_category: "Colleges",
  },
  {
    college_name: "KUK Kurukshetra - Kurukshetra University, Kurukshetra",
    college_url:
      config.apiUrl+"/kurukshetra-university-kurukshetra",
    college_category: "Colleges",
  },
  {
    college_name: "Kerala Agricultural University, Thrissur",
    college_url:
      config.apiUrl+"/kerala-agricultural-university-thrissur",
    college_category: "Colleges",
  },
  {
    college_name:
      "YSP University - Dr Yashwant Singh Parmar University of Horticulture and Forestry, Nauni",
    college_url:
      config.apiUrl+"/dr-yashwant-singh-parmar-university-of-horticulture-and-forestry-nauni",
    college_category: "Colleges",
  },
  {
    college_name: "DY Patil Education Society, Kolhapur",
    college_url:
      config.apiUrl+"/dy-patil-education-society-kolhapur",
    college_category: "Colleges",
  },
  {
    college_name:
      "MRIIRS Faridabad - Manav Rachna International Institute of Research and Studies, Faridabad",
    college_url:
      config.apiUrl+"/manav-rachna-international-institute-of-research-and-studies-faridabad",
    college_category: "Colleges",
  },
  {
    college_name: "Kuvempu University, Shankaraghatta",
    college_url:
      config.apiUrl+"/kuvempu-university-shankaraghatta",
    college_category: "Colleges",
  },
  {
    college_name: "Mangalore University - Mangalore University, Mangalore",
    college_url:
      config.apiUrl+"/mangalore-university-mangalore",
    college_category: "Colleges",
  },
  {
    college_name: "BLDE University, Bijapur",
    college_url:
      config.apiUrl+"/blde-university-bijapur",
    college_category: "Colleges",
  },
  {
    college_name:
      "CEC Landran - Chandigarh Engineering College, Landran Campus, Mohali",
    college_url:
      config.apiUrl+"/chandigarh-engineering-college-landran-campus-mohali",
    college_category: "Colleges",
  },
  {
    college_name: "SCB Medical College, Cuttack",
    college_url:
      config.apiUrl+"/scb-medical-college-cuttack",
    college_category: "Colleges",
  },
  {
    college_name:
      "PSGIMSR Coimbatore - PSG Institute of Medical Sciences and Research, Peelamedu",
    college_url:
      config.apiUrl+"/psg-institute-of-medical-sciences-and-research-peelamedu",
    college_category: "Colleges",
  },
  {
    college_name: "Rajalakshmi Engineering College, Chennai",
    college_url:
      config.apiUrl+"/rajalakshmi-engineering-college-chennai",
    college_category: "Colleges",
  },
  {
    college_name: "SR University, Warangal",
    college_url: config.apiUrl+"/sr-university-warangal",
    college_category: "Colleges",
  },
  {
    college_name:
      "Annamalai University - Annamalai University, Annamalai Nagar",
    college_url:
      config.apiUrl+"/annamalai-university-annamalai-nagar",
    college_category: "Colleges",
  },
  {
    college_name: "Manipur University, Imphal",
    college_url:
      config.apiUrl+"/manipur-university-imphal",
    college_category: "Colleges",
  },
  {
    college_name: "Manipal University, Jaipur",
    college_url:
      config.apiUrl+"/manipal-university-jaipur",
    college_category: "Colleges",
  },
  {
    college_name: "Nirma University - Nirma University, Ahmedabad",
    college_url:
      config.apiUrl+"/nirma-university-ahmedabad",
    college_category: "Colleges",
  },
  {
    college_name:
      "APGSU Shimla - Alakh Prakash Goyal Shimla University, Shimla",
    college_url:
      config.apiUrl+"/alakh-prakash-goyal-shimla-university-shimla",
    college_category: "Colleges",
  },
  {
    college_name:
      "IIPM Bangalore - Indian Institute of Plantation Management, Bangalore",
    college_url:
      config.apiUrl+"/indian-institute-of-plantation-management-bangalore",
    college_category: "Colleges",
  },
  {
    college_name: "GBS Kolkata - Globsyn Business School, Kolkata",
    college_url:
      config.apiUrl+"/globsyn-business-school-kolkata",
    college_category: "Colleges",
  },
  {
    college_name:
      "BULMIM New Delhi - Bharatiya Vidya Bhavan's Usha and Lakshmi Mittal Institute of Management, New Delhi",
    college_url:
      config.apiUrl+"/bharatiya-vidya-bhavans-usha-and-lakshmi-mittal-institute-of-management-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name:
      "ISBF Delhi - Indian School of Business and Finance, New Delhi",
    college_url:
      config.apiUrl+"/indian-school-of-business-and-finance-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name:
      "NISM Raigad - National Institute of Securities Markets, Raigad",
    college_url:
      config.apiUrl+"/national-institute-of-securities-markets-raigad",
    college_category: "Colleges",
  },
  {
    college_name:
      "Sri Sharada Institute of Indian Management Research, New Delhi",
    college_url:
      config.apiUrl+"/sri-sharada-institute-of-indian-management-research-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "Indian Institute of Materials Management, Bangalore",
    college_url:
      config.apiUrl+"/indian-institute-of-materials-management-bangalore",
    college_category: "Colleges",
  },
  {
    college_name: "HDF School of Management, Cuttack",
    college_url:
      config.apiUrl+"/hdf-school-of-management-cuttack",
    college_category: "Colleges",
  },
  {
    college_name: "Adikavi Nannaya University, Rajahmundry",
    college_url:
      config.apiUrl+"/adikavi-nannaya-university-rajahmundry",
    college_category: "Colleges",
  },
  {
    college_name:
      "WBUHS Kolkata - The West Bengal University of Health Sciences, Kolkata",
    college_url:
      config.apiUrl+"/the-west-bengal-university-of-health-sciences-kolkata",
    college_category: "Colleges",
  },
  {
    college_name: "Adesh University, Bathinda",
    college_url:
      config.apiUrl+"/adesh-university-bathinda",
    college_category: "Colleges",
  },
  {
    college_name:
      "ESI Post Graduate Institute of Medical Sciences and Research, New Delhi",
    college_url:
      config.apiUrl+"/esi-post-graduate-institute-of-medical-sciences-and-research-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "Institute of Mental Health and Hospital, Agra",
    college_url:
      config.apiUrl+"/institute-of-mental-health-and-hospital-agra",
    college_category: "Colleges",
  },
  {
    college_name: "Gandhigram Rural Institute, Dindigul",
    college_url:
      config.apiUrl+"/the-gandhigram-rural-institute-dindigul",
    college_category: "Colleges",
  },
  {
    college_name: "Vasantrao Naik Marathwada Krishi Vidyapeeth, Parbhani",
    college_url:
      config.apiUrl+"/vasantrao-naik-marathwada-krishi-vidyapeeth-parbhani",
    college_category: "Colleges",
  },
  {
    college_name:
      "Shri Lal Bahadur Shastri National Sanskrit University, New Delhi",
    college_url:
      config.apiUrl+"/shri-lal-bahadur-shastri-national-sanskrit-university-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name:
      "TIFR Mumbai - Tata Institute of Fundamental Research, Mumbai",
    college_url:
      config.apiUrl+"/tata-institute-of-fundamental-research-mumbai",
    college_category: "Colleges",
  },
  {
    college_name:
      "IGIDR Mumbai - Indira Gandhi Institute of Development Research, Mumbai",
    college_url:
      config.apiUrl+"/indira-gandhi-institute-of-development-research-mumbai",
    college_category: "Colleges",
  },
  {
    college_name: "ICFAI University, Himachal Pradesh",
    college_url:
      config.apiUrl+"/icfai-university-himachal-pradesh",
    college_category: "Colleges",
  },
  {
    college_name: "ICFAI University, Sikkim",
    college_url:
      config.apiUrl+"/icfai-university-sikkim",
    college_category: "Colleges",
  },
  {
    college_name: "NIA Pune - National Insurance Academy, Pune",
    college_url:
      config.apiUrl+"/national-insurance-academy-pune",
    college_category: "Colleges",
  },
  {
    college_name:
      "Assam Rajiv Gandhi University of Cooperative Management, Sivasagar",
    college_url:
      config.apiUrl+"/assam-rajiv-gandhi-university-of-cooperative-management-sivasagar",
    college_category: "Colleges",
  },
  {
    college_name: "IBA Greater Noida - Indus Business Academy, Greater Noida",
    college_url:
      config.apiUrl+"/indus-business-academy-greater-noida",
    college_category: "Colleges",
  },
  {
    college_name: "ICFAI University, Tura, Meghalaya",
    college_url:
      config.apiUrl+"/icfai-university-tura-meghalaya",
    college_category: "Colleges",
  },
  {
    college_name: "Bridge School of Management, Gurgaon",
    college_url:
      config.apiUrl+"/bridge-school-of-management-gurgaon",
    college_category: "Colleges",
  },
  {
    college_name:
      "IHM Goa - Institute of Hotel Management Catering Technology and Applied Nutrition, Goa",
    college_url:
      config.apiUrl+"/institute-of-hotel-management-catering-technology-and-applied-nutrition-goa",
    college_category: "Colleges",
  },
  {
    college_name: "Pravara Institute of Medical Sciences, Loni",
    college_url:
      config.apiUrl+"/pravara-institute-of-medical-sciences-loni",
    college_category: "Colleges",
  },
  {
    college_name:
      "SGPGIMS Lucknow - Sanjay Gandhi Postgraduate Institute of Medical Sciences, Lucknow",
    college_url:
      config.apiUrl+"/sanjay-gandhi-postgraduate-institute-of-medical-sciences-lucknow",
    college_category: "Colleges",
  },
  {
    college_name: "JD Institute of Fashion Technology, Delhi",
    college_url:
      config.apiUrl+"/jd-institute-of-fashion-technology-delhi",
    college_category: "Colleges",
  },
  {
    college_name:
      "RGNUL Patiala - Rajiv Gandhi National University of Law, Patiala",
    college_url:
      config.apiUrl+"/rajiv-gandhi-national-university-of-law-patiala",
    college_category: "Colleges",
  },
  {
    college_name:
      "TNDALU Chennai - The Tamilnadu Dr Ambedkar Law University, Chennai",
    college_url:
      config.apiUrl+"/the-tamilnadu-dr-ambedkar-law-university-chennai",
    college_category: "Colleges",
  },
  {
    college_name:
      "Makhanlal Chaturvedi National University of Journalism and Communication, Bhopal",
    college_url:
      config.apiUrl+"/makhanlal-chaturvedi-national-university-of-journalism-and-communication-bhopal",
    college_category: "Colleges",
  },
  {
    college_name: "MICA, Ahmedabad",
    college_url: config.apiUrl+"/mica-ahmedabad",
    college_category: "Colleges",
  },
  {
    college_name: "RDVV Jabalpur - Rani Durgavati Vishwavidyalaya, Jabalpur",
    college_url:
      config.apiUrl+"/rani-durgavati-vishwavidyalaya-jabalpur",
    college_category: "Colleges",
  },
  {
    college_name:
      "MGCGV Chitrakoot - Mahatma Gandhi Chitrakoot Gramodaya Vishwavidyalaya, Chitrakoot",
    college_url:
      config.apiUrl+"/mahatma-gandhi-chitrakoot-gramodaya-vishwavidyalaya-chitrakoot",
    college_category: "Colleges",
  },
  {
    college_name: "Amity University, Gwalior",
    college_url:
      config.apiUrl+"/amity-university-gwalior",
    college_category: "Colleges",
  },
  {
    college_name: "Shobhit University, Gangoh",
    college_url:
      config.apiUrl+"/shobhit-university-gangoh",
    college_category: "Colleges",
  },
  {
    college_name: "Techno Global University, Sironj",
    college_url:
      config.apiUrl+"/techno-global-university-sironj",
    college_category: "Colleges",
  },
  {
    college_name: "Rabindranath Tagore University, Raisen",
    college_url:
      config.apiUrl+"/rabindranath-tagore-university-raisen",
    college_category: "Colleges",
  },
  {
    college_name: "Central University of Haryana, Mahendergarh",
    college_url:
      config.apiUrl+"/central-university-of-haryana-mahendergarh",
    college_category: "Colleges",
  },
  {
    college_name:
      "Central University Jammu - Central University of Jammu, Jammu",
    college_url:
      config.apiUrl+"/central-university-of-jammu-jammu",
    college_category: "Colleges",
  },
  {
    college_name:
      "IITRAM Ahmedabad - Institute of Infrastructure Technology Research and Management, Ahmedabad",
    college_url:
      config.apiUrl+"/institute-of-infrastructure-technology-research-and-management-ahmedabad",
    college_category: "Colleges",
  },
  {
    college_name: "GKV Haridwar - Gurukula Kangri Vishwavidyalaya, Haridwar",
    college_url:
      config.apiUrl+"/gurukula-kangri-vishwavidyalaya-haridwar",
    college_category: "Colleges",
  },
  {
    college_name:
      "IARI New Delhi - Indian Agricultural Research Institute, New Delhi",
    college_url:
      config.apiUrl+"/indian-agricultural-research-institute-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "FRI Dehradun - Forest Research Institute, Dehradun",
    college_url:
      config.apiUrl+"/forest-research-institute-dehradun",
    college_category: "Colleges",
  },
  {
    college_name: "KR Mangalam University, Gurgaon",
    college_url:
      config.apiUrl+"/kr-mangalam-university-gurgaon",
    college_category: "Colleges",
  },
  {
    college_name: "Doon University, Dehradun",
    college_url:
      config.apiUrl+"/doon-university-dehradun",
    college_category: "Colleges",
  },
  {
    college_name: "Indus School of Business Management, Gurgaon",
    college_url:
      config.apiUrl+"/indus-school-of-business-management-gurgaon",
    college_category: "Colleges",
  },
  {
    college_name: "International Management Centre, New Delhi",
    college_url:
      config.apiUrl+"/international-management-centre-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "Auro University, Surat",
    college_url: config.apiUrl+"/auro-university-surat",
    college_category: "Colleges",
  },
  {
    college_name:
      "SKIMS Srinagar - Sher-I-Kashmir Institute of Medical Sciences, Srinagar",
    college_url:
      config.apiUrl+"/sher-i-kashmir-institute-of-medical-sciences-srinagar",
    college_category: "Colleges",
  },
  {
    college_name: "Maharaj Vinayak Global University, Jaipur",
    college_url:
      config.apiUrl+"/maharaj-vinayak-global-university-jaipur",
    college_category: "Colleges",
  },
  {
    college_name: "Jharkhand Rai University, Ranchi",
    college_url:
      config.apiUrl+"/jharkhand-rai-university-ranchi",
    college_category: "Colleges",
  },
  {
    college_name: "Mangalam Institute of Management, Ranchi",
    college_url:
      config.apiUrl+"/mangalam-institute-of-management-ranchi",
    college_category: "Colleges",
  },
  {
    college_name: "Sree Narayana Law College, Poothotta",
    college_url:
      config.apiUrl+"/sree-narayana-law-college-poothotta",
    college_category: "Colleges",
  },
  {
    college_name: "Government Law College, Kozhikode",
    college_url:
      config.apiUrl+"/government-law-college-kozhikode",
    college_category: "Colleges",
  },
  {
    college_name: "Swami Dayal Bhatnagar Law College, Sikandrabad",
    college_url:
      config.apiUrl+"/swami-dayal-bhatnagar-law-college-sikandrabad",
    college_category: "Colleges",
  },
  {
    college_name: "Tagore Teachers Training College, Jaipur",
    college_url:
      config.apiUrl+"/tagore-teachers-training-college-jaipur",
    college_category: "Colleges",
  },
  {
    college_name:
      "State Institute of Hotel Management Catering Technology and Applied Nutrition, Jodhpur",
    college_url:
      config.apiUrl+"/state-institute-of-hotel-management-catering-technology-and-applied-nutrition-jodhpur",
    college_category: "Colleges",
  },
  {
    college_name: "Bridge School of Management, Noida",
    college_url:
      config.apiUrl+"/bridge-school-of-management-noida",
    college_category: "Colleges",
  },
  {
    college_name: "IHM Faridabad - Institute of Hotel Management, Faridabad",
    college_url:
      config.apiUrl+"/institute-of-hotel-management-faridabad",
    college_category: "Colleges",
  },
  {
    college_name:
      "Institute of Hotel Management and Catering Technology, Silvassa",
    college_url:
      config.apiUrl+"/institute-of-hotel-management-and-catering-technology-silvassa",
    college_category: "Colleges",
  },
  {
    college_name:
      "Ranjita Institute of Hotel Management and Catering Technology, Bhubaneswar",
    college_url:
      config.apiUrl+"/ranjita-institute-of-hotel-management-and-catering-technology-bhubaneswar",
    college_category: "Colleges",
  },
  {
    college_name: "Goa University - Goa University,Taleigao Plateau, Goa",
    college_url:
      config.apiUrl+"/goa-universitytaleigao-plateau-goa",
    college_category: "Colleges",
  },
  {
    college_name: "PRSU - Pt Ravishankar Shukla University, Raipur",
    college_url:
      config.apiUrl+"/pt-ravishankar-shukla-university-raipur",
    college_category: "Colleges",
  },
  {
    college_name: "CDLU Sirsa - Chaudhary Devi Lal University, Sirsa",
    college_url:
      config.apiUrl+"/chaudhary-devi-lal-university-sirsa",
    college_category: "Colleges",
  },
  {
    college_name: "Dr BR Ambedkar University, Srikakulam",
    college_url:
      config.apiUrl+"/dr-br-ambedkar-university-srikakulam",
    college_category: "Colleges",
  },
  {
    college_name:
      "NIMS Hyderabad - Nizams Institute of Medical Sciences, Hyderabad",
    college_url:
      config.apiUrl+"/nizams-institute-of-medical-sciences-hyderabad",
    college_category: "Colleges",
  },
  {
    college_name: "Vikrama Simhapuri University, Nellore",
    college_url:
      config.apiUrl+"/vikrama-simhapuri-university-nellore",
    college_category: "Colleges",
  },
  {
    college_name: "Palamuru University, Mahboobnagar",
    college_url:
      config.apiUrl+"/palamuru-university-mahboobnagar",
    college_category: "Colleges",
  },
  {
    college_name: "Princeton PG College, Hyderabad",
    college_url:
      config.apiUrl+"/princeton-pg-college-hyderabad",
    college_category: "Colleges",
  },
  {
    college_name: "Garden City University, Bangalore",
    college_url:
      config.apiUrl+"/garden-city-university-bangalore",
    college_category: "Colleges",
  },
  {
    college_name: "DBRAU Agra - Dr BR Ambedkar University, Agra",
    college_url:
      config.apiUrl+"/dr-br-ambedkar-university-agra",
    college_category: "Colleges",
  },
  {
    college_name:
      "BFUHS Faridkot - Baba Farid University of Health Sciences, Faridkot",
    college_url:
      config.apiUrl+"/baba-farid-university-of-health-sciences-faridkot",
    college_category: "Colleges",
  },
  {
    college_name:
      "BRABU Muzaffarpur - Babasaheb Bhimrao Ambedkar Bihar University, Muzaffarpur",
    college_url:
      config.apiUrl+"/babasaheb-bhimrao-ambedkar-bihar-university-muzaffarpur",
    college_category: "Colleges",
  },
  {
    college_name: "Satavahana University, Karimnagar",
    college_url:
      config.apiUrl+"/satavahana-university-karimnagar",
    college_category: "Colleges",
  },
  {
    college_name: "Bhupendra Narayan Mandal University, Madhepura",
    college_url:
      config.apiUrl+"/bhupendra-narayan-mandal-university-madhepura",
    college_category: "Colleges",
  },
  {
    college_name: "Shaheed Mahendra Karma Vishwavidyalaya, Jagdalpur",
    college_url:
      config.apiUrl+"/shaheed-mahendra-karma-vishwavidyalaya-jagdalpur",
    college_category: "Colleges",
  },
  {
    college_name:
      "BPSMV Sonipat - Bhagat Phool Singh Mahila Vishwavidyalaya, Khanpur Kalan",
    college_url:
      config.apiUrl+"/bhagat-phool-singh-mahila-vishwavidyalaya-khanpur-kalan",
    college_category: "Colleges",
  },
  {
    college_name: "Bihar Agricultural University, Bhagalpur",
    college_url:
      config.apiUrl+"/bihar-agricultural-university-bhagalpur",
    college_category: "Colleges",
  },
  {
    college_name: "Bihar Yoga Bharati, Munger",
    college_url:
      config.apiUrl+"/bihar-yoga-bharati-munger",
    college_category: "Colleges",
  },
  {
    college_name: "Azim Premji University, Bangalore",
    college_url:
      config.apiUrl+"/azim-premji-university-bangalore",
    college_category: "Colleges",
  },
  {
    college_name: "SLCs College of Pharmacy, Hayathnagar",
    college_url:
      config.apiUrl+"/slcs-college-of-pharmacy-hayathnagar",
    college_category: "Colleges",
  },
  {
    college_name: "DAVV - Devi Ahilya Vishwavidyalaya, Indore",
    college_url:
      config.apiUrl+"/devi-ahilya-vishwavidyalaya-indore",
    college_category: "Colleges",
  },
  {
    college_name:
      "YSRUHS Vijayawada - Dr YSR University of Health Sciences, Vijayawada",
    college_url:
      config.apiUrl+"/dr-ysr-university-of-health-sciences-vijayawada",
    college_category: "Colleges",
  },
  {
    college_name:
      "CHARUSAT Anand - Charotar University of Science and Technology, Petlad",
    college_url:
      config.apiUrl+"/charotar-university-of-science-and-technology-petlad",
    college_category: "Colleges",
  },
  {
    college_name: "Central University of Gujarat, Gandhinagar",
    college_url:
      config.apiUrl+"/central-university-of-gujarat-gandhinagar",
    college_category: "Colleges",
  },
  {
    college_name:
      "HNGU Patan - Hemchandracharya North Gujarat University, Patan",
    college_url:
      config.apiUrl+"/hemchandracharya-north-gujarat-university-patan",
    college_category: "Colleges",
  },
  {
    college_name:
      "Jai Prakash University Chapra - Jai Prakash Vishwavidyalaya, Chapra",
    college_url:
      config.apiUrl+"/jai-prakash-vishwavidyalaya-chapra",
    college_category: "Colleges",
  },
  {
    college_name: "Gautam Buddha University, Greater Noida",
    college_url:
      config.apiUrl+"/gautam-buddha-university-greater-noida",
    college_category: "Colleges",
  },
  {
    college_name:
      "DSRRAU Jodhpur - Dr Sarvepalli Radhakrishnan Rajasthan Ayurved University, Jodhpur",
    college_url:
      config.apiUrl+"/dr-sarvepalli-radhakrishnan-rajasthan-ayurved-university-jodhpur",
    college_category: "Colleges",
  },
  {
    college_name:
      "NIPER Guwahati - National Institute of Pharmaceutical Education and Research Guwahati",
    college_url:
      config.apiUrl+"/national-institute-of-pharmaceutical-education-and-research-guwahati",
    college_category: "Colleges",
  },
  {
    college_name: "Karnataka Samskrit University, Bangalore",
    college_url:
      config.apiUrl+"/karnataka-samskrit-university-bangalore",
    college_category: "Colleges",
  },
  {
    college_name:
      "National Museum Institute of the History of Art, Conservation and Museology, New Delhi",
    college_url:
      config.apiUrl+"/national-museum-institute-of-history-of-art-conservation-and-museology-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name:
      "National Institute of Educational Planning and Administration, New Delhi",
    college_url:
      config.apiUrl+"/national-institute-of-educational-planning-and-administration-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "Nilamber Pitamber University, Medininagar",
    college_url:
      config.apiUrl+"/nilamber-pitamber-university-medininagar",
    college_category: "Colleges",
  },
  {
    college_name: "Sant Gahira Guru Vishwavidyalaya, Ambikapur",
    college_url:
      config.apiUrl+"/sant-gahira-guru-vishwavidyalaya-ambikapur",
    college_category: "Colleges",
  },
  {
    college_name: "Santosh Deemed to be University, Ghaziabad",
    college_url:
      config.apiUrl+"/santosh-deemed-be-university-ghaziabad",
    college_category: "Colleges",
  },
  {
    college_name:
      "Sardar Vallabhbhai Patel University of Agriculture and Technology, Meerut",
    college_url:
      config.apiUrl+"/sardar-vallabhbhai-patel-university-of-agriculture-and-technology-meerut",
    college_category: "Colleges",
  },
  {
    college_name: "Navsari Agricultural University, Navsari",
    college_url:
      config.apiUrl+"/navsari-agricultural-university-navsari",
    college_category: "Colleges",
  },
  {
    college_name: "Sai Nath University, Ranchi",
    college_url:
      config.apiUrl+"/sai-nath-university-ranchi",
    college_category: "Colleges",
  },
  {
    college_name: "Nava Nalanda Mahavihara, Nalanda",
    college_url:
      config.apiUrl+"/nava-nalanda-mahavihara-nalanda",
    college_category: "Colleges",
  },
  {
    college_name:
      "SVIMS Tirupati - Sri Venkateswara Institute of Medical Sciences, Tirupati",
    college_url:
      config.apiUrl+"/sri-venkateswara-institute-of-medical-sciences-tirupati",
    college_category: "Colleges",
  },
  {
    college_name:
      "SSUS Kalady - Sree Sankaracharya University of Sanskrit, Kalady",
    college_url:
      config.apiUrl+"/sree-sankaracharya-university-of-sanskrit-kalady",
    college_category: "Colleges",
  },
  {
    college_name: "Government Dental College, Kadapa",
    college_url:
      config.apiUrl+"/government-dental-college-kadapa",
    college_category: "Colleges",
  },
  {
    college_name:
      "MIMS Nellimarla - Maharajahs Institute of Medical Sciences, Nellimarla",
    college_url:
      config.apiUrl+"/maharajahs-institute-of-medical-sciences-nellimarla",
    college_category: "Colleges",
  },
  {
    college_name: "MediCiti Institute of Medical Sciences, Medchal",
    college_url:
      config.apiUrl+"/mediciti-institute-of-medical-sciences-medchal",
    college_category: "Colleges",
  },
  {
    college_name: "Shri Jagannath Sanskrit Vishvavidyalaya, Puri",
    college_url:
      config.apiUrl+"/shri-jagannath-sanskrit-vishvavidyalaya-puri",
    college_category: "Colleges",
  },
  {
    college_name: "Sikkim University, Gangtok",
    college_url:
      config.apiUrl+"/sikkim-university-gangtok",
    college_category: "Colleges",
  },
  {
    college_name: "Raja Mansingh Tomar Music and Arts University, Gwalior",
    college_url:
      config.apiUrl+"/raja-mansingh-tomar-music-and-arts-university-gwalior",
    college_category: "Colleges",
  },
  {
    college_name: "Andhra Bharathi Institute of Computer Sciences, Tanuku",
    college_url:
      config.apiUrl+"/andhra-bharathi-institute-of-computer-sciences-tanuku",
    college_category: "Colleges",
  },
  {
    college_name: "Tilak Maharashtra Vidyapeeth, Pune",
    college_url:
      config.apiUrl+"/tilak-maharashtra-vidyapeeth-pune",
    college_category: "Colleges",
  },
  {
    college_name:
      "Rajiv Gandhi College for PG and Professional Studies, Rajahmundry",
    college_url:
      config.apiUrl+"/rajiv-gandhi-college-for-pg-and-professional-studies-rajahmundry",
    college_category: "Colleges",
  },
  {
    college_name: "SAU Delhi - South Asian University, New Delhi",
    college_url:
      config.apiUrl+"/south-asian-university-new-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "Pannala Ram Reddy College of Business Management, Hyderabad",
    college_url:
      config.apiUrl+"/pannala-ram-reddy-college-of-business-management-hyderabad",
    college_category: "Colleges",
  },
  {
    college_name: "Suresh Gyan Vihar University, Jaipur",
    college_url:
      config.apiUrl+"/suresh-gyan-vihar-university-jaipur",
    college_category: "Colleges",
  },
  {
    college_name: "Sri Shivani Institute of Management, Karimnagar",
    college_url:
      config.apiUrl+"/sri-shivani-institute-of-management-karimnagar",
    college_category: "Colleges",
  },
  {
    college_name:
      "Vidya Jyothi Institute of Management and Technology, Kurnool",
    college_url:
      config.apiUrl+"/vidya-jyothi-institute-of-management-and-technology-kurnool",
    college_category: "Colleges",
  },
  {
    college_name: "Swami Vivekanand University, Sagar",
    college_url:
      config.apiUrl+"/swami-vivekanand-university-sagar",
    college_category: "Colleges",
  },
  {
    college_name: "Sri Venkateswara Veterinary University, Tirupati",
    college_url:
      config.apiUrl+"/sri-venkateswara-veterinary-university-tirupati",
    college_category: "Colleges",
  },
  {
    college_name: "University of Patanjali, Haridwar",
    college_url:
      config.apiUrl+"/university-of-patanjali-haridwar",
    college_category: "Colleges",
  },
  {
    college_name: "Sri Venkateswara Vedic University, Tirupati",
    college_url:
      config.apiUrl+"/sri-venkateswara-vedic-university-tirupati",
    college_category: "Colleges",
  },
  {
    college_name: "Indur PG College of Business Management, Bodhan",
    college_url:
      config.apiUrl+"/indur-pg-college-of-business-management-bodhan",
    college_category: "Colleges",
  },
  {
    college_name: "Dr Jyothirmayi Degree College MBA, Adoni",
    college_url:
      config.apiUrl+"/dr-jyothirmayi-degree-college-mba-adoni",
    college_category: "Colleges",
  },
  {
    college_name:
      "Uttarakhand Open University (UOU) - Uttarakhand Open University, Haldwani",
    college_url:
      config.apiUrl+"/uttarakhand-open-university-haldwani",
    college_category: "Colleges",
  },
  {
    college_name: "Sikkim Professional University, Sikkim",
    college_url:
      config.apiUrl+"/sikkim-professional-university-sikkim",
    college_category: "Colleges",
  },
  {
    college_name:
      "YCMOU Nashik - Yashwantrao Chavan Maharashtra Open University, Nashik",
    college_url:
      config.apiUrl+"/yashwantrao-chavan-maharashtra-open-university-nashik",
    college_category: "Colleges",
  },
  {
    college_name:
      "Arignar Anna Institute of Management Studies and Computer Applications, Sriperumbudur",
    college_url:
      config.apiUrl+"/arignar-anna-institute-of-management-studies-and-computer-applications-sriperumbudur",
    college_category: "Colleges",
  },
  {
    college_name: "Martin Luther Christian University, Shillong",
    college_url:
      config.apiUrl+"/martin-luther-christian-university-shillong",
    college_category: "Colleges",
  },
  {
    college_name: "Maharishi Mahesh Yogi Vedic Vishwavidyalaya, Jabalpur",
    college_url:
      config.apiUrl+"/maharishi-mahesh-yogi-vedic-vishwavidyalaya-jabalpur",
    college_category: "Colleges",
  },
  {
    college_name:
      "IIPS Mumbai - International Institute for Population Sciences, Mumbai",
    college_url:
      config.apiUrl+"/international-institute-for-population-sciences-mumbai",
    college_category: "Colleges",
  },
  {
    college_name: "Indira Kala Sangit Vishwavidyalaya, Khairagarh",
    college_url:
      config.apiUrl+"/indira-kala-sangit-vishwavidyalaya-khairagarh",
    college_category: "Colleges",
  },
  {
    college_name: "Apeejay Stya University, Sohna",
    college_url:
      config.apiUrl+"/apeejay-stya-university-sohna",
    college_category: "Colleges",
  },
  {
    college_name:
      "BUEST Baddi - Baddi University of Emerging Sciences and Technology, Baddi",
    college_url:
      config.apiUrl+"/baddi-university-of-emerging-sciences-and-technology-baddi",
    college_category: "Colleges",
  },
  {
    college_name: "Rajiv Gandhi University of Knowledge Technologies, Nuzvid",
    college_url:
      config.apiUrl+"/rajiv-gandhi-university-of-knowledge-technologies-nuzvid",
    college_category: "Colleges",
  },
  {
    college_name: "CMJ University, Shillong",
    college_url:
      config.apiUrl+"/cmj-university-shillong",
    college_category: "Colleges",
  },
  {
    college_name: "MIT University, Shillong",
    college_url:
      config.apiUrl+"/mit-university-shillong",
    college_category: "Colleges",
  },
  {
    college_name: "Alice Institute of Technology, Ranchi",
    college_url:
      config.apiUrl+"/alice-institute-of-technology-ranchi",
    college_category: "Colleges",
  },
  {
    college_name: "BKR College of Engineering and Technology, Tiruvallur",
    college_url:
      config.apiUrl+"/bkr-college-of-engineering-and-technology-tiruvallur",
    college_category: "Colleges",
  },
  {
    college_name: "Kakatiya University, Warangal",
    college_url:
      config.apiUrl+"/kakatiya-university-warangal",
    college_category: "Colleges",
  },
  {
    college_name:
      "RUHS Jaipur - Rajasthan University of Health Sciences, Jaipur",
    college_url:
      config.apiUrl+"/rajasthan-university-of-health-sciences-jaipur",
    college_category: "Colleges",
  },
  {
    college_name: "Kishan Institute of Engineering and Technology, Meerut",
    college_url:
      config.apiUrl+"/kishan-institute-of-engineering-and-technology-meerut",
    college_category: "Colleges",
  },
  {
    college_name:
      "IGDTUW Delhi - Indira Gandhi Delhi Technical University for Women, Delhi",
    college_url:
      config.apiUrl+"/indira-gandhi-delhi-technical-university-for-women-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "SD Institute of Technology and Management, Israna",
    college_url:
      config.apiUrl+"/sd-institute-of-technology-and-management-israna",
    college_category: "Colleges",
  },
  {
    college_name:
      "JNTUH University - Jawaharlal Nehru Technological University, Hyderabad",
    college_url:
      config.apiUrl+"/jawaharlal-nehru-technological-university-hyderabad",
    college_category: "Colleges",
  },
  {
    college_name: "KLE Technological University, Hubballi",
    college_url:
      config.apiUrl+"/kle-technological-university-hubballi",
    college_category: "Colleges",
  },
  {
    college_name:
      "West Bengal University of Animal and Fishery Sciences, Kolkata",
    college_url:
      config.apiUrl+"/west-bengal-university-of-animal-and-fishery-sciences-kolkata",
    college_category: "Colleges",
  },
  {
    college_name: "Jodhpur National University, Jodhpur",
    college_url:
      config.apiUrl+"/jodhpur-national-university-jodhpur",
    college_category: "Colleges",
  },
  {
    college_name: "AIMA Delhi - AIMA Centre for Management Education, Delhi",
    college_url:
      config.apiUrl+"/aima-centre-for-management-education-delhi",
    college_category: "Colleges",
  },
  {
    college_name: "Taxila Business School, Jaipur",
    college_url:
      config.apiUrl+"/taxila-business-school-jaipur",
    college_category: "Colleges",
  },
  {
    college_name: "Udai Institute of Management Studies, Jaipur",
    college_url:
      config.apiUrl+"/udai-institute-of-management-studies-jaipur",
    college_category: "Colleges",
  },
  {
    college_name: "Indraprastha College of Management, Jodhpur",
    college_url:
      config.apiUrl+"/indraprastha-college-of-management-jodhpur",
    college_category: "Colleges",
  },
  {
    college_name: "Aravali Institute of Management, Jodhpur",
    college_url:
      config.apiUrl+"/aravali-institute-of-management-jodhpur",
    college_category: "Colleges",
  },
  {
    college_name: "KIM, Harihar - Kirloskar Institute of Management, Harihar",
    college_url:
      config.apiUrl+"/kirloskar-institute-of-management-harihar",
    college_category: "Colleges",
  },
  {
    college_name: "ITM Institute of Management and Research, Nagpur",
    college_url:
      config.apiUrl+"/itm-institute-of-management-and-research-nagpur",
    college_category: "Colleges",
  },
  {
    college_name: "Shanti Business School, Ahmedabad",
    college_url:
      config.apiUrl+"/shanti-business-school-ahmedabad",
    college_category: "Colleges",
  },
  {
    college_name:
      "DDE MDU Rohtak - Directorate of Distance Education, Maharshi Dayanand University, Rohtak",
    college_url:
      config.apiUrl+"/directorate-of-distance-education-maharshi-dayanand-university-rohtak",
    college_category: "Colleges",
  },
  {
    college_name: "CEPT University, Ahmedabad",
    college_url:
      config.apiUrl+"/cept-university-ahmedabad",
    college_category: "Colleges",
  },
  {
    college_name: "Green Fort Engineering College, Hyderabad",
    college_url:
      config.apiUrl+"/green-fort-engineering-college-hyderabad",
    college_category: "Colleges",
  },
  {
    college_name: "Don Bosco Institute of Technology and Science, Guntur",
    college_url:
      config.apiUrl+"/don-bosco-institute-of-technology-and-science-guntur",
    college_category: "Colleges",
  },
  {
    college_name: "AMR Institute of Technology, Adilabad",
    college_url:
      config.apiUrl+"/amr-institute-of-technology-adilabad",
    college_category: "Colleges",
  },
  {
    college_name:
      "SHUATS Allahabad - Sam Higginbottom Institute of Agriculture Technology and Science, Allahabad",
    college_url:
      config.apiUrl+"/sam-higginbottom-institute-of-agriculture-technology-and-science-allahabad",
    college_category: "Colleges",
  },
  {
    college_name: "Rohtak Institute of Engineering and Management, Rohtak",
    college_url:
      config.apiUrl+"/rohtak-institute-of-engineering-and-management-rohtak",
    college_category: "Colleges",
  },
  {
    college_name: "Sri Krishnadevaraya University, Anantapur",
    college_url:
      config.apiUrl+"/sri-krishnadevaraya-university-anantapur",
    college_category: "Colleges",
  },
  {
    college_name: "Oriental University, Indore",
    college_url:
      config.apiUrl+"/oriental-university-indore",
    college_category: "Colleges",
  },
  {
    college_name: "SV Institute of Engineering and Technology, Moinabad",
    college_url:
      config.apiUrl+"/sv-institute-of-engineering-and-technology-moinabad",
    college_category: "Colleges",
  },
  {
    college_name: "NCR Technical Campus, Aligarh",
    college_url:
      config.apiUrl+"/ncr-technical-campus-aligarh",
    college_category: "Colleges",
  },
  {
    college_name: "Techno India University, Kolkata",
    college_url:
      config.apiUrl+"/techno-india-university-kolkata",
    college_category: "Colleges",
  },
  {
    college_name: "RKDF University, Bhopal",
    college_url: config.apiUrl+"/rkdf-university-bhopal",
    college_category: "Colleges",
  },
  {
    college_name: "UKA Tarsadia University, Bardoli",
    college_url:
      config.apiUrl+"/uka-tarsadia-university-bardoli",
    college_category: "Colleges",
  },
  {
    college_name:
      "Sri Vasavi Institute of Engineering and Technology, Peravali",
    college_url:
      config.apiUrl+"/sri-vasavi-institute-of-engineering-and-technology-peravali",
    college_category: "Colleges",
  },
  {
    college_name: "Peoples University, Bhopal",
    college_url:
      config.apiUrl+"/peoples-university-bhopal",
    college_category: "Colleges",
  },
  {
    college_name:
      "UEM Jaipur - University of Engineering and Management, Jaipur",
    college_url:
      config.apiUrl+"/university-of-engineering-and-management-jaipur",
    college_category: "Colleges",
  },
  {
    college_name: "SPJIMR, Mumbai",
    college_url: config.apiUrl+"/spjimr-mumbai",
    college_category: "Colleges",
  },
  {
    college_name: "NIMT Institute of Technology and Management, Ghaziabad",
    college_url:
      config.apiUrl+"/nimt-institute-of-technology-and-management-ghaziabad",
    college_category: "Colleges",
  },
  {
    college_name: "BIMM Pune - Balaji Institute of Modern Management, Pune",
    college_url:
      config.apiUrl+"/balaji-institute-of-modern-management-pune",
    college_category: "Colleges",
  },
  {
    college_name: "SVK Institute of Management, Nagpur",
    college_url:
      config.apiUrl+"/svk-institute-of-management-nagpur",
    college_category: "Colleges",
  },
  {
    college_name:
      "Graduate School of Business and Administration, Greater Noida",
    college_url:
      config.apiUrl+"/graduate-school-of-business-and-administration-greater-noida",
    college_category: "Colleges",
  },
  {
    college_name:
      "IPM Ghaziabad - Institute of Productivity and Management, Ghaziabad",
    college_url:
      config.apiUrl+"/institute-of-productivity-and-management-ghaziabad",
    college_category: "Colleges",
  },
  {
    college_name: "Yogi Vemana University, Kadapa",
    college_url:
      config.apiUrl+"/yogi-vemana-university-kadapa",
    college_category: "Colleges",
  },
  {
    college_name: "JSS Science and Technology University, Mysuru",
    college_url:
      config.apiUrl+"/jss-science-and-technology-university-mysuru",
    college_category: "Colleges",
  },
  {
    college_name:
      "Vishwa Bharathi Institute of Technology and Sciences, Ranga Reddy",
    college_url:
      config.apiUrl+"/vishwa-bharathi-institute-of-technology-and-sciences-ranga-reddy",
    college_category: "Colleges",
  },
  {
    college_name: "ITM University, Gwalior",
    college_url: config.apiUrl+"/itm-university-gwalior",
    college_category: "Colleges",
  },
  {
    college_name:
      "SLIET Longowal - Sant Longowal Institute of Engineering and Technology, Longowal",
    college_url:
      config.apiUrl+"/sant-longowal-institute-of-engineering-and-technology-longowal",
    college_category: "Colleges",
  },
  {
    college_name: "Noorul Islam Centre For Higher Education, Kanyakumari",
    college_url:
      config.apiUrl+"/noorul-islam-centre-for-higher-education-kanyakumari",
    college_category: "Colleges",
  },
  {
    college_name: "PEC Chandigarh - Punjab Engineering College, Chandigarh",
    college_url:
      config.apiUrl+"/punjab-engineering-college-chandigarh",
    college_category: "Colleges",
  },
  {
    college_name: "Sakthi Engineering College, Thiruninravur",
    college_url:
      config.apiUrl+"/sakthi-engineering-college-thiruninravur",
    college_category: "Colleges",
  },
  {
    college_name: "SB Institute of Engineering and Technology, Kaithal",
    college_url:
      config.apiUrl+"/sb-institute-of-engineering-and-technology-kaithal",
    college_category: "Colleges",
  },
  {
    college_name: "SelaQui Academy of Higher Education, Dehradun",
    college_url:
      config.apiUrl+"/selaqui-academy-of-higher-education-dehradun",
    college_category: "Colleges",
  },
  {
    college_name: "Navrachana University, Vadodara",
    college_url:
      config.apiUrl+"/navrachana-university-vadodara",
    college_category: "Colleges",
  },
  {
    college_name: "BITS Hyderabad - BITS Pilani- Hyderabad Campus, Hyderabad",
    college_url:
      config.apiUrl+"/bits-pilani-hyderabad-campus-hyderabad",
    college_category: "Colleges",
  },
  {
    college_name: "SRM Institute of Science and Technology, Vadapalani Campus",
    college_url:
      config.apiUrl+"/srm-institute-of-science-and-technology-vadapalani-campus",
    college_category: "Colleges",
  },
];
const SearchBox: React.FC<SearchProps> = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [suggestions, setSuggestions] = useState<College[]>([]);
  const searchBoxRef = useRef<HTMLDivElement | null>(null);
  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue: string = event.target.value;
    setSearchTerm(inputValue);
    if (inputValue === "") {
      setSuggestions([]);
    } else {
      const filteredSuggestions: any = collegeNames.filter((suggestion) =>
        suggestion.college_name.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    }
  };
  const handleSuggestionClick = (suggestion: College) => {
    setSearchTerm(suggestion.college_name);
    setSuggestions([]);
    window.location.href = suggestion.college_url;
  };

  const handleClearInput = () => {
    setSearchTerm("");
    setSuggestions([]);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target as Node) // Cast event.target to Node type
      ) {
        setSuggestions([]); // Clear the suggestions
        setSearchTerm(""); // Reset the input search box
        setIsExpanded(false); // Close the popup
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="search-box align-middle w-full" ref={searchBoxRef}>
      {isExpanded && (
        <div
          className={`md:w-[1000px] mx-auto absolute z-[1111] bg-white rounded-lg left-0 right-0 translate-y-24 top-24 p-5 gap-4 ${
            isExpanded
              ? "animate__animated animate__slideInDown"
              : "animate__animated animate__slideInUp"
          }`}
          style={{ boxShadow: "0 5px 4px rgba(0,0,0,.161)" }}
        >
          <div className="flex flex-col gap-2">
            <h3>
              Search for best colleges, Courses, Exams and Education updates
            </h3>
            <div className="flex flex-row relative items-center justify-end">
              <label className="absolute left-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width={16}
                  height={16}
                  fill="#717171"
                >
                  <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                </svg>
              </label>
              <input
                type="text"
                placeholder="Enter what you are looking for: College, Specialization, Course or Exam"
                value={searchTerm}
                onChange={handleSearchChange}
                className={`searchbox w-full border border-gray-200 ${
                  isExpanded ? "expanded" : ""
                }`}
              />
              {searchTerm && (
                <button
                  className={`animate__animated animate__fadeOut absolute right-2`}
                  onClick={handleClearInput}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    fill="#717171"
                    width={14}
                    height={14}
                  >
                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                </button>
              )}
            </div>
          </div>
          {suggestions.length > 1 ? (
            <div
              className={`py-6 relative ${
                suggestions.length > 0
                  ? "animate__animated animate__fadeIn"
                  : ""
              }`}
            >
              <hr className="border-gray-200 py-2" />
              <div className="min-h-[200px] max-h-[300px] overflow-y-auto">
                <ul>
                  {suggestions.map((suggestion, index) => (
                    <div>
                      <li
                        key={index}
                        className="cursor-pointer text-sm text-[#333] font-medium py-2 px-4 hover:bg-gray-100 flex flex-row justify-between"
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        <span>{suggestion.college_name}</span>
                        <span>{suggestion.college_category}</span>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
   <div className="relative flex items-center">
  <input
    type="text"
    placeholder="Search"
    className="hidden md:block border rounded-3xl pl-4 pr-10 py-2 w-full cursor-pointer focus:outline-none "
    onFocus={(e) => {
      handleToggleExpand();
      e.target.blur();
    }}
  />
  <button
    type="button"
    className="md:absolute md:right-0 md:top-0 md:bottom-0 md:py-2  rounded-r-md "
    onClick={handleToggleExpand}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.58329 2.79163C5.55622 2.79163 2.29163 6.05622 2.29163 10.0833C2.29163 14.1104 5.55622 17.375 9.58329 17.375C13.6104 17.375 16.875 14.1104 16.875 10.0833C16.875 6.05622 13.6104 2.79163 9.58329 2.79163ZM1.04163 10.0833C1.04163 5.36586 4.86586 1.54163 9.58329 1.54163C14.3007 1.54163 18.125 5.36586 18.125 10.0833C18.125 12.2171 17.3426 14.1681 16.049 15.6651L18.7752 18.3914C19.0193 18.6354 19.0193 19.0312 18.7752 19.2752C18.5312 19.5193 18.1354 19.5193 17.8914 19.2752L15.1651 16.549C13.6681 17.8426 11.7171 18.625 9.58329 18.625C4.86586 18.625 1.04163 14.8007 1.04163 10.0833Z"
        fill="#333333"
      />
    </svg>
  </button>
</div>

    </div>
  );
};

export default SearchBox;
