import React from "react";

const UGCDetailedInfo: React.FC = () => {
  return (
    <div className="w-full max-md:gap-5  my-3 px-20 py-16  max-md:px-5 bg-[#fdf5e6] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
      {/* Text Content */}
      <div className="md:w-[70%] leading-8">
        <h2 className="text-2xl font-bold text-[#173CBA]">
          University Grants Commission (UGC)
        </h2>
        <p>
          The University Grants Commission (UGC) was established in November
          1956 as a statutory body of the Government of India, aimed at
          coordinating and maintaining standards in higher education across the
          country. Here’s an overview of its key functions and responsibilities:
        </p>
        <h3 className="font-semibold">Key Functions of UGC</h3>
        <ul className="leading-8 list-none">
          <li>
            <strong className="text-[#173CBA]">Funding</strong> UGC provides
            financial assistance to universities and colleges for development,
            research initiatives, and infrastructure improvements.
          </li>
          <li>
            <strong className="text-[#173CBA]">Standards Maintenance</strong>{" "}
            The commission sets and maintains standards for higher education
            institutions, ensuring quality in teaching and research.
          </li>
          <li>
            <strong className="text-[#173CBA]">Recognition</strong> UGC is
            responsible for recognizing universities and courses, ensuring that
            they comply with the necessary operational criteria.
          </li>
          <li>
            <strong className="text-[#173CBA]">Regulation</strong> It oversees
            the functioning of higher education institutions to ensure adherence
            to policies and regulations.
          </li>
          <li>
            <strong className="text-[#173CBA]">Research Promotion</strong> UGC
            promotes research across various fields by funding projects and
            encouraging collaboration among institutions.
          </li>
          <li>
            <strong className="text-[#173CBA]">Policy Development</strong> The
            commission advises the government on higher education policies and
            reforms, contributing to the development of the education sector.
          </li>
          <li>
            <strong className="text-[#173CBA]">Accreditation</strong> UGC plays
            a role in the accreditation process, assessing and evaluating the
            quality of educational institutions.
          </li>
          <li>
            <strong className="text-[#173CBA]">Additional Information</strong>{" "}
            The UGC operates from three locations in Delhi and is the only
            grant-giving agency in India with the dual responsibilities of
            providing funds and maintaining educational standards.
          </li>
        </ul>
        <p>
          For more detailed information about its functions, guidelines, and
          resources, you can visit the official UGC website:{" "}
          <a
            href="https://www.ugc.gov.in/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            https://www.ugc.gov.in/
          </a>
        </p>
      </div>

      {/* Image Section */}
      <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
        <img
          src="/images/regulatory-professionals/UGC.png" //image
          alt="UGC Logo"
          className=""
        />
      </div>
    </div>
  );
};

export default UGCDetailedInfo;
