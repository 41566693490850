import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../util/config";

export const fetchDetailData = createAsyncThunk(
  "educationLoan/fetchDetailData",
  async () => {
    const response = await axios.get(
      config.apiUrl + "/api/scholarship-loan/loan-detail"
    );
    return response.data?.data;
  }
);
