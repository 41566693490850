import React, { useState, useEffect, useRef } from "react";
import Loader from "../../Loader/Loader";
import config from "../../../util/config";
import OverviewTab from "../../../components/college-details/components/OverviewTab";
import RankingsTab from "./RankingsTab";
import CoursesFeesTab from "./CoursesFeesTab";
import AdmissionsTab from "./AdmissionsTab";
import CutoffTab from "./CutoffsTab";
import CampusFacilitiesTab from "./CampusFacilitiesTab";
import CollegeLifeTab from "./CampusLifeTab";
import CMResourcesTab from "./CMResourcesTab";
import PlacementsTab from "./PlacementsTab";
import ResearchTab from "./ResearchTab";
import { useParams } from "react-router-dom";

import type { SVGProps } from "react";

export function EpRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path
        fill="currentColor"
        d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312z"
      ></path>
    </svg>
  );
}
interface Tab {
  title: string;
  name: string;
  orderIndex: number;
  subTabs?: Tab[];
}

const NavigationTabs = ({ tabs }: { tabs: Tab[] }) => {
  const { collegeName } = useParams<{ collegeName: string }>();
  const [activeTab, setActiveTab] = useState<string>(tabs[0]?.name || "");
  const [tabContent, setTabContent] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const tabContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tabs.length > 0) {
      setActiveTab(tabs[0].name);
      scrollToFirstTab();
    }
  }, [tabs]);

  useEffect(() => {
    if (activeTab && collegeName) {
      fetchTabContent(activeTab);
    }
  }, [activeTab, collegeName]);

  useEffect(() => {
    const checkScrollable = () => {
      const tabContainer = tabContainerRef.current;
      if (tabContainer) {
        setIsScrollable(tabContainer.scrollWidth > tabContainer.clientWidth);
      }
    };

    checkScrollable();
    window.addEventListener("resize", checkScrollable);
    return () => window.removeEventListener("resize", checkScrollable);
  }, [tabs]);
  const fetchTabContent = async (tabName: string) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${config.apiUrl}/api/college/college/collegeDetails/${collegeName}/${tabName}`,
        { method: "GET", headers: { Accept: "*/*" } }
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch content for ${tabName}`);
      }

      const data = await response.json();
      let content = null;

      switch (tabName) {
        case "overview":
          content = {
            key_0: data.data?.details?.key_0 || null,
            key_6: data.data?.details?.key_6 || null,
            key_1: data.data?.details?.key_1 || null,
            key_2: data.data?.details?.key_2 || null,
            key_3: data.data?.details?.key_3 || null,
            key_4: data.data?.details?.key_4 || null,
            key_5: data.data?.details?.key_5 || null,
          };
          break;

        case "rankings":
          content = {
            rankings: {
              key_4: data.data?.details?.key_4 || null,
              key_1: data.data?.details?.key_1 || null,
              key_3: data.data?.details?.key_3 || null,
              key_2: data.data?.details?.key_2 || null,
              key_0: data.data?.details?.key_0 || null,
              key_5: data.data?.details?.key_5 || null,
              key_6: {
                title:
                  data.data?.details?.key_6?.title || "Knowledge Hub (FAQs)",
                orderIndex: data.data?.details?.key_6?.orderIndex || "0",
                array: data.data?.details?.key_6?.array || [],
              },
              similarColleges: data.data?.details?.similarColleges || [],
            },
          };
          break;

        case "courses-&-fees":
          content = {
            CoursesFees: {
              key_0: data.data?.details?.key_0 || null,
              // faqs: data.data?.details?.knowledgehubfaqs?.array || [],
              similarColleges: data.data?.details?.similarColleges || [],
            },
          };
          break;

        case "admissions":
          content = {
            admissionsDetails: {
              key_0: data.data?.details?.key_0 || null,
              key_5: data.data?.details?.key_5 || null,
              key_6: data.data?.details?.key_6 || null,
              key_7: data.data?.details?.key_7 || null,
              key_3: data.data?.details?.key_3 || null,
              key_1: data.data?.details?.key_1 || null,
              key_4: data.data?.details?.key_4 || null,
              key_2: data.data?.details?.key_2 || null,
              key_8: {
                title:
                  data.data?.details?.key_8?.title || "Knowledge Hub (FAQs)",
                orderIndex: data.data?.details?.key_8?.orderIndex || "0",
                array: data.data?.details?.key_8?.array || [],
              },
              similarColleges: data.data?.details?.similarColleges || [],
            },
          };
          break;

        case "cut-off":
          content = {
            cutoffDetails: {
              key_0: data.data?.details?.key_0 || null,
              key_1: data.data?.details?.key_1 || null,
              key_2: data.data?.details?.key_2 || null,
              faqs: data.data?.details?.knowledgehubfaqs?.array || [],
              similarColleges: data.data?.details?.similarColleges || [],
            },
          };
          break;

        case "campus-facilities":
          content = {
            facilitiesData: {
              key_1: {
                title: data.data?.details?.key_1?.title || "Campus Facilities",
                description:
                  data.data?.details?.key_1?.description ||
                  "No information available.",
                array: data.data?.details?.key_1?.array || [],
              },
              key_7: data.data?.details?.key_7 || null,
              key_6: data.data?.details?.key_6 || null,
              bank: data.data?.details?.bank || null,
              key_5: data.data?.details?.key_5 || null,
              key_11: data.data?.details?.key_11 || null,
              key_8: data.data?.details?.key_8 || null,
              key_13: data.data?.details?.key_13 || null,
              key_4: data.data?.details?.key_4 || null,
              key_12: data.data?.details?.key_12 || null,
              key_9: data.data?.details?.key_9 || null,
              similarColleges: data.data?.details?.similarColleges || [],
              key_0: data.data?.details?.key_0 || null,
            },
          };
          break;

        case "placements":
          content = {
            placementData: {
              key_0: data.data?.details?.key_0 || null,
              key_1: data.data?.details?.key_1 || null,
              key_3: data.data?.details?.key_3 || null,
              key_5: data.data?.details?.key_5 || null,
              key_4: data.data?.details?.key_4 || null,
              key_2: data.data?.details?.key_2 || null,
              key_6: data.data?.details?.key_6 || null,
              similarColleges: data.data?.details?.similarColleges || [],
            },
          };
          break;

        case "research":
          content = {
            key_3: data.data?.details?.key_3 || null,
            key_2: data.data?.details?.key_2 || null,
            key_1: data.data?.details?.key_1 || null,
            key_4: data.data?.details?.key_4 || null,
            theliveinlabsprogram:
              data.data?.details?.theliveinlabsprogram || null,
            key_7: data.data?.details?.key_7 || null,
            key_5: data.data?.details?.key_5 || null,
            key_0: data.data?.details?.key_0 || null,
            similarColleges: data.data?.details?.similarColleges || [],
          };
          break;

        case "cm-resources":
          content = {
            cmResourceData: {
              chatWithAmbassadors: data.data["chat-with-ambassedors"] || [],
              chatIntro: data.data?.details?.chatwithourambassadors || null,
              newsAndArticles: data.data?.details?.newsandarticles || null,
              key_2: data.data?.details?.key_2 || null,
              key_3: data.data?.details?.key_3 || null,
              visualStories: data.data?.details?.visualstories || null,
              key_0: data.data?.details?.key_0 || null,
              similarColleges: data.data?.details?.similarColleges || [],
            },
          };
          break;

        case "college-life":
          content = {
            collegeLifeData: {
              key_0: data.data?.details?.key_0 || null,
              key_1: data.data?.details?.key_1 || null,
              key_2: data.data?.details?.key_2 || null,
              key_3: data.data?.details?.key_3 || null,
              key_4: {
                title: data.data?.details?.key_4?.title,
                description:
                  data.data?.details?.key_4?.description ||
                  "No information available.",
                array: data.data?.details?.key_4?.array || [],
              },
              faqs: data.data?.details?.knowledgehubfaqs?.array || [],
              similarColleges: data.data?.details?.similarColleges || [],
            },
          };
          break;

        default:
          console.warn("Unknown tab:", tabName);
      }

      setTabContent(content);
      setLoading(false);
    } catch (error) {
      console.error(`Error fetching content for ${tabName}:`, error);
      setLoading(false);
    }
  };
  const scrollToFirstTab = () => {
    const tabContainer = tabContainerRef.current;
    if (tabContainer) {
      tabContainer.scrollTo({ left: 0, behavior: "smooth" });
    }
  };

  const scrollToLastTab = () => {
    const tabContainer = tabContainerRef.current;
    if (tabContainer) {
      tabContainer.scrollTo({
        left: tabContainer.scrollWidth - tabContainer.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const handleLeftClick = () => {
    scrollToFirstTab();
  };

  const handleRightClick = () => {
    scrollToLastTab();
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsSticky(scrollY > 760);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (!tabs.length) {
    return <p>No tabs available</p>;
  }
  return (
    <div>
      <div
        className={`transition-opacity duration-500 ${
          isSticky
            ? "fixed top-0 left-0 w-full opacity-100 z-50 shadow-lg"
            : "opacity-100"
        }`}
      >
        <ul
          className="flex items-center justify-between space-x-4 px-4 py-2 overflow-x-auto"
          style={{
            background: "linear-gradient(180deg, #173CBA 0%, #06AFA9 100%)",
          }}
        >
          {isScrollable && (
            <button
              className="bg-[#00CC99] border border-white rounded text-white py-3 px-2"
              onClick={handleLeftClick}
            >
              <span className="material-icons">
                <EpRight className="rotate-180" />
              </span>
            </button>
          )}

          <div
            id="tabContainer"
            ref={tabContainerRef}
            className="overflow-x-auto scrollbar-hide w-full mx-auto"
          >
            <ul className="list-none flex gap-4 items-center text-center w-full mx-auto xl:justify-center">
              {tabs.map((tab) => (
                <li
                  key={tab.orderIndex}
                  className={`text-base py-1.5 px-3.5 font-semibold rounded-sm cursor-pointer whitespace-nowrap ${
                    activeTab === tab.name
                      ? "bg-green text-white"
                      : "bg-white text-black hover:bg-green hover:text-white"
                  }`}
                  onClick={() => setActiveTab(tab.name)}
                >
                  {tab.title}
                </li>
              ))}
            </ul>
          </div>

          {isScrollable && (
            <button
              className="bg-[#00CC99] border border-white rounded text-white py-2 px-2"
              onClick={handleRightClick}
            >
              <span className="material-icons">
                <EpRight />
              </span>
            </button>
          )}
        </ul>
      </div>
      <div className="w-full">
        {loading ? (
          <Loader />
        ) : (
          <div>
            {activeTab === "overview" && tabContent && (
              <OverviewTab collegeDetails={{ details: tabContent }} />
            )}
            {activeTab === "rankings" && tabContent?.rankings && (
              <RankingsTab rankings={tabContent.rankings} />
            )}
            {activeTab === "courses-&-fees" && tabContent?.CoursesFees && (
              <CoursesFeesTab CoursesFees={tabContent.CoursesFees} />
            )}
            {activeTab === "admissions" && tabContent?.admissionsDetails && (
              <AdmissionsTab admissionsDetails={tabContent.admissionsDetails} />
            )}
            {activeTab === "cut-off" && tabContent?.cutoffDetails && (
              <CutoffTab cutoffData={tabContent.cutoffDetails} />
            )}
            {activeTab === "placements" && tabContent?.placementData && (
              <PlacementsTab placementData={tabContent.placementData} />
            )}
            {activeTab === "campus-facilities" &&
              tabContent?.facilitiesData && (
                <CampusFacilitiesTab
                  facilitiesData={tabContent.facilitiesData}
                />
              )}
            {activeTab === "research" && tabContent && (
              <ResearchTab data={tabContent} />
            )}
            {activeTab === "college-life" && tabContent?.collegeLifeData && (
              <CollegeLifeTab collegeLifeData={tabContent.collegeLifeData} />
            )}
            {activeTab === "cm-resources" && tabContent?.cmResourceData && (
              <CMResourcesTab resourcesData={tabContent.cmResourceData} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavigationTabs;
