import React, { useState, useRef, useEffect } from "react";
import type { SVGProps } from "react";
import "./CustomTabsNavigationBar.scss";
import TrendIconRight from "../../images/blog-article/TrendIconRight.svg";
import TrendIconLeft from "../../images/blog-article/TrendIconLeft.svg";
import { Link } from "react-router-dom";
import { getBlogOrNewsUrl } from "../../screens/blogs-articles/utils";

interface CustomTabsNavigationBarProps {
  tabs: { data: any }[];
}

const CustomTabsNavigationBar: React.FC<CustomTabsNavigationBarProps> = ({
  tabs,
}) => {
  console.log("CustomTabsNavigationBar", tabs);
  const [activeTab, setActiveTab] = useState(1);
  const [hover, setHover] = useState<number | null>(null);
  const [isSticky, setIsSticky] = useState(false);
  const menuRef = useRef<HTMLUListElement>(null);

  const handleClick = (id: number) => {
    setActiveTab(id);
  };
  const [disableLeft, setDisableLeft] = useState(true); // Add state for left icon
  const [disableRight, setDisableRight] = useState(false); // Add state for right icon

  const scrollLeft = () => {
    const scrollAmount = menuRef.current?.offsetWidth || 200; // Get container width or default
    menuRef.current?.scrollBy({ left: -scrollAmount, behavior: "smooth" });
  };

  const scrollRight = () => {
    const scrollAmount = menuRef.current?.offsetWidth || 200; // Get container width or default
    menuRef.current?.scrollBy({ left: scrollAmount, behavior: "smooth" });
  };
  useEffect(() => {
    const handleScroll = () => {
      const stickyPosition = 730;
      setIsSticky(window.pageYOffset > stickyPosition);

      const scrollLeft = menuRef.current?.scrollLeft || 0;
      const scrollWidth = menuRef.current?.scrollWidth || 0;
      const clientWidth = menuRef.current?.clientWidth || 0;

      setDisableLeft(scrollLeft === 0);
      setDisableRight(scrollLeft + clientWidth + 5 >= scrollWidth);
      console.log("sdasd", scrollLeft + clientWidth + 5, scrollWidth);
    };

    menuRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      menuRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log(disableLeft, disableRight, "dfvmk");

  // list rendering

  const tabList = (tabs: any) => {
    // console.log("tabs", tabs);
    return (
      <>
        {tabs?.map((tab: any) => (
          <Link
            to={getBlogOrNewsUrl(tab)}
            state={{
              from: "BlogDetail",
              blogDetail: tab,
            }}
            key={tab.id}
            className={`
          custom-tab-trending cursor-pointer w-[70%] flex border-[#173CBA]`}
            onClick={() => handleClick(tab?.id)}
          >
          <button className="text-xs md:text-sm max-md:w-[100%]  overflow-hidden text-ellipsis whitespace-nowrap"> 
          {tab?.trendingTopic || tab?.title}
            </button>
          </Link>
        ))}
      </>
    );
  };

  return (
    <div className="flex flex-col justify-center">
      <div
        className={`w-full bg-white ${
          isSticky
            ? " top-0 z-20 bg-white w-full transition duration-700"
            : "relative transition duration-300"
        }`}
      >
        <div className="container relative flex items-center mx-auto">
          <button
            onClick={scrollLeft}
            className={`hidden md:block absolute left-0 ${
              disableLeft ? "text-gray-500 cursor-not-allowed" : ""
            }`}
            disabled={disableLeft}
          >
            <img src={TrendIconLeft} className="text-grey" />
          </button>
          <div className=" flex items-center px-2 overflow-hidden 5">
            <ul
              className="w-full flex overflow-x-auto tabs whitespace-nowrap scrollbar-hide"
              ref={menuRef}
            >
              {tabList(tabs)}
            </ul>
          </div>
          <button
            onClick={scrollRight}
            className={`hidden md:block absolute right-0 ${
              disableRight ? "text-gray-500 cursor-not-allowed" : ""
            }`}
            disabled={disableRight}
          >
            <img src={TrendIconRight} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomTabsNavigationBar;
