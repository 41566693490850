import React, { useState } from "react";

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col mt-5 w-full leading-7 gap-5 max-md:w-full">
      <button
        className="flex flex-row text-[14px] gap-3.5 items-center text-left w-full max-md:w-full"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        {isOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <rect width="23" height="23" rx="3" fill="#173CBA" />
            <path
              d="M5 12H17"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <rect width="23" height="23" rx="3" fill="#173CBA" />
            <path
              d="M5 12H17"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M11 18L11 6"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        )}
        <span className="font-semibold  max-md:line-clamp-1">{question}</span>
      </button>
      {isOpen && (
        <div className="flex-auto leading-6 font-normal max-md:w-full">
          {answer}
        </div>
      )}
    </div>
  );
};

const FAQ: React.FC = () => {
  const faqItems = [
    {
      question: "How can I refer my friends/connections?",
      answer:
        "Visit Collegementor.com/refer and earn. If you have already signed up with College Mentor, either enter your friend's email ID who you would like to refer or simply share the referral invite link with them. If you haven't yet signed up with College Mentor, do that first and then follow the above mentioned step.",
    },
    {
      question: "Where Can i find the referral code?",
      answer:
        "Your referral code can be found in your account dashboard after logging in to the College Mentor website.",
    },
    {
      question: "Who can I refer for the cashback offer?",
      answer:
        "You can refer any student who is looking to enroll in higher education programs, both in India and abroad.",
    },
    {
      question: "How and when will I receive my referral payment?",
      answer:
        "Referral payments are processed after the referred student successfully enrolls and pays their tuition fees. You'll receive 50% of your earnings after 45 days from enrollment, and the remaining 50% after 90 days.",
    },
    {
      question: "Are there any restrictions on the referral offer?",
      answer:
        "Yes, the offer is only valid for new students not already registered with College Mentor, and for enrollments through College Mentor tie-up Universities.",
    },
    {
      question:
        "The referral benefit I have received is incorrect (Example: instead of ₹30,000, ₹ 28,500 is credited) ?",
      answer:
        "If you believe there's a discrepancy in your referral benefit, please contact our support team with details of your referral for review and resolution.",
    },
    {
      question: "My friend missed applying my referral code ?",
      answer:
        "If your friend forgot to use your referral code during registration, please contact our support team within 48 hours of their registration with both your and your friend's details.",
    },
    {
      question: "How to contact if anything to be discussed ?",
      answer:
        "For any queries or discussions regarding the referral program, please reach out to our dedicated support team at support@collegementor.com or call our helpline at +1 (234) 567-8900.",
    },
  ];

  return (
    <div className="flex flex-col  max-md:px-7  px-20 max-md:w-full text-sm font-semibold py-10 text-black w-full max-md:mt-5">
      <h2 className="text-3xl font-bold text-[#173CBA] leading-[55px]">
        Knowledge Hub (FAQ'S)
      </h2>
      {faqItems.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  );
};

export default FAQ;
