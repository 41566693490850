import React from "react";
import CollegeCard from "../CollegeCard";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";

interface CollegesPreferenceSliderBoxProps {
  header: string;
  title: string;
  collegesList: any;
}

const CollegesPreferenceSliderBox: React.FC<
  CollegesPreferenceSliderBoxProps
> = ({ header = "", title, collegesList = [] }) => {
  const getLocation = (college: any) => {
    return college.collegeCity && college.collegeState
      ? `${college.collegeCity}, ${college.collegeState}`
      : "Location not available";
  };
  return (
    <div className="border border-[#e0e0e9] bg-gradient-to-b from-[#e9ecff] to-white p-6 pb-3 relative mb-6 flex flex-col gap-4">
      <div className="">
        <span className="bg-[#f5a041] font-medium px-4 py-1.5 inline-flex rounded-md text-sm">
          {header}
        </span>
      </div>
      <div className="flex flex-col justify-between md:flex-row">
        <div className="MainHeadleft">
          <span>{title}</span>
        </div>
        <div className="MainHeadright">
          <span>{collegesList ? collegesList.length : 0} colleges</span>
        </div>
      </div>
      {/* <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"> */}
      {collegesList && collegesList.length > 0 && (
        <CustomSwiper
          className="!px-2"
          items={collegesList?.map((college: any, index: number) => (
            <CollegeCard
              key={index}
              collegeName={college.collegeName}
              image={college.iconUrl}
              category={college.category}
              categoryId={college.collegeId}
              location={getLocation(college)}
              type={"explore"}
            />
          ))}
          navigation={"bottom"}
          autoplay={true}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 4, spaceBetween: 20 },
            1024: { slidesPerView: 4, spaceBetween: 20 },
            1280: { slidesPerView: 3, spaceBetween: 30 },
          }}
          progressBar={true}
        />
      )}
      {/* </div> */}
    </div>
  );
};

export default CollegesPreferenceSliderBox;
