import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import useLeadGeneration from "../../hooks/useLeadGeneration";

const EducationForAllLanding = () => {
  const { invokeLeadGeneration } = useLeadGeneration();

  return (
    <>
      <div className="font-roboto text-grey flex flex-col gap-4 lg:gap-1">
        <section className="text-white flex max-lg:flex-col bg-[#0A0A60]">
          <div className="py-12 px-6 lg:px-12 lg:w-1/2 self-center">
            <Breadcrumbs
              links={[
                { name: "Home", path: "/" },
                { name: "Education for All", path: "/" },
              ]}
            />
            <h1 className="mt-6 text-4xl lg:text-5xl font-bold">
              Education for All Pledge
            </h1>
            <h1 className="text-4xl lg:text-5xl font-light">
              One-to-One Education Initiative
            </h1>
            <p className="mt-4 text-lg lg:text-xl">
              Highlights the direct link between supporting one child’s
              education and aiding another’s journey to a prestigious
              university.
            </p>
            <p className="mt-4 text-lg lg:text-xl">
              Represents the exchange of providing scholarships to
              underprivileged students in return for supporting studentsaiming
              for prestigious institutions.
            </p>
            <div className="mt-4 text-sm lg:text-base flex gap-6 flex-wrap">
              <button
                className="shrink-0 bg-green px-4 py-2 rounded"
                onClick={() =>
                  invokeLeadGeneration("education-for-all", "join-the-cause")
                }
              >
                Join the Cause <FontAwesomeIcon icon={faArrowRight} />
              </button>
              <Link
                to="/education-for-all-intermediate"
                className="shrink-0 bg-green px-4 py-2 rounded"
              >
                Track Sponsored Student <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          </div>
          <div className="lg:w-1/2 self-end">
            <img
              className="w-full object-cover"
              src="/images/education-for-all/school-kids.jpg"
            />
          </div>
        </section>
        <section className="flex max-lg:flex-col bg-[#D9F6EF]">
          <div className="py-12 px-6 lg:px-12 lg:w-1/2 self-center">
            <h1 className="text-4xl lg:text-5xl font-bold text-blue">
              Education for All Pledge
            </h1>
            <h1 className="text-4xl lg:text-5xl font-light text-blue">
              One-to-One Education Initiative
            </h1>
            <p className="mt-4 text-lg lg:text-xl">
              Through our Education for All Pledge, we create a meaningful
              impact by linking global academic dreams with local educational
              support. For every student who uses our service to gain admission
              to a prestigious university worldwide, you have the option to
              sponsor one full year of mentoring for a government school student
              in India.
            </p>
            <p className="mt-4 text-lg lg:text-xl">
              You can choose to sponsor a student from your known family,
              friends, or acquaintances studying in government schools, or we
              can automatically assign a student in your name. This mentorship
              program allows you to track the progress of the sponsored student
              and witness firsthand the positive impact of your support.
            </p>
            <p className="mt-4 text-lg lg:text-xl">
              Your support not only advances your own academic goals but also
              empowers the next generation to navigate educational challenges
              and career choices with confidence. Together, we can make a
              significant difference by fostering educational growth, career
              readiness, and holistic well-being in those who need it most.
            </p>
            <p className="mt-4 text-lg lg:text-xl">
              <strong>Note :</strong> We are honored to support and mentor the
              children of military families, recognizing their parents'
              dedication and sacrifice for our nation. This sponsorship is
              funded entirely through the profits from our college mentoring
              services, reflecting our commitment to giving back as responsible
              citizens.
            </p>
          </div>
          <div className="lg:w-1/2 self-end">
            <img
              className="w-full object-cover"
              src="/images/education-for-all/two-school-girls.png"
            />
          </div>
        </section>
        <section className="flex flex-col lg:flex-row-reverse bg-[#FFF9E5]">
          <div className="py-12 px-6 lg:px-12 lg:w-3/4 self-center">
            <h1 className="text-4xl lg:text-5xl font-bold text-blue">
              Why This Initiative?
            </h1>
            <p className="mt-4 text-lg lg:text-xl">
              As we advance and achieve success, it is vital to give back to
              society. Society has shaped us with its lessons—both good and
              bad—and now it’s time to contribute to the upliftment of others.
              Our Education for All Pledge: One-to-One Education Initiative is
              designed to mentor government school students in India, enhancing
              their educational opportunities and quality of life.
            </p>
            <p className="mt-4 text-lg lg:text-xl">
              In a rapidly evolving world where careers are constantly changing
              and artificial intelligence is reshaping all areas of education,
              it is crucial to stay informed about emerging technologies and
              career paths. Through this initiative, we provide comprehensive
              mentoring that includes support for exams, college applications,
              counselling process, scholarships, and mental health. By
              addressing these critical areas, we aim to equip students with the
              tools they need to succeed academically and emotionally.
            </p>
          </div>
          <div className="lg:w-1/4 self-end">
            <img
              className="w-full object-cover"
              src="/images/education-for-all/shekar.png"
            />
          </div>
        </section>
        <section className="flex max-lg:flex-col items-center bg-[#FFF1FA]">
          <div className="py-12 px-6 lg:px-12 lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl font-bold text-blue">
              For a Sustainable World
            </h1>
            <p className="mt-4 text-lg lg:text-xl">
              The 17 Sustainable Development Goals (SDGs) were adopted by all UN
              Member States in 2015 as part of the 2030 Agenda. They address
              global challenges such as poverty, inequality, climate change, and
              justice, with specific targets to create a more sustainable and
              equitable world.
            </p>
            <p className="mt-4 text-lg lg:text-xl">
              Each one of us has to do our bit. We take pride in supporting the
              United Nations Sustainable Development Goal #4 of Quality
              Education and Education for All
            </p>
          </div>
          <div className="lg:w-1/2">
            <img
              className="w-full object-cover"
              src="/images/education-for-all/sustainable.jpg"
            />
          </div>
        </section>
        <section className="bg-[#EEF2FF] overflow-hidden">
          <div className="flex flex-col lg:flex-row-reverse items-center">
            <div className="py-12 px-6 lg:px-12 lg:w-1/2">
              <h1 className="text-4xl lg:text-5xl font-bold text-blue">
                Join the Cause
              </h1>
              <p className="mt-4 text-lg lg:text-xl">
                To volunteer as a mentor, please email us at
                educationforall@collegementor.com, and our team will reach out
                to you. Alternatively, you can help by selling our services to
                students in need who are aspiring to attend their dream
                universities.
              </p>
            </div>
            <div className="lg:w-1/2">
              <img
                className="w-full object-cover"
                src="/images/education-for-all/join-the-cause.png"
              />
            </div>
          </div>
          <div className="max-lg:w-[200vw] max-lg:-translate-x-[10%]">
            <img
              className="my-12 w-full  object-cover opacity-30"
              src="/images/education-for-all/monuments.png"
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default EducationForAllLanding;
