import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./IkigaiMentorSection.scss";
import { Navigation } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import config from "../../../util/config";

interface MentorCardProps {
  imageUrl: string;
  name: string;
  role: string;
}

interface Mentor {
  imageUrl: string;
  name: string;
  role: string;
}

const MentorCard: React.FC<MentorCardProps> = ({ imageUrl, name, role }) => {
  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-md w-[237px] max-md:w-[247px] max-md:mx-auto">
      <img
        loading="lazy"
        src={imageUrl}
        alt={`${name} - ${role}`}
        className="w-full h-52 object-cover"
      />
      <div className="bg-[#173CBA] text-white text-center p-4 relative">
        <h3 className="text-lg font-semibold">{name}</h3>
        <p className="text-sm">{role}</p>
      </div>
    </div>
  );
};

export const IkigaiMentorSection: React.FC = () => {
  const [mentors, setMentors] = useState<Mentor[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMentors = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${config.apiUrl}/api/auth/mentors/mentorList`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch mentor data");
        }

        const responseData = await response.json();
        setMentors(responseData.data || []);
      } catch (error: any) {
        console.error("Error fetching mentors:", error);
        setError(error.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchMentors();
  }, []);

  if (loading) {
    return <div className="text-center py-10">Loading mentors...</div>;
  }

  if (error) {
    return <div className="text-center py-10 text-red-500">{error}</div>;
  }

  return (
    <div className="flex flex-col items-center px-20 pt-10 pb-16 w-full bg-[#FFF1FA] max-md:px-5 max-md:w-full">
      <div className="flex flex-col w-full max-md:w-full">
        <h3 className="md:self-start md:ml-20 text-4xl font-light text-[#173CBA] md:leading-[55px] max-md:max-w-full">
          We will get you Admitted into <br /> Top Class Universities Worldwide
        </h3>

        <div className="ikigai-mentor-section lg:w-[1250px] mx-auto md:w-full flex items-center mt-8 px-10 relative max-md:w-full">
          <div className="swiper-button-prev lg:ml-[-45px]"></div>
          <div className="swiper-button-next lg:mr-[-25px]"></div>
          <Swiper
            modules={[Navigation]}
            loop={true}
            spaceBetween={20}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            breakpoints={{
              320: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
              1280: { slidesPerView: 4 },
            }}
            className="mt-8"
          >
            {mentors.map((mentor, index) => (
              <SwiperSlide key={index}>
                <MentorCard {...mentor} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default IkigaiMentorSection;
