import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import "./TypesOfScholarships.scss";
import { useMediaQuery } from 'react-responsive';

interface TypeScholarshipProps {
  image: string;
  type: string;
  url: string;
}
const scholarshipTypes = [
  {
    image: "/images/scholarship/landing/means.png",
    type: "Means Based Scholarship",
    category: 2,
  },
  {
    image: "/images/scholarship/landing/merit.png",
    type: "Merit Based Scholarship",
    category: 3,
  },
  {
    image: "/images/scholarship/landing/girls.png",
    type: "Girls Scholarship",
    category: 1,
  },
  {
    image: "/images/scholarship/landing/school.png",
    type: "School Scholarship",
    category: null,
  },
  {
    image: "/images/scholarship/landing/college.png",
    type: "College Scholarship",
    category: null,
  },
  {
    image: "/images/scholarship/landing/international.png",
    type: "International Scholarship",
    category: null,
  },
  {
    image: "/images/scholarship/landing/minorities.png",
    type: "Minorities Scholarship",
    category: null,
  },
  {
    image: "/images/scholarship/landing/sports.png",
    type: "Sports Based Scholarship",
    category: null,
  },
];

const buildURL = (baseUrl: string, params: Record<string, any>) => {
  const url = new URL(baseUrl, window.location.origin);
  Object.entries(params).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      url.searchParams.append(key, value);
    }
  });
  return url.toString();
};

const TypeOfScholarships: React.FC = () => {
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1280px)" });
  const displayedScholarshipData = isLargeScreen
    ? scholarshipTypes.slice(0, 8)
    : scholarshipTypes.slice(0, 4);

  if (!scholarshipTypes) {
    return null;
  }

  return (
    <div className="py-12 bg-[#E9EBF8]">
      <div className="container">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-12">
          {displayedScholarshipData.map((item, index) => (
            <a
              key={index}
              href={buildURL("/scholarships/list-of-scholarships-in-india", {
                category: item.category,
              })}
            >
              <div
                className="bg-white rounded-lg shadow-lg"
                style={{ width: "305px" }}
              >
                <div className="w-full h-[207px]">
                  <img
                    src={item.image}
                    alt={item.type}
                    className="w-full h-[207px] object-cover rounded-t-lg"
                  />
                </div>
                <div className="font-bold text-[14px] text-center bg-white py-8">
                  {item.type}
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TypeOfScholarships;

