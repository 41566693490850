import { keyframes } from "@emotion/react";

export const customAnimationTop = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -80px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const customAnimationTopLong = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -160px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const customAnimationBottom = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 80px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const customAnimationBottomLong = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 160px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const customAnimationRight = keyframes`
  from {
    opacity: 0;
    transform: translate3d(80px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const customAnimationRightLong = keyframes`
  from {
    opacity: 0;
    transform: translate3d(160px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const customAnimationLeft = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-80px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const customAnimationLeftLong = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-160px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const scaleFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 80px, 0) scale(0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
`;

export const scaleFromBottomLong = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 160px, 0) scale(0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
`;
