import React from "react";

const MentorDetailsTabs = () => {
  return (
    <div className="bg-[#FFF9E5]    ">
      <div className="container p-6 min-max-xss:p-3">
        <div className="flex space-x-4 min-max-xss:space-x-2 mb-6">
          <button className="md:px-4 md:py-2 md:text-xl md:font-semibold max-md:font-normal max-md:px-2  rounded bg-[#173CBA] text-white">
            About Mentor
          </button>
          <button className="md:px-4 md:py-2 md:text-xl md:font-semibold max-md:font-normal max-md:px-2 rounded bg-[#173CBA] text-white">
            Skills & Experience
          </button>
          <button className="md:px-4 md:py-2 md:text-xl md:font-semibold max-md:font-normal max-md:px-2 rounded bg-[#173CBA] text-white">
            Reviews
          </button>
        </div>

        <div className="text-gray-800">
          <h2 className="text-xl font-semibold text-[#173CBA]">About Mentor</h2>
          <p className="mt-4 text-sm">
            Rajas Shekar brings 15 years of experience in college admissions,
            guiding students through application processes for institutions in
            India and abroad. He specializes in exam preparation, personalized
            application assistance, and securing scholarships. His expertise has
            helped numerous students achieve their academic and career goals.
            Here’s a summary of his background.
          </p>
          <div className="mt-4">
            <p className="text-sm font-semibold">Professional Experience</p>
            <p className="text-sm">
              Admissions Guidance: Over 15 years, Rajas has helped countless
              students navigate the intricacies of college admissions, both in
              India and abroad.
            </p>
          </div>
          <div className="mt-4">
            <p className="text-sm font-semibold">Exam Preparation</p>
            <p className="text-sm">
              He has developed strategies and resources for standardized tests
              commonly required for admissions, ensuring students are
              well-prepared.
            </p>
          </div>
          <div className="mt-4">
            <p className="text-sm font-semibold">Application Assistance</p>
            <p className="text-sm">
              Rajas specializes in crafting compelling applications, guiding
              students through personal statements, essays, and recommendation
              letters.
            </p>
          </div>
          <div className="mt-4">
            <p className="text-sm font-semibold">Scholarship Advising</p>
            <p className="text-sm">
              He has a strong track record in identifying and securing
              scholarships, helping students finance their education.
            </p>
          </div>
          <div className="mt-4">
            <p className="text-sm font-semibold">
              Study India or Abroad Expertise
            </p>
            <p className="text-sm">
              With in-depth knowledge of international education systems, Rajas
              assists students in exploring and applying to universities across
              various countries.
            </p>
          </div>

          <div className="mt-6 p-4 bg-white border border-[#A3A2AC] rounded-md flex max-md:flex-wrap items-center justify-between md:w-[850px] max-md:w-full">
            <div className="flex items-center">
              <span className="text-blue-700 text-xl mr-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="33"
                  viewBox="0 0 42 33"
                  fill="none"
                >
                  <path
                    d="M41.1056 9.0348C41.1056 9.0348 41.1056 9.34107 41.1056 9.4942C41.1056 9.4942 41.1056 9.64733 41.1056 9.80046C41.1056 10.413 40.9528 10.8724 40.8 11.4849C40.1887 13.1694 39.1191 14.5476 37.4382 15.7726H37.2854C37.2854 15.9258 38.0494 23.2761 38.0494 23.2761L31.9371 18.3759H31.7842H31.6314C31.3258 18.3759 29.4921 18.529 29.0337 18.529C28.2696 18.529 27.3528 18.529 26.5887 18.3759C26.5887 18.0696 26.5887 17.7633 26.5887 17.4571C26.5887 16.0789 26.2831 14.7007 25.5191 13.4756C24.9078 12.2506 23.8382 11.1787 22.6157 10.2599C20.9348 9.0348 18.9483 8.11601 16.809 7.65661C17.2674 5.81902 18.337 4.13457 20.1708 2.75638C22.4629 0.918794 25.6719 0 28.8809 0C34.8404 0 40.0359 3.21578 41.1056 7.80974C41.1056 8.26914 41.2584 8.88167 41.2584 9.34107L41.1056 9.0348Z"
                    fill="#42BB93"
                  />
                  <path
                    d="M21.0876 11.4849C19.5595 10.2599 17.7258 9.4942 15.7393 9.0348C15.5865 9.0348 15.4337 9.0348 15.2809 9.0348C15.1281 9.0348 14.9753 9.0348 14.8225 9.0348C14.0584 9.0348 13.1416 8.88167 12.3775 8.88167C6.41798 8.88167 1.22247 12.0975 0.152808 16.6914C0.152808 17.1508 0 17.7633 0 18.2227C0 18.6821 0 18.529 0 18.6821C0 18.6821 0 18.8353 0 18.9884C0 19.6009 0.152809 20.0603 0.305618 20.6729C0.916854 22.3573 1.98652 23.7355 3.66742 24.9606H3.82022C3.82022 25.1137 3.05618 32.464 3.05618 32.464L9.32135 27.4107H9.62697C9.93258 27.4107 11.7663 27.7169 12.2247 27.7169C15.5865 27.7169 18.6427 26.7981 20.9348 24.9606C22.7685 23.5824 23.8382 21.8979 24.2966 20.0603C24.2966 19.9072 24.2966 19.7541 24.2966 19.6009C24.2966 19.4478 24.2966 19.2947 24.2966 19.1415C24.2966 18.8353 24.2966 18.6821 24.2966 18.3759C24.2966 15.9258 23.0742 13.6288 20.782 11.7912L21.0876 11.4849ZM6.11236 20.0603C4.88989 20.0603 3.97303 19.1415 3.97303 17.9165C3.97303 16.6914 4.88989 15.7726 6.11236 15.7726C7.33483 15.7726 8.25169 16.6914 8.25169 17.9165C8.25169 19.1415 7.33483 20.0603 6.11236 20.0603ZM12.2247 20.0603C11.0022 20.0603 10.0854 19.1415 10.0854 17.9165C10.0854 16.6914 11.0022 15.7726 12.2247 15.7726C13.4472 15.7726 14.364 16.6914 14.364 17.9165C14.364 19.1415 13.4472 20.0603 12.2247 20.0603ZM18.4899 20.0603C17.2674 20.0603 16.3506 19.1415 16.3506 17.9165C16.3506 16.6914 17.2674 15.7726 18.4899 15.7726C19.7124 15.7726 20.6292 16.6914 20.6292 17.9165C20.6292 19.1415 19.7124 20.0603 18.4899 20.0603Z"
                    fill="#324BA0"
                  />
                </svg>
              </span>
              <p className="text-sm text-[#383838]">
                <span className="font-semibold">Open to inquiries</span>
                <br />
                You can message Rajashekar to ask questions before booking their
                services.
              </p>
            </div>
            <button className="bg-[#D9F6EF] text-[#383838] px-4 py-2 rounded font-semibold max-md:mt-4 max-md:ml-10 ">
              Get in touch
            </button>
          </div>
        </div>

        <div className="text-gray-800 mt-8">
          <h2 className="text-xl font-semibold text-[#173CBA]">
            Skills and Attributes
          </h2>
          <p className="mt-4 text-sm">
            Personalized Mentorship: Rajas tailors his approach to meet the
            unique needs of each student, considering their academic background
            and career aspirations.
          </p>
          <div className="mt-4">
            <p className="text-sm font-semibold">Networking</p>
            <p className="text-sm">
              He has built relationships with admissions officers and
              educational institutions, providing students with insider
              insights.
            </p>
          </div>
          <div className="mt-4">
            <p className="text-sm font-semibold">Cultural Sensitivity</p>
            <p className="text-sm">
              Rajas understands the diverse backgrounds of students and the
              specific challenges they face in the admissions process.
            </p>
          </div>
          <div className="mt-4">
            <p className="text-sm font-semibold">Workshops and Seminars</p>
            <p className="text-sm">
              He regularly conducts workshops on college admissions, preparing
              students for interviews and application submissions.
            </p>
          </div>
          <div className="mt-4">
            <p className="text-sm font-semibold">Impact</p>
            <p className="text-sm">
              Rajas Shekar’s mentorship has not only guided students toward
              successful admissions but has also inspired many to pursue their
              academic and career goals with confidence. His commitment to
              supporting students through this critical phase of their education
              is evident in the success stories of those he has mentored.
            </p>
          </div>

          <div className="mt-6 flex flex-wrap gap-2">
            {[
              "Skills",
              "Communication",
              "Personalized Guidance",
              "Admissions Expertise",
              "Exam Preparation",
              "Application Assistance",
              "Scholarship Advising",
              "Study Abroad Knowledge",
              "Analytical Skills",
              "Motivational Coaching",
              "Networking",
            ].map((skill) => (
              <span
                key={skill}
                className="px-3 py-1 bg-white text-[#383838] rounded-full text-xs"
              >
                {skill}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorDetailsTabs;
