import React, { useState } from "react";

interface LinkItem {
  text: string;
  url: string;
}

interface AccordionItemProps {
  title: string;
  links: LinkItem[];
}

const AccordionItem: React.FC<AccordionItemProps> = ({ title, links }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-y gray-300 border-">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between w-full py-3 text-left text-white focus:outline-none"
      >
        <span className="text-lg font-semibold">{title}</span>
        <span>{isOpen ? "-" : "+"}</span>
      </button>
      {isOpen && (
        <div className="py-4">
          {/* Mobile View: Display as flex row with dividers */}
          <div className="block text-sm text-gray-200 sm:hidden">
            <div className="flex flex-wrap">
              {links.map((link, index) => (
                <React.Fragment key={index}>
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline"
                  >
                    {link.text}
                  </a>
                  {index < links.length - 1 && <span className="mx-2">|</span>}
                </React.Fragment>
              ))}
            </div>
          </div>

          {/* Desktop View: 5-column grid */}
          <div className="hidden gap-4 text-sm text-gray-200 sm:grid sm:grid-cols-5">
            {links.map((link, index) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="py-2 hover:underline"
              >
                {link.text}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
