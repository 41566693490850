import React, { useState } from "react";

interface GenericSectionProps {
  title: string;
  description: string | FAQItem[];
}

interface FAQItem {
  question: string;
  answer: string;
}

const GenericSection: React.FC<GenericSectionProps> = ({
  title,
  description,
}) => {
  const [openIndex, setOpenIndex] = useState<number | null>(
    Array.isArray(description) && description.length > 0 ? 0 : null
  );

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="flex flex-col gap-6">
      <h3 className="text-3xl font-bold text-blue">{title}</h3>
      <div>
        {Array.isArray(description) ? (
          <div className="flex flex-col gap-4">
            {description.map((faq: FAQItem, index: number) => (
              <div key={index} className="border-b last:border-b-0">
                <button
                  onClick={() => toggleFAQ(index)}
                  className="w-full text-black text-base text-left font-semibold gap-3"
                >
                  <div className="flex items-center gap-3">
                    <span className="text-2xl bg-blue text-white rounded-md flex flex-col px-1 py-0 w-6 h-6 justify-center items-center">
                      {openIndex === index ? "−" : "+"}
                    </span>
                    <span>{faq.question}</span>
                  </div>
                </button>
                {openIndex === index && (
                  <div className="px-6 py-3">
                    <p className="text-black">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div
            className="text-black text-base examDetailsTable examDetailsList flex flex-col gap-4"
            dangerouslySetInnerHTML={{ __html: description as string }}
          />
        )}
      </div>
    </div>
  );
};

export default GenericSection;
