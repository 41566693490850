import React from "react";
import TitleCard from "../../components/TitleCard";
import BlogListItem from "../../components/BlogListItem";
import BlogsBanner from "../../components/BlogsAdBanner";
import BannerImage from "../../../../images/blog-article/blogsAdBanner.png";
import BlogShareFloatingMenu from "../../components/BlogShareFloatingMenu";
import addTopMechanicalEngineering from "../../../../images/courses/top-mechanical-add.svg";
import AmritaHorizontal from "../../../../images/blog-article/Amrita-Horizontal.png";
import AmritaVertical from "../../../../images/blog-article/Amrita-Vertical.png";
import AdityaVertical from "../../../../images/blog-article/Aditya-Vertical.png";
import { Link } from "react-router-dom";
import usePagination from "../../../../components/filter/customHooks/usePagination";
import CustomPagination from "../../../../components/pagination/CustomPagination";
import {
  captilizeFirstLetter,
  splitAndCapitilize,
} from "../../../../util/helpers/helperFunctions";
import RelatedBlogs from "../../blogs-articles-details/sections/RelatedBlogs";
import { GetWindowDimensions } from "../../../../hooks/window-hooks/WindowHooks";
import ViksitBharatScholarshipAd from "../../components/ViksitBharatScholarshipAd";
interface BlogsListContainerProps {
  blogsCategory: any;
  blogsList: any;
  paginationProps: any;
  blogRelated: any[];
  otherNews: any[];
}
const bannerImage2 = "/images/blogsBanner.png";

const BlogsListContainer: React.FC<BlogsListContainerProps> = ({
  blogsCategory,
  blogsList,
  paginationProps,
  blogRelated,
  otherNews,
}) => {
  const handleOnHoverListItem = (e: any) => {
    console.log("category", blogsCategory);
  };

  const { width } = GetWindowDimensions();

  return (
    <>
      <h1 className="text-3xl font-semibold">
        {(blogsCategory && splitAndCapitilize(blogsCategory)) ||
          "Category Name"}
      </h1>
      {blogsList?.results?.length <= 0 && <h1>No results found </h1>}
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 gap-y-4 md:gap-4">
        <div className="flex flex-col gap-4 lg:gap-8 col-span-3">
          <div className="flex flex-col gap-4">
            {blogsList?.results
              ?.slice(0, 3)
              ?.map((blogItem: any, index: any) => (
                <div className="pb-3 border-b border-[#CCCCCC] related-blog last:border-b-0">
                  <BlogListItem
                    parentFunction={handleOnHoverListItem}
                    blogItem={blogItem}
                  />
                </div>
              ))}
          </div>

          {blogsList?.results?.length > 0 && (
            <ViksitBharatScholarshipAd />
            // <div className="w-full py-4 md:block">
            //   <BlogsBanner
            //     width="100%"
            //     height=""
            //     image={AmritaHorizontal}
            //     link="https://www.amrita.edu/"
            //   />
            // </div>
          )}

          <div className="box-border flex flex-col gap-4 p-0 m-0">
            {blogsList?.results?.slice(3)?.map((blogItem: any, index: any) => (
              <div className="p-0 m-0 border-b border-[#CCCCCC] related-blog last:border-b-0">
                <BlogListItem
                  parentFunction={handleOnHoverListItem}
                  blogItem={blogItem}
                />
              </div>
            ))}
          </div>
          <CustomPagination
            currentPage={paginationProps?.currentPage}
            totalPages={paginationProps?.totalPages}
            handlePrevPage={paginationProps?.handlePrevPage}
            handleNextPage={paginationProps?.handleNextPage}
            goToPage={paginationProps?.goToPage}
          />
          {/* <div className="visible p-4 bg-gray-100 md:hidden sm:block">
            Add Section
          </div> */}
          {/* <div className="flex flex-row items-center justify-center py-4">
            <a
              href="courses/list-of--courses-in-india"
              className="white-color map-mentor button-styles arrow-button"
            >
              View All
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="currentColor"
                className="arrow-icon"
              >
                <path
                  d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div> */}
        </div>

        <div>
          {width > 700 && (
            <div className="col-span-1 h-[auto] ">
              {/* <a href="https://www.amrita.edu/" target="_blank">
                <img
                  src={AmritaVertical}
                  alt="Advertisement"
                  className="mx-auto"
                />
              </a> */}
            </div>
          )}
          <RelatedBlogs
            title="Knowledge Hub"
            blogRelated={blogRelated} // Pass the handler function here
            otherNews={otherNews}
          />

          {width > 700 && (
            <div className="col-span-1 h-[auto] ">
              {/* <a href="https://www.amrita.edu/" target="_blank">
                <img
                  src={AdityaVertical}
                  alt="Advertisement"
                  className="mx-auto"
                />
              </a> */}
            </div>
          )}
        </div>
        {/* <div className="hidden p-4 bg-gray-100 md:block sm:hidden">
          <div className="course-quiz-box  bg-[#C7EAFB] pt-4">
            <div>
              <img
                src="/images/course-details/course-quiz-text.png"
                alt="course quiz text"
                className="mx-auto"
              />
            </div>
            <div>
              <img
                src="/images/course-details/course-quiz.png"
                alt="course quiz"
                className=""
              />
            </div>
            <div>
              <Link
                to={"/courses/quiz-modal"}
                className="w-full h-12 bg-[#F6C] text-white justify-end text-xl font-extrabold"
              >
                Take Our Quiz
              </Link>
            </div>
          </div>
          
        </div> */}
      </div>
    </>
  );
};

export default BlogsListContainer;
