import React from "react";
import { Accordion, Option } from "./Accordion";

export interface FilterCategory {
  categoryName: string;
  categoryKey: string;
  options: Option[];
}

interface DesktopFiltersProps {
  filters: FilterCategory[];
  setSelectedVal: (selected: any) => void;
  setFilters: (filters: FilterCategory[]) => void;
}

const DesktopFilters: React.FC<DesktopFiltersProps> = ({
  filters,
  setSelectedVal,
  setFilters,
}) => {
  const onlyFilter = filters?.filter(
    (filter) => filter.categoryKey !== "sortBy"
  );
  const handleFilterChange = (
    value: string,
    filter: FilterCategory,
    index: number
  ) => {
    setSelectedVal((prevSelected: any) => {
      const updatedCategory =
        prevSelected[filter.categoryKey] === value ? "" : value;

      const newSelectedVal = {
        ...prevSelected,
        [filter.categoryKey]: updatedCategory,
      };

      const requestPayload: { [key: string]: string | number } = {};

      Object.keys(newSelectedVal).forEach((key) => {
        const value = newSelectedVal[key];

        if (value) {
          requestPayload[key] = value;
        }
      });

      requestPayload.sortBy = "";
      requestPayload.offset = 0;
      requestPayload.fetchCount = 10;
      requestPayload.search = "";
      console.log("filter change", newSelectedVal);

      return newSelectedVal;
    });

    const updatedFilter = onlyFilter.map((filter, filterIndex) => {
      if (filterIndex === index) {
        const options = filter.options.map((option) => {
          return {
            ...option,
            isSelected: option.key === value,
          };
        });

        console.log("options", options);

        return {
          ...filter,
          options: options,
        };
      }
      return filter;
    });

    setFilters(updatedFilter);
  };

  return (
    <>
      {onlyFilter.map((filter, index) => (
        <Accordion
          key={index}
          filters={{
            label: filter.categoryName,
            options: filter.options,
          }}
          isOpen={false}
          onFilterChange={(value: string) =>
            handleFilterChange(value, filter, index)
          }
          title={filter.categoryName}
        />
      ))}
    </>
  );
};

export default DesktopFilters;
