import React from "react";

const RegulatoryCouncilCards = () => {
  return (
    <div className="flex flex-col">
      {/* AICTE Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#EFF] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            All India Council for Technical Education (AICTE)
          </h2>
          <p>
            The All India Council for Technical Education (AICTE) plays a
            crucial role in overseeing and enhancing technical education in
            India. Here's a summary of what you can find on the AICTE website
          </p>

          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">Approved Institutes </strong>
              Approved Institutes A comprehensive list of state-wise approved
              institutions for various programs, including: Degree and Diploma
              in Engineering and Technology
            </li>
            <ul className="list-disc pl-6  text-black font-normal">
              <li>MBA and MCA</li>
              <li>Pharmacy</li>
              <li>Architecture and Applied Arts</li>
              <li>Hotel Management and Catering Technology</li>
              <li>M.E. / M.Tech. / M.Pharm. / M.Arch.</li>
            </ul>
            <li>
              <strong className="text-[#173CBA]">Accredited Programs</strong>{" "}
              Information on programs accredited by the National Board of
              Accreditation (NBA).
            </li>
            <li>
              <strong className="text-[#173CBA]">Model Curriculum</strong>{" "}
              Guidelines for undergraduate programs, which help institutions
              align their courses with national standards.
            </li>
            <li>
              <strong className="text-[#173CBA]">Recommended Books </strong>A
              list of books and resources for management education.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Updates and Notifications
              </strong>
              A Information about policies, initiatives, and events related to
              technical education.
            </li>
          </ul>
          <p>
            For more detailed information, you can visit their official website:
            <a
              href="https://www.aicte-india.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              https://www.aicte-india.org/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/AICTE.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/* AICTE Card ends */}
      {/* NMC Starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#EEF2FF] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            National Medical Commision (NMC)
          </h2>
          <p>
            National Medical Commision (NMC) The National Medical Commission
            (NMC) in India, which succeeded the Medical Council of India (MCI),
            is responsible for overseeing medical education and practice.
            website
          </p>

          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">
                Recognized Courses and Colleges{" "}
              </strong>
              Recognized Courses and Colleges A searchable database of medical
              courses and colleges recognized by the NMC, allowing users to
              search by university, state, or course.
            </li>

            <li>
              <strong className="text-[#173CBA]">Registration Status</strong>{" "}
              Information on the status of applications for medical
              professionals seeking registration with the NMC.
            </li>
            <li>
              <strong className="text-[#173CBA]">Regulatory Guidelines</strong>{" "}
              Regulatory Guidelines Details on regulations regarding medical
              education standards, examination protocols, and the establishment
              of new medical colleges.
            </li>

            <li>
              <strong className="text-[#173CBA]">
                Updates and Notifications
              </strong>
              Latest news, guidelines, and policies related to medical education
              and practice in India.
            </li>
          </ul>
          <p>
            For more detailed information, you can visit their official website:
            <a
              href="https://www.aicte-india.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              https://www.nmc.org.in/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/NMC.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/* NMC ends */}
      {/* ICAR Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#FFF9E5] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            Indian Council for Agricultural Research (ICAR)
          </h2>
          <p>
            The Indian Council for Agricultural Research (ICAR) plays a pivotal
            role in advancing agricultural research and education in India.
            Here’s an overview of ICAR’s functions and initiatives
          </p>

          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">Research Centers</strong>
              ICAR has established numerous research centers across the country
              to address the diverse agricultural needs and challenges.
            </li>

            <li>
              <strong className="text-[#173CBA]">
                Human Resource Development
              </strong>{" "}
              The council promotes agricultural education by setting up
              agricultural universities throughout India, enhancing the quality
              of education and research in agricultural sciences.
            </li>
            <li>
              <strong className="text-[#173CBA]">Funding Support</strong> ICAR
              provides funding to around 30 State Agricultural Universities, one
              Central University, and several Deemed Universities, facilitating
              their development and research initiatives.
            </li>

            <li>
              <strong className="text-[#173CBA]">Scientific Workforce</strong>
              Approximately 26,000 scientists are employed in these universities
              and research centers, with over 6,000 scientists working on
              ICAR-supported coordinated projects, focusing on teaching,
              research, and extension education.
            </li>
            <li>
              <strong className="text-[#173CBA]">Policy Development</strong>
              ICAR contributes to policy formulation in agriculture, addressing
              food security and sustainable agricultural practices.
            </li>
          </ul>
          <p>
            For more detailed information, you can visit the official ICAR
            website:
            <a
              href=" https://icar.org.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              https://icar.org.in/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/ICAR.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/* ICAR card ends */}
      {/* NCTE Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#FFF1FA] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            National Council for Teacher Education (NCTE)
          </h2>
          <p>
            The National Council for Teacher Education (NCTE) plays a crucial
            role in overseeing and regulating teacher education in India. Here’s
            a summary of its functions and what you can find on the NCTE website
          </p>
          <h3 className="font-semibold my-2">Functions of NCTE</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">
                Coordination and Monitoring
              </strong>{" "}
              Ensures the planned development of the teacher education system
              across the country.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Guidelines for Qualifications
              </strong>{" "}
              Establishes minimum qualifications for individuals aspiring to
              become teachers.
            </li>
            <li>
              <strong className="text-[#173CBA]">Norms for Courses</strong> Sets
              standards for various teacher education courses and trainings.
            </li>
            <li>
              <strong className="text-[#173CBA]">Compliance Guidelines</strong>{" "}
              Provides guidelines for institutions seeking recognition to offer
              new courses or training programs.
            </li>
            <li>
              <strong className="text-[#173CBA]">Examination Standards</strong>{" "}
              Lays down standards for examinations leading to teacher education
              qualifications.
            </li>
            <li>
              <strong className="text-[#173CBA]">Periodic Review</strong>{" "}
              Regularly examines the implementation of norms, guidelines, and
              standards in teacher education.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Institution Recognition
              </strong>{" "}
              Grants recognition to institutions offering teacher education
              programs.
            </li>
            <h3 className="font-semibold my-2">Functions of NCTE</h3>
            <li>
              <strong className="text-[#173CBA]">
                List of Recognized Institutions
              </strong>{" "}
              Detailed information about institutions recognized by NCTE,
              including the courses they offer.
            </li>
            <li>
              <strong className="text-[#173CBA]">Fact Sheets</strong> Summary
              fact sheets about institutions with key details on their courses.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Teacher as a Transformer
              </strong>{" "}
              An engaging section where students can share stories and recall
              teachers who made a significant impact on their lives.
            </li>
          </ul>
          <p>
            For more detailed information, you can visit the official NCTE
            website:
            <a
              href=" https://ncte.gov.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              https://ncte.gov.in/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/NCTE.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/* NCTE card ends */}
      {/* DCI Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#EFF] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            Dental Council of India (DCI)
          </h2>
          <p>
            The Dental Council of India (DCI) is a statutory body established
            under the Dentists Act of 1948, with the primary responsibility of
            regulating dental education and practice across India. Here’s a
            concise overview of its key functions
          </p>
          <h3 className="font-semibold my-2">Functions of DCI</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">
                Recognition of Dental Degrees
              </strong>{" "}
              DCI recognizes dental degrees awarded by various universities,
              ensuring that qualifications meet national standards.
            </li>
            <li>
              <strong className="text-[#173CBA]">Setting Standards</strong> The
              council establishes and maintains uniform standards for dental
              education, ensuring quality across all dental institutions.
            </li>
            <li>
              <strong className="text-[#173CBA]">Minimum Requirements</strong>{" "}
              DCI prescribes minimum requirements for staff qualifications,
              infrastructure, and facilities in dental colleges.
            </li>
            <li>
              <strong className="text-[#173CBA]">Curriculum Development</strong>{" "}
              The council develops the syllabus and examination schemes for
              dental education programs, ensuring they are up-to-date and
              relevant.
            </li>
            <li>
              <strong className="text-[#173CBA]">Regulation of Practice</strong>{" "}
              DCI oversees the professional conduct and practice standards for
              dentists, ensuring ethical practices and patient care.
            </li>
            <li>
              <strong className="text-[#173CBA]">Additional Information</strong>{" "}
              For more detailed information about its functions, recognized
              institutions, and regulations, you can visit the official DCI
              website: DCI.
            </li>
          </ul>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/DCI.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/* DCI card ends */}
      {/* PCI Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#EEF2FF] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            Pharmacy Council of India (PCI)
          </h2>
          <p>
            The Pharmacy Council of India (PCI) is the regulatory authority for
            pharmacy education and practice in India, established under the
            Pharmacy Act of 1948. Here’s an overview of its key functions and
            what you can find on the PCI website
          </p>
          <h3 className="font-semibold my-2">Functions of PCI</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">
                Regulation of Pharmacy Education
              </strong>{" "}
              PCI sets the minimum standards for pharmacy education, ensuring
              quality at the graduate level
            </li>
            <li>
              <strong className="text-[#173CBA]">Training Standards</strong>{" "}
              Prescribes the nature and duration of practical training required
              before taking examinations.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Infrastructure Requirements
              </strong>{" "}
              Specifies the necessary equipment and facilities that must be
              available for students in approved pharmacy programs.
            </li>
            <li>
              <strong className="text-[#173CBA]">Examination Standards</strong>{" "}
              Determines the subjects of examination and the standards that
              students must achieve.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Conditions for Admission
              </strong>{" "}
              Outlines the criteria for admission to pharmacy examinations.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                What’s Available on the PCI Website
              </strong>{" "}
              Approved Institutions: A state-wise list of institutions
              recognized by PCI for degree and diploma programs, along with
              details of approved intake and the duration of approval.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Registration Information
              </strong>{" "}
              Information regarding the registration process for pharmacists,
              which is managed by State Pharmacy Councils.
            </li>
          </ul>
          <p>
            For more detailed information, you can visit the official PCI
            website:
            <a
              href=" https://www.pci.nic.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              https://www.pci.nic.in/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/PCI.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/* PCI card ends */}
      {/* INC Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#FFF9E5] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            Indian Nursing Council (INC)
          </h2>
          <p>
            The Indian Nursing Council (INC) is a statutory body established
            under the Indian Nursing Council Act of 1947, tasked with regulating
            and maintaining uniform standards for nursing education and practice
            in India. Here’s a summary of its key functions
          </p>
          <h3 className="font-semibold my-2">Functions of INC</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">Curriculum Standards</strong>{" "}
              INC prescribes standard curricula for the training of nurses,
              midwives, auxiliary nurse-midwives, and health visitors, as well
              as for training courses for nursing educators and administrators.
            </li>
            <li>
              <strong className="text-[#173CBA]">Admission Conditions</strong>{" "}
              The council sets conditions for admission to various nursing
              courses, ensuring a consistent and quality-driven approach to
              nursing education.
            </li>
            <li>
              <strong className="text-[#173CBA]">Examination Standards</strong>{" "}
              INC establishes standards for examinations and other requirements
              that must be met to secure recognition for nursing programs.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Regulation of Nursing Practice
              </strong>{" "}
              The council also plays a role in regulating the practice of
              nursing to ensure that practitioners meet the required standards
              of care.
            </li>
            <h3 className="font-semibold my-2">
              What’s Available on the INC Website:
            </h3>
            <li>
              <strong className="text-[#173CBA]">
                Information on Recognized
              </strong>{" "}
              Institutions Details about nursing schools and programs recognized
              by the INC.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Guidelines and Regulations
              </strong>{" "}
              Access to various guidelines, regulations, and policies governing
              nursing education and practice.
            </li>
            <li>
              <strong className="text-[#173CBA]">Resources for Nurses</strong>{" "}
              Information and resources aimed at supporting nursing
              professionals and educators.
            </li>
          </ul>
          <p>
            For more detailed information, you can visit the official INC
            website:
            <a
              href=" www.indiannursingcouncil.org"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              www.indiannursingcouncil.org
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/INC.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/* INC card ends */}
      {/* BCI Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#FFF1FA] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            Bar Council of India (BCI)
          </h2>
          <p>
            The Bar Council of India (BCI) is the regulatory authority for the
            legal profession in India, established under the Advocates Act of
            1961. Here’s an overview of its key functions and responsibilities
          </p>
          <h3 className="font-semibold my-2">Functions of BCI</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">Rule-Making Authority</strong>{" "}
              BCI has the power to make rules for regulating the conduct of
              advocates and maintaining standards within the legal profession.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Professional Conduct Guidelines
              </strong>{" "}
              It lays down guidelines for the standards of professional conduct
              and etiquette that advocates must observe.
            </li>
            <li>
              <strong className="text-[#173CBA]">Enrollment Standards </strong>{" "}
              BCI prescribes the criteria for enrollment as advocates,
              determining who is eligible to practice law.
            </li>
            <li>
              <strong className="text-[#173CBA]">Practice Conditions</strong>{" "}
              The council specifies the conditions under which advocates can
              practice and the circumstances that define what constitutes
              practicing as an advocate in a court.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Legal Education Oversight
              </strong>{" "}
              BCI also oversees legal education, ensuring that law colleges meet
              the necessary standards for training future lawyers.
            </li>

            <h3 className="font-semibold my-2">
              What’s Available on the INC Website:
            </h3>
            <li>
              <strong className="text-[#173CBA]">
                Regulations and Guidelines
              </strong>{" "}
              Access to various rules, regulations, and guidelines governing the
              legal profession.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                {" "}
                List of Recognized Law Schools
              </strong>{" "}
              Information about law schools and their accreditation status.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Resources for Advocates
              </strong>{" "}
              Information and resources that support practicing lawyers and
              legal professionals.
            </li>
          </ul>
          <p>
            For more detailed information, you can visit the official BCI
            website:
            <a
              href="www.barcouncilofindia.org"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              www.barcouncilofindia.org
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/BCI.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/* BCI card ends */}
      {/* CCRH Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#EFF] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            Central Council for Research in Homoeopathy (CCRH)
          </h2>
          <p>
            The Central Council for Research in Homoeopathy (CCRH) is a
            statutory body established under the Homoeopathy Central Council Act
            of 1973. Here’s an overview of its key functions and
            responsibilities
          </p>
          <h3 className="font-semibold my-2">Functions of CCRH</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">
                Recognition of Qualifications
              </strong>{" "}
              Recognition of Qualifications CCRH prescribes and recognizes
              qualifications in homeopathy, ensuring that institutions meet the
              necessary standards.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Central Register Maintenance
              </strong>{" "}
              The council maintains a Central Register of Homoeopathy, which
              includes registered practitioners and their qualifications.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Regulation of Institutions
              </strong>{" "}
              Regulation of Institutions Universities and medical institutions
              wishing to grant homeopathic qualifications must apply to the
              council, providing information on their courses and examination
              processes.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Inspection and Assessment
              </strong>{" "}
              CCRH has the authority to appoint inspectors to evaluate
              examinations and assess the facilities of institutions offering
              homeopathy education.
            </li>
            <li>
              <strong className="text-[#173CBA]">Research Promotion </strong>{" "}
              The council is involved in promoting research in homeopathy,
              contributing to the evidence base for homeopathic practices.
            </li>

            <h3 className="font-semibold my-2">
              What’s Available on the CCRH Website:
            </h3>
            <li>
              <strong className="text-[#173CBA]">
                Information on Recognized Institutions
              </strong>{" "}
              Details about institutions approved by the council for homeopathy
              education.
            </li>
            <li>
              <strong className="text-[#173CBA]">Research Publications</strong>{" "}
              Access to research studies and publications related to homeopathy.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Guidelines and Regulations
              </strong>{" "}
              Information on regulations governing homeopathy practice and
              education.
            </li>
          </ul>
          <p>
            For more detailed information, you can visit the official CCRH
            website:
            <a
              href=" www.ccrhindia.nic.in"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              www.ccrhindia.nic.in
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/CCRH.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/* CCRH card ends */}
      {/* CCIM Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#EEF2FF] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            Central Council for Indian Medicine (CCIM)
          </h2>
          <p>
            The Central Council for Indian Medicine (CCIM) is a statutory body
            established under the Indian Medicine Central Council Act of 1970.
            Its primary role is to regulate education and practice in Indian
            systems of medicine, including Ayurveda, Siddha, and Unani Tibb.
            Here’s an overview of its key functions
          </p>
          <h3 className="font-semibold my-2">Functions of CCIM</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">
                Minimum Education Standards
              </strong>{" "}
              CCIM prescribes the minimum educational standards for institutions
              offering courses in Indian medicine.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Central Register Maintenance
              </strong>{" "}
              The council maintains a Central Register of practitioners in
              Indian medicine, ensuring that qualified individuals are
              recognized.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Professional Conduct Standards
              </strong>{" "}
              It establishes standards of professional conduct, etiquette, and a
              code of ethics for practitioners.
            </li>
            <li>
              <strong className="text-[#173CBA]">Inspection Authority</strong>{" "}
              CCIM is empowered to appoint medical inspectors to oversee
              examinations and assess the facilities of educational
              institutions.
            </li>
            <li>
              <strong className="text-[#173CBA]">Regulatory Framework</strong>{" "}
              The council frames regulations regarding the curriculum, duration
              of study, practical training, examination standards, and the
              overall infrastructure required for medical education in Indian
              systems.
            </li>

            <h3 className="font-semibold my-2">
              What’s Available on the CCIM Website:
            </h3>
            <li>
              <strong className="text-[#173CBA]">
                List of Recognized Colleges
              </strong>{" "}
              A comprehensive list of colleges recognized by CCIM for education
              in Ayurveda, Siddha, and Unani Tibb.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Guidelines and Regulations
              </strong>{" "}
              Access to regulations and guidelines governing education and
              practice in Indian medicine.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Resources for Practitioners
              </strong>{" "}
              Information and resources aimed at supporting practitioners and
              institutions in Indian medicine.
            </li>
          </ul>
          <p>
            For more detailed information, you can visit the official CCIM
            website:
            <a
              href=" https://ccimindias.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              https://ccimindias.in/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/CCIM.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/* CCIM card ends */}
      {/* NCISM Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#FFF9E5] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            National Commission for Indian System of Medicine (NCISM)
          </h2>
          <p>
            The National Commission for Indian System of Medicine (NCISM) was
            established following the repeal of the Indian Medicine Central
            Council (IMCC) Act, 1970, and its provisions came into force on June
            11, 2021. Here’s an overview of the aims and objectives of NCISM
          </p>
          <h3 className="font-semibold my-2">Aims and Objectives of NCISM</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">
                Quality Education Access
              </strong>{" "}
              Improve access to quality and affordable education in Ayurveda,
              Unani, Siddha, and Sowa-Rigpa (AUS&SR).
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Availability of Professionals
              </strong>{" "}
              Ensure an adequate supply of high-quality AUS&SR medical
              professionals across the country.
            </li>
            <li>
              <strong className="text-[#173CBA]">Equitable Healthcare</strong>{" "}
              Promote equitable and universal healthcare, making AUS&SR services
              accessible to all citizens, with a focus on community health.
            </li>
            <li>
              <strong className="text-[#173CBA]">Research Integration</strong>{" "}
              Encourage medical professionals to adopt the latest research in
              their practice and contribute to ongoing research.
            </li>
            <li>
              <strong className="text-[#173CBA]">Institution Assessment</strong>{" "}
              Conduct periodic assessments and ratings of medical institutions
              in a transparent manner.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                National Register Maintenance
              </strong>{" "}
              Maintain a national register for AUS&SR medical practitioners in
              India.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Ethical Standards Enforcement
              </strong>{" "}
              Enforce high ethical standards in all aspects of AUS&SR medical
              services.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Grievance Redressal Mechanism
              </strong>{" "}
              Establish an effective grievance redressal mechanism for
              addressing concerns and issues in the sector.
            </li>
          </ul>
          <p>
            For more details about the NCISM and its initiatives, you can visit
            the official website:
            <a
              href=" https://ncismindia.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              https://ncismindia.org/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/NCISM.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/* NCISM card ends */}
      {/* COA Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#FFF1FA] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            Council of Architecture (COA)
          </h2>
          <p>
            The Council of Architecture (COA) is the regulatory authority for
            the architecture profession in India, established under the
            Architects Act of 1972. Here’s an overview of its key functions and
            what you can find on the COA website
          </p>
          <h3 className="font-semibold my-2">Key Functions of COA</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">
                Registration of Architects
              </strong>{" "}
              COA registers individuals as architects, granting them the right
              to practice and use the title "Architect." Only registered
              individuals or firms with all partners registered can use this
              title.
            </li>
            <li>
              <strong className="text-[#173CBA]">Standards of Education</strong>{" "}
              The council prescribes the qualifications and educational
              standards for architecture programs, including eligibility for
              admission, course duration, faculty requirements, and examination
              standards.
            </li>
            <li>
              <strong className="text-[#173CBA]">Regulation of Practice</strong>{" "}
              COA governs the professional conduct of architects through the
              Architects (Professional Conduct) Regulations, 1989, which cover
              ethics, engagement conditions, and competition guidelines.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Inspections and Compliance
              </strong>{" "}
              The council conducts periodic inspections of educational
              institutions to ensure compliance with established standards and
              informs the Central Government about these standards.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Government Recommendations
              </strong>{" "}
              COA can recommend the recognition or de-recognition of
              qualifications to the Government of India.
            </li>
            <h3 className="font-semibold my-2">
              What’s Available on the COA Website
            </h3>
            <li>
              <strong className="text-[#173CBA]">
                Acts, Rules, and Regulations
              </strong>{" "}
              Access to the Architects Act, rules, and regulations governing the
              profession.
            </li>
            <li>
              <strong className="text-[#173CBA]">List of Institutions</strong> A
              comprehensive list of colleges and universities offering
              architecture courses in India.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Architectural Resources
              </strong>{" "}
              Information on architectural design practices, guidelines, and
              important legal judgments related to registration.
            </li>
            <li>
              <strong className="text-[#173CBA]">Events and Activities</strong>{" "}
              Information on ongoing competitions and events in the field of
              architecture.
            </li>
          </ul>
          <p>
            For more detailed information, you can visit the official COA
            website:
            <a
              href="https://www.coa.gov.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              https://www.coa.gov.in/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/COA.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/* COA card ends */}
      {/* RCI Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#EEFFFF] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            Rehabilitation Council of India (RCI)
          </h2>
          <p>
            The Rehabilitation Council of India (RCI) is a statutory body
            established under the Rehabilitation Council of India Act of 1992,
            aimed at regulating and standardizing rehabilitation and special
            education services in India. Here’s an overview of its key functions
            and responsibilities
          </p>
          <h3 className="font-semibold my-2">Key Functions of RCI</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">
                Standardization of Training
              </strong>{" "}
              RCI is responsible for setting standards for the training of
              professionals in the fields of rehabilitation and special
              education.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Regulation of Qualifications
              </strong>{" "}
              The council recognizes qualifications for professionals delivering
              services to individuals with disabilities. Only those with
              RCI-recognized qualifications are allowed to practice in this
              field.
            </li>
            <li>
              <strong className="text-[#173CBA]">Legal Provisions</strong> Legal
              Provisions The RCI Act mandates that anyone providing
              rehabilitation services without recognized qualifications may face
              prosecution, emphasizing the importance of proper training and
              credentialing.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Broad-Based Responsibilities
              </strong>{" "}
              The council’s responsibilities have expanded to encompass a wide
              range of services related to rehabilitation, ensuring
              comprehensive support for individuals with disabilities.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Collaboration with Organizations
              </strong>{" "}
              RCI collaborates with various organizations to enhance the quality
              of rehabilitation services and promote awareness of best
              practices.
            </li>

            <h3 className="font-semibold my-2">
              What’s Available on the RCI Website
            </h3>
            <li>
              <strong className="text-[#173CBA]">
                Information on Recognized
              </strong>{" "}
              Courses Details about training programs and courses recognized by
              RCI for various rehabilitation professions.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Guidelines and Regulations
              </strong>{" "}
              Access to regulations, standards, and policies related to
              rehabilitation and special education.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Resources for Professionals
              </strong>{" "}
              Information and resources for practitioners in the field to stay
              updated on standards and best practices.
            </li>
          </ul>
          <p>
            For more detailed information, you can visit the official RCI
            website:
            <a
              href="https://rehabcouncil.nic.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              https://rehabcouncil.nic.in/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/RCI.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/* RCI card ends */}
      {/*MGNCRE Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#EEF2FF] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            Mahatma Gandhi National Council of Rural Education (MGNCRE)
          </h2>
          <p>
            The Mahatma Gandhi National Council of Rural Education (MGNCRE) is
            an autonomous society established by the Ministry of Human Resource
            Development, Government of India, with the aim of promoting rural
            higher education. Here’s an overview of its key objectives and
            functions
          </p>
          <h3 className="font-semibold my-2">Key Objectives of MGNCRE</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">
                Promote Rural Higher Education
              </strong>{" "}
              MGNCRE focuses on enhancing educational opportunities in rural
              areas to improve livelihoods through education, following Mahatma
              Gandhi's concept of Nai Talim, which emphasizes functional and
              value-based education
            </li>
            <li>
              <strong className="text-[#173CBA]">Teacher Training</strong> The
              council is involved in training teachers to improve the quality of
              education in rural settings.
            </li>
            <li>
              <strong className="text-[#173CBA]">Research and Extension</strong>{" "}
              MGNCRE collaborates with policy-making bodies like UGC and AICTE,
              as well as research organizations such as CSIR, to support
              research and extension activities aimed at rural
              development.MGNCRE collaborates with policy-making bodies like UGC
              and AICTE, as well as research organizations such as CSIR, to
              support research and extension activities aimed at rural
              development.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Networking with Educational Institutions
              </strong>{" "}
              The council encourages educational institutions and voluntary
              organizations to align their development efforts with Gandhian
              educational philosophy.
            </li>
            <li>
              <strong className="text-[#173CBA]">Capacity Building</strong>{" "}
              MGNCRE works on initiatives to build the capacity of educators and
              institutions to effectively address the educational needs of rural
              communities
            </li>

            <h3 className="font-semibold my-2">
              What’s Available on the MGNCRE Website
            </h3>
            <li>
              <strong className="text-[#173CBA]">
                Resources and Publications
              </strong>{" "}
              Access to various resources, reports, and publications related to
              rural education.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Programs and Initiatives
              </strong>{" "}
              Information on ongoing programs and initiatives aimed at promoting
              rural education and teacher training.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Collaborative Opportunities
              </strong>{" "}
              Details on collaboration opportunities for institutions and
              organizations interested in rural education.
            </li>
          </ul>
          <p>
            For more detailed information, you can visit the official MGNCRE
            website: http://www.mgncre.org/
            <a
              href="http://www.mgncre.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              http://www.mgncre.org/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/MGNCRE.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/* MGNCRE card ends */}
      {/*VCI Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#FFF9E5] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            Veterinary Council of India (VCI)
          </h2>
          <p>
            The Veterinary Council of India (VCI) is a statutory body
            established under the Indian Veterinary Council Act of 1984. It
            operates under the Department of Animal Husbandry and Dairying,
            Ministry of Fisheries, Animal Husbandry and Dairying, Government of
            India. Here’s an overview of its key functions and responsibilities
          </p>
          <h3 className="font-semibold my-2">Key Functions of VCI</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">
                Regulation of Veterinary Practice
              </strong>{" "}
              VCI is responsible for regulating veterinary practice in India,
              ensuring that practitioners meet the required standards.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Standards of Veterinary Education
              </strong>{" "}
              The council sets and maintains standards for veterinary education
              across institutions in India, ensuring quality training for future
              veterinarians.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Indian Veterinary Practitioners' Register
              </strong>{" "}
              VCI prepares and maintains a register of qualified veterinary
              practitioners in India, ensuring accountability and professional
              standards.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Recognition of Veterinary Qualifications
              </strong>{" "}
              The council recommends the recognition or withdrawal of
              recognition of veterinary qualifications to the central
              government, overseeing the legitimacy of educational programs.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Electoral Roll Maintenance
              </strong>{" "}
              VCI also maintains the electoral roll for the election of council
              members, facilitating democratic processes within the
              organization.
            </li>

            <h3 className="font-semibold my-2">
              What’s Available on the VCI Website
            </h3>
            <li>
              <strong className="text-[#173CBA]">
                Regulations and Guidelines
              </strong>{" "}
              Access to the regulations and guidelines governing veterinary
              education and practice.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                List of Recognized Institutions
              </strong>{" "}
              Information about veterinary colleges and institutions recognized
              by VCI.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Resources for Practitioners
              </strong>{" "}
              Resources and updates relevant to veterinary professionals in
              India.
            </li>
          </ul>
          <p>
            For more detailed information, you can visit the official VCI
            website:
            <a
              href="https://vci.dahd.gov.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              https://vci.dahd.gov.in/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/VCI.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/* VCI card ends */}
      {/*NCVET Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#FFF1FA] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            National Council of Vocational Education and Training (NCVET)
          </h2>
          <p>
            The National Council of Vocational Education and Training (NCVET) is
            established to enhance the quality and effectiveness of vocational
            education and training in India. Here’s an overview of its key
            objectives and functions
          </p>
          <h3 className="font-semibold my-2">Key Objectives of NCVET</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">Industry Interfacing</strong>{" "}
              NCVET aims to create strong links between vocational education and
              industry to ensure that training meets market demands.
            </li>
            <li>
              <strong className="text-[#173CBA]">Quality Improvement</strong>{" "}
              The council is responsible for the development and qualitative
              improvement of vocational education and training systems.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Regulation and Recognition
              </strong>{" "}
              NCVET grants recognition to awarding bodies and assessment
              agencies, monitoring their performance to ensure standards are
              met.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Skill Information Providers
              </strong>{" "}
              It oversees skill information providers to ensure the accurate
              dissemination of information related to vocational training and
              employment opportunities.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Integration of Regulatory Systems
              </strong>{" "}
              The council works to integrate the fragmented regulatory system in
              vocational education, ensuring consistent quality assurance across
              the training value chain.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Skilled Manpower Availability
              </strong>{" "}
              By enhancing the quality of vocational training, NCVET aims to
              facilitate the availability of highly skilled manpower, improving
              employability and contributing to economic growth.
            </li>

            <h3 className="font-semibold my-2">
              What’s Available on the NCVET Website
            </h3>
            <li>
              <strong className="text-[#173CBA]">
                Guidelines and Regulations
              </strong>{" "}
              Access to policies, guidelines, and regulations governing
              vocational education and training.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                List of Recognized Institutions
              </strong>{" "}
              Information about veterinary colleges and institutions recognized
              by VCI.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Resources for Stakeholders
              </strong>{" "}
              Resources and updates for stakeholders in the vocational education
              sector.
            </li>
          </ul>
          <p>
            For more detailed information, you can visit the official NCVET
            website:
            <a
              href="https://ncvet.gov.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              https://ncvet.gov.in/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/NCVET.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/*NCVET  card ends */}
      {/*ICAI Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#EEFFFF] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            Institute of Chartered Accountants of India (ICAI)
          </h2>
          <p>
            The Institute of Chartered Accountants of India (ICAI) is a
            statutory body established under the Chartered Accountants Act,
            1949, for the regulation and development of the chartered
            accountancy profession in India. It operates under the Ministry of
            Corporate Affairs and is the largest professional body of chartered
            accountants globally.
          </p>
          <h3 className="font-semibold my-2">Key Features</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">Governance</strong> Managed by
              a Council of 40 members—32 elected by chartered accountants and 8
              nominated by the Central Government.
            </li>
            <li>
              <strong className="text-[#173CBA]">Recognition</strong> Esteemed
              for maintaining high standards in technical and ethical practices,
              as well as rigorous examination and education standards.
            </li>
            <p className="text-[#173CBA] font-semibold">
              Functions
              <ul className="list-disc pl-6  text-black font-normal">
                <li>Regulates the profession of accountancy.</li>
                <li>
                  Oversees education and examination for the Chartered
                  Accountancy course.
                </li>
                <li>Provides continuing professional education for members.</li>
                <li>Conducts post-qualification courses.</li>
                <li>
                  Formulates accounting standards and prescribes auditing
                  procedures.
                </li>
                <li>
                  Establishes ethical standards and monitors quality through
                  peer reviews.
                </li>
                <li>
                  Exercises disciplinary jurisdiction and reviews financial
                  reporting.
                </li>
                <li>Advises the government on policy matters.</li>
                <li>
                  The ICAI plays a crucial role in enhancing the credibility and
                  effectiveness of the accounting profession in India.
                </li>
              </ul>
            </p>
          </ul>
          <p>
            For more detailed information, you can visit the official ICAI
            website:
            <a
              href="https://www.icai.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              https://www.icai.org/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/ICAI.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/*ICAI  card ends */}
      {/*NBA Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#EEF2FF] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            National Board of Accreditation (NBA)
          </h2>
          <p>
            The National Board of Accreditation (NBA) in India was established
            to enhance the quality of technical education through a robust
            accreditation process. Here’s an overview of its background,
            objectives, and functions
          </p>
          <h3 className="font-semibold my-2">Background</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">Initial Establishment</strong>{" "}
              Initial Establishment NBA was initially set up by the All India
              Council of Technical Education (AICTE) in 1994 to assess the
              quality of programs offered by educational institutions at various
              levels.
            </li>
            <li>
              <strong className="text-[#173CBA]">Independent Body</strong> As of
              January 7, 2010, NBA became an independent autonomous body,
              completely independent of AICTE in administrative and financial
              matters, following amendments to its Memorandum of Association in
              April 2013.
            </li>

            <h3 className="font-semibold my-2">Objectives</h3>
            <li>
              <strong className="text-[#173CBA]">Quality Assurance</strong> The
              primary objective of NBA is to ensure the quality and relevance of
              technical education programs, particularly in engineering,
              technology, management, architecture, pharmacy, and hotel
              management.
            </li>
            <li>
              <strong className="text-[#173CBA]">Industry Alignment</strong> NBA
              aims to ensure that educational programs meet industry
              requirements, producing graduates equipped with the necessary
              skills and competencies.
            </li>
            <h3 className="font-semibold my-2">Key Features</h3>
            <li>
              <strong className="text-[#173CBA]">Accreditation Process</strong>
              NBA evaluates technical programs based on established norms,
              considering various factors such as institutional mission,
              governance, infrastructure, teaching quality, curriculum design,
              and support services.
            </li>
            <li>
              <strong className="text-[#173CBA]"> Evaluation Criteria</strong>
              The evaluation includes parameters that assess educational
              outcomes and program effectiveness, aligning with international
              best practices.
            </li>
            <li>
              <strong className="text-[#173CBA]">Continuous Improvement</strong>
              NBA regularly updates its processes, parameters, and criteria for
              accreditation to maintain relevance and quality in technical
              education.
            </li>
            <h3 className="font-semibold my-2">Importance of NBA</h3>
            <li>
              <strong className="text-[#173CBA]">
                Enhancing Educational Standards
              </strong>
              Through its accreditation process, NBA plays a critical role in
              raising the standards of technical education in India.
            </li>
          </ul>
          <p>
            For more detailed information about its accreditation process,
            guidelines, and updates, you can visit the official NBA website :
            <a
              href="https://www.nbaind.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              https://www.nbaind.org/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/NBA.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/*NBA  card ends */}
      {/*NAAC Card starts */}
      <div className="w-full max-md:gap-5 my-[6px] px-20 py-16  max-md:px-5 bg-[#FFF9E5] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">
            National Assessment and Accreditation Council (NAAC)
          </h2>
          <p>
            The National Assessment and Accreditation Council (NAAC) is an
            autonomous body established by the University Grants Commission
            (UGC) in India. Its primary role is to assess and accredit higher
            education institutions to ensure quality and enhance the standards
            of education. Here’s an overview of NAAC’s key functions and
            objectives
          </p>
          <h3 className="font-semibold my-2">Key Features</h3>
          <ul className="leading-9 list-none">
            <li>
              <strong className="text-[#173CBA]">
                Assessment and Accreditation
              </strong>{" "}
              NAAC evaluates institutions based on various criteria, including
            </li>
            <li>
              <strong className="text-[#173CBA]">Teaching and Learning</strong>{" "}
              Quality of education delivery, curriculum design, and student
              engagement.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Research and Innovation
              </strong>{" "}
              Research output, contributions to knowledge, and innovation
              initiatives.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Infrastructure and Resources
              </strong>{" "}
              Availability and adequacy of physical and academic resources, such
              as libraries, laboratories, and facilities.
            </li>
            <li>
              <strong className="text-[#173CBA]">
                Governance and Leadership
              </strong>{" "}
              Institutional governance, leadership, and strategic planning.
            </li>
            <li>
              <strong className="text-[#173CBA]">Quality Enhancement</strong>{" "}
              NAAC aims to promote a culture of quality in higher education by
              providing institutions with a framework for continuous
              improvement.
            </li>

            <h3 className="font-semibold my-2">Importance of NAAC</h3>
            <li>
              <strong className="text-[#173CBA]">National Standards</strong>{" "}
              NAAC sets benchmarks for quality assurance in higher education,
              contributing to the overall improvement of educational standards
              in India.
            </li>
            <li>
              <strong className="text-[#173CBA]">Accountability</strong> Through
              accreditation, NAAC holds institutions accountable for their
              educational practices and outcomes.
            </li>
            <li>
              <strong className="text-[#173CBA]">Global Recognition</strong>{" "}
              Accreditation from NAAC can enhance the credibility of
              institutions, making them more competitive in the global education
              landscape.
            </li>
          </ul>
          <p>
            For more detailed information you can visit the official NAAC
            website:
            <a
              href="http://naac.gov.in/index.php/en/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              http://naac.gov.in/index.php/en/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/NAAC.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/*NAAC  card ends */}
      {/*ARIIA Card starts */}
      <div className="w-full  my-[6px] px-20 py-16  max-md:px-5 bg-[#FFF1FA] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <h2 className="text-2xl font-bold text-[#173CBA]">ARIIA</h2>
          <p>
            which stands for Atal Ranking of Institutions on Innovation
            Achievements, is an initiative launched in 2019 by the Ministry of
            Education in India. It is implemented by the All India Council for
            Technical Education (AICTE) and the Ministry of Education's
            Innovation Cell. The primary purpose of ARIIA is to assess and rank
            higher education institutions based on their innovation and
            entrepreneurial activities.
          </p>
          <span className="text-2xl font-bold text-[#173CBA]">
            {" "}
            In its inaugural edition
          </span>
          <p>
            ARIIA saw participation from 496 renowned institutions, reflecting
            its importance in promoting a culture of innovation in the education
            sector. The initiative aims to encourage institutions to enhance
            their innovation capabilities by providing a transparent mechanism
            for evaluation and ranking. This process not only fosters healthy
            competition among institutions but also offers valuable insights for
            students, parents, and policymakers, guiding their decisions
            regarding educational choices.
          </p>
          <span className="text-2xl font-bold text-[#173CBA]">Overall</span>
          <p>
            ARIIA plays a crucial role in strengthening the innovation ecosystem
            within Indian higher education, motivating institutions to
            prioritize and develop their innovative potential.
          </p>

          <p>
            For more detailed information, you can visit the official ARIIA
            website:
            <a
              href="http://www.ariia.gov.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              http://www.ariia.gov.in/
            </a>
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-[30%] flex justify-center  my-auto mx-auto">
          <img
            src="/images/regulatory-professionals/ARIIA.png"
            alt="UGC Logo"
            className=""
          />
        </div>
      </div>
      {/*ARIIA  card ends */}
      {/*SCHE Card starts */}
      <div className="w-full   px-20 py-16  max-md:px-5 bg-[#F4F4F4] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8">
          <p>
            <h2 className="text-2xl font-bold text-[#173CBA]">
              State Councils of Higher Education
            </h2>
            Following the National Policy on Education, respective state
            governments have established State Councils of Higher Education
            (SCHE). These councils prepare coordinated programmes of development
            of higher education in each state. Thus they seek to consolidate the
            efforts and investments of institutions of higher education with the
            state.
          </p>
        </div>
      </div>
      {/*SCHE card ends */}
      {/*APSCHE Card starts */}
      <div className="w-full   px-20 py-16  max-md:px-5 bg-[#FFF9E5] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8 max-md:w-full">
          <div className="flex flex-col text-base leading-7 text-black">
            <h3 className="text-lg font-semibold leading-9 max-md:max-w-full">
              Andhra Pradesh State Council of Higher Education
            </h3>
            <p className="mt-1 ">
              <span className="font-semibold">Address</span>: 1st floor, JNTU
              Masab Tank Campus, Mahaver Marg, Opp. Mahaver Hospital, Hyderabad-
              500 028. Andhra Pradesh
            </p>
            <p className="mt-1 ">
              <span className="font-semibold">Phone</span>: 23311594
            </p>
            <p className="mt-1 ">
              <span className="font-semibold">Email Id</span>:
              apsche2003@yahoo.co.in, apsche@ap.nic.in
            </p>
            <p className="mt-1 ">
              <span className="font-semibold">Website</span>:
              https://apsche.ap.gov.in/
            </p>
          </div>
        </div>
      </div>
      {/*APSCHE  card ends */}
      {/*KSHEC Card starts */}
      <div className="w-full   px-20 py-16  max-md:px-5 bg-[#FFF1FA] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8 max-md:w-full">
          <div className="flex flex-col text-base leading-7 text-black">
            <h3 className=" text-lg font-semibold leading-9">
              Kerala State Higher Education Council
            </h3>
            <p className="text-base font-semibold leading-9">
              Science and Technology Museum Campus
            </p>
            <p className="mt-1 ">
              <span className="font-semibold">Address </span>: Vikas Bhavan
              P.O., Thiruvananthapuram-695033, Kerala, India
            </p>
            <p className="mt-1 ">
              <span className="font-semibold">Phone</span> : 0471 2301290,
              2301291, 2301292, 2301293 <strong> / Fax </strong>: 0471 2301290
            </p>
            <p className="mt-1 ">
              <span className="font-semibold">Email Id</span>:
              heckerala@gmail.com
            </p>
            <p className="mt-1 ">
              <span className="font-semibold">Website</span>:
              http://www.kshec.kerala.gov.in
            </p>
          </div>
        </div>
      </div>
      {/*KSHEC  card ends */}
      <div className="w-full   px-20 py-16  max-md:px-5 bg-[#EEFFFF] text-black flex flex-col-reverse md:flex-row md:items-start md:space-x-8 ">
        {/* Text Content */}
        <div className="md:w-[70%] leading-8 max-md:w-full">
          <div className="flex flex-col text-base leading-7 text-black">
            <h3 className=" text-lg font-semibold leading-9">
              Tamil Nadu State Council for Higher Education (TANSCHE)
            </h3>
            <p className="text-base font-semibold leading-9">
              Lady Willingdon College Campus
            </p>
            <p className="mt-1 ">
              <span className="font-semibold">Address </span>: Kamarajar Salai,
              Triplicane, Chennai-600 005 (Tamil Nadu)
            </p>
            <p className="mt-1 ">
              <span className="font-semibold">Phone</span> : 28440961/ 28446486{" "}
              <strong> / Fax </strong>:044 - 28446486
            </p>
            <p className="mt-1 ">
              <span className="font-semibold">Email Id</span>:
              tansche_edu@yahoo.co.in
            </p>
            <p className="mt-1 ">
              <span className="font-semibold">Website</span>:
              https://www.tn.gov.in/detail_contact/7348/5
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegulatoryCouncilCards;
