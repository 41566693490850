import { Link } from "react-router-dom";
import "./CareerDetailsStyles.scss";

const CareerPaths: React.FC<{ careerPaths: any; topColleges: any }> = ({
  careerPaths,
  topColleges,
}) => {
  if (!careerPaths) return null;

  return (
    <section>
      <div className="container mx-auto">
        <div className="bg-white relative">
          <div className="flex flex-col lg:flex-row justify-between md:pr-8 gap-6">
            <div className="flex flex-col w-full lg:w-4/5">
              <div className="flex max-md:flex-col max-md:w-full">
                <h3 className="text-xl max-md:text-center lg:text-2xl font-semibold text-white bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0">
                  {careerPaths.title}
                </h3>
              </div>
              <div
                className="text-black text-base leading-7 flex flex-col gap-5 py-5 px-8 careerPathsDescription"
                dangerouslySetInnerHTML={{
                  __html: careerPaths.description as string,
                }}
              ></div>
            </div>
            <div className="hidden lg:flex flex-col items-center justify-center w-full lg:w-1/5 relative bg-[#FFF1FA]">
              <div className="py-4  bg-[#FFF1FA]">
                <div className="relative">
                  <img src="/images/course-details/medical-course.png" alt="" />
                  <div
                    className="w-[189px] h-[75px] bg-[#fff] absolute bottom-0 right-0"
                    style={{ borderRadius: "25px 0px 0px 0px" }}
                  >
                    <h3 className="text-[#383838] text-base font-normal p-3">
                      Top Medical Courses Without <strong> NEET</strong>
                    </h3>
                  </div>
                </div>
                <Link
                  to={"/blogdetails-page"}
                  className="flex mx-auto  text-white py-1 text-sm px-3 bg-[#173CBA] rounded-full group w-[125px] mt-2"
                >
                  <span className="mr-2">Read more</span>
                  <div className="bg-[#0C9] text-[#fff] rounded-full p-1 group-hover:translate-x-2 transition-transform duration-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.293 4.293a1 1 0 011.414 0L16 9.586a1 1 0 010 1.414l-5.293 5.293a1 1 0 01-1.414-1.414L13.586 11H4a1 1 0 110-2h9.586L9.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          {/* Top Colleges Section */}
          <div className="top-colleges mt-10">
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
              {topColleges?.map((college: any, index: number) => (
                <div
                  key={index}
                  className="flex flex-col items-center p-4 border rounded-lg shadow-sm"
                >
                  <img
                    src={college.imageUrl}
                    alt={college.collegeName}
                    className="w-16 h-16 object-cover mb-4"
                  />
                  <p className="text-center font-semibold">
                    {college.collegeName}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareerPaths;
