import React from "react";
import FacilitySection from "../campusfacilities/FacilitySection";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import CampusLatestUpdates from "../campusfacilities/CampusLatestUpdates";
import MultiCampusOverview from "../campusfacilities/MultiCampusOverview";

interface CampusFacilitiesTabProps {
  facilitiesData: {
    key_0?: {
      title: string;
      description: string;
    };
    key_1?: {
      title: string;
      description: string;
      array: Array<{ title: string; description: string }>;
    };
    key_4?: { title: string; description: string };
    key_5?: { title: string; description: string };
    key_7?: { title: string; description: string };
    key_6?: { title: string; description: string };
    key_9?: { title: string; description: string };
    key_13?: { title: string; description: string };
    key_12?: { title: string; description: string };
    key_11?: { title: string; description: string };
    key_8?: { title: string; description: string };
    similarColleges?: Array<{
      image: string;
      name: string;
      logo: string;
      location: string;
      title: string;
    }>;
  };
}

const CampusFacilitiesTab: React.FC<CampusFacilitiesTabProps> = ({
  facilitiesData,
}) => {
  const facilities = [
    facilitiesData.key_4,
    facilitiesData.key_5,
    facilitiesData.key_7,
    facilitiesData.key_6,
    facilitiesData.key_9,
    facilitiesData.key_13,
    facilitiesData.key_12,
    facilitiesData.key_11,
    facilitiesData.key_8,
  ].filter(
    (facility): facility is { title: string; description: string } =>
      facility !== undefined &&
      facility.title !== undefined &&
      facility.description !== undefined
  );

  return (
    <div className="py-10 bg-[#f4f4f4]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Campus Latest Updates */}
          <div className="w-full lg:w-9/12">
            {facilitiesData.key_0 && (
              <CampusLatestUpdates
                title={facilitiesData.key_0.title}
                description={facilitiesData.key_0.description}
              />
            )}
          </div>
        </div>
      </div>

      {/* Render Facility Sections */}
      {facilities.map((facility, index) => (
        <FacilitySection
          key={`${facility.title}-${index}`}
          title={facility.title}
          description={facility.description}
          index={index}
          tabType="campusFacilities"
        />
      ))}
      <div className="container mx-auto mt-10">
        {/* Render MultiCampusOverview */}
        {facilitiesData.key_1 && (
          <MultiCampusOverview
            title={facilitiesData.key_1.title}
            description={facilitiesData.key_1.description}
            array={facilitiesData.key_1.array}
          />
        )}
      </div>

      {/* Render Similar Colleges */}
      {facilitiesData.similarColleges &&
        facilitiesData.similarColleges.length > 0 && (
          <SimilarEngineeringColleges
            colleges={facilitiesData.similarColleges}
          />
        )}
    </div>
  );
};

export default CampusFacilitiesTab;
