import MentorCard from "./MentorCard";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../util/config";

interface Mentor {
  id: number;
  imageUrl: string;
  name: string;
  role: string;
  experiance: string;
  worksOn: string;
  location: string;
}
const Mentors = () => {
  const [mentorsDatao, setMentorsDatao] = useState<Mentor[]>([]);

  useEffect(() => {
    axios({
      url: config.apiUrl + "/api/auth/user/mentorList",
      method: "GET",
    })
      .then((response) => {
        setMentorsDatao(response.data.data);
        console.log(mentorsDatao);
      })
      .catch((error) => {
        console.error("Error fetching mentor data:", error);
      });
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 m-2">
      {mentorsDatao.map((mentor, index) => (
        <MentorCard
          key={index}
          id={mentor.id}
          imageUrl={mentor.imageUrl}
          name={mentor.name}
          role={mentor.role}
          experiance={mentor.experiance}
          worksOn={mentor.worksOn}
          location={mentor.location}
        />
      ))}
    </div>
  );
};

export default Mentors;
