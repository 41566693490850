import React, { useState, useEffect, useRef } from 'react';
import CarouselModule from 'react-spring-3d-carousel';
import { config } from 'react-spring';

// Define the types for the props
interface Card {
  key: number; // Key for each card
  content: JSX.Element; // Content of the card (React element)
}

interface CarrousselProps {
  cards: Card[]; // Array of cards
  width: string; // Width of the carousel
  height: string; // Height of the carousel
  margin: string; // Margin for the carousel
  offset?: number; // Optional offset value
  showArrows?: boolean; // Optional show arrows
  goToSlide?: number; // Optional initial slide index
}

// Use Carousel directly if it's exported as a default export
const Carousel = (CarouselModule as any).default || CarouselModule;

const Carroussel: React.FC<CarrousselProps> = (props) => {
  const [offsetRadius, setOffsetRadius] = useState(props.offset || 2); // Default value
  const [showArrows, setShowArrows] = useState(props.showArrows || false); // Default value
  const [goToSlide, setGoToSlide] = useState(props.goToSlide || 0); // Default value
  const [cards, setCards] = useState(props.cards.map((element, index) => ({ ...element, key: index })));
  const [isHovered, setIsHovered] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Type for interval reference

  // Update cards when props change
  useEffect(() => {
    setCards(props.cards.map((element, index) => ({ ...element, key: index })));
  }, [props.cards]);

  useEffect(() => {
    setOffsetRadius(props.offset || 2); // Ensure there's a fallback
    setShowArrows(props.showArrows || false); // Ensure there's a fallback
  }, [props.offset, props.showArrows]);

  useEffect(() => {
    setGoToSlide(props.goToSlide || 0); // Ensure there's a fallback
  }, [props.goToSlide]);

  useEffect(() => {
    if (!isHovered) {
      intervalRef.current = setInterval(() => {
        setGoToSlide((prevSlide) => (prevSlide + 1) % cards.length);
      }, 3000); // Change slide every 3 seconds
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current); // Cleanup interval on component unmount or hover
    };
  }, [isHovered, cards.length]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (intervalRef.current) clearInterval(intervalRef.current);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const highlightStyle: React.CSSProperties = {
    transform: 'scale(1.1)',
    transition: 'transform 0.3s',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)',
  };

  return (
    <div
      style={{ width: props.width, height: props.height, margin: props.margin }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Carousel
        slides={cards.map((card, index) => ({
          ...card,
          content: (
            <div style={index === goToSlide ? highlightStyle : {}}>
              {card.content}
            </div>
          ),
        }))}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.gentle}
      />
    </div>
  );
};

export default Carroussel;
