import React from "react";

export default function MentorBanner() {
  return (
    <div className="lg:h-[634px] md:h-auto bg-gradient-to-b from-[#edfcff] to-[#89c9ed]  min-max-xss:pb-7 min-max-xs:pb-7">
      <div className="container flex min-max-xss:flex-col w-full min-max-xs:flex-col ">
        <div className="lg:w-2/5 md:w-full flex flex-col justify-center lg:pl-10  pr-5 min-max-xss:mb-10 min-max-xs:mb-10">
          <h1 className="text-4xl font-bold text-[#383838] mb-7 mt-28">
            Every Student Deserves an Inspiring Mentor
          </h1>

          <p className=" text-[#747474] text-xl mb-7">
            Through mentoring, students become the architects of their own
            careers, with guidance serving as the blueprint to shape their
            journey towards excellence.
          </p>

          <button className="w-60 px-3 py-[5px] text-white bg-[#0C9] text-base rounded-full hover:bg-green-600 transition duration-300">
            Explore Membership →
          </button>
        </div>

        <div className="lg:relative lg:w-3/5 md:w-full lg:flex md:flex-col items-center lg:justify-center ">
          <div className="lg:absolute lg:top-24 lg:left-12">
            <div className="relative md:flex md:flex-col">
              <div className="lg:absolute lg:top-1/4 md:mx-auto min-max-xss:mx-auto min-max-xs:mx-auto min-max-xss:mb-4 min-max-xs:mb-4 lg:left-56 transform lg:-translate-x-1/2 w-32 h-32 bg-[#D9D9D9] rounded-full  border-4 border-white"></div>

              <div className="lg:absolute lg:top-40 lg:left-1/4 lg:w-[455px] md:w-[330px] h-24 bg-white rounded-lg shadow"></div>
            </div>
          </div>

          <div className="lg:absolute lg:top-[370px] lg:left-[200px]">
            <div className="relative md:flex md:flex-col">
              <div className="lg:absolute lg:top-1/4 md:mx-auto min-max-xss:mx-auto min-max-xs:mx-auto min-max-xss:mb-4 min-max-xs:mb-4 lg:left-56 transform lg:-translate-x-1/2 w-32 h-32 bg-[#D9D9D9] rounded-full "></div>

              <div className="lg:absolute lg:top-40 lg:left-1/4 lg:w-[455px] md:w-[330px] h-24 bg-white rounded-lg shadow"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
