import React, { useState, useCallback, useEffect } from "react";
import "./ExploreTopAdmissionMentors.scss";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay } from "swiper/modules";

import type { SVGProps } from "react";
import { Link, useNavigate } from "react-router-dom";

interface ExploreTopAdmission {
  title: string;
  caption: string;
}

interface TopMentorsListProps {
  data: {
    admissionMentors: {
      mentors: Array<{
        profession: string;
        imageUrl: string;
        name: string;
        location: string;
        id: number;
      }>;
    };
  };
}

const ExploreTopAdmissionContent: ExploreTopAdmission[] = [
  {
    title: "Explore Top Admission Mentors",
    caption: "Get personalized guidance from top admission mentors",
  },
];

const ExploreTopAdmissionMentors: React.FC<TopMentorsListProps> = ({
  data,
}) => {
  const topAdmissionMentors = data.admissionMentors.mentors;
  const [swiperRef, setSwiperRef] = useState<SwiperClass | undefined>();
  const [navBtnDisabled, setNavBtnDisabled] = useState({
    prev: true,
    next: false,
  });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [isPrevHovered, setIsPrevHovered] = useState(false);

  useEffect(() => {
    if (swiperRef && swiperRef.slides) {
      setTotalSlides(swiperRef.slides.length);
    }
  }, [swiperRef]);

  const handleSlideChange = () => {
    if (swiperRef) {
      setCurrentSlide(swiperRef.realIndex || 0);
      setNavBtnDisabled({
        prev: swiperRef.isBeginning,
        next: swiperRef.isEnd,
      });
    }
  };

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  const progressBarElement = (
    <div className="flex gap-4 items-center">
      <span>{currentSlide + 1}</span>
      <div className="w-24 h-1 bg-[#D9D9D9]">
        <div
          className="h-full bg-[#173CBA]"
          style={{
            width: totalSlides
              ? ((currentSlide + 1) / totalSlides) * 100 + "%"
              : "0%",
          }}
        ></div>
      </div>
      <span>{totalSlides}</span>
    </div>
  );

  const prevButton = (
    <button
      className="p-2.5"
      onClick={handlePrevious}
      onMouseEnter={() => setIsPrevHovered(true)}
      onMouseLeave={() => setIsPrevHovered(false)}
    >
      <LeftArrow />
    </button>
  );

  const nextButton = (
    <button className="p-2.5" onClick={handleNext}>
      <RightArrow isPrevHovered={isPrevHovered} />
    </button>
  );
  const navigate = useNavigate();
  const handleMentorClick = (mentorId: number) => {
    navigate(`/mentor/${mentorId}`);
  };

  return (
    <div className="bg-[#FFFAFA] flex py-9">
      <div className="container mx-auto">
        <div className="flex flex-col">
          {ExploreTopAdmissionContent.map((content) => (
            <div key={content.title} className="flex flex-col gap-2">
              <h3 className="text-4xl md:text-5xl font-medium text-black text-center md:text-left">
                {content.title}
              </h3>
              <p className="text-grey text-lg hidden md:block">
                {content.caption}
              </p>
            </div>
          ))}
          <div className="flex flex-col py-8">
            <Swiper
              onSwiper={setSwiperRef}
              onSlideChange={handleSlideChange}
              spaceBetween={30}
              className="w-full topAdministator pb-24"
              loop={true}
              modules={[Autoplay]}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 4,
                },
                1280: {
                  slidesPerView: 5,
                  autoplay: false,
                },
              }}
            >
              {topAdmissionMentors.map((mentor, index) => (
                <SwiperSlide key={mentor.id}>
                  <div
                    className={`flex flex-col items-center relative max-md:w-60 xl:w-60 mx-auto ${
                      index === 0 ? "firstslide" : ""
                    } ${index === 1 ? "secondslide" : ""} ${
                      index === 2 ? "thirdslide" : ""
                    } ${index === 3 ? "fourthslide" : ""} ${
                      index === 4 ? "fifthslide" : ""
                    }`}
                  >
                    <div className="h-60 xl:w-60 lg:h-64 overflow-hidden relative ">
                      <div>
                        <img
                          src={mentor.imageUrl}
                          alt={mentor.name}
                          className="rounded-2xl overflow-hidden w-full h-full object-cover"
                        />
                      </div>
                    </div>
                    <div className="absolute -bottom-12 bg-white p-3.5 rounded-2xl shadow-md mx-auto w-4/5 lg:w-[85%] gap-1">
                      <div className="flex flex-col items-center justify-center">
                        <h4 className="text-base font-bold text-black">
                          {mentor.name}
                        </h4>
                        <p className="text-black text-xs leading-5 text-center">
                          {mentor.location}
                        </p>
                      </div>

                      <Link to={`/mentor/${mentor.id}`}>
                        <div className="absolute w-8 h-8 bg-[#00B489] cursor-pointer rounded-full right-1 bottom-1 text-center flex items-center justify-center transform transition-transform duration-300 hover:translate-x-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="12"
                            viewBox="0 0 15 12"
                            fill="none"
                          >
                            <path
                              d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM14.5303 6.53033C14.8232 6.23744 14.8232 5.76256 14.5303 5.46967L9.75736 0.6967C9.46447 0.403806 8.98959 0.403806 8.6967 0.6967C8.40381 0.989593 8.40381 1.46447 8.6967 1.75736L12.9393 6L8.6967 10.2426C8.40381 10.5355 8.40381 11.0104 8.6967 11.3033C8.98959 11.5962 9.46447 11.5962 9.75736 11.3033L14.5303 6.53033ZM1 6.75L14 6.75L14 5.25L1 5.25L1 6.75Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="flex justify-center container swiper-buttons relative mx-auto lg:py-7 xl:hidden">
              {prevButton}
              {progressBarElement}
              {nextButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreTopAdmissionMentors;

const LeftArrow = () => {
  return (
    <div className="p-4 border border-black hover:border-[#173CBA] rounded-full bg-white hover:bg-[#173CBA] group">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="fill-current text-black group-hover:text-white"
          d="M13.7917 5.45842H3.375L6.80208 2.03134C6.89972 1.9345 6.97721 1.8193 7.0301 1.69236C7.08298 1.56542 7.11021 1.42927 7.11021 1.29176C7.11021 1.15425 7.08298 1.01809 7.0301 0.891158C6.97721 0.764222 6.89972 0.649012 6.80208 0.552176C6.60691 0.358165 6.3429 0.249268 6.06771 0.249268C5.79252 0.249268 5.5285 0.358165 5.33333 0.552176L0.864584 5.03134C0.473346 5.42024 0.252326 5.94844 0.25 6.50008V6.50008C0.255069 7.04811 0.47588 7.57207 0.864584 7.95842L5.33333 12.4477C5.5285 12.6417 5.79252 12.7506 6.06771 12.7506C6.3429 12.7506 6.60691 12.6417 6.80208 12.4477C6.89972 12.3508 6.97721 12.2356 7.0301 12.1087C7.08298 11.9818 7.11021 11.8456 7.11021 11.7081C7.11021 11.5706 7.08298 11.4345 7.0301 11.3075C6.97721 11.1806 6.89972 11.0654 6.80208 10.9686L3.375 7.54168H13.7917C14.0669 7.54168 14.3309 7.43278 14.5261 7.23876C14.621 7.14244 14.6962 7.02807 14.7479 6.90284C14.7995 6.77762 14.826 6.64356 14.825 6.50842C14.825 6.23997 14.7101 5.98167 14.5129 5.79008C14.3157 5.59849 14.0525 5.48737 13.7812 5.48737L13.7917 5.45842Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

interface RightArrowProps {
  isPrevHovered: boolean;
}

const RightArrow: React.FC<RightArrowProps> = ({ isPrevHovered }) => {
  return (
    <div
      className={`p-4 border ${
        isPrevHovered ? "border-[#173CBA] bg-[#173CBA]" : "border-black"
      } rounded-full bg-[#173CBA] text-white hover:bg-[#173CBA] group`}
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={`fill-current ${
            isPrevHovered ? "text-white" : "text-white"
          } group-hover:text-white`}
          d="M0.208333 9.54158H10.625L7.19792 12.9687C7.10028 13.0655 7.02279 13.1807 6.9699 13.3076C6.91702 13.4346 6.88979 13.5707 6.88979 13.7082C6.88979 13.8457 6.91702 13.9819 6.9699 14.1088C7.02279 14.2358 7.10028 14.351 7.19792 14.4478C7.39309 14.6418 7.6571 14.7507 7.93229 14.7507C8.20748 14.7507 8.4715 14.6418 8.66667 14.4478L13.1354 9.96866C13.5267 9.57976 13.7477 9.05156 13.75 8.49992V8.49992C13.7449 7.95189 13.5241 7.42793 13.1354 7.04158L8.66667 2.55231C8.4715 2.3583 8.20748 2.2494 7.93229 2.2494C7.6571 2.2494 7.39309 2.3583 7.19792 2.55231C7.10028 2.64915 7.02279 2.76436 6.9699 2.89129C6.91702 3.01823 6.88979 3.15438 6.88979 3.29189C6.88979 3.4294 6.91702 3.56555 6.9699 3.69249C7.02279 3.81943 7.10028 3.93464 7.19792 4.03148L10.625 7.45832H0.208333C-0.0668565 7.45832 -0.330871 7.56722 -0.526036 7.76124C-0.620992 7.85756 -0.696202 7.97193 -0.747878 8.09716C-0.799555 8.22238 -0.826044 8.35644 -0.825042 8.49158C-0.825042 8.76003 -0.7101 9.01833 -0.512907 9.20992C-0.315714 9.40151 -0.0524644 9.51263 0.218752 9.51263L0.208333 9.54158Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};
