import React, { FC, useState, useEffect, useRef } from "react";
import "./CollegeBanner.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const CollegeBanner = () => {
  const [imageIndex, setImageIndex] = useState(() =>
    parseInt(localStorage.getItem("bannerIndex") || "0")
  );
  const firstImageRef = useRef<HTMLImageElement | null>(null);
  const secondImageRef = useRef<HTMLImageElement | null>(null);
  const thirdImageRef = useRef<HTMLImageElement | null>(null);
  const fourthImageRef = useRef<HTMLImageElement | null>(null);
  const fifthImageRef = useRef<HTMLImageElement | null>(null);
  const sixthImageRef = useRef<HTMLImageElement | null>(null);
  const mapButtonRef = useRef<HTMLButtonElement | null>(null);

  const imageSets = [
    [
      "/images/College-landing/Banner/banner-1-1.jpg",
      "/images/College-landing/Banner/banner-1-2.jpg",
      "/images/College-landing/Banner/banner-1-3.jpg",
      "/images/College-landing/Banner/banner-1-4.jpg",
      "/images/College-landing/Banner/banner-1-5.jpg",
    ],
    [
      "/images/College-landing/Banner/banner-2-1.jpg",
      "/images/College-landing/Banner/banner-2-2.jpg",
      "/images/College-landing/Banner/banner-2-3.jpg",
      "/images/College-landing/Banner/banner-2-4.jpg",
      "/images/College-landing/Banner/banner-2-5.jpg",
    ],
    [
      "/images/College-landing/Banner/banner-3-1.jpg",
      "/images/College-landing/Banner/banner-3-2.jpg",
      "/images/College-landing/Banner/banner-3-3.jpg",
      "/images/College-landing/Banner/banner-3-4.jpg",
      "/images/College-landing/Banner/banner-3-5.jpg",
    ],
  ];
  const selectedImageSet = imageSets[imageIndex];

  useEffect(() => {
    localStorage.setItem("bannerIndex", ((imageIndex + 1) % 3).toString());
  }, []);

  useEffect(() => {
    if (firstImageRef.current)
      firstImageRef.current.classList.add("animate-translate");
    if (thirdImageRef.current)
      thirdImageRef.current.classList.add("animate-translate");
    if (fifthImageRef.current)
      fifthImageRef.current.classList.add("animate-translate");

    const timer = setTimeout(() => {
      if (secondImageRef.current)
        secondImageRef.current.classList.add(
          "animate-translate-2",
          "animate-translate-delay-1"
        );
      if (fourthImageRef.current)
        fourthImageRef.current.classList.add(
          "animate-translate-2",
          "animate-translate-delay-1"
        );
      if (sixthImageRef.current)
        sixthImageRef.current.classList.add(
          "animate-translate-2",
          "animate-translate-delay-1"
        );
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="top-hidden flex w-full bg-gradient-to-b from-[#EDFCFF] to-[#89C9ED] sm:bg-gradient-to-b sm:from-[#FFFFFF] sm:to-[#CDEDFF]">
      <div className="container">
        <div className=" flex lg:flex-row flex-col mt-12 lg:mt-40 max-lg:mb-40">
          <div className="lg:w-[40%] w-full">
            <div>
              <div className="text-[#383838] font-roboto text-light text-4xl lg:text-customSize  max-lg:text-center">
                We Help to <span className="font-bold">Build</span> <br />
                <div className="flex justify-center font-bold">
                  <span className=" px-1 py-1 bg-[#173CBA] text-[#ffffff] text-center justify-center items-center">
                    Your
                  </span>
                  <span className="px-1 py-1 bg-green text-[#ffffff]">
                    {" "}
                    Dream
                  </span>
                </div>
              </div>
            </div>
            <div className="flex text-[#747474] mt-4 text-base justify-center text-center px-0 md:px-20 lg:px-0">
              Our mission is to help students make informed decisions about
              choosing their dream college
            </div>
            <div className="mt-5">
              <div className="w-full lg:w-[468px] flex gap-5 relative mb-60 md:mb-72 lg:mb-32 text-[--primary-color] mt-5 lg:mt-10 px-0 md:px-20 lg:px-0">
                <SearchBox />
              </div>
            </div>
          </div>
          <div className="lg:w-[50%] translate-y-4 md:-translate-y-32 lg:translate-y-[0px] translate-x-20 md:translate-x-48 lg:translate-x-40">
            <div
              className="absolute flex gap-2 w-[110%]"
              style={{ transform: "rotate(-23deg)" }}
            >
              <div>
                <img
                  ref={firstImageRef}
                  className="w-[120px] h-[140px] md:w-[160px] md:h-[185px] lg:w-[255px] lg:h-[290px] m-2 rounded-lg"
                  src={selectedImageSet[0]}
                  alt=""
                />
                <img
                  ref={secondImageRef}
                  className="w-[120px] h-[140px] md:w-[160px] md:h-[185px] lg:w-[255px] lg:h-[290px] m-2 rounded-lg"
                  src={selectedImageSet[1]}
                  alt=""
                />
              </div>
              <div>
                <img
                  ref={thirdImageRef}
                  className="w-[120px] h-[140px] md:w-[160px] md:h-[185px] lg:w-[255px] lg:h-[340px] m-2 rounded-lg"
                  src={selectedImageSet[2]}
                  alt=""
                />
                <img
                  ref={fourthImageRef}
                  className="w-[120px] h-[140px] md:w-[160px] md:h-[185px] lg:w-[255px] lg:h-[240px] m-2 rounded-lg"
                  src={selectedImageSet[3]}
                  alt=""
                />
              </div>
              <div className="translate-y-12 lg:translate-y-32">
                <img
                  ref={fifthImageRef}
                  className="w-[120px] h-[140px] md:w-[160px] md:h-[185px] lg:w-[255px] lg:h-[290px] translate-y-8 md:translate-y-12 lg:translate-y-32 m-2 rounded-lg"
                  src={selectedImageSet[4]}
                  alt=""
                />
                <div
                  ref={sixthImageRef}
                  className="w-[120px] h-[70px] md:w-[160px] md:h-[90px]  lg:w-[222px] lg:h-[111px] translate-y-8 md:translate-y-12 lg:translate-y-32 m-2 rounded-lg bg-blue"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeBanner;

const collegeList = [
  "Indian Institute of Technology Bombay",
  "Indian Institute of Technology Delhi",
  "Indian Institute of Technology Madras",
  "Indian Institute of Technology Kanpur",
  "Indian Institute of Technology Kharagpur",
  "Indian Institute of Science",
  "Indian Institute of Technology Roorkee",
  "Indian Institute of Technology Guwahati",
  "National Institute of Technology Tiruchirappalli",
  "Birla Institute of Technology and Science, Pilani",
  "All India Institute of Medical Sciences, New Delhi",
  "Jawaharlal Nehru University",
  "Banaras Hindu University",
  "Anna University",
  "University of Delhi",
  "National Institute of Technology Surathkal",
  "Manipal Academy of Higher Education",
  "Vellore Institute of Technology",
  "Savitribai Phule Pune University",
  "Amrita Vishwa Vidyapeetham",
];

const SearchBox = () => {
  const [search, setSearch] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredColleges, setFilteredColleges] = useState<string[]>([]);

  const handleSearchChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setSearch(value);

    if (value.length >= 3) {
      const filtered = collegeList.filter((college) =>
        college.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredColleges(filtered);
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  };

  const handleOptionClick = (college: React.SetStateAction<string>) => {
    setSearch(college);
    setIsDropdownOpen(false);
  };

  const handleClear = () => {
    setSearch("");
    setIsDropdownOpen(false);
  };

  return (
    <form className="bg-white pl-3 py-1 pr-1 md:pl-4 md:pr-2 md:py-2 rounded-full flex flex-row w-full items-center justify-between">
      <div className="flex flex-row gap-2 items-center w-full">
        <div className="flex flex-col w-5 h-5">
          <svg
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4 md:w-5 md:h-5"
          >
            <path
              d="M16.6425 14.9422C17.7163 13.4758 18.3586 11.6747 18.3586 9.72205C18.3586 4.83413 14.3817 0.857178 9.49374 0.857178C4.60582 0.857178 0.628906 4.83413 0.628906 9.72205C0.628906 14.61 4.60586 18.5869 9.49379 18.5869C11.4464 18.5869 13.2477 17.9445 14.7141 16.8707L20.5217 22.6783L22.4502 20.7498C22.4502 20.7498 16.6425 14.9422 16.6425 14.9422ZM9.49379 15.8593C6.10952 15.8593 3.35658 13.1063 3.35658 9.72205C3.35658 6.33778 6.10952 3.58485 9.49379 3.58485C12.8781 3.58485 15.631 6.33778 15.631 9.72205C15.631 13.1063 12.878 15.8593 9.49379 15.8593Z"
              fill="#113CC0"
            />
          </svg>
        </div>
        <div className="relative w-full">
          <input
            type="text"
            id="search"
            value={search}
            onChange={handleSearchChange}
            placeholder="Search College"
            className="block w-full px-2 pr-6 text-xs md:text-base text-[#A3A2AC] outline-none"
          />
          {search.length > 0 && (
            <button
              type="button"
              onClick={handleClear}
              className="absolute right-1 top-1/2 transform text-xl -translate-y-1/2 text-blue hover:text-gray-600"
            >
              <FontAwesomeIcon icon={faClose} size="sm" />
            </button>
          )}
          {isDropdownOpen && filteredColleges.length > 0 && (
            <div className="absolute mt-1 w-full bg-white border rounded-md shadow-lg z-20">
              {filteredColleges.map((college) => (
                <div
                  key={college}
                  onClick={() => handleOptionClick(college)}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                >
                  {college}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Link
        className="arw-button-college bg-[--primary-color] text-white py-1 px-3 rounded-full"
        style={{
          background: "linear-gradient(90deg, #173CBA 0%, #06AFA9 100%)",
        }}
        to={"/college-details"}
      >
        <div className="flex items-center whitespace-nowrap">
          <span className="hidden md:block">Search College</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="currentColor"
            className="arrow-icon m-2"
          >
            <path
              d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </Link>
    </form>
  );
};
