import React from "react";
import { Link } from "react-router-dom";
import {
  captilizeFirstLetter,
  splitAndCapitilize,
} from "../../../util/helpers/helperFunctions";
interface BlogsBreadcrumbProps {
  newsTitle: {
    title: string;
    slug: string;
  };
  newsSubTopic: {
    title: string;
    slug: string;
  };
  newsTopic: {
    title: string;
    slug: string;
  };
  home: {
    title: string;
    slug: string;
  };
  stream: {
    title: string;
    slug: string;
  };
}
const SvgIcon: React.FC = () => (
  <svg
    className="w-6 h-6 fill-gray-500"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
      clipRule="evenodd"
    />
  </svg>
);

const BlogsBreadcrumb: React.FC<BlogsBreadcrumbProps> = ({
  newsTitle = { title: "", slug: "" },
  newsSubTopic = { title: "", slug: "" },
  newsTopic = { title: "", slug: "" },
  home = { title: "Home", slug: "/" },
  stream = { title: "", slug: "" },
}) => {
  // console.log("sadsads", newsTopic, newsSubTopic, newsTitle,stream);
  return (
    <nav className="flex text-gray-700" aria-label="Breadcrumb">
      <ol className="flex flex-wrap space-x-1 md:space-x-1">
        <li className="flex items-center">
          <Link
            to={"/blogs"}
            className="ml-1 text-sm text-[#0C9] font-inter hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
          >
                {splitAndCapitilize(home?.title)}
                </Link>
        </li>
        {stream?.title && (
          <li className="flex items-center">
            <SvgIcon />

            <span className="ml-1 text-sm text-gray-500 cursor-auto font-inter md:ml-2">
            {splitAndCapitilize(stream?.title)}
            </span>
          </li>
        )}
        {newsTopic.title && (
          <li className="flex items-center">
            <SvgIcon />
            {newsSubTopic.title || newsTitle.title ? (
              <Link
                to={`/blogs/${newsTopic.slug}`}
                className="ml-1 text-sm text-[#0C9] hover:text-gray-900 font-inter md:ml-2"
              >
                {splitAndCapitilize(newsTopic?.title)}
              </Link>
            ) : (
              <span className="ml-1 text-sm text-gray-500 cursor-auto font-inter md:ml-2">
                {splitAndCapitilize(newsTopic?.title)}
                </span>
            )}
          </li>
        )}

        {newsSubTopic.title && (
          <li className="flex items-center">
            <SvgIcon />

            {newsTitle.title ? (
              <Link
                to={`/blogs/${newsTopic.slug}`}
                state={{
                  subTopic: newsSubTopic.title,
                }}
                className="ml-1 text-sm text-[#0C9] hover:text-gray-900 font-inter md:ml-2"
              >
                {splitAndCapitilize(newsSubTopic?.title)}
                </Link>
            ) : (
              <span className="ml-1 text-sm text-gray-500 cursor-auto font-inter md:ml-2">
                {splitAndCapitilize(newsSubTopic?.title)}
                </span>
            )}
          </li>
        )}
        {newsTitle.title && (
          <li className="flex items-center">
            <SvgIcon />

            <span className="ml-1 text-sm text-gray-500 cursor-auto font-inter md:ml-2">
            {splitAndCapitilize(newsTitle?.title)}
            </span>
          </li>
        )}
      </ol>
    </nav>
  );
};

export default BlogsBreadcrumb;
