import React, { useState, useEffect } from "react";
import axios from "axios";
import Banner from "../../components/study-abroad-landing/Banner/Banner";
import Loader from "../../components/Loader/Loader";
import CarosalLogos from "../../components/study-abroad-landing/CarosalLogos";
import AboutEducationMentorsGlobally from "../../components/study-abroad-landing/AboutEducationMentorsGlobally";
import WhyStudyAbroadSec from "../../components/study-abroad-landing/WhyStudyAbroad";
import ChooseYourDreamCountry from "../../components/study-abroad-landing/ChooseYourDreamCountry";
import SuccessStories from "../../components/study-abroad-landing/SuccessStories";
import UniversitiesWorldwideMentors from "../../components/study-abroad-landing/UniversitiesWorldwideMentors";
import TopRankedUniversitiesQS from "../../components/study-abroad-landing/TopRankedUniversitiesQS";
import KnowledgeHub from "../../components/study-abroad-landing/KnowledgeHub";
import Guides from "../../components/study-abroad-landing/Guides";
import ExploreKnowledgeHub from "../../components/study-abroad-landing/ExploreKnowledgeHub/ExploreKnowledgeHub";
import config from "../../util/config";
import { useLocation } from "react-router-dom";

export const StudyAbroadLanding = () => {
  const [studyAbroadData, setStudyAbroadData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const selectedCountry = params.get("country");
  const [blogsAndArticlesData, setBlogsAndArticlesData] = useState<any>([]);
  const [courseCareersData, setcourseCareersData] = useState<any>([]);

  useEffect(() => {
    const fetchStudyAbroadData = async () => {
      try {
        const response = await axios.get(
          config.apiUrl + "/api/studyAbroad/landing-page"
        );
        setStudyAbroadData(response.data);
        getBlogsandNews();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchStudyAbroadData();
  }, []);

  useEffect(() => {
    if (!loading) {
      const searchCountryParam = new URLSearchParams(window.location.search);
      const hasCountryParam = searchCountryParam.has("country");

      if (hasCountryParam) {
        const dreamCountrySection = document.getElementById("dream-country");
        if (dreamCountrySection) {
          dreamCountrySection.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [loading]);

  const getBlogsandNews = () => {
    axios
      .get(config.apiUrl + `/api/blogs/blogsandnews`)
      .then((response) => {
        setcourseCareersData(
          response.data.sections[0]["Career & Courses"].subcategories[
            "Articles & Blogs"
          ].results
        );
        prepareCareersData(
          response.data.sections[0]["Career & Courses"].subcategories[
            "Articles & Blogs"
          ].results
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const prepareCareersData = (data: any) => {
    const filteredData = data.filter((item: any) =>
      item.subTopic.toLowerCase().includes("".toLowerCase())
    );
    setBlogsAndArticlesData(filteredData);
  };

  if (loading) {
    return <Loader />;
  }

  const {
    rankedByQs,

    successStories,
    faqs,
    guides,
  } = studyAbroadData?.data || {};
  const {
    careerInterest,
    careerbulleyes,
    careerVisualStories,
    careerspotlights,
    blogsAndArticles,
  } = studyAbroadData.knowledgeHub || {};

  return (
    <div>
      <Banner />
      <CarosalLogos />
      <AboutEducationMentorsGlobally />
      <WhyStudyAbroadSec />
      <ChooseYourDreamCountry selectedCountry={selectedCountry || ""} />
      <UniversitiesWorldwideMentors />
      <TopRankedUniversitiesQS rankedByQs={rankedByQs} />
      <SuccessStories successStories={successStories} />
      <ExploreKnowledgeHub
        careerInterest={careerInterest || []}
        careerbullseye={careerbulleyes || []}
        careerVisualStories={careerVisualStories || []}
        careerspotlights={careerspotlights || []}
        blogsAndArticles={blogsAndArticlesData || []}
      />
      <KnowledgeHub faqData={faqs} />
      <Guides guides={guides} />
    </div>
  );
};
