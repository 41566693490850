import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import FilterMenu from "./FilterMenu";
import FilterTabs from "./FilterTabs";
import { FilterCategory } from "../../../components/filter/FilterEnum";
import { addNewFilter } from "../../../store/slices/filterSlice";
import { getOptionData } from "../my-profile/myProfileThunk";
import { AppDispatch, RootState } from "../../../store/store";

const Filter = () => {
  const dispatch = useDispatch<AppDispatch>();

  const optionData = useSelector(
    (state: RootState) => state.myProfile.optionData
  );

  const streamFilterData = {
    categoryKey: "stream",
    categoryName: "Stream",
    type: FilterCategory.MultiSelect,
    options: optionData?.lookingForStream?.map(
      (item: { streamId: number; streamName: string }) => ({
        key: item.streamId.toString(),
        name: item.streamName,
      })
    ),
  };

  const stateFilterData = {
    categoryKey: "state",
    categoryName: "State",
    type: FilterCategory.MultiSelect,
    options: optionData?.states?.map(
      (item: { stateId: number; stateName: string }) => ({
        key: item.stateId.toString(),
        name: item.stateName,
      })
    ),
  };

  const cityFilterData = {
    categoryKey: "city",
    categoryName: "City",
    type: FilterCategory.MultiSelect,
    options: optionData?.cities?.map(
      (item: { cityId: number; cityName: string }) => ({
        key: item.cityId.toString(),
        name: item.cityName,
      })
    ),
  };

  const [showFilter, setShowFilter] = useState(false);

  const [selectedFilterCategoryIndex, setSelectedFilterCategoryIndex] =
    useState(0);

  const handleSelectedFilterCategoryIndexChange = (newIndex: number) =>
    setSelectedFilterCategoryIndex(newIndex);

  useEffect(() => {
    dispatch(getOptionData());
  }, []);

  useEffect(() => {
    if (optionData) {
      dispatch(addNewFilter(streamFilterData));
      dispatch(addNewFilter(stateFilterData));
      dispatch(addNewFilter(cityFilterData));
    }
  }, [optionData == null]);

  return (
    <div className="flex">
      <FilterTabs
        handleShowFilter={() => setShowFilter(true)}
        changeFilterMenuActiveCategory={(index) =>
          setSelectedFilterCategoryIndex(index)
        }
      />
      <FilterMenu
        isOpen={showFilter}
        onClose={() => {
          setShowFilter(false);
        }}
        selectedCategoryIndex={selectedFilterCategoryIndex}
        handleSelectedCategoryIndexChange={
          handleSelectedFilterCategoryIndexChange
        }
      />
    </div>
  );
};

export default Filter;
