import React from "react";
import ClgLatestUpdates from "../courses-fees/ClgLatestUpdates";
import KnowledgeHubFAQ from "../KnowledgeHubFAQ";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import UniversityMentorHelp from "../UniversityMentorHelp";
import ApplicationForms from "../sidebar/ApplicationForms";
import CoursesList from "../sidebar/CoursesList";

interface CoursesFeesTabProps {
  CoursesFees: {
    key_0?: {
      title: string;
      description: string;
    };
    faqs?: Array<{ title: string; description: string }>;
    similarColleges?: Array<{
      image: string;
      name: string;
      location: string;
      title: string;
    }>;
  };
}

const CoursesFeesTab: React.FC<CoursesFeesTabProps> = ({ CoursesFees }) => {
  const { key_0, faqs, similarColleges } = CoursesFees;

  return (
    <>
      <div className="py-10 bg-[#f4f4f4]">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="w-full lg:w-9/12">
              {key_0 && (
                <ClgLatestUpdates
                  title={key_0.title}
                  description={key_0.description}
                />
              )}
            </div>
            <div className="w-full lg:w-3/12 hidden lg:block">
              <div className="flex flex-col gap-10">
                <ApplicationForms />
                <CoursesList />
              </div>
            </div>
          </div>
        </div>
        <UniversityMentorHelp />
      </div>

      {similarColleges && similarColleges.length > 0 && (
        <SimilarEngineeringColleges colleges={similarColleges} />
      )}
    </>
  );
};

export default CoursesFeesTab;
