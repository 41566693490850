// FlightAnimation.js
import React, { useRef, useEffect, useState } from "react";
import planeImage from "../../../../../../images/authentication/plane-svg.svg";
import planeRotated from "../../../../../../images/authentication/plane-rotated.svg";
import planeNew from "../../../../../../images/authentication/plane-new.svg";
import planeRotatedRepo from "../../../../../../images/authentication/plane-svgrepo-com.svg";
import TakeOffplane from "../../../../../../images/authentication/takeoff-the-plane-svg.svg";
import { GetWindowDimensions } from "../../../../../../hooks/window-hooks/WindowHooks";

interface FlightAnimationProps {
  step: number;
  steps: number;
}

const FlightAnimation: React.FC<FlightAnimationProps> = ({ step, steps }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const plane = useRef(new Image());

  const previousStepRef = useRef<number>(step);
  const animationRef = useRef<number | null>(null);

  useEffect(() => {
    // Set up the plane image
    plane.current.src = planeNew;
    plane.current.onload = () => setImageLoaded(true);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !imageLoaded) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Canvas dimensions
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const planeSize = 40;

    // Curve control points
    const startX = 20;
    const startY = canvasHeight - 10;
    const endX = canvasWidth - 20;
    const endY = startY;
    const controlX = canvasWidth / 2;
    const controlY = -20; // Adjusted control point for more pronounced curve

    // Function to draw the plane and path
    // const drawPathAndPlane = (progress: number) => {
    //   // Clear canvas
    //   ctx.clearRect(0, 0, canvas.width, canvas.height);

    //   // Draw the dashed curve path
    //   ctx.setLineDash([10, 10]); // Dashed line (---)
    //   ctx.beginPath();
    //   ctx.moveTo(startX, startY);
    //   ctx.quadraticCurveTo(controlX, controlY, endX, endY);
    //   ctx.strokeStyle = "#ffffff"; // White color for the path
    //   ctx.lineWidth = 1;
    //   ctx.stroke();
    //   ctx.setLineDash([]);

    //   // Draw dots along the curve
    //   for (let i = 0; i < steps; i++) {
    //     const dotProgress = i / (steps - 1);
    //     const dotX = (1 - dotProgress) * (1 - dotProgress) * startX + 2 * (1 - dotProgress) * dotProgress * controlX + dotProgress * dotProgress * endX;
    //     const dotY = (1 - dotProgress) * (1 - dotProgress) * startY + 2 * (1 - dotProgress) * dotProgress * controlY + dotProgress * dotProgress * endY;
    //     ctx.fillStyle = i <= step ? "#ffffff" : "#444444";  // White for completed dots, grey for upcoming dots
    //     ctx.beginPath();
    //     ctx.arc(dotX, dotY, 3, 0, Math.PI * 2);
    //     ctx.fill();
    //   }

    //   // Calculate the flight's position
    //   const startT = previousStepRef.current / (steps - 1);
    //   const endT = step / (steps - 1);
    //   const currentT = startT + (endT - startT) * progress;

    //   const xPosition = (1 - currentT) * (1 - currentT) * startX + 2 * (1 - currentT) * currentT * controlX + currentT * currentT * endX;
    //   const yPosition = (1 - currentT) * (1 - currentT) * startY + 2 * (1 - currentT) * currentT * controlY + currentT * currentT * endY;

    //   // Calculate angle for rotation along the curve
    //   const deltaX = 6 * (1 - currentT) * (controlX - startX) + 6 * currentT * (endX - controlX);
    //   const deltaY = 2 * (1 - currentT) * (controlY - startY) + 10 * currentT * (endY - controlY);
    //   const angle = Math.atan2(deltaY, deltaX);

    //   // Draw and rotate the plane image
    //   ctx.save();
    //   ctx.translate(xPosition, yPosition);
    //   ctx.rotate(angle);
    //   ctx.drawImage(plane.current, -planeSize / 2, -planeSize / 2, planeSize, planeSize);
    //   ctx.restore();
    // };

    const drawPathAndPlane = (progress: number) => {
      // Clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw the dashed curve path
      ctx.setLineDash([10, 10]); // Dashed line (---)
      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.quadraticCurveTo(controlX, controlY, endX, endY);
      ctx.strokeStyle = "#ffffff"; // White color for the path
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.setLineDash([]);

      // Draw dots along the curve
      for (let i = 0; i < steps; i++) {
        const dotProgress = i / (steps - 1);
        const dotX =
          (1 - dotProgress) * (1 - dotProgress) * startX +
          2 * (1 - dotProgress) * dotProgress * controlX +
          dotProgress * dotProgress * endX;
        const dotY =
          (1 - dotProgress) * (1 - dotProgress) * startY +
          2 * (1 - dotProgress) * dotProgress * controlY +
          dotProgress * dotProgress * endY;

        // Set dot color based on completion
        ctx.fillStyle = i <= step ? "#ffffff" : "#ffffff"; // White for completed dots, grey for upcoming dots
        ctx.beginPath();
        ctx.arc(dotX, dotY, 3, 0, Math.PI * 2); // Dot size reduced
        ctx.fill();
      }

      // Calculate the flight's position
      const startT = previousStepRef.current / (steps - 1);
      const endT = step / (steps - 1);
      const currentT = startT + (endT - startT) * progress;

      const xPosition =
        (1 - currentT) * (1 - currentT) * startX +
        2 * (1 - currentT) * currentT * controlX +
        currentT * currentT * endX;
      const yPosition =
        (1 - currentT) * (1 - currentT) * startY +
        2 * (1 - currentT) * currentT * controlY +
        currentT * currentT * endY;

      // Calculate angle for rotation along the curve
      const deltaX =
        2 * (1 - currentT) * (controlX - startX) +
        2 * currentT * (endX - controlX);
      const deltaY =
        2 * (1 - currentT) * (controlY - startY) +
        2 * currentT * (endY - controlY);
      // const angle = Math.atan2(deltaY, deltaX);
      const adjustment = step < 5 ? Math.PI / 8 : -Math.PI / -7; // Adjust based on step
      const angle = Math.atan2(deltaY, deltaX) + adjustment;

      // Draw and rotate the plane image
      ctx.save();
      ctx.translate(xPosition, yPosition); // Move to the plane's position
      ctx.rotate(angle); // Rotate the plane to align with the curve
      ctx.drawImage(
        plane.current,
        -planeSize / 2,
        -planeSize / 2,
        planeSize,
        planeSize
      );
      ctx.restore();
    };

    // Animation loop for smooth transition
    const animateTransition = (startTimestamp: number) => {
      const duration = 500; // Duration of the animation in milliseconds
      const timestamp = performance.now();
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);

      drawPathAndPlane(progress);

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(() =>
          animateTransition(startTimestamp)
        );
      } else {
        previousStepRef.current = step; // Update previous step after animation completes
      }
    };

    // Trigger animation only after the image is loaded and the step is changed
    if (imageLoaded) {
      if (previousStepRef.current !== step) {
        // Start animation when the step changes
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
        }
        animationRef.current = requestAnimationFrame((timestamp) =>
          animateTransition(timestamp)
        );
      } else {
        // Ensure the flight is visible in the correct initial position for step 1
        drawPathAndPlane(1);
      }
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [step, steps, imageLoaded]);

  const { width, height } = GetWindowDimensions();

  return (
    <canvas
      ref={canvasRef}
      width="300"
      height="67"
      style={{
        verticalAlign: "top",
        width: "333px",
        height: height <= 768 ? "58px" : "100px",
        // border: "1px solid #ddd",
        marginBottom: "20px",
      }}
    />
  );
};

export default FlightAnimation;
