import React from "react";
import QuicBanner from "../../images/exams/quiz-add.png";
import { EpRight } from "../college-details/components/NavigationTabs";

const bannerData = {
  subtitle: "Find Your",
  title: "Perfect Course With Interactive",
  thintitle: "Course Quiz",
  buttonText: "Take Our Quiz",
  imageUrl: QuicBanner,
};

const CourseQuizBanner: React.FC = () => {
  return (
    <div
      className={`bg-[#C7EAFB] flex flex-col items-center text-center p-6 mx-auto`}
    >
      <div className="flex flex-col">
        <h4 className={`text-2xl font-bold text-blue mb-2`}>
          <span className="font-medium text-2xl">{bannerData.subtitle}</span>
          <br />
          <strong className="text-3xl font-bold">{bannerData.title}</strong>
          <br />
          <span className="font-normal text-4xl">{bannerData.thintitle}</span>
        </h4>
      </div>

      <div className="w-full mb-6">
        <img
          src={bannerData.imageUrl}
          alt="Course Quiz Illustration"
          className="w-full h-auto"
        />
      </div>
      <div className="w-full">
        <button
          className={`bg-[#FF66CC] text-white text-lg font-bold py-3 px-6 rounded-full transition duration-300 w-full flex gap-4 justify-center items-center group`}
        >
          {bannerData.buttonText}{" "}
          <div className="w-7 h-7 rounded-full bg-blue flex justify-center items-center transform transition-transform duration-300 group-hover:translate-x-2">
            <EpRight />
          </div>
        </button>
      </div>
    </div>
  );
};

export default CourseQuizBanner;
