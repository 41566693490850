import React from "react";

const MentorDosAndDonts = () => {
  return (
    <div className="bg-[#D9F6EF] pb-10 max-md:py-10">
      <div className="container">
        <div className="lg:w-[900px] md:w-full   ">
          <div className="bg-[#173CBA] text-white text-2xl font-semibold px-4 py-2 lg:w-[900px] md:w-full">
            Do's & Don'ts During Mentor Sessions
          </div>

          <div className="p-6 text-[#383838]">
            <h3 className="text-lg font-semibold text-[#383838] mb-2">Do's:</h3>
            <ul className="list-disc list-inside space-y-1 ml-4">
              <li>
                <span className="font-semibold">Be Prepared</span>: Come with
                specific questions and topics to discuss.
              </li>
              <li>
                <span className="font-semibold">Be Open and Honest</span>: Share
                your thoughts, challenges, and goals openly.
              </li>
              <li>
                <span className="font-semibold">Listen Actively</span>: Pay
                attention to your mentor's advice and insights.
              </li>
              <li>
                <span className="font-semibold">Take Notes</span>: Document key
                points and action items during the session.
              </li>
              <li>
                <span className="font-semibold">Set Goals</span>: Establish
                clear objectives for what you want to achieve in the session.
              </li>
              <li>
                <span className="font-semibold">Follow Up</span>: After the
                session, recap what you learned and how you plan to implement
                it.
              </li>
              <li>
                <span className="font-semibold">Respect Time</span>: Be punctual
                and respectful of your mentor's time.
              </li>
            </ul>

            <h3 className="text-lg font-semibold text-[#383838] mt-6 mb-2">
              Don'ts:
            </h3>
            <ul className="list-disc list-inside space-y-1 ml-4">
              <li>
                <span className="font-semibold">Don't Be Disrespectful</span>:
                Avoid interrupting or dismissing your mentor's advice.
              </li>
              <li>
                <span className="font-semibold">Don't Come Unprepared</span>:
                Failing to prepare can waste valuable time.
              </li>
              <li>
                <span className="font-semibold">
                  Don't Dominate the Conversation
                </span>
                : Ensure there's a balance between speaking and listening.
              </li>
              <li>
                <span className="font-semibold">Don't Ignore Feedback</span>: Be
                open to constructive criticism and use it to grow.
              </li>
              <li>
                <span className="font-semibold">Don't Be Passive</span>: Engage
                actively in the discussion rather than just listening.
              </li>
              <li>
                <span className="font-semibold">
                  Don't Expect Immediate Solutions
                </span>
                : Understand that some challenges take time to address.
              </li>
              <li>
                <span className="font-semibold">
                  Don't Avoid Difficult Topics
                </span>
                : Address your challenges and concerns honestly.
              </li>
            </ul>

            <p className="mt-6 text-sm text-gray-700">
              During mentor sessions, be prepared and open to feedback while
              actively engaging in the discussion. Avoid dominating the
              conversation or ignoring constructive criticism to foster a
              productive relationship.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorDosAndDonts;
