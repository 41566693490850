import React, { useState } from "react";
import BannerImg from "../../images/study-abroad/University/BannerHigherEducation.png";
import { EpRight } from "../study-abroad-landing/Banner/Banner";
import LeadGeneration from "../LeadGeneration";

interface BannerSectionProps {
  data: {
    title: string;
    description: string;
    paragraphText: string;
    buttonText: string;
  };
}

const StudyUniversityBanner: React.FC<BannerSectionProps> = ({ data }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <section className="bg-[#FFF1FA] py-12 md:py-16">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row items-center gap-7 lg:gap-14">
          <div className="w-full lg:w-1/2">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-4">
                <h2 className="text-blue text-4xl lg:text-5xl font-bold lg:font-light">
                  {data.title}
                </h2>
                <p
                  className="text-base text-black"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></p>
                <p
                  className="text-base text-black"
                  dangerouslySetInnerHTML={{ __html: data.paragraphText }}
                ></p>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row">
                  <button
                    onClick={handlePopup}
                    className="bg-green hover:bg-blue button-styles py-3.5 px-3 green-arrow-button flex flex-row items-center justify-center gap-2 text-white"
                  >
                    {data.buttonText} <EpRight className="green-arrow-icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="rounded-lg">
              <div>
                <img
                  src={BannerImg}
                  alt="Banner Image"
                  className="w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StudyUniversityBanner;
