import React from "react";
import { Link } from "react-router-dom";

const rankingCardsData = [
  {
    id: 1,
    iconUrl: "/images/ranking-framework/ranking-overall.png",
    title: "Overall",
  },
  {
    id: 2,
    iconUrl: "/images/ranking-framework/ranking-info-icon.png",
    title: "University",
  },
  {
    id: 3,
    iconUrl: "/images/ranking-framework/ranking-info-icon.png",
    title: "Engineering",
  },
  {
    id: 4,
    iconUrl: "/images/ranking-framework/ranking-info-icon.png",
    title: "Management",
  },
  {
    id: 5,
    iconUrl: "/images/ranking-framework/ranking-info-icon.png",
    title: "Law",
  },
  {
    id: 6,
    iconUrl: "/images/ranking-framework/ranking-info-icon.png",
    title: "Medical",
  },
  {
    id: 7,
    iconUrl: "/images/ranking-framework/ranking-info-icon.png",
    title: "Architecture",
  },
  {
    id: 8,
    iconUrl: "/images/ranking-framework/ranking-info-icon.png",
    title: "Pharmacy",
  },
  {
    id: 9,
    iconUrl: "/images/ranking-framework/ranking-info-icon.png",
    title: "Arts",
  },
  {
    id: 10,
    iconUrl: "/images/ranking-framework/ranking-info-icon.png",
    title: "Commerce",
  },
  {
    id: 11,
    iconUrl: "/images/ranking-framework/ranking-info-icon.png",
    title: "Science",
  },
  {
    id: 12,
    iconUrl: "/images/ranking-framework/ranking-info-icon.png",
    title: "Agriculture",
  },
  {
    id: 13,
    iconUrl: "/images/ranking-framework/ranking-info-icon.png",
    title: "Fashion",
  },
  {
    id: 14,
    iconUrl: "/images/ranking-framework/ranking-info-icon.png",
    title: "Dental",
  },
  {
    id: 15,
    iconUrl: "/images/ranking-framework/ranking-info-icon.png",
    title: "Hospitality",
  },
  {
    id: 16,
    iconUrl: "/images/ranking-framework/ranking-info-icon.png",
    title: "Design",
  },
];

const RankingInfo: React.FC = () => {
  return (
    <div className="flex flex-wrap px-20 bg-[#F7F7F7] py-16 w-full max-md:px-5 max-md:pt-14 max-md:max-w-full gap-12">
      {rankingCardsData.map((card) => (
        <div
          key={card.id}
          className="flex flex-col text-sm leading-7 text-center whitespace-nowrap max-w-[130px] text-neutral-700 mx-2 mb-6"
        >
          <Link to={"/ranking-framework-two"}>
            <div className="flex w-full bg-white cursor-pointer rounded-md border border-solid border-[#A3A2AC] h-[131px] min-h-[131px] p-7">
              <img
                src={card.iconUrl}
                alt={card.title}
                className="object-contain w-full aspect-[2.67]"
              />
            </div>
          </Link>
          <h2 className="self-center mt-1 text-base font-normal">
            {card.title}
          </h2>
        </div>
      ))}
    </div>
  );
};

export default RankingInfo;
