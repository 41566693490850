import ApplySmarter from "./components/ApplySmarter";
import BuildingBridges from "./components/BuildingBridges";
import FeaturedColleges from "./components/FeaturedColleges";
import CollegeBanner from "./components/CollegeBanner";
import StateWise from "./components/StateWise";
import { useEffect } from "react";
import Loader from "../../../components/Loader/Loader";
import About from "./components/About";

import ChooseCollegeMentor from "./components/ChooseCollegeMentor";
import WorldClassUni from "./components/WorldClassUni";
import Preference from "./components/Preference";
import Dreams from "./components/Dreams";
import FindPerfectCollege from "./components/FindPerfectCollege";
import axios from "axios";
import collegeLanding, { update } from "../../../store/slices/collegeLanding";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import config from "../../../util/config";
import WebinarAndArticles from "./components/WebinarAndArticles";

const CollegeLanding = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          url: config.apiUrl + "/api/college/landing-page",
          method: "GET",
        });
        dispatch(update(response.data?.data));
      } catch (e) {
        console.log("error fetching college landing data");
      }
    };
    fetchData();
  }, []);
  const featuredColleges = useSelector(
    (state: RootState) => state.collegeLanding.featuredColleges
  );
  const blogsAndArticles = useSelector(
    (state: RootState) => state.collegeLanding.blogsAndArticles
  );

  return (
    <div className="font-inter">
      <CollegeBanner />
      <FeaturedColleges
        title={"Featured Colleges"}
        featuredColleges={featuredColleges}
      />
      <BuildingBridges />
      <FindPerfectCollege />
      <ApplySmarter />
      <Preference />
      <ChooseCollegeMentor />
      <Dreams />
      <WorldClassUni />
      <StateWise />
      <WebinarAndArticles />
      <About />
    </div>
  );
};

export default CollegeLanding;
