export const ExploreColleges = [
  {
    text: "Engineering Colleges In India",
    url: "https://example.com/trending1",
  },
  { text: "Manipal University", url: "https://example.com/trending2" },
  { text: "Amrita University", url: "https://example.com/trending3" },
  { text: "Sastra University", url: "https://example.com/trending4" },
  { text: "VIT University", url: "https://example.com/trending5" },
  { text: "PES University", url: "https://example.com/trending4" },
  { text: "Srm University", url: "https://example.com/trending5" },
  { text: "Thapar University", url: "https://example.com/trending5" },
];

export const FooterLinks = [
  {
    title: "Explore Careers",
    links: [
      { text: "Doctor", url: "/careers" },
      { text: "Agricultural Scientist", url: "/careers" },
      { text: "Airforce Officer", url: "/careers" },
      { text: "AI/Machine Learning Engineer", url: "/careers" },
      { text: "Data Scientist/Analyst", url: "/careers" },
      { text: "Cybersecurity Specialist", url: "/careers" },
      { text: "Chartered Accountant (CA)", url: "/careers" },
      { text: "Clinical Psychologist", url: "/careers" },

      { text: "Data Scientist/Analyst", url: "/careers" },
      { text: "Environmental Engineer", url: "/careers" },
      { text: "Fashion Designer", url: "/careers" },
      { text: "Sports Manager", url: "/careers" },
      { text: "Fashion Designer", url: "/careers" },
      { text: "Game Developer", url: "/careers" },
    ],
  },
  {
    title: "Explore Exams",
    links: [
      { text: "JEE Main 2025 - Engineering", url: "/exams" },
      { text: "JEE Advanced 2025 - Engineering (IITs)", url: "/exams" },
      { text: "CUET 2025 - Engineering & Liberal Arts", url: "/exams" },
      { text: "BITSAT2025 - Engineering & Pharma", url: "/exams" },
      { text: "VITEEE  2025 - Engineering", url: "/exams" },
      { text: "NEET 2025  - Medical", url: "/exams" },
      { text: "NID Entrance Exam - Design", url: "/exams" },
      { text: "NIFT 2025 - Fashion & Deisgn", url: "/exams" },
      { text: " NATA 2025 - Architecture", url: "/exams" },
      { text: "IPMAT 2025 -Business Management", url: "/exams" },
      { text: "ICAR AIEEA 2025 - Agriculture", url: "/exams" },
      { text: "CAT 2025 -  MBA", url: "/exams" },
      { text: "CLAT 2025 - Law", url: "/exams" },
      { text: "NDA 2025- Defence Services", url: "/exams" },
    ],
  },
  {
    title: "Explore Colleges",
    links: [
      { text: "Engineering Colleges In India", url: "/college-intermediate" },
      { text: "Medical Colleges in India", url: "/college-intermediate" },
      { text: "Management Colleges In India ", url: "/college-intermediate" },
      { text: "Law Colleges In India", url: "/college-intermediate" },
      { text: "Architecture Colleges In India", url: "/college-intermediate" },
      { text: "Agriculture Colleges In India", url: "/college-intermediate" },
      {
        text: "Hospatality & Tourism Colleges In India",
        url: "/college-intermediate",
      },
      {
        text: "Institutions of Eminence In India",
        url: "/college-intermediate",
      },
      { text: "Design Colleges In India", url: "/college-intermediate" },
      { text: "Liberal arts Colleges In India", url: "/college-intermediate" },
      { text: "Top Deemed Universites In India", url: "/college-intermediate" },
      { text: "State Universites In India", url: "/college-intermediate" },
      { text: "State Private Universities", url: "/college-intermediate" },

      { text: "Fake Universites In India", url: "/college-intermediate" },
    ],
  },
  {
    title: "Explore Resources",
    links: [
      { text: "News", url: "/resource-a" },
      { text: "Articles", url: "/resource-b" },
      { text: "Education Boards", url: "/resource-c" },
      { text: "Professional Councils", url: "/resource-a" },
      { text: "Scholarships", url: "/resource-b" },
      { text: "Student Mentor", url: "/resource-c" },
      { text: "Career Mentor", url: "/resource-b" },
      { text: "Admission Mentor", url: "/resource-c" },
      { text: "Health Mentor", url: "/resource-a" },
      { text: "Scholarship Mentor", url: "/resource-b" },
      { text: "Loan Mentor", url: "/resource-c" },
      { text: "Campus Tour", url: "/resource-b" },
      { text: "Visual Stories ", url: "/resource-c" },
      { text: "Application To Admission ", url: "/resource-c" },
    ],
  },

  {
    title: "Explore Courses",
    links: [
      { text: "Diploma", url: "/courses" },
      { text: "B.E | B.TECH", url: "/courses" },
      { text: "M.E | M.TECH", url: "/courses" },
      { text: "MBBS | MD | MS |M.Ch", url: "/courses" },
      { text: "BDS | MDS ", url: "/courses" },
      { text: "B.Arch | M.Arch", url: "/courses" },
      { text: "B.Com | M.Com", url: "/courses" },
      { text: "BALLB", url: "/courses" },
      { text: "LLB | LLM", url: "/courses" },
      { text: "BCA | MCA", url: "/courses" },
      { text: "BBA | MBA", url: "/courses" },
      { text: "B.Sc | M.Sc", url: "/courses" },
      { text: "BA | MA", url: "/courses" },
      { text: "B.Des | M.Des", url: "/courses" },
    ],
  },
  {
    title: "Explore Rankings & Accrediations ",
    links: [
      { text: "Nirf Overall Rankings", url: "/resource-a" },
      { text: "Nirf Universiteis Rankings", url: "/resource-b" },
      { text: "Nirf College rankings", url: "/resource-c" },
      { text: "Nirf Reserch Rankings", url: "/resource-a" },
      { text: "Nirf Engineering Rankings", url: "/resource-b" },
      { text: "Nirf Management Rankings", url: "/resource-c" },
      { text: "Nirf Pharmacy Rankings", url: "/resource-a" },
      { text: "Nirf Medical Rankings", url: "/resource-b" },
      { text: "Nirf Dental Rankings", url: "/resource-c" },
      { text: "Nirf Law Rankings", url: "/resource-a" },
      { text: "Nirf Architecture Rankings", url: "/resource-b" },
      { text: "Nirf Innvoation Rankings", url: "/resource-c" },
      {
        text: "QS rankings & Times Higher Education (THE)",
        url: "/resource-a",
      },
      { text: "NAAC | NBA | ABET ", url: "/resource-b" },
    ],
  },

  {
    title: "Explore Predictor & Scholarship Tools",
    links: [
      { text: "JEE Main College Predictor", url: "/resource-a" },
      { text: "JEE Advanced College Predictor", url: "/resource-b" },
      { text: "NEET College Predictor", url: "/resource-c" },
      { text: "NEET Rank Predictor", url: "/resource-a" },
      { text: "CLAT College Predictor", url: "/resource-b" },
      { text: "CAT College Predictor", url: "/resource-c" },
      { text: "NIFT College Predictor", url: "/resource-a" },
      { text: "CUET College Predictor", url: "/resource-b" },
      { text: "College Compare", url: "/resource-c" },
      { text: "Schoalrships For Girls", url: "/resource-a" },
      { text: "Scholarships For Sports", url: "/resource-b" },
      { text: "Schoalrhsips For Merit", url: "/resource-c" },
      { text: "Schoalrhsips For Minority", url: "/resource-c" },
    ],
  },

  {
    title: "Explore Study Abroad",
    links: [
      { text: "SAT (Scholastic Assessment Test) ", url: "/resource-a" },
      { text: "GRE (Graduate Record Examination)", url: "/resource-b" },
      {
        text: "IELTS (International English Language Testing System)",
        url: "/resource-c",
      },
      { text: "United States", url: "/resource-a" },
      { text: "United Kingdom", url: "/resource-b" },
      { text: "Canada", url: "/resource-c" },
      { text: "Australia", url: "/resource-a" },
      { text: "Germany", url: "/resource-b" },
      { text: "Netherlands", url: "/resource-c" },
      { text: "Sweden", url: "/resource-a" },
      { text: "New Zealand", url: "/resource-b" },
      { text: "Singapore", url: "/resource-c" },
      {
        text: "France",
        url: "/resource-a",
      },
    ],
  },
];

export const ImportantLinks = [
  {
    title: "Quick Links",
    links: [
      { text: "About Us", url: "/resource-a" },
      { text: "Contact Us", url: "/resource-b" },
      {
        text: "Join Us",
        url: "/resource-c",
      },
      { text: "Advertise With Us", url: "/resource-a" },
      { text: "Connect With Us", url: "/resource-b" },
      { text: "FAQ", url: "/resource-c" },
    ],
  },
  {
    title: "Other Links",
    links: [
      { text: "Privacy Policy", url: "/resource-a" },
      { text: "Sitemap", url: "/resource-b" },
      {
        text: "Hyperlinking Policy",
        url: "/resource-c",
      },
      { text: "Copyright Policy ", url: "/resource-a" },
      { text: "Terms & Conditions", url: "/resource-b" },
    ],
  },
];
