import React from "react";

const ReferEarnTerms = () => {
  return (
    <div>
      <div className="p-4 md:p-8 bg-white text-gray-700 max-w-7xl mx-auto">
        {/* Section: Terms and Conditions Title */}
        <h3 className="text-2xl md:text-3xl text-red font-bold  mb-6">
          Terms and Conditions
        </h3>

        {/* Section: Referral Policy */}
        <div className="mb-8">
          <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">
            Referral Policy
          </h2>
          <p className="mb-4">
            This Referral Policy applies to users (referred to as “user”,
            “users”, “your”, or “you”) who are participating in the
            Collegementor Referral Scheme (the “Scheme”). Your participation in
            the Scheme is deemed if (a) you share your referral code with others
            by any means whatsoever, or (b) you use another’s referral code in
            your application.
          </p>
          <p className="mb-4">
            By participating in this Scheme, you acknowledge and agree to be
            bound by all of the terms and conditions of this Referral Policy
            (“Policy”), which are in addition to and subject to Collegementor’s
            “Terms of Use” and “Privacy Policy” available on the Platform.
            Collegementor offers curated and specially designed higher education
            and industry-relevant diploma, degree, certification programs, and
            career assistance services (“Program”).
          </p>
        </div>

        {/* Section: Definitions */}
        <section className="mb-8">
          <h3 className="text-lg md:text-xl font-semibold text-gray-800 mb-2">
            Definitions
          </h3>
          <ul className="list-disc pl-5 mb-4">
            <li className="mb-2">
              <strong>Referrer:</strong> A person who refers someone for Study
              India or Study Abroad for admissions through Collegementor tie-up
              universities.
            </li>
            <li className="mb-2">
              <strong>Enrolled Referrer:</strong> A Collegementor user who has
              paid the full amount for an Admission Program and is making the
              reference.
            </li>
            <li className="mb-2">
              <strong>Referral Code:</strong> The code generated when the
              Referrer signs up on the Collegementor platform or otherwise in a
              manner decided by Collegementor.
            </li>
            <li className="mb-2">
              <strong>Referee:</strong> The friend who enrolls for the first
              time in a Collegementor admissions tie-up/universities and program
              through the Referrer’s reference, using a valid Referral Code.
            </li>
          </ul>
        </section>

        {/* Section: Criteria */}
        <section className="mb-8">
          <h3 className="text-lg md:text-xl font-semibold text-gray-800 mb-2">
            Criteria
          </h3>
          <p className="mb-4">
            The Referrer must share their Referral Code with the Referee, which
            must be used/applied by the Referee in their application process at
            Collegementor for enrollment in a program. It is the responsibility
            of the Referrer and Referee solely to ensure that the Referral Code
            is used before the application submission. Collegementor shall not
            accept the Referral Code any time after the application submission
            by a Referee.
          </p>
        </section>

        {/* Section: Value & Disbursement of Reward */}
        <section className="mb-8">
          <h3 className="text-lg md:text-xl font-semibold text-gray-800 mb-2">
            Value & Disbursement of Reward
          </h3>
          <p className="mb-4">
            The amount of Reward receivable is dependent on the status of the
            Referrer and the Referee at the time when such Reward is to be
            disbursed.
          </p>
        </section>

        {/* Section: Refer & Earn Festival */}
        <section className="mb-8">
          <h3 className="text-lg md:text-xl font-semibold text-gray-800 mb-2">
            “Refer & Earn Festival” TnCs
          </h3>
          <p className="mb-4">
            Refer 2 or more friends during the Refer & Earn period from January
            1, 2025, to September 30, 2025, and earn INR 10,000 + up to INR
            1,50,000. Ensure your friends apply your referral code and pay the
            block fees for a course with a minimum fee of ₹90,000. Your friend
            must make the full payment before the program starts.
          </p>
        </section>

        {/* Section: Not Enrolled Referrers */}
        <div className="mb-8">
          <h3 className="text-lg md:text-xl font-semibold text-gray-800 mb-2">
            1.Not Enrolled Referrers
          </h3>
          <p className="mb-4">
            The Not Enrolled Referrer shall receive an amount up to ₹5,000 per
            referral, subject to TDS, within thirty (30) working days from the
            commencement date of the cohort of the Friend’s Program, up to a
            maximum of ten (10) referrals in a quarter.
          </p>
        </div>

        <div className="mb-8">
          <h3 className="text-lg md:text-xl font-semibold text-gray-800 mb-2">
            2. Enrolled Referrer/Expert Policy
          </h3>
          <p className="mb-4">
            The Reward receivable by the Enrolled Referrer or Expert, as the
            case may be, shall be determined based on the Program which the
            Referee has registered and paid for. For example, if the Enrolled
            Referrer has enrolled in a Deemed to be University CSE program in
            Data Science and the Referee has enrolled in an affiliated college
            in CSE, the Enrolled Referrer shall be eligible for a Reward
            attributable to the affiliated Program only. The Referee’s Reward
            shall be a fee waiver (if applicable) on the Program fees, which
            shall be applied at the balance payment stage and not at the time of
            block payment/caution amount payment.
          </p>
          <h3 className="text-lg md:text-xl font-semibold mb-2 text-black">
            3.The Enrolled Referrer
          </h3>
          <p className="mb-4">
            The Enrolled Referrer or the Expert will receive the Reward in the
            form of a cash payout, after deducting TDS as per applicable laws.
            The Enrolled Referrer or the Expert shall receive the referral
            benefits, subject to TDS, in a split of the first 50% within 45
            working days from the date of full payment of Program fees by the
            Referee, and the rest 50% would be released within 60 working days
            post the Referee’s program batch commencement date or once
            Collegementor receives the login list from the enrolled university.
            The Enrolled Referrer who earns a cash payout as a referral reward
            from Collegementor needs to submit their PAN account details to get
            the payment processed. PAN details form will be shared with the
            Enrolled Referrer by referral@collegementor.com to fill in the
            details and submit to Collegementor. All these benefits are
            applicable only for learners/experts and will not be applicable if
            the learner is a Collegementor employee.
          </p>
        </div>
        <div className="mb-8">
          <h3 className="text-lg md:text-xl font-semibold text-gray-800 mb-2">
            4. Terms Applicable:
          </h3>
          <p className="mb-4">
            Collegementor users who enroll again for another program shall be
            eligible for the aforementioned alumni discount of the program in
            which the user is enrolling. In this case, the user cannot use any
            referral code and will not be eligible for any referral reward. The
            Enrolled Referrer’s batch should also have commenced before the
            payout window if they are an enrolled learner in order to be
            eligible for the Enrolled Referrer benefits. The benefits under this
            Referral Policy cannot be clubbed with any other scholarship/fee
            waiver or other benefits. If the Referee has already availed of
            another benefit, the benefits of this Referral Policy shall not
            apply. For further clarity, please drop an email to
            referral@collegementor.com.
          </p>
          <p className="mb-4">
            If the Enrolled Referrer does not avail of the referral benefit by
            sharing PAN or bank details within 3 months from the date of the
            Referee’s batch commencement, the referral benefit will lapse and
            become void. Referee benefits are pre-applied at the time of fee
            payment except for programs from IIT Delhi, where the benefits are
            processed in the form of cashback. Both the Referee and Referrer
            shall be eligible for any referral benefit only if the Referral Code
            is used at the time of sign-up or at the time of application
            submission with Collegementor, before blocking the seats. The
            Referrer needs to submit their PAN/bank details only. Any third
            party PAN or bank account details cannot be accepted to avail
            referral reward. As per the new Sectiocome Tax Act by the Finance
            Act 2021, if you are a "specified person" as prescribed under
            section 206 AB, the tax deducted at source shall be at (a) 5% or (b)
            twice the rate applicable to referral fees; whichever is higher.
            (TAT for TDS certificate would be 45 days after QTR end (1, 2 & 3
            QTR) for 4th QTR (JFM) it would be 70 days). "Specified person"
            means a person who has not filed the returns of income for both of
            the two assessment years relevant to the two previous years
            immediately prior to the previous year in which tax is required to
            be deducted, for which the time limit for filing return of income
            under sub-section (1) of section 139 has expired; and the aggregate
            of tax deducted at source and tax collected at source in their case
            is rupees fifty thousand or more in each of these two previous
            years.
          </p>
          <p className="mb-4">
            The Income Tax Department has facilitated a link where businesses
            can check if any person is a 'specified person' and thereby deduct
            the relevant taxes to comply with the provisions of Section 206 AB.
          </p>
        </div>
        <div className="mb-8">
          <h3 className="text-lg md:text-xl font-semibold text-gray-800 mb-2">
            5. Fair Usage Requirements
          </h3>
          <p className="mb-4">
            Collegementor's Referral Policy has been designed to make Study
            India or Study Abroad a lucrative and economical option for the
            friends and family members of our admissions community. The Referrer
            must refrain from using their code publicly on social media
            platforms as this will lead to ineligibility for referral rewards.
            Referral invitation links with
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReferEarnTerms;
