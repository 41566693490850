import React from "react";
import "../styles/college_details_page.scss";

interface BulletinBoardProps {
  description: string;
  title: string;
}

const BulletinBoard = ({ title, description }: BulletinBoardProps) => {
  return (
    <div className="bulletin-board bg-white shadow-lg">
      <h4 className="text-base font-bold bg-[#113cc0] text-center text-white py-2">
        {title}
      </h4>
      <div
        className="text-sm text-gray-800 bulletin-board-list"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

export default BulletinBoard;
