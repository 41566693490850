import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import NavigationTabs from "../../components/exam-details/NavigationTabs";
import config from "../../util/config";
import "./exam-details-styles.scss";
import Banner from "../../components/exam-details/Banner";
import BannerImg from "../../images/exams/banner/exam-banner-img.jpg";

export interface Tab {
  name: string;
  title: string;
}

interface Action {
  icon: string;
  text: string;
  link: string;
}

interface Button {
  text: string;
  link: string;
  type: "primary" | "secondary";
}

interface BannerData {
  title: string;
  description: string;
  buttons: Button[];
  imageUrl: string;
}

const bannerData: BannerData = {
  title: "NEET",
  description:
    "NEET 2024 - Know Exam Date, Registration Process, Eligibility, Selection Criteria, Syllabus & More!",

  buttons: [
    { text: "Apply Now", link: "#apply", type: "primary" },
    { text: "Talk to Mentor", link: "#talk-to-mentor", type: "secondary" },
  ],
  imageUrl: BannerImg,
};

const ExamDetails: React.FC = () => {
  const { examName } = useParams<{ examName: string }>();
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTabs = async () => {
      try {
        const response = await fetch(
          `${config.apiUrl}/api/exam-details/tabnames`,
          {
            method: "GET",
            headers: { Accept: "*/*" },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch tab names");
        }

        const data = await response.json();
        setTabs(data);
      } catch (err) {
        console.error("Error fetching tabs:", err);
        setError("Unable to load tabs.");
      } finally {
        setLoading(false);
      }
    };

    fetchTabs();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error || tabs.length === 0) {
    return (
      <div className="container mx-auto text-center py-10">
        <p className="text-red-500 font-bold">
          {error || "No tabs available."}
        </p>
      </div>
    );
  }

  return (
    <div>
      <Banner data={bannerData} />
      <NavigationTabs tabs={tabs} examName={examName || ""} />
    </div>
  );
};

export default ExamDetails;
