import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../../util/config";

export const fetchLandingData = createAsyncThunk(
  "scholarship/fetchLandingData",
  async () => {
    const response = await axios.get(
      config.apiUrl + "/api/scholarship-loan/scholarship-landing"
    );
    return response.data?.data;
  }
);
