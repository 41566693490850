import { useScroll, useTransform } from "framer-motion";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

const START_THRESHOLD = 100;
const END_THRESHOLD = 500;

const useFloatItemAnimation = () => {
  const { scrollY } = useScroll();

  const checkpointRefs = useRef<(HTMLDivElement | null)[]>([]);
  const itemRef = useRef<HTMLDivElement>(null);
  console.log(checkpointRefs);

  const [positions, setPositions] = useState<Array<{ x: number; y: number }>>([
    { x: 0, y: 0 },
  ]);
  const [itemPos, setItemPos] = useState({ x: 0, y: 0 });

  const [isPulse, setIsPulse] = useState(false);

  const calculatePositions = () => {
    if (checkpointRefs.current.length > 0) {
      const positions = checkpointRefs.current.map((ref) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          return {
            x: rect.left + window.scrollX,
            y: rect.top + window.scrollY,
          };
        }
        return { x: 0, y: 0 };
      });
      setPositions(positions);
    }

    if (itemRef.current) {
      const itemRect = itemRef.current.getBoundingClientRect();
      setItemPos({
        x: itemRect.left + window.scrollX,
        y: itemRect.top + window.scrollY,
      });
    }
  };

  useLayoutEffect(() => {
    calculatePositions();

    const timeoutId = setTimeout(calculatePositions, 500);

    return () => clearTimeout(timeoutId);
  }, [checkpointRefs, itemRef]);

  let yBreakPoints: number[] = [];
  let yPathPoints: number[] = [];
  let xPathPoints: number[] = [];
  let scalePathPoints: number[] = [];

  positions.forEach((position, index) => {
    if (index == 0) {
      yBreakPoints.push(0);
      yBreakPoints.push(position.y - START_THRESHOLD);
    } else {
      yBreakPoints.push(position.y - END_THRESHOLD);
      yBreakPoints.push(position.y - START_THRESHOLD);
    }

    yPathPoints.push(position.y - itemPos.y);
    yPathPoints.push(position.y - itemPos.y);

    xPathPoints.push(position.x - itemPos.x);
    xPathPoints.push(position.x - itemPos.x);

    scalePathPoints.push(1);
    scalePathPoints.push(1);
  });

  useLayoutEffect(() => {
    const updatePulse = (latestScrollY: number) => {
      const checkpointIndex = yBreakPoints.findIndex(
        (yBreakPoint) => yBreakPoint > latestScrollY
      );

      if (checkpointIndex % 2 == 1) {
        if (!isPulse) setIsPulse(true);
      } else {
        if (isPulse) setIsPulse(false);
      }
    };

    const unsubscribe = scrollY.onChange(updatePulse);

    return () => unsubscribe();
  }, [scrollY, yBreakPoints, isPulse]);

  const y = useTransform(scrollY, yBreakPoints, yPathPoints);
  const x = useTransform(scrollY, yBreakPoints, xPathPoints);
  const scale = useTransform(scrollY, yBreakPoints, scalePathPoints);

  return {
    checkpointRefs,
    animationItemRef: itemRef,
    animatedItemProps: { x, y, scale },
    isPulse,
  };
};

export default useFloatItemAnimation;
