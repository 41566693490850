import { Link } from "react-router-dom";
import "./CareerDetailsStyles.scss";
const CertificationSection: React.FC<{ certifications: any }> = ({
  certifications,
}) => {
  if (!certifications) return null;

  return (
    <section>
      <div className="container mx-auto">
        <div className="bg-white relative">
          <div className="flex flex-col lg:flex-row justify-between md:pr-8 gap-6">
            <div className="flex flex-col w-full lg:w-4/5">
              <div className="flex max-md:flex-col max-md:w-full">
                <h3 className="text-xl max-md:text-center lg:text-2xl font-semibold text-white bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0">
                  {certifications.title}
                </h3>
              </div>
              <div
                className="text-grey text-base leading-7 flex flex-col gap-5 py-5 px-8 certificationsList"
                dangerouslySetInnerHTML={{
                  __html: certifications.description as string,
                }}
              ></div>
            </div>
            <div className="flex flex-col items-center justify-center w-full lg:w-1/5 relative bg-[#FFF1FA]">
              <div className="find-out-colleges relative mt-2">
                <img src="/images/course-details/FindOutColleges1.png" alt="" />
                <div className="absolute bottom-[1px] left-[15px]">
                  <img
                    src="/images/course-details/admission.png"
                    alt=""
                    className="mx-auto mb-4"
                  />
                  <Link
                    to={"/college-predictor"}
                    className="flex mx-auto  text-white py-1 text-sm px-3 bg-[#173CBA] rounded-full group"
                  >
                    <span className="mr-2">Check College Predictor now</span>
                    <div className="bg-[#0C9] text-[#fff] rounded-full p-1 group-hover:translate-x-2 transition-transform duration-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9.293 4.293a1 1 0 011.414 0L16 9.586a1 1 0 010 1.414l-5.293 5.293a1 1 0 01-1.414-1.414L13.586 11H4a1 1 0 110-2h9.586L9.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CertificationSection;
