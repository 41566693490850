import React, { useState, useEffect, useRef } from "react";

interface RevealFromBottomProps {
    className?: string
    children: React.ReactNode;
}
const RevealFromBottom: React.FC<RevealFromBottomProps> = ({ className = "", children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            const revealElement = ref.current
            if (revealElement) {
                const { top } = revealElement.getBoundingClientRect();
                const windowHeight = window.innerHeight;
                if (top < windowHeight) {
                    setTimeout(() => {
                        setIsVisible(true);
                    }, 500);
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll()

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return <div ref={ref} className={"overflow-hidden " + className}>
        <div className={`relative ${isVisible ? "" : "translate-y-[120%]"} transition-transform duration-1000 ease-in-out`}>{children}</div>
    </div>
}

export default RevealFromBottom