import React from "react";

interface Initiative {
  title: string;
  description: string;
}

const ResearchInitiatives = ({ initiatives }: { initiatives: Initiative }) => (
  <div>
    <h2>{initiatives.title}</h2>
    <div className="p-4 bg-white rounded shadow">
      <p dangerouslySetInnerHTML={{ __html: initiatives.description }} />
    </div>
  </div>
);

export default ResearchInitiatives;
