import React from "react";
import buldIcon from "../../images/courses/building-icon.svg";
import "./SuccessStrip.scss";
import { Link } from "react-router-dom";
import useLeadGeneration from "../../hooks/useLeadGeneration";

const SuccessStripContent = [
  {
    title: "Book your guided college tour instantly",
    button: "Campus Tour",
  },
];
const SuccessStrip: React.FC = () => {
  const { invokeLeadGeneration } = useLeadGeneration();

  const handleLeadGeneration = () => {
    invokeLeadGeneration(
      "course-landing",
      "applications-admissions-explore-college"
    );
  };
  return (
    <div className="bg-gradient-to-r from-blue-900 to-teal-500 flex py-8 my-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-row w-full">
          {SuccessStripContent.map((content) => (
            <div className="flex  md:flex-row gap-4 justify-between items-center w-full">
              <div className="flex success-heading flex-row gap-3 items-center">
                <img src={buldIcon} alt="building icon" className="w-11 h-11" />
                <h3 className="text-xl lg:text-2xl font-bold text-white m-0 xs:text-xs sm:text-xs capitalize">
                  {content.title}
                </h3>
              </div>
              <div className="flex success-btn flex-col">
              <button
        onClick={handleLeadGeneration}
                  className="button-styles white-color btn-style flex flex-row items-center justify-center gap-2 sm:text-xs sm:w-48] bg-[#173CBA] text-lg font-semibold border-2 border-white"
                >
                  {content.button}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SuccessStrip;
