import React, { useState } from "react";
import { useForm } from "react-hook-form";
import StepNavigationWrapper from "./common/StepNavigationWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import {
  nextStep,
  prevStep,
  updateForm,
} from "../../../../store/slices/registrationFormSlice";
import OptionGroup from "./common/OptionsCard/OptionGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
const NextHigherEducation = () => {
  const dispatch = useDispatch();
  const { firstName, lastName, email, mobileNumber, currentLevelOfStudy } =
    useSelector((state: RootState) => state.registrationFormSlice.data);

  const { step } = useSelector(
    (state: RootState) => state.registrationFormSlice
  );

  const { pafData } = useSelector((state: RootState) => state.auth);
  const { register, handleSubmit } = useForm();

  const [selectedCard, setSelectedCard] = useState<any>("");
  const [selectedSingle, setSelectedSingle] = useState<any>(null);

  const onSubmit = (data: any) => {
    dispatch(updateForm({ degree: selectedSingle }));
    dispatch(nextStep());
  };

  const handleCardClick = (card: string) => {
    console.log("CourseLookingFor - Message from Child: ", card);
    setSelectedCard(card);
  };

  const handleSingleSelectionChange = (selected: any) => {
    setSelectedSingle(selected);
  };

  const handleNext = () => {
    dispatch(updateForm({ higherEducation: selectedSingle }));
    dispatch(nextStep());
  };

  const handlePrev = () => {
    dispatch(prevStep());
  };
  return (
    <div className="flex flex-col w-auto gap-2">
      {" "}
      <StepNavigationWrapper
        onNext={handleSubmit(onSubmit)}
        showNext={false}
        showBack={true}
        stepName="Next Higher Education ?"
        stepDescription=""
      >
        {/* <PlaceholderChipGroup
        cards={COURSE_LEVEL}
        selectMode={"multiple"}
        handleCardSelect={handleCardClick}
      /> */}
        <div className="w-full p-4 overflow-auto">
          {/* old Implementation */}
          {/* <OptionGroup
          mode={"single"}
          options={COURSE_LEVEL}
          handleCardSelect={handleCardClick}
          cardSize={"40"}
        /> */}

          {/* <OptionGroup
          options={pafData?.lookingForDegree}
          mode="single"
          getTitle={(option) =>
            typeof option === "string" ? option : option.title
          }
          handleCardSelect={handleCardClick}
          cardSize={"40"}
        /> */}

          <OptionGroup
            options={pafData?.nextHigherEducation}
            mode="single"
            getTitle={(option) =>
              typeof option === "string" ? option : option.title
            }
            onSelectionChange={handleSingleSelectionChange}
            cardSize="52"
            localStorageKey="nextHigherEducation"
          />
          {/* <h3 className="mt-4">Selected Single Option (Full Object):</h3>
        <pre>{JSON.stringify(selectedSingle, null, 2)}</pre> */}
          {/* <div className="flex justify-center mt-10">
          <button
            type="button"
            onClick={handleNext}
            className="transition duration-0 hover:duration-150 p-3 border rounded-full bg-[#113cc0] text-white hover:bg-[#113cc0] hover:text-white  ease-in-out"
            style={{ padding: "8px 18px" }}
          >
            Continue
          </button>
        </div> */}

          <div className="flex items-center justify-center mt-10">
            {/* <button
            type="button"
            onClick={handleNext}
            className="transition duration-0 hover:duration-150 p-3 border rounded-full bg-[#113cc0] text-white hover:bg-[#113cc0] hover:text-white  ease-in-out"
            style={{ padding: "8px 18px" }}
          >
            Continue
          </button> */}
            <div className="flex flex-row items-center gap-2">
              <div className="flex items-center gap-2">
                {/* <button
                  className="flex items-center justify-center w-8 h-8 text-black bg-white rounded-full hover:text-green"
                  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                  onClick={handlePrev}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <span>Step {step} of 10 </span>
                <button
                  className="flex items-center justify-center w-8 h-8 text-black bg-white rounded-full hover:text-green"
                  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                  onClick={handleNext}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button> */}

                <button
                  className="px-2 py-2 m-3 text-sm font-semibold duration-200 rounded-full md:px-6 blue-color button-styles"
                  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                  onClick={handlePrev}
                >
                  Back
                </button>

                <button
                  className="px-2 py-2 m-3 text-sm font-semibold duration-200 rounded-full md:px-6 blue-color button-styles"
                  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </StepNavigationWrapper>
    </div>
  );
};

export default NextHigherEducation;
