import React from "react";

export const CareerDiscoveryBanner: React.FC = () => {
  return (
    <div className="px-20 py-16 w-full max-md:px-8 max-md:max-w-full bg-gradient-to-b from-[#EDFCFF] to-[#89C9ED] max-md:py-8">
      <div className="flex justify-between max-md:flex-col">
        <div className="flex flex-col w-4/12 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
            <h1 className="text-3xl font-light leading-10 text-black max-md:max-w-full">
              Discover <span className="font-bold">Your IKIGAI</span>
              <br /> and Your Ideal Career
            </h1>
            <p className="mt-3 text-lg leading-8 text-black max-md:max-w-full">
              Receive expert guidance, counseling, and mentorship to uncover a
              future path that you'll both enjoy and excel in. Start your
              journey toward a fulfilling career today!
            </p>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
          <img
            loading="lazy"
            src="/images/career-discovery/career-discovery-banner.png"
            alt="Career Discovery Illustration"
            className="object-contain grow w-full rounded-none aspect-[1.21] max-md:mt-10 max-md:max-w-full md:h-[480px]"
          />
        </div>
      </div>
    </div>
  );
};
