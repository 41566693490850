import React from "react";

interface GenericSectionProps {
  title: string;
  description: string | FAQItem[];
}

interface FAQItem {
  question: string;
  answer: string;
}

const GenericSection: React.FC<GenericSectionProps> = ({
  title,
  description,
}) => {
  return (
    <div className="flex flex-col gap-3">
      <h3 className="text-3xl font-bold text-blue">{title}</h3>
      {typeof description === "string" ? (
        <div
          className="text-black text-base examDetailsTable examDetailsList flex flex-col gap-4"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      ) : (
        <div className="text-red-500">Invalid content format</div>
      )}
    </div>
  );
};

export default GenericSection;
