import React, { useEffect, useState } from "react";
import MentorBanner from "./components/MentorBanner";
import FeaturedMentors from "./components/FeaturedMentors";
import { MentorMenteeMatch } from "./components/MentorMenteeMatch";
import { FAQSection } from "./components/FAQSection";
import { NoteSection } from "./components/NoteSection";
import { PricingPlans } from "./components/PricingPlans";
import { MentorGrid } from "./components/MentorGrid";
import { SpeakToMentors } from "./components/SpeakToMentors";
import MentorNetwork from "./components/MentorNetwork";
import ExploreMore from "./components/ExploreMore";
import config from "../../util/config";

export interface Country {
  countryId: number;
  countryName: string;
}

export interface Mentor {
  id: number;
  name: string;
  role: string;
  experience: string;
  worksOn: string | null;
  imageUrl: string;
  location: string | null;
  country: Country;
  featured: boolean;
}

export const Mentors: React.FC = () => {
  const [mentors, setMentors] = useState<Mentor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    
    const fetchMentors = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          config.apiUrl + "/api/auth/mentors/mentorList"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch mentor data");
        }
        const responseData = await response.json();
        setMentors(responseData.data); // Access the "data" field from API response
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMentors();
        
  }, []);

 

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  
  return (
    <div>
    {!loading ? <div className="mentors-section">
      <MentorBanner />
      <FeaturedMentors mentors={mentors} />
      <MentorMenteeMatch />
      <MentorNetwork />
      <SpeakToMentors />
      <PricingPlans />
      <NoteSection />
      <ExploreMore />
      <FAQSection />
      <MentorGrid />
    </div> : <>Loading....</> }</div>
   
  );
};
