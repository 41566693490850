import React, { useState } from "react";

interface FAQItem {
  question: string;
  answer: string;
}

interface FAQSectionProps {
  title: string;
  faqs: FAQItem[];
}

const FAQSection: React.FC<FAQSectionProps> = ({ title, faqs }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(0);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="py-5">
      <h3 className="text-3xl font-bold text-blue">{title}</h3>
      <div className="flex flex-col gap-4">
        {faqs.map((faq, index) => (
          <div key={index} className="border-b last:border-b-0">
            <button
              onClick={() => toggleFAQ(index)}
              className="w-full text-black text-left font-semibold py-2"
            >
              <div className="flex items-center gap-3">
                <span className="text-xl bg-blue text-white rounded-md h-6 w-6 text-center flex justify-center items-center">
                  {openIndex === index ? "−" : "+"}
                </span>
                <span>{faq.question}</span>
              </div>
            </button>
            {openIndex === index && (
              <div className="px-4 py-2">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
