import React from "react";

const RegulatoryProfessional = () => {
  return (
    <div className="px-20 py-10 w-full max-md:px-5 max-md:max-w-full bg-[#F4F4F4] my-3">
      <h3 className="text-[#173CBA] text-center text-[36px] max-md:text-[32px] font-light">
        Regulatory & Professional Councils in India
      </h3>
      <p className="text-black text-[20px] md:mx-[100px] md:px-10">
        Professional councils are responsible for recognition of courses,
        promotion of professional institutions andproviding grants to
        undergraduate programmes and various awards. The statutory professional
        councils are
      </p>
      <div className=" w-full border-t border-[#747474] border-2 mt-4"></div>
      <div className="w-full p-4 mt-6">
        <div className="flex flex-col md:flex-row  ">
          <ul className="flex-1 list-disc list-inside space-y-2 px-4">
            <li>University Grants Commission (UGC)</li>
            <li>Association of Indian Universities (AIU)</li>
            <li>All India Council for Technical Education (AICTE)</li>
            <li>National Medical Commission (NMC)</li>
            <li>Indian Council for Agricultural Research (ICAR)</li>
            <li>National Council for Teacher Education (NCTE)</li>
            <li>Dental Council of India (DCI)</li>
            <li>Pharmacy Council of India (PCI)</li>
          </ul>

          <ul className="flex-1 list-disc list-inside space-y-2 px-4">
            <li>Indian Nursing Council (INC)</li>
            <li>Bar Council of India (BCI)</li>
            <li>Central Council for Research in Homoeopathy (CCRH)</li>
            <li>Central Council for Indian Medicine (CCIM)</li>
            <li>National Commission for Indian System of Medicine (NCISM)</li>
            <li>Council of Architecture (CoA)</li>
          </ul>

          <ul className="flex-1 list-disc list-inside space-y-2 px-4">
            <li>Rehabilitation Council of India (RCI)</li>
            <li>Mahatma Gandhi National Council of Rural Education (MGNCRE)</li>
            <li>Veterinary Council of India (VCI)</li>
            <li>
              National Council of Vocational Education and Training (NCVET)
            </li>
            <li>Institute of Chartered Accountants of India (ICAI)</li>
            <li>Indian Council of Agricultural Research (ICAR)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RegulatoryProfessional;
