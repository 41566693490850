import React from "react";
import CutoffTable2024 from "../cutoffs/CutoffTable2024";
import CutoffTable2023 from "../cutoffs/CutoffTable2023";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import CutoffLatestUpdates from "../cutoffs/CutoffLatestUpdates";
import ApplicationForms from "../sidebar/ApplicationForms";
import CoursesList from "../sidebar/CoursesList";

interface CutoffTabProps {
  cutoffData: {
    key_1?: {
      title: string;
      description: string;
    };
    key_2?: {
      title: string;
      description: string;
    };
    key_0?: {
      title: string;
      description: string;
    };
    similarColleges?: Array<{
      image: string;
      name: string;
      logo: string;
      location: string;
      title: string;
    }>;
  };
}

const CutoffTab: React.FC<CutoffTabProps> = ({ cutoffData }) => {
  const { key_1, key_2, key_0, similarColleges } = cutoffData;

  return (
    <div className="py-10 bg-[#f4f4f4]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-9/12">
            {key_0 && (
              <CutoffLatestUpdates
                title={key_0.title}
                description={key_0.description}
              />
            )}
            {key_1 && (
              <CutoffTable2024
                title={key_1.title}
                description={key_1.description}
              />
            )}
            {key_2 && (
              <CutoffTable2023
                title={key_2.title}
                description={key_2.description}
              />
            )}
          </div>
          <div className="w-full lg:w-3/12 hidden lg:block">
            <div className="flex flex-col gap-10">
              <ApplicationForms />
              <CoursesList />
            </div>
          </div>
        </div>
        {similarColleges && similarColleges.length > 0 && (
          <SimilarEngineeringColleges colleges={similarColleges} />
        )}
      </div>
    </div>
  );
};

export default CutoffTab;
