import React from "react";
import AboutNEETSection from "./Overview/AboutNEETSection";
import CoursesSection from "./Overview/CoursesSection";
import HighlightsSection from "./Overview/HighlightsSection";
import ImportantDatesSection from "./Overview/ImportantDatesSection";
import ExamUpdates from "./ExamUpdates"; // Import the ExamUpdates component

interface Section {
  title: string;
  description: string;
  imageUrl?: string;
  order: string;
}

interface OverviewTabProps {
  data: { [key: string]: Section };
}

const OverviewTab: React.FC<OverviewTabProps> = ({ data }) => {
  const sortedSections = Object.keys(data)
    .map((key) => ({ key, ...data[key] }))
    .sort((a, b) => parseInt(a.order) - parseInt(b.order));

  return (
    <div className="flex flex-col mx-auto">
      {sortedSections.map((section, index) => {
        switch (section.key) {
          case "key_0":
            return (
              <div key={section.key}>
                <AboutNEETSection
                  title={section.title}
                  description={section.description}
                  imageUrl={section.imageUrl}
                />
                <ExamUpdates />
              </div>
            );
          case "key_1":
            return (
              <div key={section.key}>
                <CoursesSection
                  title={section.title}
                  description={section.description}
                />
              </div>
            );
          case "key_2":
            return (
              <div key={section.key}>
                <HighlightsSection
                  title={section.title}
                  description={section.description}
                />
              </div>
            );
          case "key_3":
            return (
              <div key={section.key}>
                <ImportantDatesSection
                  title={section.title}
                  description={section.description}
                />
              </div>
            );
          default:
            return (
              <div
                key={section.key}
                className="p-4 border rounded-md text-red-500"
              >
                Unknown section: {section.title}
              </div>
            );
        }
      })}
    </div>
  );
};

export default OverviewTab;
