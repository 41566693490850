import { useSelector } from "react-redux";
import FAQV2 from "../../../../components/FAQV2";
import { RootState } from "../../../../store/store";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import LeadGeneration from "../../../../components/LeadGeneration";

const Banks = () => {
  const loans = useSelector((state: RootState) => state.educationLoan.loans);

  const [leadGenerationPopupIsOpen, setLeadGenerationPopupIsOpen] =
    useState(false);

  return (
    <div className="bg-pink-light p-8">
      <h1 className="text-4xl font-bold text-blue">
        Top Banks & NBFC for Education Loan
      </h1>
      <p className="mt-2 mb-4">
        Major banks and NBFCs offer specialized education loans with a range of
        benefits and efficient approval processes.
      </p>
      <FAQV2
        questionsAndAnswers={loans.map((item) => ({
          question: `${item.bankName} Education Loan`,
          answer: (
            <div className="html-content">
              {parse(item.description)}
              <button
                onClick={() => setLeadGenerationPopupIsOpen(true)}
                className="px-4 py-2 rounded text-base text-white bg-green block w-fit mt-2"
              >
                Let's Talk to Loan Mentor{" "}
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          ),
        }))}
      />
    </div>
  );
};

export default Banks;
