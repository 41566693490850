import React, { useState, useRef, useEffect } from "react";
import "./ContactBar.scss";
import DataJson from "./DataJson3";

interface Data {
  [key: string]: {
    [key: string]: string[] | { [key: string]: string[] };
  };
}

type DataKeys = keyof typeof DataJson;

const menuItems = Object.keys(DataJson) as DataKeys[];

const ContactBar: React.FC = () => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const megamenuRef = useRef<HTMLDivElement>(null);
  const navItemRef = useRef<HTMLUListElement>(null);
  const subMenuRefs = useRef<(HTMLUListElement | null)[]>([]);
  const isSmallScreen = window.innerWidth < 1024;
  const mainItems = isSmallScreen
    ? menuItems.slice(0, 4)
    : menuItems.slice(0, 6);
  const moreItems = isSmallScreen ? menuItems.slice(4) : menuItems.slice(6);
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [selectedMoreItem, setSelectedMoreItem] = useState<string | null>(
    moreItems[0]
  );

  const renderItems = (data: any) => {
    return Object.entries(data).map(([key, value], index) => {
      if (Array.isArray(value)) {
        return (
          <div key={key} className="pr-8">
            <h4 className="font-bold superheader py-4 ">{key}</h4>
            <ul
              className=" border-l-4 border-l-[#E0E0E0] submegamenu list-none p-0 ml-4 pl-2"
              ref={(el) => (subMenuRefs.current[index] = el)}
            >
              {value.map((item: any, idx: number) => (
                <li
                  key={idx}
                  className="listtabs py-1 hover:font-bold hover:text-[#173CBA]"
                >
                  <a href={item.url} className="hover:text-[#173CBA] ">
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        );
      }

      if (typeof value === "object" && value !== null) {
        return (
          <div key={key} className="pr-8">
            <h4 className="font-bold superheader py-4 ">{key}</h4>
            <div className="ml-4 border-l-4 border-l-[#E0E0E0] pl-2">
              {Object.entries(value).map(([subKey, subValue]) => (
                <div key={subKey} className="group">
                  <h4 className="font-bold subheader text-[#0C9]">{subKey}</h4>
                  <ul
                    className=" submegamenu list-none p-0 "
                    ref={(el) => (subMenuRefs.current[index] = el)}
                  >
                    {subValue.map((item: any, idx: number) => (
                      <li
                        key={idx}
                        className="listtabs py-1 hover:font-bold hover:text-[#173CBA]"
                      >
                        <a href={item.url} className="hover:text-[#173CBA]">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            {key.includes("Exams") && (
              <button className="ml-4 mt-2 text-white bg-[#173CBA] px-2 py-1 rounded-3xl">
                View All
              </button>
            )}
          </div>
        );
      }

      return null;
    });
  };

  const handleMouseEnter = (item: string) => {
    setHoveredItem(item);
    setShowMoreMenu(false);
  };

  const handleMouseLeave = (event: React.MouseEvent) => {
    const target = event.relatedTarget;

    if (
      target instanceof Node &&
      !navItemRef.current?.contains(target) &&
      !megamenuRef.current?.contains(target)
    ) {
      setHoveredItem(null);
      setShowMoreMenu(false);
    }
  };
  const handleMoreHover = () => {
    setHoveredItem(null);
    setShowMoreMenu(true);
  };
  const handleMoreItemHover = (item: string) => {
    setSelectedMoreItem(item);
  };
  return (
    <div className="relative contact-nav hidden md:block bg-[#113CC0] max-md:px-5">
      <div
        className="container flex items-center justify-center md:gap-1 lg:gap-5 mx-auto font-medium text-white max-md:flex-wrap"
        onMouseLeave={handleMouseLeave}
      >
        <div className="contact-nav-left flex md:gap-[4px] lg:gap-[24px] justify-center items-center text-sm whitespace-nowrap">
          <nav className="items-center navbar-contact">
            <ul
              className="nav-list-contact flex md:gap-[4px] lg:gap-[16px]"
              ref={navItemRef}
            >
              {mainItems.map((item, index) => (
                <li
                  key={index}
                  className="nav-item"
                  onMouseEnter={() => handleMouseEnter(item)}
                >
                  <a
                    href={`/${item.toLowerCase()}`}
                    className="flex items-center gap-[4px] nav-link-contact py-4"
                  >
                    {item}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M14.0324 6.15752C13.9626 6.08722 13.8797 6.03143 13.7883 5.99335C13.6969 5.95527 13.5989 5.93567 13.4999 5.93567C13.4009 5.93567 13.3028 5.95527 13.2114 5.99335C13.12 6.03143 13.0371 6.08722 12.9674 6.15752L9.53236 9.59251C9.46264 9.66281 9.37969 9.7186 9.28829 9.75668C9.1969 9.79475 9.09887 9.81436 8.99986 9.81436C8.90085 9.81436 8.80282 9.79475 8.71143 9.75668C8.62004 9.7186 8.53708 9.66281 8.46736 9.59251L5.03236 6.15752C4.96264 6.08722 4.87969 6.03143 4.78829 5.99335C4.6969 5.95527 4.59887 5.93567 4.49986 5.93567C4.40085 5.93567 4.30282 5.95527 4.21143 5.99335C4.12003 6.03143 4.03708 6.08722 3.96736 6.15752C3.82767 6.29804 3.74927 6.48813 3.74927 6.68627C3.74927 6.88441 3.82767 7.07449 3.96736 7.21502L7.40986 10.6575C7.83174 11.0789 8.40361 11.3155 8.99986 11.3155C9.59611 11.3155 10.168 11.0789 10.5899 10.6575L14.0324 7.21502C14.1721 7.07449 14.2505 6.88441 14.2505 6.68627C14.2505 6.48813 14.1721 6.29804 14.0324 6.15752Z"
                          fill="#ffffff"
                        />
                      </svg>
                    </span>
                  </a>

                  {hoveredItem === item && (
                    <div
                      ref={megamenuRef}
                      className="megamenu absolute px-4 left-0 z-1000000 w-screen overflow-y-auto bg-white shadow-lg top-full text-[#373737] rounded-md grid grid-cols-3 gap-4 pb-6"
                    >
                      {renderItems(DataJson[item as keyof typeof DataJson])}
                    </div>
                  )}
                </li>
              ))}

              {moreItems.length > 0 && (
                <li className="nav-item" onMouseEnter={handleMoreHover}>
                  <a className="flex items-center gap-[4px] nav-link-contact py-4 cursor-pointer">
                    More
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M14.0324 6.15752C13.9626 6.08722 13.8797 6.03143 13.7883 5.99335C13.6969 5.95527 13.5989 5.93567 13.4999 5.93567C13.4009 5.93567 13.3028 5.95527 13.2114 5.99335C13.12 6.03143 13.0371 6.08722 12.9674 6.15752L9.53236 9.59251C9.46264 9.66281 9.37969 9.7186 9.28829 9.75668C9.1969 9.79475 9.09887 9.81436 8.99986 9.81436C8.90085 9.81436 8.80282 9.79475 8.71143 9.75668C8.62004 9.7186 8.53708 9.66281 8.46736 9.59251L5.03236 6.15752C4.96264 6.08722 4.87969 6.03143 4.78829 5.99335C4.6969 5.95527 4.59887 5.93567 4.49986 5.93567C4.40085 5.93567 4.30282 5.95527 4.21143 5.99335C4.12003 6.03143 4.03708 6.08722 3.96736 6.15752C3.82767 6.29804 3.74927 6.48813 3.74927 6.68627C3.74927 6.88441 3.82767 7.07449 3.96736 7.21502L7.40986 10.6575C7.83174 11.0789 8.40361 11.3155 8.99986 11.3155C9.59611 11.3155 10.168 11.0789 10.5899 10.6575L14.0324 7.21502C14.1721 7.07449 14.2505 6.88441 14.2505 6.68627C14.2505 6.48813 14.1721 6.29804 14.0324 6.15752Z"
                          fill="#ffffff"
                        />
                      </svg>
                    </span>
                  </a>

                  {showMoreMenu && (
                    <div
                      ref={megamenuRef}
                      className="megamenu absolute flex flex-row left-0 z-1000000 w-screen overflow-y-auto bg-white shadow-lg top-full text-[#373737] rounded-md  gap-24 pb-6"
                    >
                      <div className="more-menu-left col-span-1 py-12">
                        {moreItems.map((item, index) => (
                          <div
                            key={index}
                            onMouseEnter={() => handleMoreItemHover(item)}
                            className={`whitespace-nowrap flex items-center justify-between font-bold py-2 px-12 cursor-pointer rounded-md border border-gray-300 transition-colors duration-200 ${
                              selectedMoreItem === item
                                ? "text-[#173CBA] bg-gray-100"
                                : "text-black hover:bg-gray-200"
                            }`}
                            style={{ width: "100%" }}
                          >
                            {item}
                            <span className="inline-flex items-center transition-transform duration-200 transform -rotate-90 text-black">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                className="ml-auto"
                              >
                                <path
                                  d="M14.0324 6.15752C13.9626 6.08722 13.8797 6.03143 13.7883 5.99335C13.6969 5.95527 13.5989 5.93567 13.4999 5.93567C13.4009 5.93567 13.3028 5.95527 13.2114 5.99335C13.12 6.03143 13.0371 6.08722 12.9674 6.15752L9.53236 9.59251C9.46264 9.66281 9.37969 9.7186 9.28829 9.75668C9.1969 9.79475 9.09887 9.81436 8.99986 9.81436C8.90085 9.81436 8.80282 9.79475 8.71143 9.75668C8.62004 9.7186 8.53708 9.66281 8.46736 9.59251L5.03236 6.15752C4.96264 6.08722 4.87969 6.03143 4.78829 5.99335C4.6969 5.95527 4.59887 5.93567 4.49986 5.93567C4.40085 5.93567 4.30282 5.95527 4.21143 5.99335C4.12003 6.03143 4.03708 6.08722 3.96736 6.15752C3.82767 6.29804 3.74927 6.48813 3.74927 6.68627C3.74927 6.88441 3.82767 7.07449 3.96736 7.21502L7.40986 10.6575C7.83174 11.0789 8.40361 11.3155 8.99986 11.3155C9.59611 11.3155 10.168 11.0789 10.5899 10.6575L14.0324 7.21502C14.1721 7.07449 14.2505 6.88441 14.2505 6.68627C14.2505 6.48813 14.1721 6.29804 14.0324 6.15752Z"
                                  fill="#000000"
                                />
                              </svg>
                            </span>
                          </div>
                        ))}
                      </div>

                      <div className="more-menu-right col-span-2">
                        {selectedMoreItem && (
                          <div className="nested-megamenu-content grid md:grid-cols-2 lg:grid-cols-3 px-2">
                            {renderItems(
                              DataJson[
                                selectedMoreItem as keyof typeof DataJson
                              ]
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </li>
              )}

              <li className="nav-item hidden lg:block">
                <a
                  href={`/study-abroad`}
                  className="flex items-center gap-[4px] nav-link-contact py-4"
                >
                  Study Abroad
                </a>
              </li>
              <li className="nav-item">
                <a
                  href={`/viksit-bharat-scholarship`}
                  className="flex items-center gap-[4px] nav-link-contact py-4"
                >
                  Viksit Bharat Scholsorhip Test - 1Cr
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default ContactBar;
