import React from "react";
import RankingBanner from "./components/RankingBanner";
import RankingInfo from "./components/RankingInfo";

const RankingFramework = () => {
  return (
    <div>
      <RankingBanner />
      <RankingInfo />
    </div>
  );
};

export default RankingFramework;
