import React, { SVGProps, useState } from "react";
import LeadGeneration from "../../../LeadGeneration";

export interface UniversityCardProps {
  logo: string;
  name: string;
  description: string;
  time: string;
}
export function MaterialSymbolsLightClose(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z"
      ></path>
    </svg>
  );
}
const UniversityCard = ({
  logo,
  name,
  description,
  time,
}: UniversityCardProps) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div className="card flex justify-between bg-[#fff] p-3 border-b-[1px] border-[#DAD5D5]">
      <div className="card-logo w-9 mr-4">
        <img src={logo} alt="University Logo" />
      </div>
      <div className="card-content w-48">
        <h2 className="text-[#383838] text-xs font-semibold">{name}</h2>
        <p className="text-[#747474] text-xs font-normal">{description}</p>
        <p className="text-[10px] text-[#747474] font-normal">Time: {time}</p>
        <div className="flex justify-end">
          <button
            className="w-16 h-6 rounded-3xl bg-[#173CBA] text-white"
            onClick={handlePopup}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default UniversityCard;
