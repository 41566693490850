import React, { useEffect, useState } from "react";
import OptionCard from "./OptionCard";

type Option = string | { [key: string]: any };

type OptionGroupProps = {
  options: Option[];
  mode: "single" | "multiple";
  getTitle: (option: Option) => string;
  onSelectionChange: (selected: Option | Option[]) => void;
  cardSize: string;
  localStorageKey: string;
};

const OptionGroup: React.FC<OptionGroupProps> = ({
  options,
  mode,
  getTitle,
  onSelectionChange,
  cardSize,
  localStorageKey,
}) => {
  // State for single-select or multiselect
  const [selectedOption, setSelectedOption] = useState<
    Option | Option[] | null
  >(mode === "single" ? null : []);

  // Load initial state from localStorage
  useEffect(() => {
    const savedSelection = JSON.parse(
      localStorage.getItem("paf_latest") || "{}"
    );
    const existingData = JSON.parse(localStorage.getItem("paf_latest") || "{}");
    console.log("existingData", existingData);
    console.log("existingData[localStorageKey]", existingData[localStorageKey]);
    if (existingData[localStorageKey]) {
      const parsedSelection = existingData[localStorageKey];

      setSelectedOption(parsedSelection);
      onSelectionChange(parsedSelection);
    }
  }, [localStorageKey, onSelectionChange]);

  const handleOptionClick = (option: Option) => {
    if (mode === "single") {
      setSelectedOption(option);
      onSelectionChange(option); // Notify parent of selected object
    } else {
      if (Array.isArray(selectedOption)) {
        const isAlreadySelected = selectedOption.includes(option);
        const newSelected = isAlreadySelected
          ? selectedOption.filter((selected) => selected !== option) // Deselect if already selected
          : [...selectedOption, option]; // Add new option
        setSelectedOption(newSelected);
        onSelectionChange(newSelected); // Notify parent of selected objects
      }
    }
  };

  // Helper function to check if an option is selected
  const isSelected = (option: Option) => {
    if (mode === "single") {
      return selectedOption === option;
    } else {
      return Array.isArray(selectedOption) && selectedOption.includes(option);
    }
  };

  const hasSelection =
    mode === "single"
      ? selectedOption !== null
      : Array.isArray(selectedOption) && selectedOption.length > 0;

  console.log("selectedOption", selectedOption);

  return (
    <div className="container mx-auto">
      {/* <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"> */}
      {/* <div className="flex flex-wrap justify-start gap-4"> */}
      {/* <div className="grid grid-flow-row auto-rows-auto gap-4 grid-cols-[repeat(auto-fit,minmax(120px,1fr))]"> */}
      <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3 auto-rows-auto">
        {options?.map((option, index) => (
          <OptionCard
            key={index}
            title={getTitle(option)} // Get title using the getTitle function
            // color={['#4CAF50', '#FF5722', '#03A9F4'][index % 3]} // Assign color based on index
            isSelected={isSelected(option)} // Check if the option is selected
            onClick={() => handleOptionClick(option)} // Handle click
            cardSize={cardSize}
            image={typeof option === "object" ? option.streamIcon : ""}
            defaultColor="white" // Default color for all options
            nonSelectedColor="#e6f9f5" // Background color for non-selected options when there’s a selection
            highlightColor="white" // Highlight color for selected option
            hasSelection={hasSelection}
          />
        ))}
      </div>
    </div>
  );
};

export default OptionGroup;
