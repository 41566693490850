import React from "react";

const DiscoveringGoals = () => {
  return (
    <div className="px-20 py-9 w-full bg-[#173CBA] max-md:px-5 max-md:max-w-full my-1">
      <div className="flex gap-5 max-md:flex-col justify-between px-8">
        <div className="flex flex-col w-[300px] max-md:ml-0 max-md:w-full">
          <div className="flex grow gap-5  text-xl leading-7 text-white max-md:mt-10">
            <img
              loading="lazy"
              src="/images/career-discovery/bulb-svg.svg"
              alt="Discovering goals icon"
              className="object-contain shrink-0 aspect-[0.94] w-[68px]"
            />
            <div className="my-auto">
              Discovering Your
              <br />
              Goals
            </div>
          </div>
        </div>

        <div className="flex flex-col w-[300px] max-md:ml-0 max-md:w-full">
          <div className="flex grow gap-5  text-xl leading-7 text-white max-md:mt-10">
            <img
              loading="lazy"
              src="/images/career-discovery/home-svg.svg"
              alt="Choosing universities icon"
              className="object-contain shrink-0 aspect-[1.1] w-[75px]"
            />
            <div className="my-auto">Choosing Your Best-Fit Universities</div>
          </div>
        </div>

        <div className="flex flex-col w-[300px] max-md:ml-0 max-md:w-full">
          <div className="flex grow gap-5  text-xl leading-7 text-white max-md:mt-10">
            <img
              loading="lazy"
              src="/images/career-discovery/note.svg"
              alt="Perfecting applications icon"
              className="object-contain shrink-0 aspect-[0.84] w-[61px]"
            />
            <div className="my-auto">
              Perfecting Your
              <br />
              Applications
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscoveringGoals;
