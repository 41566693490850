import React from "react";

export const NoteSection = () => {
  return (
    <div className="bg-gradient-to-r from-[#173CBA] to-[#06AFA9] text-white p-4   ">
      <div className="container flex items-center">
        <div className="bg-[#00C799] text-white font-bold py-2 px-6 rounded-full mr-4">
          NOTE
        </div>

        <div>
          <h3 className="font-semibold text-lg">
            Free for Government - Studying Students
          </h3>
          <p className="text-sm">
            Upon confirmation of Identity proof, Eligible students are paired
            with near-peer mentors
          </p>
        </div>
      </div>
    </div>
  );
};
